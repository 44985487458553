import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../Containers/Loader';
import Oops from '../Containers/Oops';
import { MdOutlineClose } from 'react-icons/md';

// import Modal from 'react-bootstrap/Modal';

ReactSession.setStoreType("localStorage");

const DigiPartnerPharmacyOrders = () => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState(false);
  const [digiPartOrders, setDigiPartOrders] = useState(null);
  const [fillDigiPartOrders, setFillDigiPartOrders] = useState(null);
  const [dpPharmacyOrderDetails, setDpPharmacyOrderDetails] = useState(null);
  const [dpPharmacyOrderItems, setDpPharmacyOrderItems] = useState(null);
  const [orderTotals, setOrderTotals] = useState([0, 0, 0, 0]);
  const [newOrderItems, setNewOrderItems] = useState([]);

  const [loader, setLoader] = useState(false);
  const [QRCode, setQRCode] = useState(null);

  const getQRCode = () => {
    setLoader(true);
    var apiURL = "http://43.205.199.21/digiPartnerAPI?getQRCode=1&user_Id=" + ReactSession.get("id");
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      
      if (res.status === "success") {
        window.scrollTo(0, 0);
        setQRCode(res.qrCode);
        document.getElementById('Dialogue').classList.remove("hide");
      }
      else {
        // console.log("Already Exist");
        continueWithOrder();
      }
      setLoader(false);
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  const continueWithOrder = () => {
    // document.getElementById('Dialogue').classList.add("hide");
    setQRCode(null);
    createOrder();
  }

  const createOrder = () => {
    setLoader(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        pharmachyOrderAcknowledge: 1,
        dp_id: ReactSession.get("id"),
        digiOrderItems: newOrderItems,
        vendorDetails: dpPharmacyOrderDetails[0],
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "validityExpired")
        {
          alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
        }

        if (data.status === 'success') {
          alert("Order Confirmed Successfully");
          document.getElementById('Dialogue').classList.add("hide");
          ReactSession.set("pendingBill", true);
          navigate("/DPDashboard");
        }
        else {
          alert("Error Placing Orders");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const calculateIfInclude = (pro_id, isInclude) => {
    var newItems = [];
    var Tqty = 0;
    var Tmrp = 0;
    var Tprice = 0;
    for (let i = 0; i < newOrderItems.length; i++) {
      if (pro_id !== newOrderItems[i][0]) {
        newItems.push([newOrderItems[i][0], newOrderItems[i][1], newOrderItems[i][2], newOrderItems[i][3], newOrderItems[i][4], newOrderItems[i][5], newOrderItems[i][6], newOrderItems[i][7], newOrderItems[i][8]]);
        Tqty += parseInt(newOrderItems[i][1]);
        Tmrp += parseInt(newOrderItems[i][1]) * newOrderItems[i][2];
        Tprice += parseInt(newOrderItems[i][1]) * newOrderItems[i][3];
      }
      else if (isInclude) {
        newItems.push([newOrderItems[i][0], newOrderItems[i][1], newOrderItems[i][2], newOrderItems[i][3], 0, newOrderItems[i][5], newOrderItems[i][6], newOrderItems[i][7], newOrderItems[i][8]]);
        Tqty += parseInt(newOrderItems[i][1]);
        Tmrp += parseInt(newOrderItems[i][1]) * newOrderItems[i][2];
        Tprice += parseInt(newOrderItems[i][1]) * newOrderItems[i][3];
      }
      else {
        newItems.push([newOrderItems[i][0], newOrderItems[i][1], newOrderItems[i][2], newOrderItems[i][3], 1, newOrderItems[i][5], newOrderItems[i][6], newOrderItems[i][7], newOrderItems[i][8]]);
      }
      setNewOrderItems(newItems);
      setOrderTotals([orderTotals[0], Tqty, Tmrp, Tprice]);
    }
  }

  const calculateNewValue = (e, pro_id) => {
    var qty = e.target.value;
    var newItems = [];
    var Tqty = 0;
    var Tmrp = 0;
    var Tprice = 0;
    for (let i = 0; i < newOrderItems.length; i++) {

      if (pro_id !== newOrderItems[i][0] && newOrderItems[i][4] === 0) {
        newItems.push([newOrderItems[i][0], newOrderItems[i][1], newOrderItems[i][2], newOrderItems[i][3], newOrderItems[i][4], newOrderItems[i][5], newOrderItems[i][6], newOrderItems[i][7], newOrderItems[i][8]]);
        Tqty += parseInt(newOrderItems[i][1]);
        Tmrp += parseInt(newOrderItems[i][1]) * newOrderItems[i][2];
        Tprice += parseInt(newOrderItems[i][1]) * newOrderItems[i][3];
      }
      else if (newOrderItems[i][4] === 0) {
        newItems.push([newOrderItems[i][0], parseInt(qty), newOrderItems[i][2], newOrderItems[i][3], newOrderItems[i][4], newOrderItems[i][5], newOrderItems[i][6], newOrderItems[i][7], newOrderItems[i][8]]);
        Tqty += parseInt(qty);
        Tmrp += parseInt(qty) * newOrderItems[i][2];
        Tprice += parseInt(qty) * newOrderItems[i][3];
      }
      else {
        newItems.push([newOrderItems[i][0], parseInt(qty), newOrderItems[i][2], newOrderItems[i][3], newOrderItems[i][4], newOrderItems[i][5], newOrderItems[i][6], newOrderItems[i][7], newOrderItems[i][8]]);
      }
      setNewOrderItems(newItems);
      setOrderTotals([orderTotals[0], Tqty, Tmrp, Tprice]);
      // console.log(newOrderItems[i]);
    }
  }

  const getDPOrders = (dtFrom = null, dtTo = null) => {
    setLoader(true);
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?digiOrders=1&user_Id=' + ReactSession.get('id')
    if (dtFrom != null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;

    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "success") {
        setDigiPartOrders(resp.allOrders);
        setFillDigiPartOrders(resp.allOrders);
      }
      setLoader(false);
    }).catch((err) => {
      console.error("API Error :", err);
    });
  }

  const [menuCollapse, setMenuCollapse] = useState(false);
  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }

  const getPharmacyOrderDetails = (orderId) => {
    setLoader(true);
    
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?digiOrders=2&user_Id=' + ReactSession.get('id') + '&order_id=' + orderId;
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "success") {
        var invItems = [];
        setDpPharmacyOrderDetails(resp.orderDetails);
        setOrderTotals([resp.orderDetails[0][16], resp.orderDetails[0][16], resp.orderDetails[0][17], resp.orderDetails[0][18]]);
        setDpPharmacyOrderItems(resp.orderItems);
        for (let i = 0; i < resp.orderItems.length; i++) {
          invItems.push([resp.orderItems[i][5], resp.orderItems[i][9], resp.orderItems[i][10], resp.orderItems[i][11], 0, resp.orderItems[i][6], resp.orderItems[i][7], resp.orderItems[i][8], resp.orderItems[i][3]]);
        }

        setNewOrderItems(invItems);
      }
      setLoader(false);
    }).catch((err) => {
      console.error("API Error :", err);
    });
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          getDPOrders();
        }

        console.log("PH: ",dpPharmacyOrderDetails,fillDigiPartOrders)
  }, []);

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        {loader ? <Loader /> : ''}
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent digiPartOrdersContainer' id='DigiDashContent' style={{ backgroundColor: 'white', width: dpPharmacyOrderItems !== null ? "90vw" : "" }}>

          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
            {menuCollapse ?
              <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
              :
              <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
            }
          </div>

          <div style={{ margin: '20px' }}>
            <div className='digiPartOrders' style={{ width: '100%', paddingBottom: '15px' }}>
              <div>
                {
                  dpPharmacyOrderDetails !== null ?
                    <img onClick={() => setDpPharmacyOrderDetails(null)} src={require("../../static/DPImages/back.png")} alt="" className="backBtn" />
                    : null
                }
                <h2 style={{ marginLeft: dpPharmacyOrderDetails !== null ? "42px" : "0px" }}>
                {/* Wrong Con */}
                {/* { dpPharmacyOrderDetails === null ?  'Pharmacy ' : dpPharmacyOrderDetails[0][22] ==='1' ? 'Retail ' : 'Pharmacy '} */}
                
                {/* Right Con */}
                {parseInt(ReactSession.get('dpType')) > 1 ? 'Pharmacy Orders' : 'User Orders'}
                </h2>
                <img src={require("../../static/DPImages/calendar.png")} alt="" />
              </div>

              {/* <div style={{ fontSize: '2.5vw' }}>
                Pharmacy Orders
              </div> */}

              {/* <div style={{ fontSize: '1vw' }}>
                Date From _ _ / _ _ / _ _  to  _ _ / _ _ / _ _
              </div> */}
              {dpPharmacyOrderDetails == null ?
                <>
                  <div className='digiOrdersSearch'>
                    <input type='text' name="digiOrderSearchTxt" id="digiOrderSearchTxt" placeholder='Search Customer Name...' onChange={(e) => {
                      const filData = digiPartOrders.filter(data =>
                        data[2]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                        data[3]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                        data[4]?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                        data[5]?.toLowerCase().includes(e.target.value?.toLowerCase())
                      );
                      setFillDigiPartOrders(filData);
                    }
                    } />
                    <i className="ri-search-line"></i>
                  </div>
                  <div className="date">
                    <button>DD-MM-YYYY</button>
                    <img src={require("../../static/DPImages/calendar.png")} alt="" />
                  </div>
                </> : ""

              }
            </div>

            {
              fillDigiPartOrders !== null ?
              dpPharmacyOrderDetails === null && fillDigiPartOrders.length == 0 ? <>
                <Oops title={"No Records Found !"}/>
              </> : "" : ""
            }

            {dpPharmacyOrderDetails === null ?
              <div>
                <div className='orderStatusFilter'>
                  {statusFilter ?
                    <Tooltip title="Remove Filter">
                      <img onClick={() => {
                        setFillDigiPartOrders(digiPartOrders);
                        setStatusFilter(false);
                      }} className="closeBtn" style={{ width: '20px', height: '20px' }} src={require('../../static/DPImages/Icons/filterClear.png')} alt="" />
                    </Tooltip>
                    :
                    <Tooltip title="Filter">
                      <img onClick={() => {
                        document.getElementById('digiStatusSearch').classList.remove('hide');
                        document.getElementById('digiOrderSearchTxt2').value = "";
                        document.getElementById('digiOrderSearchTxt2').focus();
                      }} className="closeBtn" style={{ width: '20px', height: '20px' }} src={require('../../static/DPImages/Icons/filter.png')} alt="" />
                    </Tooltip>
                  } &nbsp;&nbsp;Status Filter
                  <div className='digiStatusSearch hide' id='digiStatusSearch'>
                    <input style={{ width: '100%', fontSize: '90%' }} type='text' name="digiOrderSearchTxt2" id="digiOrderSearchTxt2" placeholder='Search by order status and hit Enter...' onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const filData = digiPartOrders.filter(data =>
                          data[15]?.toLowerCase().includes(e.target.value?.toLowerCase())
                        );
                        setFillDigiPartOrders(filData);
                        setStatusFilter(true);
                        document.getElementById('digiStatusSearch').classList.add('hide');
                      }
                    }
                    } />
                  </div>
                </div>
               

                <div>
                  {
                    fillDigiPartOrders && fillDigiPartOrders !== null ?
                      fillDigiPartOrders.map((data, index) => {
                        return (
                          <div className='digiPartOrdersCard' key={index} style={{ display: 'flex', padding: 5, justifyContent: 'space-between', width: '100%' }}>
                            <div className='digiPartOrdersCardRight' style={{ display: 'block', width: '70%', color: 'gray' }}>
                              <div>{data[2]}</div>
                              <div>{data[3]}</div>
                              <div>{data[4]}</div>
                              <div>{data[5]}-{data[7]}, {data[6]}</div>
                              <div>Order Date & Time : { data[9] !==null ? data[9].split('T')[0] : null} | { data[9] !==null ? data[9].split('T')[1] : null}</div>
                              <div>Order ID : {data[0]}</div>
                              <div style={{ color: "#000", fontWeight: 'bold' }}>{data[15] === "Delivered" || data[15] === "Approved" ? '' : 'Estimated '}MRP Value : {Number(data[11]).toFixed(2)} | {data[15] === "Delivered" || data[15] === "Approved" ? 'Paid ' : 'Estimated Payable Value '}:{Number(data[12]).toFixed(2)} </div>
                              <div style={{ color: "#000", fontWeight: 'bold' }}>{parseInt(data[13]) > 0 ? <>Delivery Charge : {data[13]} | Net Payable : {Number(data[14]).toFixed(2)} </>:'' }</div>
                              {data[15] === "Delivered" ?
                                <div>
                                <span style={{ color: 'green' }}>Successfully Delivered </span> 
                                on {data[16]?.split('T')[0]} | {data[16]?.split('T')[1]}
                                </div>
                                : null}
                            </div>


                            <div className='digiPartOrdersCardLeft' style={{height:"25vh",display:"flex",flexDirection:"column",alignItems:"flex-end"}}>
                              <div style={{fontSize:"14px",color:"gray",marginTop:8}}>
                                Status : &nbsp;<span style={{ color: data[15] === " Delivered" || data[15] === " Approved" ? "green" : "red" }}>{data[15] === "0" ? " Pending" : data[15]}</span>
                              </div>
                              <div style={{fontSize:"14px",color:"gray",marginBottom:"10px"}}>Contact : {data[8]}</div>
                              <div></div>
                              <div className="btnBlue" style={{padding:"5px",width:"100px",alignSelf:"flex-end",marginTop:"auto"}} onClick={() => getPharmacyOrderDetails(data[0])}>Details</div>
                            </div>
                          </div>

                        )
                      })
                      : null
                  }
                </div>
              </div>
              :
              <div className='dgPharmacyOrderDetails'>
                {
                  dpPharmacyOrderDetails !== null ?
                    <div className="pharaOrderDetailDiv">
                      <div className="pharaOrderDetailDivLeft">
                        <div>
                          <h2 style={{ margin: 0, padding: 0, fontSize: '1.2vw' }}>{dpPharmacyOrderDetails[0][3]}</h2>
                          <p style={{ margin: 0, padding: 0, fontSize: '14px' }}>{dpPharmacyOrderDetails[0][6]}, {dpPharmacyOrderDetails[0][7]}</p>
                          <p style={{ margin: 0, padding: 0, fontSize: '14px' }}>{dpPharmacyOrderDetails[0][7]} - {dpPharmacyOrderDetails[0][8]}, {dpPharmacyOrderDetails[0][10]}</p>
                        </div>

                        <div>
                          <p style={{ margin: 0, padding: 0, fontSize: '14px' }}>Order Date & Time : {new Date(dpPharmacyOrderDetails[0][14]).toDateString()} | {new Date(dpPharmacyOrderDetails[0][14]).toLocaleTimeString()}</p>
                          <p style={{ margin: 0, padding: 0, fontSize: '14px' }}>Order ID : {dpPharmacyOrderDetails[0][0]}</p>
                          <b style={{ margin: 0, padding: 0, fontSize: '14px' }}>Estimated MRP Value: {Number(dpPharmacyOrderDetails[0][17]).toFixed(2)} | Estimated PTR Value : {Number(dpPharmacyOrderDetails[0][18]).toFixed(2)}</b>
                          <b style={{ margin: 0, padding: 0, fontSize: '14px' }}>{parseInt(dpPharmacyOrderDetails[0][20]) > 0 ? <><br/>Delivery Charge : {dpPharmacyOrderDetails[0][20]} | Net Payable : {Number(dpPharmacyOrderDetails[0][21]).toFixed(2)} </>:'' }</b>

                        </div>
                      </div>
                      <div className="pharaOrderDetailDivRight">
                        <h4 style={{ margin: 0, padding: 0, fontSize: "14px" }}>Status : <span style={{ color: dpPharmacyOrderDetails[0][15] === "Pending For Confirmation" ? "red" : "" }}>{dpPharmacyOrderDetails[0][15]}</span></h4>
                        <p style={{ margin: 0, padding: 0 ,fontSize: "14px"}}>GST Number : {dpPharmacyOrderDetails[0][11]}</p>
                        <p style={{ margin: 0, padding: 0,fontSize: "14px" }}>Drug License Number :
                          {dpPharmacyOrderDetails[0][12] !== null && dpPharmacyOrderDetails[0][12].search(new RegExp("undefined")) ?
                            <>
                              {dpPharmacyOrderDetails[0][12].split('___')[0]} , {dpPharmacyOrderDetails[0][12].split('___')[1]}
                            </>
                            : null
                          }</p>

                        <p style={{ margin: 0, padding: 0, fontSize: '14px' }}>
                          {dpPharmacyOrderDetails[0][13] !== null && dpPharmacyOrderDetails[0][13].search(new RegExp("undefined")) && dpPharmacyOrderDetails[0][13].length > 3 ?
                            <>
                              Whole-Saler : {dpPharmacyOrderDetails[0][13].split('___')[0]} , {dpPharmacyOrderDetails[0][13].split('___')[1]}
                            </>
                            : null
                          }
                        </p>
                        <button className="uploadFiles">View Upload Now <i className="ri-download-2-line"></i></button>
                      </div>
                    </div>
                    : "null"
                }
                <h3 style={{ margin: 0, padding: 0, fontSize: "23px" }}>Order Details</h3>
                <div className='orderDetailsMainCon'>
                  <div className='orderDetailsContainer'>

                    <div className="orderDetailsTableHead">
                      <div className='itemsDetails' style={{ fontSize: "14px" }}>Item Details</div>
                      <div className="orderQuantity" style={{ fontSize: "14px" }}>Order Quantity</div>
                      <div className="itemAvail" style={{ fontSize: "14px" }}>Item Availability</div>
                      <div className="itemAvail" style={{ fontSize: "14px" }}>Unit Available</div>
                    </div>

                    {
                      dpPharmacyOrderItems !== null ?
                        dpPharmacyOrderItems.map((item, index) => {

                          return (
                            <div key={index} className={index % 2 === 0 ? 'purchBookRecord orderDetailsTableHead' : 'purchBookRecordGray orderDetailsTableHead'}>
                              <div className='itemsDetails'>
                                <div style={{fontSize:"16px"}}>{item[6]}</div>
                                <p style={{ margin: 0, padding: 0, color: "gray", fontSize: 14 }}>{String(item[7]).length < 41 ? `${item[7]}` : `${item[7].substring(0, 40)}...`} </p>
                              </div>

                              <div className="orderQuantity" style={{ fontSize: "14px" }}>
                                <div style={{ width: "100%" }}>{item[9]} Units</div>
                              </div>

                              <div className="itemAvail">
                                <div className={`itemAvailCheckBox checkBox-${index}`} che="true" onClick={(e) => {
                                  let elem = document.querySelector("." + e.target.classList[1]);
                                  if (elem.getAttribute("che") === "true") {
                                    elem.setAttribute("che", false);
                                    calculateIfInclude(item[5], false);
                                  }
                                  else {
                                    elem.setAttribute("che", true);
                                    calculateIfInclude(item[5], true);
                                  }
                                }}></div>
                                {/* {item[11]} :: {item[10]} */}
                              </div>

                              <div className="itemAvail">
                                <input type='text' style={{ width: '50%', textAlign: 'center',fontSize:"14px",border:"none",outline:"1px solid #d1d5db" }} defaultValue={item[9]} id={`unit${index}`} onChange={(e) => { calculateNewValue(e, item[5]); }} />
                              </div>
                            </div>
                          )
                        })

                        : null
                    }


                  </div>

                  {/* order summary */}
                  <div className="orderSummary">
                    <div className="orderSummaryHead">
                      <b>Order Summary</b>
                    </div>

                    <div>
                      <p style={{ margin: 0, padding: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>Total Items <span>{orderTotals[0]}</span></p>
                      <p style={{ margin: 0, padding: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>Total Available Items <span>{orderTotals[1]}</span></p>
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      <p style={{ margin: 0, padding: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>Estimated MRP Value <span>{Number(orderTotals[2]).toFixed(2)}</span></p>
                      <p style={{ margin: 0, padding: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>Estimated PTR Value<span>{Number(orderTotals[3]).toFixed(2)}</span></p>
                    </div>

                    <p className='orderSummaryDesc'>By clicking the "Notify Availability" button, you are acknowledging, that among the orders you have selected
                      from those sent to you, all the  items you chose are available but the remaining items are not available in my
                      inventory, for more information please review our
                      <Link to="/termsCon" style={{ textDecoration: "none", color: "#00C0FE" }}> Terms And Conditions</Link></p>
                    {
                      dpPharmacyOrderDetails[0][15]  === "Approved" ? 
                      <button className="btnBlue" onClick={() => { getQRCode(); }}>Notify Availability & Billing</button>
                      :
                      <div className="btnBlue">Approval pending from SheetERP</div>
                    }
                    {/* comment Box */}
                    <p style={{ margin: 0, padding: 0, marginTop: "10px" }}>Comment Box</p>
                    <textarea className='commentBox' name="comment" id="comment" placeholder='Add Comments to Retailer'></textarea>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      {/* Whats App QR Code Dialogue  */}
      <div className='Dialogue hide' id='Dialogue'>
        {loader ? <Loader /> : ''}
        <div className='DialogueMSG'>
        <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <img src={require("../../static/DPImages/mainLogos/newLogo.png")} alt="" />
          <i style={{fontSize:"25px",cursor:"pointer"}} onClick={()=>{
           document.getElementById('Dialogue').classList.add('hide');
          }}><MdOutlineClose /></i>
        </div>
          <hr />
          <div >
            <div className="details">

              <h3>Use whatsApp for your order</h3>

              <div className='flex'>
                <div>
                  <p style={{ marginTop: "40px" }}>1. Open whatsapp on your phone</p>
                  <p>2. Tab menu : on android , or  setting on iphone</p>
                  <p>3. Tab link devices and than link a device</p>
                  <p>4. Point your phone at this screen to capture the QR code</p>

                  <p style={{ marginTop: "30px", color: "#00C7E4" }} className='linkWithPhone'>Link with phone number</p>
                  <Link to="/ContactUs" style={{ textDecoration: "none", color: "#00C7E4", textAlign: "center" }}>Need help ?</Link>
                  <span className='btnPrimary2 continue' style={{padding:"10px 50px"}} onClick={() => {
                    continueWithOrder();
                  }}>Continue</span>
                </div>

                <div className="qrCode">
                  {QRCode && QRCode != null ?
                    <img src={QRCode} alt="" />
                    : ''
                  }  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DigiPartnerPharmacyOrders