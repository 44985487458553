import React from 'react'

const TrendCatalogue = () => {
  return (
    <>
      <div className='row pt-2 topPartition'>
        <div className="col-6" >
          <h4 style={{ color: 'grey' }}>Trending Products</h4>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <h6 style={{ color: 'cornflowerblue' }}>Browse All</h6>
        </div>
      </div>
      <div className="row d-flex">
        <div className="col-12 col-sm-12 col-md-4 col-lg-2 trendz">
          <img src={require('../../static/images/TrendingProducts/Chyawanprash.jpg')} alt="..." />
          <h6>Dabur Chyawanprash</h6>
        </div>
        <div className="border border-2 border-top-0 border-bottom-0 border-end-0 col-12 col-sm-12 col-md-4 col-lg-2  trendz">
          <img src={require('../../static/images/TrendingProducts/HimalayaLive52.png')} alt="..." />
          <h6 >Himalaya Live 52</h6>
        </div>
        <div className="border border-2 border-top-0 border-bottom-0 col-12 col-sm-12 col-md-4 col-lg-2 trendz">
          <img src={require('../../static/images/TrendingProducts/safi.jpg')} alt="..." />
          <h6 >Safi</h6>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-2 trendz">
          <img src={require('../../static/images/TrendingProducts/ChywanprashSF.jpg')} alt="..." />
          <h6 >Dabur Chyawanprash Sugar Free</h6>
        </div>
        <div className="border border-2 border-top-0 border-bottom-0 col-12 col-sm-12 col-md-4 col-lg-2 trendz">
          <img src={require('../../static/images/TrendingProducts/HajmolaRegular.jpg')} alt="..." />
          <h6 >Hajmola Candy</h6>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-2 trendz">
          <img src={require('../../static/images/TrendingProducts/PEESafe.png')} alt="..." />
          <h6 >PEE Safe</h6>
        </div>
      </div>
    </>
  )
}
export default TrendCatalogue