import React from 'react'
import DashNavbar from '../../DashContainers/DashNavbar'
import DashPanel from '../../DashContainers/DashPanel'
import { FiSearch } from 'react-icons/fi'
import Select from 'react-select';


const ProductAvailability = () => {

  const options = [
    { value: "Bhopal", label: "Bhopal", state: "Madhya Pradesh" },
  { value: "London", label: "London", state: "Westminster" },  
  { value: "Paris", label: "Paris", state: "Île-de-France" },  
  { value: "New York City", label: "New York City", state: "New York" },  
  { value: "Berlin", label: "Berlin", state: "Berlin" },  
  { value: "Rome", label: "Rome", state: "Lazio" },  
  { value: "Buenos Aires", label: "Buenos Aires", state: "Autonomous City of Buenos Aires" },  
  { value: "Rio de Janeiro", label: "Rio de Janeiro", state: "Rio de Janeiro" },  
  { value: "Barcelona", label: "Barcelona", state: "Catalonia" },  
  { value: "Mexico City", label: "Mexico City", state: "Mexico City" }
  ]

  const toggleCheckBox = (el) => {
    let elem = document.querySelector(el);
    if (elem.getAttribute("data-check") === "true") {
      elem.setAttribute("data-check", false);
    }
    else {
      elem.setAttribute("data-check", true);
    }
  }

  return (
    <>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents productAvailability' id='dashContents'>

          <div>
            <h3>Manage Product Availability</h3>
          </div>

          <div className='flex'>


            <div className="productsCon " style={{ width: "55%" }}>
              <div className="searchBox" style={{ width: "100%", marginBottom: "10px", marginTop: "5px", border: "1.5px solid #000", borderRadius: "5px", display: "flex", alignItems: "center" }}>
                <input style={{ flex: 1, padding: 10, border: 0, outline: 0, fontStyle: "italic", borderRadius: 5 }} type="text" placeholder='Search Medicine / Health care products by name...!' />
                <i style={{ width: "70px", background: "#000", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", height: "45px", borderTopRightRadius: "5px", borderBottomRightRadius: "5px", fontSize: 20, cursor: "pointer" }}><FiSearch /></i>
              </div>

              <div className="head" style={{ background: "#000", color: "#fff", width: "100%", height: 60, display: "flex", alignItems: "center", padding: "0px 10px" }}>
                <div style={{ width: "60%" }}>
                  <p style={{ margin: 0, padding: 0 }}>Item Name</p>
                </div>
                <div style={{ width: "20%" }}>
                  <p style={{ margin: 0, padding: 0, textAlign: "center" }}>Assign City</p>
                </div>
                <div style={{ width: "20%" }}>
                  <p style={{ margin: 0, padding: 0, textAlign: "center" }}>Assign to all Cities</p>
                </div>
              </div>

              <div className="products customScroll" style={{ height: "60vh", overflowY: "auto", width: "100%", paddingTop: 10 }}>

                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1" data-check={false} onClick={() => { toggleCheckBox(".checkBox-assignCity-1") }}></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1" data-check={false} onClick={() => { toggleCheckBox(".assign-to-all-1") }}></div>
                  </div>
                </div>

                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1"></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1"></div>
                  </div>
                </div>

                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1"></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1"></div>
                  </div>
                </div>

                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1"></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1"></div>
                  </div>
                </div>

                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1"></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1"></div>
                  </div>
                </div>

                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1"></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1"></div>
                  </div>
                </div>

                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1"></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1"></div>
                  </div>
                </div>


                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1"></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1"></div>
                  </div>
                </div>

                <div className="product" style={{ width: "100%", cursor: "pointer", height: 60, display: "flex", alignItems: "center", padding: "0px 10px"}}>
                  <div style={{ width: "60%" }}>
                    <p style={{ margin: 0, padding: 0 }}>Telma 40 Tablet</p>
                    <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Strip of 10 Tablets</p>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox checkBox-assignCity-1"></div>
                  </div>
                  <div style={{ width: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="customCheckBox assign-to-all-1"></div>
                  </div>
                </div>

              </div>

            </div>

            <div className="citiesCon" style={{ width: "40%", marginTop: 8 }}>
              <Select
                defaultValue={[options[2], options[3]]}
                isMulti
                name="cities"
                options={options}
                className="cities-select"
                classNamePrefix="select"
              />

              <div className="cities customScroll" style={{ height: "70vh", overflowY: "auto" }}>

                {
                  options ? options.map((el, index) => {
                    return (
                      <>
                        <div className="city">
                          <p style={{ color: "gray", margin: 0, padding: 0 }}><b style={{ color: "#000" }}>{el.label}</b>, {el.state}</p>
                          <button className="select">Select</button>
                        </div>
                      </>
                    )
                  }) : ""
                }


              </div>

              <button className="btnBlue">Update</button>

            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default ProductAvailability