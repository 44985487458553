import React from 'react'
import { Link } from 'react-router-dom'

const ChatWithUs = () => {


  const adminPhoneNumber = '9300859938'; // Replace with admin's phone number
  const encodedMessage = encodeURIComponent('Hello! I have a question.'); // Encode the message

  const handleWhatsAppChat = () => {
    const whatsappURL = `https://web.whatsapp.com/send?phone=${adminPhoneNumber}&text=${encodedMessage}`;
    window.open(whatsappURL, '_blank'); // Open in a new tab/window
  };


  return (
    <>
      <div onClick={handleWhatsAppChat} className="chatWithUsCon">
        <img src={require("../../static/images/Icons/call.gif")} alt="" />
      </div>
    </>
  )
}

export default ChatWithUs