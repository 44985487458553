import React from 'react'

const UsersComments = () => {

  const nextSlide = () => {
    document.getElementsByClassName("tmpusers")[0].innerHTML = document.getElementsByClassName("users")[0].innerHTML
    document.getElementsByClassName("users")[0].innerHTML = document.getElementsByClassName("users")[1].innerHTML
    document.getElementsByClassName("users")[1].innerHTML = document.getElementsByClassName("users")[2].innerHTML
    document.getElementsByClassName("users")[2].innerHTML = document.getElementsByClassName("tmpusers")[0].innerHTML
  }
  const prevSlide = () => {
    document.getElementsByClassName("tmpusers")[0].innerHTML = document.getElementsByClassName("users")[2].innerHTML
    document.getElementsByClassName("users")[2].innerHTML = document.getElementsByClassName("users")[1].innerHTML
    document.getElementsByClassName("users")[1].innerHTML = document.getElementsByClassName("users")[0].innerHTML
    document.getElementsByClassName("users")[0].innerHTML = document.getElementsByClassName("tmpusers")[0].innerHTML
  }

  function revealUserComment() {
    var reveals = document.querySelectorAll(".revealUserComment");
    
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 5;
    
      if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
      } else {
      reveals[i].classList.remove("active");
      }
    }
    }
    
    window.addEventListener("scroll", revealUserComment);
  
  return (
    <>
      <div className='row pt-2 topPartition'>
        <div className='col-5' >
          <img className="qouteBanner revealUserComment" src={require("../../static/images/userComments/UserComments.png")} alt="..." />
        </div>
        <div className="col-7 ">
          <div className='row'>
            <div className='col-8'>
              <img className ="qouteImg" src={require("../../static/images/userComments/quote.png")} alt=".." />
            </div>
            <div className='qoute col-2 d-flex justify-content-end' onClick={() => { prevSlide() }}>
              <img src={require("../../static/images/userComments/prev.png")} alt=".." />
            </div>
            <div className='qoute col-2 d-flex justify-content-end' onClick={() => { nextSlide() }}>
              <img src={require("../../static/images/userComments/next.png")} alt=".." />
            </div>

          </div>
          <div className='tmpusers' style={{ display: 'none' }}>
          </div>
          <div className='users'>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy
              text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and
              text of the printing and typesetting industry.
            </p>
            <div className='row user pt-2' >
              <div className='col-2 ' >
                <img src={require("../../static/images/userComments/Anant.jpg")}  alt=".." />
              </div>
              <div className='col-8 pt-3 identity'>
                <h5>Anant Yadav</h5>
                <p>Sr Branch Manager, ICICI Bank</p>
              </div>
            </div>
          </div>

          <div className='users' style={{ display: 'none' }}>
            <p>Ones upon a day, their was king named Jahangeer, he was very honest, pious to their people</p>
            <div className='row user pt-2' >
              <div className='col-2 ' >
                <img src={require("../../static/images/userComments/Mihir.jpg")} width="60%" alt=".." />
              </div>
              <div className='col-8 pt-3 identity'>
                <h5>Mihir Chugh</h5>
                <p>Cheif Engineer, MP-PWD</p>
              </div>
            </div>
          </div>

          <div className='users' style={{ display: 'none' }}>
            <p>Microsoft Office is an Office Automation Software Packages which include, Microsoft Word, Microsoft Excel, Microsoft Powerpoint and Microsoft Access</p>
            <div className='row user pt-2' >
              <div className='col-2 ' >
                <img src={require("../../static/images/userComments/Zameer.jpg")} width="60%" alt=".." />
              </div>
              <div className='col-8 pt-3 identity'>
                <h5>Zameer Hasan</h5>
                <p>Software Developer, Netlink</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default UsersComments