import React, { useEffect, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import { createRoot } from 'react-dom/client'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { ReactSession } from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import { LuSearch } from 'react-icons/lu';

const ManageSaltGroups = () => {
  const navigate = useNavigate();

  const [listRec, setListRec] = useState(null);
  const [RawHTML, setHTML] = useState(null);
  const [saltGroupData, setSaltGroupData] = useState(null);
  const [pagesize, setPageSize] = useState(25);
  const [noofRec, setnoofRec] = useState(0);

  // Page Navigattion Methods
  // var page = 1;
  const [page, setPage] = useState(1);

  // setCount(count + 1, () => {
  //   afterSetCountFinished();
  //  });

  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const prevPage = () => {
    // console.log("Revious Clicked :"+page);
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    // console.log("Next Clicked ...");
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
  }

  // Editor State
  const [eState, setEState] = useState(EditorState.createEmpty());
  function onEditorChange(editstate) {
    var rawContentState = convertToRaw(editstate.getCurrentContent());
    setHTML(draftToHtml(rawContentState));
  }


  // Add SaltGroup Modal Declaration
  const [addSaltGroup, setShowAddSaltGroup] = useState(false);
  const AddSaltGroupClose = () => setShowAddSaltGroup(false);
  const AddSaltGroupShow = (listRecord = null) => {
    if (listRecord !== null) {
      setListRec(listRecord);
      if (listRecord[2] === null || listRecord[2] === 'null')
        setEState(EditorState.createEmpty());
      else {
        const blocksFromHTML = convertFromHTML(listRecord[2]);
        const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        setEState(EditorState.createWithContent(content));
        var rawContentState = convertToRaw(content);
        setHTML(draftToHtml(rawContentState));
      }
    }
    else {
      setListRec(null);
      setHTML('undefined');
      setEState(EditorState.createEmpty());
    }
    setShowAddSaltGroup(true);
  }


  // Utility Methods
  const handleSubmit = (event) => {
    event.preventDefault();
    var responseMsg = "One SaltGroup added successfully"

    // newGrpName newCatImage showCat isActiveCat
    let formData = new FormData();  //formdata object
    if (listRec !== null) {
      formData.append('GroupID', listRec[0]);
      responseMsg = "One SaltGroup updated successfully"
    }

    formData.append('GroupName', document.getElementById('newGrpName').value);
    formData.append('IsActive', document.getElementById('isActiveGrpNew').checked ? "1" : "0");
    formData.append('Uses', RawHTML);

    fetch('http://43.205.199.21/manageGroupsAPI/', {
      mode: 'cors',
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
      .then((result) => {
        // console.log('Success:', result);
        alert(responseMsg);
        AddSaltGroupClose();
        GetAllGroups();
      }).catch((error) => {
        console.error('Error:', error);
      });
  }


  const searchOrder = () => {
    GetAllGroups();
  }

  const deleteRecord = (groupId) => {
    var confDel = prompt("Enter admin password for confirm deletion :");
    if (confDel === 'secret') {
      fetch('http://43.205.199.21/manageGroupsAPI/', {
        mode: 'cors',
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          Group_Id: groupId,
        })
      }).then((response) => {
        response.json();
        GetAllGroups();
      })
        .then((data) => {
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Deletion request denied..!")
  }


  const GetAllGroups = async () => {
    var stxt = document.getElementById('sertext').value;
    var getGrpURL = "http://43.205.199.21/manageGroupsAPI?page=" + page + "&pagesize=" + pagesize + "&SearText=" + stxt;
    await fetch(getGrpURL, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      setSaltGroupData(resp.data);
      setnoofRec(resp.noofRec);
      document.getElementById('pageNo').placeholder = page;
    });
  }


  useEffect(() => {
    if (!ReactSession.get('adminSheet360ERP')) {
      navigate('/Admin');
    }
    else {
      GetAllGroups();
    }
  }, [page]);

  return (
    <div>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents' id='dashContents'>
          <div className='pendOrders' style={{ width: '100%', zIndex: '1', position: "unset" }}>
            <Row style={{ flexWrap: "nowrap", paddingTop: 10 }}>
              <Col style={{ width: "37%" }} xl={8} sm={6} xs={6}>
                <h3 style={{ fontSize: 30, fontWeight: 600, marginLeft: 20 }}>Manage Salt Groups</h3>
              </Col>
              <Col style={{ color: "gray", fontSize: 16, marginLeft: 18,marginTop:"10px" }} md={3} lg={3} xl={3} sm={3} xs={3}>
                Groups Count : {noofRec}
              </Col>
              <Col style={{ position: "relative", paddingLeft: 0, paddingRight: 0, marginBottom: 5, display: "flex", alignItems: "center", background: "#E8E8E8", height: "fit-content", marginRight: 50, borderRadius: 10, float: "right" }} xl={4} sm={6} xs={6}>
                <input placeholder='Search by Salt Name...' style={{ width: '75%', background: "#E8E8E8", border: "none", padding: "10px", flex: 1, border: 0, outline: 0, borderRadius: 10 }} type='text' name="sertext" id="sertext" defaultValue={''} onKeyDown={(e) => { if (e.key === "Enter") { searchOrder(); } }} />
                <i style={{ position: "absolute", fontSize: 20, display: "flex", alignItems: "center", justifyContent: "center", right: 0, top: 0, width: 70, minHeight: "100%", background: "#000", color: "#fff", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}><LuSearch /></i>
              </Col>
            </Row>
            
            <Row className="getDataHeader" style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
              <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                ID
              </Col>
              <Col md={5} lg={5} xl={5} sm={5} xs={5}>
                Group Name
              </Col>
              <Col md={2} lg={2} xl={2} sm={2} xs={2}>
                Products Count
              </Col>
              <Col md={2} lg={2} xl={2} sm={2} xs={2}>
                Is Active
              </Col>
              <Col md={2} lg={2} xl={2} sm={2} xs={2}>
                Action
              </Col>
            </Row>
          </div>
          <div className='allPenOrders categoryContainer' id='categoryContainer' style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
            {saltGroupData ?
              saltGroupData.map((SaltGroup) => {
                return <>
                  <Row className='pendOrderRows' style={{ marginTop: '.3rem' }}>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1} style={{ textAlign: 'right' }}>
                      {SaltGroup[0]}
                    </Col>
                    <Col style={{color:"#000"}} md={5} lg={5} xl={5} sm={5} xs={5}>
                      {SaltGroup[1]}
                    </Col>
                    <Col style={{textAlign:"center"}} md={2} lg={2} xl={2} sm={2} xs={2}>
                      {SaltGroup[6]}
                    </Col>
                    <Col style={{textAlign:"center"}} md={2} lg={2} xl={2} sm={2} xs={2}>
                      {String(SaltGroup[3]) === '1' ?
                        <input type='checkbox' name='isActiveGroup' id='isActiveGroup' disabled defaultChecked />
                        :
                        <input type='checkbox' name='isActiveGroup' id='isActiveGroup' disabled />
                      }
                    </Col>
                    <Col style={{textAlign:"center"}} md={2} lg={2} xl={2} sm={2} xs={2} >
                      <img className="catActionBtn" onClick={() => { AddSaltGroupShow(SaltGroup) }} src={require('../../static/images/edit.png')} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                      &nbsp;&nbsp;&nbsp;&nbsp;<img className="catActionBtn" onClick={() => deleteRecord(SaltGroup[0])} src={require('../../static/images/trash.png')} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                    </Col>
                  </Row>
                </>
              })
              : "Salt Groups"
            }
          </div>
          <div className='addNewCat' style={{ border: 0, outline: 0 }} onClick={() => { AddSaltGroupShow() }}>
          <img style={{ width: "70px", height: "70px", borderRadius: "50%" }} src={require('../../static/AdminPanelImages/add.gif')} alt="" />
          </div>

          <div className="OrderPagination prodPagination">
            <div style={{ width: '33%', display: 'flex', justifyContent: 'center', height: '2rem', margin: "0 auto", height: "60px" }}>
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
              <input size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
            </div>
          </div>

          <div className='copyRight'>
            Sheet360ERP Copyright &copy; 2023:  All Right Reserved
          </div>
        </div>
      </div>

      {/* Add SaltGroup Modal */}

      <Modal show={addSaltGroup} onHide={AddSaltGroupClose} size="xl" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>{listRec !== null ? "Editing " : "Add New"} Salt Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form onSubmit={handleSubmit} >
            <Row style={{ marginTop: '.3rem', marginBottom: '.3rem' }}>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <p style={{margin:0,padding:0,color:"gray",fontSize:"14px"}}>Group Name</p> 
                <input type="text"
                  defaultValue={listRec !== null ? listRec[1] : ""}
                  name="newGrpName" id="newGrpName" placeholder='Group Name' style={{ width: '100%',padding:5 }} />
              </Col>
              <Col md={6} lg={6} xl={6} sm={6} xs={6}>
                <br />
                {listRec !== null ? String(listRec[3]) === '1' ?
                  <input type='checkbox' name='isActiveGrpNew' id='isActiveGrpNew' defaultChecked />
                  :
                  <input type='checkbox' name='isActiveGrpNew' id='isActiveGrpNew' />
                  :
                  <input type='checkbox' name='isActiveGrpNew' id='isActiveGrpNew' />
                } Active
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} xl={12} sm={12} xs={12} >
                <div >
                  <p style={{ fontSize: "80%", marginTop: '2%', fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}> Details</p>
                  <Editor wrapperClassName="wrapper" editorClassName="editor" toolbarClassName="toolbar"
                    onEditorStateChange={onEditorChange}
                    defaultEditorState={eState}
                  // editorState = { eState }
                  />
                  <br />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '.3rem', marginBottom: '.3rem', textAlign: 'center' }}>
              <Col lg={12} md={12} xl={12} sm={12} xs={12} >
                <button type='submit' style={{border:0,outline:0,padding:"5px 15px",background:"#00baff"}} className='btn btn-primary btn-sm'>{listRec !== null ? "Update " : "Add "} Category</button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default ManageSaltGroups