import React from 'react'
import DashNavbar from '../../DashContainers/DashNavbar'
import DashPanel from '../../DashContainers/DashPanel'
import { IoSearchOutline } from 'react-icons/io5'

const DigitalPartners = () => {
  return (
    <>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents digitalPartners outStandingReportsCon' id='dashContents' style={{ padding: "0px 20px" }}>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingBottom: "10px", marginBottom: "10px", borderBottom: "1.5px solid #000", marginTop: 10 }}>
            <h3 style={{ margin: 0, padding: 0 }}>Digital Partners</h3>

            <div className="inputBox" >
              <input type="text" style={{ fontStyle: "italic" }} placeholder='Search By Order ID/Partner Name...!' />
              <i><IoSearchOutline /></i>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: 10, marginLeft: 20 }}>
              <input type="date" className='removeDateIcon' style={{ border: 0, outline: 0, textTransform: "uppercase", color: "gray", width: "105px" }} />
              <span style={{ margin: 0, padding: 0 }}>-</span>
              <input type="date" className='removeDateIcon' style={{ border: 0, outline: 0, textTransform: "uppercase", color: "gray", width: "105px" }} />
              <img src={require("../../../static/DPImages/calendar.png")} style={{ width: 25, height: 25, cursor: "pointer" }} alt="" />

              <button style={{ padding: "2px 10px", color: "#fff", border: 0, borderRadius: "5px", outline: 0, background: "#05B5FF", margin: 0 }}>Go</button>
              <button style={{ padding: "2px 10px", color: "#fff", border: 0, borderRadius: "5px", outline: 0, background: "#05B5FF", margin: 0 }}>Print</button>

            </div>

          </div>

          <div className="partnersTable">

            <div className="tableHead" style={{ background: "#000", color: "#fff" }}>
              <div class="row">
                <div class="" style={{width:"35%"}}>
                  Partners Details
                </div>
                <div class="col-sm">
                  Domain Type
                </div>
                <div class="col-sm">
                  Total Orders | Value
                </div>
                <div class="col-sm">
                  Missed Orders | Value
                </div>
                <div class="col-sm">
                  Delivered Orders | Value
                </div>
              </div>
            </div>
            <div className="tableRecords customScroll" style={{ height: "70vh", overflowY: "auto",overflowX:"hidden" }}>
              <div class="row" style={{flexWrap:"nowrap"}}>
                <div class="" style={{flexDirection:"column",alignItems:"flex-start",width:"35%"}}>
                  <b style={{ fontWeight: "700", color: "#000" }}>Partners Details</b>
                  <p style={{margin:0,padding:0,fontSize:14}}>0755-4452156 | 9891542623</p>
                  <p style={{margin:0,padding:0,fontSize:14,textAlign:"left"}}>Shop No 3, Jawarar Chowk, Near Kailash Gajak House.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>IT Nager New Market.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>Bhopal Madhya PRadesh - 46201</p>
                </div>
                <div class="col-sm">
                  Retailer
                </div>
                <div class="col-sm">
                  52 | 44,251.00
                </div>
                <div class="col-sm" style={{ color: "red" }}>
                  12 | 12,251.00
                </div>
                <div class="col-sm" style={{ color: "green",alignItems:"flex-start",justifyContent:"center" }}>
                  36 | 31,251.00
                </div>
              </div>
              <div class="row" style={{flexWrap:"nowrap"}}>
                <div class="" style={{flexDirection:"column",alignItems:"flex-start",width:"35%"}}>
                  <b style={{ fontWeight: "700", color: "#000" }}>Partners Details</b>
                  <p style={{margin:0,padding:0,fontSize:14}}>0755-4452156 | 9891542623</p>
                  <p style={{margin:0,padding:0,fontSize:14,textAlign:"left"}}>Shop No 3, Jawarar Chowk, Near Kailash Gajak House.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>IT Nager New Market.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>Bhopal Madhya PRadesh - 46201</p>
                </div>
                <div class="col-sm">
                  Retailer
                </div>
                <div class="col-sm">
                  52 | 44,251.00
                </div>
                <div class="col-sm" style={{ color: "red" }}>
                  12 | 12,251.00
                </div>
                <div class="col-sm" style={{ color: "green",alignItems:"flex-start",justifyContent:"center" }}>
                  36 | 31,251.00
                </div>
              </div>
              <div class="row" style={{flexWrap:"nowrap"}}>
                <div class="" style={{flexDirection:"column",alignItems:"flex-start",width:"35%"}}>
                  <b style={{ fontWeight: "700", color: "#000" }}>Partners Details</b>
                  <p style={{margin:0,padding:0,fontSize:14}}>0755-4452156 | 9891542623</p>
                  <p style={{margin:0,padding:0,fontSize:14,textAlign:"left"}}>Shop No 3, Jawarar Chowk, Near Kailash Gajak House.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>IT Nager New Market.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>Bhopal Madhya PRadesh - 46201</p>
                </div>
                <div class="col-sm">
                  Retailer
                </div>
                <div class="col-sm">
                  52 | 44,251.00
                </div>
                <div class="col-sm" style={{ color: "red" }}>
                  12 | 12,251.00
                </div>
                <div class="col-sm" style={{ color: "green",alignItems:"flex-start",justifyContent:"center" }}>
                  36 | 31,251.00
                </div>
              </div>
              <div class="row" style={{flexWrap:"nowrap"}}>
                <div class="" style={{flexDirection:"column",alignItems:"flex-start",width:"35%"}}>
                  <b style={{ fontWeight: "700", color: "#000" }}>Partners Details</b>
                  <p style={{margin:0,padding:0,fontSize:14}}>0755-4452156 | 9891542623</p>
                  <p style={{margin:0,padding:0,fontSize:14,textAlign:"left"}}>Shop No 3, Jawarar Chowk, Near Kailash Gajak House.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>IT Nager New Market.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>Bhopal Madhya PRadesh - 46201</p>
                </div>
                <div class="col-sm">
                  Retailer
                </div>
                <div class="col-sm">
                  52 | 44,251.00
                </div>
                <div class="col-sm" style={{ color: "red" }}>
                  12 | 12,251.00
                </div>
                <div class="col-sm" style={{ color: "green",alignItems:"flex-start",justifyContent:"center" }}>
                  36 | 31,251.00
                </div>
              </div>
              <div class="row" style={{flexWrap:"nowrap"}}>
                <div class="" style={{flexDirection:"column",alignItems:"flex-start",width:"35%"}}>
                  <b style={{ fontWeight: "700", color: "#000" }}>Partners Details</b>
                  <p style={{margin:0,padding:0,fontSize:14}}>0755-4452156 | 9891542623</p>
                  <p style={{margin:0,padding:0,fontSize:14,textAlign:"left"}}>Shop No 3, Jawarar Chowk, Near Kailash Gajak House.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>IT Nager New Market.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>Bhopal Madhya PRadesh - 46201</p>
                </div>
                <div class="col-sm">
                  Retailer
                </div>
                <div class="col-sm">
                  52 | 44,251.00
                </div>
                <div class="col-sm" style={{ color: "red" }}>
                  12 | 12,251.00
                </div>
                <div class="col-sm" style={{ color: "green",alignItems:"flex-start",justifyContent:"center" }}>
                  36 | 31,251.00
                </div>
              </div>
              <div class="row" style={{flexWrap:"nowrap"}}>
                <div class="" style={{flexDirection:"column",alignItems:"flex-start",width:"35%"}}>
                  <b style={{ fontWeight: "700", color: "#000" }}>Partners Details</b>
                  <p style={{margin:0,padding:0,fontSize:14}}>0755-4452156 | 9891542623</p>
                  <p style={{margin:0,padding:0,fontSize:14,textAlign:"left"}}>Shop No 3, Jawarar Chowk, Near Kailash Gajak House.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>IT Nager New Market.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>Bhopal Madhya PRadesh - 46201</p>
                </div>
                <div class="col-sm">
                  Retailer
                </div>
                <div class="col-sm">
                  52 | 44,251.00
                </div>
                <div class="col-sm" style={{ color: "red" }}>
                  12 | 12,251.00
                </div>
                <div class="col-sm" style={{ color: "green",alignItems:"flex-start",justifyContent:"center" }}>
                  36 | 31,251.00
                </div>
              </div>
              <div class="row" style={{flexWrap:"nowrap"}}>
                <div class="" style={{flexDirection:"column",alignItems:"flex-start",width:"35%"}}>
                  <b style={{ fontWeight: "700", color: "#000" }}>Partners Details</b>
                  <p style={{margin:0,padding:0,fontSize:14}}>0755-4452156 | 9891542623</p>
                  <p style={{margin:0,padding:0,fontSize:14,textAlign:"left"}}>Shop No 3, Jawarar Chowk, Near Kailash Gajak House.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>IT Nager New Market.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>Bhopal Madhya PRadesh - 46201</p>
                </div>
                <div class="col-sm">
                  Retailer
                </div>
                <div class="col-sm">
                  52 | 44,251.00
                </div>
                <div class="col-sm" style={{ color: "red" }}>
                  12 | 12,251.00
                </div>
                <div class="col-sm" style={{ color: "green",alignItems:"flex-start",justifyContent:"center" }}>
                  36 | 31,251.00
                </div>
              </div>
              <div class="row" style={{flexWrap:"nowrap"}}>
                <div class="" style={{flexDirection:"column",alignItems:"flex-start",width:"35%"}}>
                  <b style={{ fontWeight: "700", color: "#000" }}>Partners Details</b>
                  <p style={{margin:0,padding:0,fontSize:14}}>0755-4452156 | 9891542623</p>
                  <p style={{margin:0,padding:0,fontSize:14,textAlign:"left"}}>Shop No 3, Jawarar Chowk, Near Kailash Gajak House.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>IT Nager New Market.</p>
                  <p style={{margin:0,padding:0,fontSize:14}}>Bhopal Madhya PRadesh - 46201</p>
                </div>
                <div class="col-sm">
                  Retailer
                </div>
                <div class="col-sm">
                  52 | 44,251.00
                </div>
                <div class="col-sm" style={{ color: "red" }}>
                  12 | 12,251.00
                </div>
                <div class="col-sm" style={{ color: "green",alignItems:"flex-start",justifyContent:"center" }}>
                  36 | 31,251.00
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default DigitalPartners