import React, { useEffect, useState } from 'react'
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar'
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel'
import { ReactSession } from 'react-client-session';
import Loader from '../Containers/Loader';
import { useNavigate } from "react-router-dom";

const ManageCatWiseDisco = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState(null);

  const getCategoryWiseDiscounts = () => {
    setIsLoading(true);
    fetch("http://43.205.199.21/manageCateDisAPI?getDPCateDis=" + ReactSession.get("id"), {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      else
      {
        setCategoriesData(resp.catDiscount);
      }
      setIsLoading(false);
    }).catch((err) =>{
      console.log("Error :",err);
    });
  }

  const updateCateDiscount = (cateId, index) =>{
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'PUT',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        user_Id: ReactSession.get("id"),
        DPDiscountCateID: cateId,
        discount: document.getElementById(('discount'+index)).value,
      })
    }).then((response) => response.json())
    .then((resp)=>{
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }

      if (resp.status === "success")
      { 
        alert("Category wise discount updated successfully");
        getCategoryWiseDiscounts();
      }
      else
      {
        alert("Update Fails, try again...");
      }
    })
    .catch((err) => {
      console.log(err.message);
    });

  }

  useEffect(() => {
    getCategoryWiseDiscounts();
  }, []);

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer'>
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>

        <div className='DigiDashContent manageProducts' id='DigiDashContent' style={{ backgroundColor: 'white', marginTop: 20,padding:"10px 50px" }}>
          {isLoading ? <Loader /> : null}

          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <h3>Manage Category Wise Discount</h3>

            {/* <button className='btnBlue' style={{ width: "120px", padding: "5px", fontSize: 13, marginTop: 20 }}>Update</button> */}
          </div>


          <div className="partyWiseSalesTable manageCatWiseDisco">
            <div className="tableHead">
              <div className="row">
                <div className="col-3" style={{ fontWeight: "bold" }}>
                  Name
                </div>

                <div className="col-sm" style={{ fontWeight: "bold" }}>
                  Total Products
                </div>

                <div className="col-sm" style={{ fontWeight: "bold" }}>
                  Discount %
                </div>

                <div className="col-sm" style={{ fontWeight: "bold" }}>
                  Cupon
                </div>

                <div className="col-sm" style={{ fontWeight: "bold" , display:"flex",alignItems:"center",justifyContent:"center"}}>
                  Action
                </div>
              </div>
            </div>

            {/* records */}
            <div className="partySalesTableReco manageCatWiseDicoTable customScroll">
              {
                categoriesData && categoriesData !== null ?
                categoriesData.map((data, index)=>{
                  const coponsList = data[4]?.split(',');
                  return (
                    <div key={index} className="row">
                      <div className="col-3">
                        {data[2]} <br />
                      </div>

                      <div className="col-sm">
                        {data[0]}
                      </div>

                      <div className="col-sm">
                        <input type="text" placeholder='Discount' defaultValue={data[3]} id={`discount${index}`} style={{ width: "80px", textAlign: "center" }} />
                      </div>

                      <div className="col-sm" style={{ color: "green",fontSize:"14px" }}>
                        {
                          coponsList && coponsList !== null ?
                          coponsList.map((coupon, ind)=>{
                            return (
                              <div key={ind} >{coupon?.split('___')[0]} -  {coupon?.split('___')[1]} %</div>
                            )
                          })
                          : null
                        }
                      </div>
                      
                      <div className="col-sm" style={{ fontWeight: "bold",display:"flex",alignItems:"center",justifyContent:"center" }}>
                        <button className="edit btnPrimary1" style={{margin: 0, padding: 0, fontSize: 13,border:0 }} onClick={() => {updateCateDiscount(data[1], index);}}>Update Discount</button>
                      </div>
                      
                    </div>
                  )
                }) 
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageCatWiseDisco