import React, { useEffect, useState, useRef, confirm } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { Tooltip } from '@material-ui/core';
import Loader from '../Containers/Loader';
// import Tooltip from '@material-ui/core/Tooltip';

ReactSession.setStoreType("localStorage");

const CreateLedger = () => {
  const navigate = useNavigate();

  const grpRef = useRef();
  const grpRef2 = useRef();
  const [ledgers, setLedgers] = useState([{ key: -1, value: -1, label: 'None' }]);
  const [ledgerGroups, setLedgerGroups] = useState(null);
  const [selectedLedGrp, setSelectedLedGrp] = useState(null);
  const [loader, setLoader] = useState(false);
  const [editLedgerDet, setEditLedgerDet] = useState(null);
  
  // Edit Ledger Modal
  const [editLedgerModal, setEditLedgerModal] = useState(false);
  const editLedgerModalShow = () => setEditLedgerModal(true);
  const editLedgerModalClose = () => {
    setEditLedgerModal(false);
    setEditLedgerDet(null);
    setSelectedLedGrp(null);
  };
  
  // Open Edit Ledger
  const openEditLedger = (led) => {
    setEditLedgerDet(led);
    editLedgerModalShow();
    setSelectedLedGrp(led[2]);
  }

  //Edit Ledger
  const editLedger = () =>{

  }

  const handleLedgerGroup = (e) => {
    removeErrorMessage('ledGroup');
    setSelectedLedGrp(e.value);
  }

  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  const selectStyle2 = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      border: '1px solid black',
      borderRadius: '0px',
      width: '100%',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '1vw',
      background: "white",
      border: '1px solid black',
      borderRadius: '0px',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      border: '1px solid black',
      borderRadius: '0px',
      width:'100%',
    }),

    input: (provided, state) => ({
      ...provided,
      fontSize: '1vw',
    }),

    placeholder: (provided, state) => ({
      ...provided,
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      width: 36,  // Set the desired width
      height: 36, // Set the desired height
    }),

    valueContainer: (provided, state) => ({
      ...provided,
    }),
  }

  //Validate & Create Ledger
  const createLed = (ledgerD=null) => {
    var ledgerName = document.getElementById('ledgerName');
    var openingBal = document.getElementById('openingBal');
    
    var ledgerName1 = document.getElementById('ledgerName1');
    var openingBal1 = document.getElementById('openingBal1');
    
    var errorElements = [];
    // if (openingBal.value==='')
    //   errorElements.push('openingBal');
    if (editLedgerDet !== null)
    {
      if (ledgerName1.value==='')
        errorElements.push('ledgerName1');  
      
      if (selectedLedGrp === null)
        errorElements.push('ledGroup1');    
    }
    else{
      if (ledgerName.value==='')
        errorElements.push('ledgerName');  
      
      if (selectedLedGrp === null)
        errorElements.push('ledGroup');    
    }
    
    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {

      const filterLed = ledgers.filter( led =>
        led[3].toLowerCase().includes(ledgerName.value.toLowerCase()) || 
        ledgerName.value.toLowerCase().includes(led[3].toLowerCase())
      );

      // alert("Account Exists :"+filterLed);
      // confirm("skasd;")
      var userConfirmed = false;
      var successMsg = "Ledger Successfully Added";
      if (editLedgerDet === null && filterLed.length > 0)
      {
        userConfirmed = window.confirm("A similar ledger is already exists :"+filterLed[0][3]+"\nWould you like to continue....")
        // console.log('User confirmed:', userConfirmed);
      }
      else
      {
        successMsg = "Ledger Successfully Updated";
      }

      if (userConfirmed || editLedgerDet !== null || filterLed.length < 1)
      {
        document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
        setLoader(true);
        fetch('http://43.205.199.21/digiPartnerAPI/', {
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        body: JSON.stringify({
            ledID: editLedgerDet !== null ? editLedgerDet[0] : null,
            dp_id: ReactSession.get("id"),
            ledgerName: editLedgerDet !== null ? ledgerName1.value : ledgerName.value,
            openingBal : editLedgerDet !== null ? openingBal1.value : openingBal.value,
            selectedLedGrp: selectedLedGrp,
          })
        })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "validityExpired")
            {
              alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
            }

          if (data.status === 'success')
            {
              alert(successMsg);
              getLedgers();
            }
          else
            alert("Error Creating Ledgers");
            setLoader(false);
            editLedgerModalClose();
        })
        .catch((err) => {
          setLoader(false);
          console.log(err.message);
        });
      }
      else{
        ledgerName.focus();
      }
    }
  }
  
  //Delete Ledger
  const deleteLedger = (ledID) =>{
    setLoader(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'DELETE',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        led_Id: ledID,
        user_Id: ReactSession.get("id"),
      })
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }

      if (data.status === 'success')
      {
        alert("Ledger Successfully Deleted");
        getLedgers();
      }
      else
        alert("Error Deleting Ledgers");
        setLoader(false);
    })
    .catch((err) => {
      setLoader(false);
      console.log(err.message);
    });
  }

  // getLedgers
  const getLedgers = () => {
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?getLedgers=1&user_Id=' + dp_id;
    setLoader(false);
    fetch(apiURL, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      else
      {
        setLedgers(res.ledgers);
        setLedgerGroups(res.ledgerGroups);
      }
      setLoader(false);
    }).catch((err) => {
      setLoader(false);
      console.log(err.message);
    });
  }

  const [menuCollapse, setMenuCollapse] = useState(false);
  const CollapseMenu = (value) =>{
    if (value)
      {
        document.getElementById('DigiDashPanel').style.display = 'block';
        document.getElementById('dpSidePanelBTN').style.left='75vw';
      }
    else
    {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left='0px';
    }
    setMenuCollapse(value);
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
          else {
            getLedgers();
          }
  }, []);


  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer'>
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent' id='DigiDashContent' style={{ paddingInline: '2%', backgroundColor: 'white' }}>

          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={()=>CollapseMenu(!menuCollapse)}>
            { menuCollapse ? 
            <img src={require('../../static/images/Icons/left-arrow.png')} alt=''/>
            :
            <img src={require('../../static/images/Icons/right-arrow.png')} alt=''/>
            }
          </div>
        
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
          <div className='VoucherHeader' style={{fontWeight:700,fontSize:30}}>
            Create Ledger
          </div>
          
          <div style={{display:'flex', justifyContent:'space-between'}}>
            
            <div style={{ width:'50%', display:'block', alignItems:'center' }}>
              <div>
                <input className="ledgerName" style={{paddingInline:'5px', marginBlock:'1%',width:"30vw",padding:"7px",outline:0,border:"1px solid rgb(204, 204, 204)",borderRadius:"5px",marginBottom:20}} type='text' name="ledgerName" id="ledgerName" 
                onKeyDown ={(e)=> {
                  removeErrorMessage('ledgerName');
                  if (e.key === "Enter" || e.key === "Tab") {
                    document.getElementById('openingBal').focus();
                  }
                  }} placeholder='Ledger Name' autoFocus />
              </div>

              <div>
                <input className="openingBal" style={{paddingInline:'5px', marginBlock:'1%',width:"30vw",padding:"7px",outline:0,border:"1px solid rgb(204, 204, 204)",borderRadius:"5px",marginBottom:20}} type='text' name="openingBal" id="openingBal" onKeyDown ={(e)=> {
                  removeErrorMessage('openingBal');
                  if (e.key === "Enter" || e.key === "Tab") {
                    grpRef.current.focus();
                  }
                  }} placeholder='Opening Balance' autoFocus />
              </div>

              <div style={{ width: '50%', marginBlock:'1%' ,marginBottom:20}} className='ledGroup' id ='ledGroup'>
                <Select onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    createLed();
                  }
                }} options={ledgerGroups} ref={grpRef}
                onChange={handleLedgerGroup}
                isSearchable={true} placeholder={"Select Ledger Group"} className='select'/>
              </div>

              <div style={{ width: '30vw',display: 'flex',justifyContent:"space-between" }}>
                <div className='digiNavBtn' onClick={() => createLed()} style={{width:"49%",padding:8,fontSize:"14px",background:"rgb(44, 156, 247)",outline:0,border:0,color:"#fff",textAlign:"center",margin:0}}>Save</div>
                <div className='digiNavBtn' style={{width:"49%",padding:8,fontSize:"14px",background:"rgb(253, 81, 81)",outline:0,border:0,color:"#fff",textAlign:"center",margin:0}}>Exit</div>
              </div>

            </div>

            {/* Available Ledgers */}
            <div style={{width:'35%',position:"absolute",zIndex:99,right:0,top:"14%"}}>
              <div style={{fontSize:'105%', backgroundColor:'#000', padding:'5px', color:'white',padding:"10px"}}>Existing Ledgers</div>
              <div className="existLedgers customScroll" style={{overflowX:'scroll', height:'80vh', border:'1px solid lightgray', fontSize:'90%'}}>
                {
                  ledgers ? ledgers.map((item) => {
                    return (
                      <div className='searchResModal' style={{ display:'flex',paddingInline:"10px", justifyContent:'space-between', alignItems:'center',margin:0}}>
                        <div style={{cursor:"pointer",margin:0,padding:0}}>
                        {"("}{item[0]}{") "} {item[3]} {'-->'} {item[4]}
                        </div>

                        <div style={{display:'flex', justifyContent:'space-between', width:'5vw',alignItems:"center",gap:5}}>
                          <Tooltip title="Edit">
                            <div className='closeBtn' onClick={()=>openEditLedger(item)}>
                              <img src={require('../../static/images/Icons/edit.png')}/>
                            </div>
                          </Tooltip>
                          
                          <Tooltip title="Remove">
                            <div className='closeBtn' onClick={()=>deleteLedger(item[0])}>
                              <img src={require('../../static/images/Icons/trash.png')}/>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    )
                  }
                  ) : ''
                }
              </div>
            </div>
          </div>          
        </div>

        {/* Edit Ledger Modal */}
        <Modal show={editLedgerModal} onHide={editLedgerModalClose} size="lg" backdrop="static" keyboard={false}>
        <Modal.Body>
          {loader ? <Loader /> : ''}
          <div className='purchModalHeader'>
            <h3>Editing Ledger</h3>
            <div style={{ display: 'flex' }}>
              <div className='purchModalCloseBTN' onClick={editLedgerModalClose}>
                <img style={{ width: '2vw', height: '2vw' }} src={require('../../static/images/Icons/close1.png')} alt="" />
              </div>
            </div>
          </div>

          { editLedgerDet !== null ?
          <div style={{fontSize:'16px',color:"gray"}}>
            Ledger ID :  {editLedgerDet[0]}
          </div>
          :''}

          <div>
            <input className="ledgerName" style={{paddingInline:'5px', marginBlock:'1%',width:"100%",padding:"7px",outline:0,border:"1px solid rgb(204, 204, 204)",borderRadius:"5px"}} type='text' name="ledgerName1" id="ledgerName1" onKeyDown ={(e)=> {
              removeErrorMessage('ledgerName1');
              if (e.key === "Enter" || e.key === "Tab") {
                document.getElementById('openingBal1').focus();
              }
              }} placeholder='Ledger Name' autoFocus defaultValue={ editLedgerDet !== null ? editLedgerDet[3]  :'' } />
          </div>

          <div>
            <input className="openingBal" style={{paddingInline:'5px', marginBlock:'1%',width:"100%",padding:"7px",outline:0,border:"1px solid rgb(204, 204, 204)",borderRadius:"5px"}} type='text' name="openingBal1" id="openingBal1" onKeyDown ={(e)=> {
              removeErrorMessage('openingBal1');
              if (e.key === "Enter" || e.key === "Tab") {
                grpRef2.current.focus();
              }
              }} placeholder='Opening Balance' defaultValue={ editLedgerDet !== null ? editLedgerDet[5]  :'' } autoFocus />
          </div>

          <div style={{ width: '100%', marginBlock:'1%' }} className='ledGroup1' id ='ledGroup1'>
            <Select onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                createLed(editLedgerDet[0]);
              }
            }} options={ledgerGroups} ref={grpRef2}
            onChange={handleLedgerGroup} defaultValue = {{key:-1, value : editLedgerDet !== null ? editLedgerDet[2] : '', label: editLedgerDet !== null ? editLedgerDet[4] : ''}} 
            isSearchable={true} placeholder={"Select Ledger Group"} className='select'/>
          </div>

          <div style={{ width: '100%', paddingBlock: '1%', display: 'flex',justifyContent:"space-between" }}>
            <button style={{padding:8,fontSize:"14px",background:"rgb(44, 156, 247)",cursor:"pointer",outline:0,border:0,color:"#fff",textAlign:"center",margin:0,width:"49%",height:"40px",borderRadius:5}} onClick={() => createLed(editLedgerDet[0])}>Save Ledger</button>
            <button style={{padding:8,fontSize:"14px",background:"rgb(253, 81, 81)",cursor:"pointer",outline:0,border:0,color:"#fff",textAlign:"center",margin:0,width:"49%",height:"40px",borderRadius:5}} className='digiNavBtn'>Exit</button>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    </>
  )
}

export default CreateLedger