import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
// import SharedItems from '../../SharedItems';
import { Link } from 'react-router-dom';

const DashNavbar = () => {
  const navigate = useNavigate();

  // window.onclick = (e) =>{
  //   console.log("Product Window Clicked");
  //   clearSearch();
  // }

  window.onclick = function (e) {   
      // console.log("Product Window Clicked");  && e.target.className !="addProddropDown"
      var clss = document.getElementsByClassName("prodCatCont");
      for(let i = 0; i<clss.length;i++)
      {
         if (e.target.className !== "DropDownInput" && e.target.className !== "dropDownBtn" && e.target.className !== "addProddropDown")
        {
          clss[i].classList.replace("show","hide");
        }  
      }
    }

  return (
    <div className='dashboardNav'>
      <div className='dashNavItem'>
        <Link to="/Admin">
          <img className='dashLogo' src={require('../../static/DPImages/Logo.png')} alt="" />
        </Link>
      </div>

     <div className="dashNavLinks">
        <Link>Home</Link>
        <Link>Login</Link>
     </div>

    </div>
  )
}

export default DashNavbar