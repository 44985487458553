import React, { useEffect, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from "react-icons/io";
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import { MdOutlineClose } from "react-icons/md";
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { ReactSession } from 'react-client-session';
import Tooltip from '@material-ui/core/Tooltip';
import { HiOutlinePhoto } from "react-icons/hi2";
import Select from 'react-select';
import defProdImage from '../../static/images/Icons/defproducts.png';
import Modal from 'react-bootstrap/Modal';
import Loader from '../Containers/Loader';

const DispOrderDetailsAdmin = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const orderId = queryParameters.get("orderId");

  const [loader, setLoader] = useState(false)
  const [medPart, setMedPart] = useState(null);
  const [ref, setRef] = useState();
  var OrderItemCode = [];
  var orderItems = {};
  var totalAmount = 0;
  const [cityOptions, setCityOptions] = useState([{ key: 0, value: '', label: 'None' },]);
  const [stateOptions, setStateOptions] = useState([{ key: 0, value: '', label: 'None' },]);

  const [orderData, setOrderData] = useState(null);
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [presImages, setPresImages] = useState(null);
  const [prodFiles,  setProdFiles] = useState(null);
  const [pharmacyOptions, setPharmacyOptions] = useState(null);
  const [ordersTotals, setOrdersTotals] = useState(null);
  const [orderAddress, setOrderAddress] = useState(null);
  const [editRec, setEditRec] = useState(null);
  const [filCityShip, setFilterCityShip] = useState();
  // var filCityShip = {};

  const [filStateShip, setFilterStateShip] = useState();
  // var filStateShip = {};
  var selectedCityShip = "";
  const handleSelectCity = e => {
    selectedCityShip = e.value;
    const optionToSelect = cityOptions.find((option) => option.value === e.value);
    // filCityShip = optionToSelect;
    setFilterCityShip(optionToSelect);
  }

  var selectedStateShip = "";
  const handleSelectState = e => {
    selectedStateShip = e.value;
    const optionToSelect = stateOptions.find((option) => option.value === e.value);
    // filStateShip = optionToSelect;
    setFilterStateShip(optionToSelect);
  }

  //Select Styles
  const selectStyle = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      // paddingTop: '-10px',
      // lineHeight: '.5rem',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      background: "white",
      // fontSize: '80%',
      // border: '1px solid gray',
      // lineHeight: '.5rem',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      height: '15px',
      minHeight: '30px',
      // fontSize: '80%',
      // paddingTop: '-20px',
      // marginTop: '.4rem',
      // border: selectedCityShip !== null ? '1px solid gray' : '2px solid orangered',
    }),

    input: (provided, state) => ({
      ...provided,
      // marginTop:'0px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginTop: '-5px',
    }),
  }

  //Modals 
  const [addItem, setShowAddItem] = useState(false);
  const AddItemClose = () => {
    setShowAddItem(false);
    getOrdersDetails();
    // location.reload;
  }

  const AddItemShow = () => {
    setShowAddItem(true); 
  }

  //Utility Methods
  function addProdToClientOrder() {
    // addItemName addItemCode addquantity
    fetch('http://43.205.199.21/manageOrdersAPI/',
      {
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        body: JSON.stringify({
          // user_id : ReactSession.get('id'),
          orderId: orderId,
          addItemCode: document.getElementById('addItemCode').value,
          addItemName: document.getElementById('addItemName').value,
          addquantity: document.getElementById('addquantity').value
        })
      }).then((response) => response.json())
      .then((result) => {
        // console.log('Success:', result);
        AddItemClose();
      }).catch((error) => {
        console.error('Error:', error);
      });
  }


  const [isAddressEdit, setisAddressEdit] = useState(false);

  function ListProducts(props) {
    const myLists = props.myLists;
    const listItems = myLists.data.map((myList) =>
      <div className='searchResModal topPartition' onClick={() => {
        document.getElementById('addItemName').value = myList[1];
        document.getElementById('addItemCode').value = myList[0];
        document.getElementById('searchRes').classList.replace('show', 'hide');
      }}>
        <div className='row'>
          <div className='col-8' style={{ fontSize: '.8rem' }} >{myList[1]}</div>
          <div className='col-4' style={{ fontSize: '.8rem' }} >Price: {myList[5]}</div>
        </div>
        <div className='row'>
          <div className='col-8' style={{ fontSize: '.8rem' }} >{myList[2]}</div>
          <div className='col-4' style={{ fontSize: '.8rem' }} >MRP :
            <span style={{ textDecoration: 'line-through' }}>{Number(myList[4]).toFixed(2)} </span>
          </div>
        </div>
      </div>
    );
    return (
      <div>
        {listItems}
      </div>
    );
  }

  const searchProd = () => {
    var itemName = document.getElementById('addItemName');
    var serachDiv = document.getElementById('searchRes');
    if (itemName.value.length > 2) {
      setTimeout(
        function () {
          serachDiv.classList.replace('hide', 'show');
          fetch("http://43.205.199.21/selectProduct?sertext=" + itemName.value, {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          }).then((response) => {
            return response.json();
          }).then((resp) => {
            createRoot(serachDiv).render(<ListProducts myLists={resp} />);
          });
        }, 2000)
    }
    else
      serachDiv.classList.replace('show', 'hide');
  }

  const deleteRecord = (orderItemId) => {
    var orderdata = { orderItem: orderItemId, }
    fetch('http://43.205.199.21/userCartsAPI/', {
      mode: 'cors',
      method: 'DELETE',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(orderdata)
    })
      .then((response) => {
        response.json();
      })
      .then((data) => {
        getOrdersDetails();
      })
      .catch((err) => {
        console.log(err.message);
      });
    setRef(0);
  }

  const saveAddress = () => {
    // shipName shipAddr filCityShip filStateShip shipPin shipLand shipContact1 shipContact2
    document.getElementById("errorMessage").innerHTML ="<div>Red marked fields are mandatory</div>";
    var ErrorElements = [];
    var checkElements = ['shipName', 'shipAddr', 'shipPin', 'shipLand']
    for (let i=0; i<checkElements.length;i++)
    {
      var ele = document.getElementById(checkElements[i]);
      if (ele.value =="")
      {
        ErrorElements.push(checkElements[i]);
        ele.classList.add("errorField");
      }
    }
    
    if (isNaN(document.getElementById('shipContact1').value) || document.getElementById('shipContact1').value === "" || document.getElementById('shipContact1').value.length < 10)
    {
      ErrorElements.push('shipContact1');
      document.getElementById('shipContact1').classList.add("errorField");
      document.getElementById("errorMessage").innerHTML += "<br/>Contact must be numeric and must contains ten digits";
    }
   
    if (isNaN(document.getElementById('shipContact2').value) || document.getElementById('shipContact2').value === "" || document.getElementById('shipContact2').value.length < 10)
    {
      ErrorElements.push('shipContact2');
      document.getElementById('shipContact2').classList.add("errorField");
      document.getElementById("errorMessage").innerHTML += "<br/>Contact must be numeric and must contains ten digits";  
    }
    
    if (ErrorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(ErrorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      setLoader(true);
      fetch('http://43.205.199.21/manageOrdersAPI/', {
        mode: 'cors',
        method: 'PUT',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        body: JSON.stringify({
          ShippingAddressID: orderAddress[0][18],
          shipName: document.getElementById('shipName').value,
          shipAddr: document.getElementById('shipAddr').value,
          filCityShip: filCityShip.value,
          filStateShip: filStateShip.value,
          shipPin: document.getElementById('shipPin').value,
          shipLand: document.getElementById('shipLand').value,
          shipContact1: document.getElementById('shipContact1').value,
          shipContact2: document.getElementById('shipContact2').value,
        })
      }).then((response) => response.json())
        .then((resp) => {
          if (resp.status === "success") {
            alert("Shipping address updated successfully");
            setisAddressEdit(false);
            getOrdersDetails();
          }
          setLoader(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }    
  }

  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }


  const updateOrder = () => {
    let formData = new FormData();    //formdata object
    // returnPolicy addcomments newPrescription  
    formData.append("orderId", orderId);
    formData.append("returnPolicy", (document.getElementById("returnPolicy").Checked ? "1" : "0"));
    formData.append("returnDays", document.getElementById("returnDays").value);
    // formData.append("addcomments", document.getElementById("addcomments").value);
    formData.append("newPrescription", document.getElementById("newPrescription").files[0]);
    formData.append("MedPartOrder", medPart ? "1" : "0");

    fetch('http://43.205.199.21/manageOrdersAPI/',
      {
        mode: 'cors',
        method: 'POST',
        body: formData,
      }).then((response) => response.json())
      .then((result) => {
        navigate('/ViewAllOrders?orderType=pending')
      }).catch((error) => {
        console.error('Error:', error);
      });
  }

  const forwardOrder = (orderId) =>{
    //[Payload] forwardOrder, order_id, to_dp_id, from_dp_id, orderStatus
    var pharPart = document.getElementsByClassName('itemAvailCheckBox');
    var to_dp_id = [];
    for(var a= 0; a < pharPart.length; a++){
      // console.log("Check Attribute for id :",pharPart[a].id ,"  Check :", pharPart[a].getAttribute("che"))
      if (pharPart[a].getAttribute("che") === "true"){
          to_dp_id.push(pharPart[a].id);
        }
    }
    
    fetch('http://43.205.199.21/ordersAPI/',
      {
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          forwardOrder: 1,
          order_id: orderId,
          to_dp_id: to_dp_id,
          from_dp_id: "Admin0001",
          orderStatus: "Pending",
        })
      }).then((response) => response.json())
      .then((resp) => {
        if(resp.status === "success")
        {
          document.querySelector(".showRetailersScreenCon").classList.toggle("active");
          alert("Order Forwarded Successfully");
        }
      }).catch((error) => {
        console.error('Error:', error);
      });
  }

  // const orderDetails = prescriptionData.map((image) => {
  //   var base64Image = presImages[image];
  //   return <>
  //     <img src={`data:image/jpeg;base64,${base64Image}`} className='prodImage' alt="" />
  //   </>
  // })

  function calculateTotals(idn) {
    var mrp = document.getElementById("newMrp" + String(idn));
    var dis = document.getElementById("newDis" + String(idn));
    var qty = document.getElementById("newQty" + String(idn));
    var tot = document.getElementById("mrpTotal" + String(idn));
    var priceTotal = document.getElementById("priceTotal" + String(idn));
    var delCharge = document.getElementById("delCharge" + String(idn));
    if (parseFloat(mrp.value) > 0 && parseFloat(dis.value) > 0 && parseFloat(qty.value) > 0) {
      tot.value =  parseFloat(mrp.value) * parseFloat(qty.value);
      priceTotal.value = ((parseFloat(mrp.value) * parseFloat(qty.value))  - ((parseFloat(mrp.value) * parseFloat(qty.value)) * parseFloat(dis.value) / 100) ).toFixed(2);
    }
  }

  const viewUploadedPresImageModel = (image) => {
    let viewUploadedPresImageModel = document.querySelector(".viewUploadedPresImageModel");
    let viewUploadedPresImage = document.querySelector(".viewUploadedPresImage");
    viewUploadedPresImageModel.classList.add("active");
    viewUploadedPresImage.setAttribute("src", image);
  }

  const getOrdersDetails = () => {
    fetch("http://43.205.199.21/userCartsAPI?orderId=" + orderId, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "success")
      {
        setPharmacyOptions(res.pharmacyOption);
        const filCityID = res.citiesD.filter(cityid => {
          return cityid.label === res.addresses[0][2];
        });
        
        if (filCityID.length > 0) {
          setFilterCityShip(filCityID[0]);
        }
    
        const filStateID = res.statesD.filter(stateid => {
          return stateid.label === res.addresses[0][3];
        });
        if (filStateID.length > 0) {
          setFilterStateShip(filStateID[0]);
        }
        
        setPresImages(res.presImages);
        setPrescriptionData(res.presImagesData);
        setMedPart(res.medPart);
        setCityOptions(res.citiesD);
        setStateOptions(res.statesD);
        setProdFiles(res.prodfiles);
        setOrderData(res.data);
        setOrdersTotals(res.totals);
        setOrderAddress(res.addresses);
      }
    });
  }

  const saveRecord = (orderItmId) =>{
    fetch('http://43.205.199.21/manageOrdersAPI/',
      {
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        body: JSON.stringify({
          // user_id : ReactSession.get('id'),
          orderId: orderId,
          orderItmId: orderItmId,
          newMrp: document.getElementById('newMrp'+String(orderItmId)).value,
          newDis: document.getElementById('newDis'+String(orderItmId)).value,
          newQty: document.getElementById('newQty'+String(orderItmId)).value
        })
      }).then((response) => response.json())
      .then((resp) => {
        if (resp.status === "success")
        {
          getOrdersDetails();
        }
      }).catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    if (!ReactSession.get('adminSheet360ERP')) {
      navigate('/Admin');
    }
    else {
      getOrdersDetails();
    }
  }, []);

  return (
    <div>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
        { loader ? <Loader/> :'' }
        <div className='dashContents orderPage' id='dashContents' style={{ margin: "20px", background: "#fff" }}>
          <Row className='pendOrders' style={{ height: '7rem', marginTop: '10vh', zIndex: '1', borderBottom: "2px solid #000", width: "88vw", paddingLeft: 0 }}>
            <Col style={{ maxWidth: "97%" }}>
              <Row >
                <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                  <div className="row">
                    <img src={require("../../static/DPImages/back.png")} onClick={() => {
                      navigate("/ViewAllOrders?orderType=all")
                    }} style={{ width: "57px", height: "30px", cursor: "pointer", marginRight: -10 }} alt="" />
                    <h2 style={{ fontSize: 25, width: "fit-content" }}>Order Details</h2>
                    {
                      medPart && medPart.length > 0 ?
                        <>
                          <Col style={{ fontSize: 20}} md={4} lg={4} xl={4} sm={6} xs={12}>
                            {medPart[0][2]}
                          </Col>
                        </>
                        : null
                    }

                  </div>

                  <p style={{ margin: 0, padding: 0, display: "flex", alignItems: "center", marginTop: -8 }}>Order ID : {orderId} &nbsp;
                     | &nbsp; Date : {orderData && orderData.length > 0 ? 
                    <>
                      {String(orderData[0][12]).split('T')[0] } | Time : {String(orderData[0][12]).split('T')[1] }
                    </>
                    : null }
                    
                  </p>

                  <div className='dispOrderDetailsHead' style={{ width: "87vw", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ display: "flex", alignItems: "center", gap: 8, marginTop: 10 }}>
                      {
                        ordersTotals && ordersTotals[0][9] !==null ?
                        <input type='checkbox' style={{ marginTop: 5 }} name="returnPolicy" id="returnPolicy" defaultChecked />
                        :
                        <input type='checkbox' style={{ marginTop: 5 }} name="returnPolicy" id="returnPolicy" />
                      }
                      {/* <input type="checkbox" style={{ marginTop: 5 }} id = "returnPolicy" /> */}
                      <span style={{ marginTop: 5 }}>
                        Replacement Applicable Within <input style={{ width: 50 }} type="text" id = "returnDays" /> Days | Coupon : Not Available
                      </span>
                    </p>

                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

                      <button className="green" style={{ fontSize: 12, minWidth: "140px", padding: "2px 10px", cursor: "pointer", border: 0, outline: 0, color: "#fff", background: "#2fc02f", borderRadius: 5 }}>Generate Prescription + </button>
                      <button className="gray" style={{ fontSize: 12, minWidth: "140px", padding: "2px 10px", cursor: "pointer", border: 0, outline: 0, color: "#000", background: "rgb(168 170 171)", borderRadius: 5 }} onClick={() => {
                        document.getElementById("newPrescription").click();
                      }}>Upload Prescription + </button>
                      <input type='file' hidden name="newPrescription" id="newPrescription" />
                      <button className="blue" style={{ fontSize: 12, minWidth: "140px", padding: "2px 10px", cursor: "pointer", border: 0, outline: 0, color: "#fff", background: "#2fc02f", borderRadius: 5 }} onClick={() => { AddItemShow() }}>Add Product + </button>
                    </div>
                  </div>
                </Col>
                <Row>
                </Row>

              </Row>
            </Col>
          </Row>

          <Row className='allPenOrders' style={{marginTop:"27vh"}} id='dispOrders' >
            {/* Orders {orderId} */}
            <div >
              {orderData ? 
                orderData.map((prod, index) => {
                  {/* var base64Image = prodFiles[prod[4]]; */}
                  OrderItemCode.push(prod[0]);
                  return <div key={index}>
                    <Row style={{ marginBottom: 10, padding: "0px 12px", width: "75vw" }}>
                      {/* <Col md={1} lg={1} xl={1} sm={2} xs={6}>
                        <img src={`data:image/jpeg;base64,${base64Image}`} style={{ width: '100%' }} onError={(e) => { e.target.src = defProdImage }} alt="" />
                      </Col> */}
                      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                        <p style={{ marginTop: '0px', marginBottom: '0px', fontSize: 17 }}>{prod[5]}</p>
                        <p style={{ marginTop: '0px', marginBottom: '0px', color: "gray" }}> <span>{prod[8]}</span> | {prod[13]}</p>
                      </div>

                      <div style={{ display: "flex", flexWrap: "nowrap", padding: 0 }}>
                        <Col style={{ width: "200px", background: "gainsboro", marginRight: 5, display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }} md={3} lg={3} xl={3} sm={6} xs={6}>
                          {/* <p style={{ marginTop: '0px', marginBottom: '0px' }}>{prod[8]}</p> */}
                          <div className="adminOrderBox" >
                            <p style={{ marginTop: '0px', marginBottom: '0px' }}>MRP :</p>
                            {
                              editRec === prod[0] ?
                              <input  
                                onKeyPress={(e)=>
                                {
                                  if (e.key ==="Enter" || e.key ==="Tab")
                                  document.getElementById("newDis"+String(prod[0])).focus();
                                  
                                }} onChange={()=>calculateTotals(prod[0])} 
                                defaultValue={Number(prod[15]).toFixed(2)} className='manageOrderInput' type='text' name="newMrp" id={("newMrp"+String(prod[0]))}/>
                            :
                            <input defaultValue={Number(prod[15]).toFixed(2)} className='manageOrderInputDis' disabled type='text'/>  
                            }
                            
                          </div>
                        </Col>

                        <Col style={{ background: "gainsboro", display: "flex", alignItems: "center", justifyContent: "center", width: 150 }} md={2} lg={2} xl={2} sm={3} xs={6}>
                          <div className="adminOrderBox">
                            <p style={{ marginTop: '0px', marginBottom: '0px', color: "green", fontWeight: 'bold' }}>Discount</p>
                            {
                              editRec === prod[0] ?
                              <input className='manageOrderInput' 
                              onKeyPress={(e)=>
                              {
                                if (e.key ==="Enter" || e.key ==="Tab")
                                document.getElementById("newQty"+String(prod[0])).focus();
                              }} onChange={()=>calculateTotals(prod[0])}
                              defaultValue={((((prod[6] - prod[7])/ prod[3] ) * 100) / (prod[6]/prod[3])).toFixed(0)} type='text' name="newDis" id={("newDis"+String(prod[0]))}/>
                            :
                              <input defaultValue={((((prod[6] - prod[7])/ prod[3] ) * 100) / (prod[6]/prod[3])).toFixed(0)} className='manageOrderInputDis' disabled type='text' />
                            }
                          </div>
                        </Col>

                        <Col style={{ background: "gainsboro", marginLeft: 5, display: "flex", alignItems: "center", justifyContent: "center" }} md={2} lg={2} xl={2} sm={3} xs={6}>
                          <div className="adminOrderBox">
                            <p style={{ marginTop: '0px', marginBottom: '0px' }}>Quantity :</p>
                            
                            {
                              editRec === prod[0] ?
                              <input className='manageOrderInput'
                              onKeyPress={(e)=>
                              {
                                if (e.key ==="Enter" || e.key ==="Tab")
                                {
                                  setEditRec(null);
                                  saveRecord(prod[0]);
                                }
                              }} onChange={()=>calculateTotals(prod[0])}
                              defaultValue={prod[3]}  type='text' name="newQty" id={("newQty"+String(prod[0]))}/>
                            :
                            <input defaultValue={prod[3]} className='manageOrderInputDis' disabled type='text'/>  
                            }
                          </div>
                        </Col>

                        <Col style={{ background: "gainsboro", marginLeft: 5, display: "flex", alignItems: "center", justifyContent: "center" }} md={2} lg={2} xl={2} sm={3} xs={6}>
                          <div className="adminOrderBox">
                            <p style={{ marginTop: '0px', marginBottom: '0px' }}><span>MRP Total</span>
                            <input defaultValue={Number(prod[6]).toFixed(2)} className='manageOrderInputDis' disabled name="mrpTotal" id={("mrpTotal"+String(prod[0]))} type='text'/></p>
                          </div>
                        </Col>

                        <Col style={{ background: "gainsboro", marginLeft: 5, display: "flex", alignItems: "center", justifyContent: "center" }} md={2} lg={2} xl={2} sm={3} xs={6}>
                          <div className="adminOrderBox">
                            <p style={{ marginTop: '0px', marginBottom: '0px' }}><span>Price Total</span>
                            <input defaultValue={Number(prod[7]).toFixed(2)} className='manageOrderInputDis' disabled namne ="priceTotal" id={("priceTotal"+String(prod[0]))} type='text'/></p>
                          </div>
                        </Col>

                        {/* <Col md={5} lg={5} xl={5} sm={9} xs={9}>
                          <Row style={{ width: "fit-content" }}>
                            <Col style={{ background: "gainsboro", padding: 5, marginLeft: 5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "14vw", marginLeft: 16 }} md={4} lg={4} xl={4} sm={4} xs={6}>
                              <p style={{ textAlign: "center", minHeight: "100%", display: "flex", gap: 5, alignItems: "flex-end" }}><span>MRP Total</span> {Number(prod[6]).toFixed(2)}</p>
                            </Col>
                            <Col style={{ background: "gainsboro", padding: 5, marginLeft: 5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "14vw" }} md={4} lg={4} xl={4} sm={4} xs={6}>
                              <p style={{ textAlign: "center", minHeight: "100%", display: "flex", gap: 5, alignItems: "flex-end" }}><span>Price Total</span> { Number(prod[7]).toFixed(2)}</p>
                            </Col>
                          </Row>
                        </Col> */}

                        <Col style={{ display: "flex", alignItems: "center", gap: 10, marginBottom: 10 }} md={1} lg={1} xl={1} sm={2} xs={6}>
                  
                          {
                            editRec===prod[0] ?
                            <Tooltip title="Save">
                              <button className='ProdCol' onClick={()=>{
                                    setEditRec(null);
                                    saveRecord(prod[0]);
                                  }} >
                                <img style={{ width: 30 }} src={require('../../static/DPImages/save.png')} alt="" />
                              </button>
                            </Tooltip>
                            : editRec === null ?
                              <Tooltip title="Edit">
                                <button className='ProdCol' onClick={()=>{
                                      setEditRec(prod[0]);
                                      // document.getElementById("newMrp"+String(index)).focus();
                                    }} >
                                  <img style={{ width: 30 }} src={require('../../static/DPImages/edit.png')} alt="" />
                                </button>
                              </Tooltip>
                            :
                              <button style={{opacity:'.5'}} className='ProdCol'>
                                <img style={{ width: 30 }} src={require('../../static/DPImages/edit.png')} alt="" />
                              </button>
                          }


                          <Tooltip title="Remove">
                            <button className='ProdCol' onClick={() => deleteRecord(prod[0])}>
                              <img style={{ width: 30 }} src={require('../../static/images/Icons/trash.png')} alt="" />
                            </button>
                          </Tooltip>
                        </Col>
                      </div>

                    </Row>
                  </div>
                })
                : null
                }

              <div style={{ width: "98.5%", margin: "0px auto", borderBottom: "2px solid gray", borderTop: "2px solid gray", minHeight: 50, alignItems: "center", justifyContent: "center" }}>
                {
                  ordersTotals && ordersTotals.length > 0 ?
                  <>
                    <h3 style={{ fontSize: "1.3vw", marginTop: 5, color: "gray" }}>Total Item : {ordersTotals[0][0]} | MRP Total : {(parseFloat(ordersTotals[0][1])).toFixed(2)} | Discount Value : {Number(ordersTotals[0][2]).toFixed(2)} | Delivery Charges : <input style={{ width: '40px', border: "none", textAlign: "center" }} type='text' id="delCharge" defaultValue={ordersTotals[0][3]} />| <span style={{ color: "#000" }}>Payable Value : {Number(ordersTotals[0][4]).toFixed(2)}</span>
                      &nbsp;
                      &nbsp;
                    </h3>
                    {
                      ordersTotals[0][16] !== null ?
                      <>
                        <h3 style={{ fontSize: "1.3vw", marginTop: 5, color: "gray" }}>Selected Pharmacy : {ordersTotals[0][16]}, {ordersTotals[0][17]}, {ordersTotals[0][18]}, {ordersTotals[0][19]}</h3>
                        <h3 style={{ fontSize: "1.3vw", marginTop: 5, color: "gray" }}>Delivery Option : {String(ordersTotals[0][15]) === '0' ? 'Standard' : 'Express - Delivery Charges - '+String(ordersTotals[0][26])}</h3>
                      </>
                      : null
                    }
                  </>
                  : null
                }
              </div>

              <Row style={{height:'50vh', marginTop: '1rem', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {/* <Col md={3} lg={3} xl={3} sm={6} xs={6} >
                  Total Amount <input style={{ width: '100%' }} type='text' id="totAmt" defaultValue={(parseFloat(ordersTotals[0][2])).toFixed(2)} />
                </Col>
                <Col md={3} lg={3} xl={3} sm={6} xs={6} >
                  Total Quantity <input style={{ width: '100%' }} type='text' id="totQty" defaultValue={ordersTotals[0][0]} />
                </Col>
                <Col md={3} lg={3} xl={3} sm={6} xs={6} >
                  Delivery charges <input style={{ width: '100%' }} type='text' id="delCharge" defaultValue={ordersTotals[0][3]} />
                </Col>
                <Col md={3} lg={3} xl={3} sm={6} xs={6} >
                  Grand Total <input style={{ width: '100%' }} type='text' id="grandTot" defaultValue={ordersTotals[0][4]} />
                </Col> */}

                <div className="prescriptionDetalsCon">
                  <p style={{ color: "red", fontSize: 14 }}>Check here Uploaded Prescriptions by users</p>
                  {
                    prescriptionData && prescriptionData !== null ? prescriptionData.map((image) => {
                      var base64Image = presImages[image];
                      return <>
                        {/* <img src={`data:image/jpeg;base64,${base64Image}`} style={{width:40,height:40}} className='prodImage' alt="" /> */}
                        <button id={`data:image/jpeg;base64,${base64Image}`} onClick={(e)=>{viewUploadedPresImageModel(e.target.id)}} className="viewUploadedPresBtn"><i><HiOutlinePhoto /></i> View Uploaded Prescription</button>
                      </> 
                    }) : "Cart Items Did Not Required Prescription "
                  }

                  {/* view image model */}
                  <div className="viewUploadedPresImageModel">
                    <i onClick={()=>{
                      document.querySelector(".viewUploadedPresImageModel").classList.remove("active");
                    }}><IoMdClose /></i>
                    <img className="viewUploadedPresImage" src="" />
                  </div>
                </div>

                {
                isAddressEdit === true ?
                  <div>
                    <Row style={{ marginTop: '1rem' }}>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Name <input style={{ width: '100%' }} type='text' id="shipName" onChange={()=>removeErrorMessage('shipName')} defaultValue={orderAddress[0][4]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Address <input style={{ width: '100%' }} type='text' id="shipAddr" onChange={()=>removeErrorMessage('shipAddr')} defaultValue={orderAddress[0][5]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        City
                        <Select options={cityOptions} id="LocationCity1" defaultValue={filCityShip}
                          value={filCityShip}
                          onChange={handleSelectCity} placeholder="Select City" styles={selectStyle} />

                        {/*  <input style={{width:'100%'}} type='text' id = "shipCity"  defaultValue={orderAddress[0][2]}/> */}
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        State
                        <Select options={stateOptions} id="StateID1" defaultValue={filStateShip}
                          value={filStateShip}
                          onChange={handleSelectState} placeholder="Select State" styles={selectStyle} />
                        {/*  <input style={{width:'100%'}} type='text' id = "shipState"  defaultValue={orderAddress[0][3]}/> */}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: '1rem' }}>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Pincode <input style={{ width: '100%' }} type='text' onChange={()=>removeErrorMessage('shipPin')} id="shipPin" defaultValue={orderAddress[0][1]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Landmark <input style={{ width: '100%' }} type='text' onChange={()=>removeErrorMessage('shipLand')} id="shipLand" defaultValue={orderAddress[0][7]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Mobile <input style={{ width: '100%' }} type='text' onChange={()=>removeErrorMessage('shipContact1')} id="shipContact1" defaultValue={orderAddress[0][6]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Additional Mobile <input style={{ width: '100%' }} type='text' onChange={()=>removeErrorMessage('shipContact2')} id="shipContact2" defaultValue={orderAddress[0][8]} />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2rem" }}>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        <div className='orderManageBtn' onClick={() => {saveAddress();}}>Save Shipping Address</div>
                      </Col>
                      {/* <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        <div className='orderManageBtn'>Cancel Order & Notify</div>
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        <div className='orderManageBtn'>Confirm & Notify</div>
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        <div className='orderManageBtn' onClick={() => { navigate('/ViewAllOrders?orderType=pending') }}>Back to pending orders</div>
                      </Col> */}
                    </Row>
                  </div> : 
                  orderAddress && orderAddress.length > 0 ? 
                  <><div className="orderPageAddressDetailsCon" style={{ width: "40%", marginLeft: 10 }}>
                    <p style={{ margin: 0, padding: 0, color: "#000", fontSize: 20, display: "flex", alignItems: "center", gap: 10 }}>{orderAddress[0][4]} <img onClick={()=>setisAddressEdit(!isAddressEdit)} src={require("../../static/DPImages/edit.png")} style={{ width: 20, marginRight: 15, cursor: "pointer" }} alt="" /></p>
                    <p style={{ margin: 0, padding: 0, color: "gray" }}>{orderAddress[0][6]} | {orderAddress[0][8]}</p>
                    <p style={{ margin: 0, padding: 0, color: "gray" }}>{orderAddress[0][5]}, {orderAddress[0][7]}</p>
                    <p style={{ margin: 0, padding: 0, color: "gray" }}>{orderAddress[0][2]}, {orderAddress[0][3]}</p>
                    <p style={{ margin: 0, padding: 0, color: "gray" }}>{orderAddress[0][1]}</p>
                  </div>

                  <div style={{ width: "50%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", flexDirection: "column", marginBottom: "100px" }}>
                    <button style={{ padding: 7, marginRight: 10, border: 0, outline: 0, minWidth: "197px", fontSize: 13, background: "#DCDCDC", color: "#000", borderRadius: 10 }} onClick={() => { updateOrder() }}>Update & Approve</button>
                    <button style={{ marginTop: 10, padding: 7, marginRight: 10, border: 0, outline: 0, minWidth: "197px", fontSize: 13, background: "#DCDCDC", color: "#000", borderRadius: 10 }}>Confirm & Notify</button>
                    <button style={{ padding: 8, marginRight: 10, marginLeft: 37, border: 0, outline: 0, minWidth: "197px", borderRadius: 10, fontSize: 13, background: "#f62d2d", color: "#fff", marginTop: 10 }}>Cancel Order</button>
                  </div>
                  </>
                  : 
                  ''
              }
                {/* {
                  orderAddress && orderAddress.length > 0 ? 
                  <div className="orderPageAddressDetailsCon" style={{ width: "40%", marginLeft: 10 }}>
                    <p style={{ margin: 0, padding: 0, color: "#000", fontSize: 20, display: "flex", alignItems: "center", gap: 10 }}>{orderAddress[0][4]} <img onClick={()=>setisAddressEdit(!isAddressEdit)} src={require("../../static/DPImages/edit.png")} style={{ width: 20, marginRight: 15, cursor: "pointer" }} alt="" /></p>
                    <p style={{ margin: 0, padding: 0, color: "gray" }}>{orderAddress[0][6]} | {orderAddress[0][8]}</p>
                    <p style={{ margin: 0, padding: 0, color: "gray" }}>{orderAddress[0][5]}, {orderAddress[0][7]}</p>
                    <p style={{ margin: 0, padding: 0, color: "gray" }}>{orderAddress[0][2]}, {orderAddress[0][3]}</p>
                    <p style={{ margin: 0, padding: 0, color: "gray" }}>{orderAddress[0][1]}</p>
                  </div>
                  : 
                  ''
                } */}

                <button className="openRetailersButton" onClick={() => {
                  document.querySelector(".showRetailersScreenCon").classList.toggle("active");
                }}><span><img src={require("../../static/AdminPanelImages/parcel.png")} alt="" /> <b>{ pharmacyOptions && pharmacyOptions.length > 0 && orderData && orderData.length > 0 ? pharmacyOptions.length / orderData.length : 0} Retailers </b>are ready to fulfill this order</span> <i style={{ fontSize: 25, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><IoIosArrowUp /></i></button>

                <div className="showRetailersScreenCon customScroll">
                  <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                    <h4>Forward Order to Nearest Available Pharmacies</h4>
                    <i style={{ fontSize: "20px", color: "#000", cursor: "pointer" }} onClick={() => {
                      document.querySelector(".showRetailersScreenCon").classList.remove("active");
                    }}><MdOutlineClose /></i>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <p style={{ color: "gray" }}>Total Pharmacies {"("}{ pharmacyOptions && pharmacyOptions.length > 0 && orderData && orderData.length > 0 ? pharmacyOptions.length / orderData.length : 0} {")"} {">>"}</p>
                    <p style={{ cursor: "pointer", color: "#000" }}>Select all</p>
                  </div>

                  { pharmacyOptions && pharmacyOptions !== null ? 
                    pharmacyOptions.map((pharmacy, index) =>{
                      totalAmount += pharmacy[6]; 
                      if (index === 0 || pharmacy[1] !== pharmacyOptions[index-1][1])
                      {
                        return (
                          <div className="pharmacy">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <h4>{pharmacy[14]}  | {pharmacy[24]}</h4>
                              {/* <div className="checkBox"></div> */}
                              <div className="itemAvail">
                                <div className={`itemAvailCheckBox checkBox-${index}`} che="false" id={`${pharmacy[1]}`} onClick={(e) => {
                                  let elem = document.querySelector("." + e.target.classList[1]);
                                  if (elem.getAttribute("che") === "true") {
                                    elem.setAttribute("che", false);
                                  }
                                  else {
                                    elem.setAttribute("che", true);
                                  }
                                }}></div>
                              </div>
                            </div>

                            <p>{pharmacy[15]} | {pharmacy[16]}</p>
                            <p>{pharmacy[18]}, {pharmacy[19]}</p>
                            <p>{pharmacy[20]}</p>
                            <p>City : {pharmacy[24]} {pharmacy[25]}</p>
                            <p>Pincode : {pharmacy[23]}</p>
                            <b style={{ color: "#000" }}>PAYABLE : {Number(totalAmount).toFixed(2)} 
                            {/* <span style={{ color: "green" }}>{"(LESS .22)"}</span> */}
                            </b>
                            <p style={{ fontSize: 13 }}><span style={{ color: "red" }}>Location : 7 min</span> {"(2.3) km"} via State Bank Square Lalghati Road</p>
                          </div>
                        );
                      }
                      else
                      {
                        return false;  
                      }
                    })
                  :''}
                  
                  <button className="forwardButton" onClick={()=>forwardOrder(orderId)}>Forward Order Now</button>

                </div>

              </Row>

              {/* {
                isAddressEdit === true ?
                  <div>
                    <Row style={{ marginTop: '1rem' }}>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Name <input style={{ width: '100%' }} type='text' id="shipName" defaultValue={orderAddress[0][4]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Address <input style={{ width: '100%' }} type='text' id="shipAddr" defaultValue={orderAddress[0][5]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        City
                        <Select options={cityOptions} id="LocationCity1" defaultValue={filCityShip}
                          // value={filCityShip}
                          onChange={handleSelectCity} placeholder="Select City" styles={selectStyle} />

                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        State
                        <Select options={stateOptions} id="StateID1" defaultValue={filStateShip}
                          // value={filStateShip}
                          onChange={handleSelectState} placeholder="Select State" styles={selectStyle} />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: '1rem' }}>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Pincode <input style={{ width: '100%' }} type='text' id="shipPin" defaultValue={orderAddress[0][1]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Landmark <input style={{ width: '100%' }} type='text' id="shipLand" defaultValue={orderAddress[0][7]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Mobile <input style={{ width: '100%' }} type='text' id="shipContact1" defaultValue={orderAddress[0][6]} />
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        Additional Mobile <input style={{ width: '100%' }} type='text' id="shipContact2" defaultValue={orderAddress[0][8]} />
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "2rem" }}>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        <div className='orderManageBtn' onClick={() => { updateOrder() }}>Update</div>
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        <div className='orderManageBtn'>Cancel Order & Notify</div>
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        <div className='orderManageBtn'>Confirm & Notify</div>
                      </Col>
                      <Col md={3} lg={3} xl={3} sm={6} xs={6}>
                        <div className='orderManageBtn' onClick={() => { navigate('/ViewAllOrders?orderType=pending') }}>Back to pending orders</div>
                      </Col>
                    </Row>
                  </div> : ""
              } */}

            </div>
          </Row>
        </div>
      </div>

      <Modal show={addItem} onHide={AddItemClose} size="lg" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>Add Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='pendOrderRows'>
            <Col lg={12} md={12} xl={12} sm={12} xs={12}>
              <input type="text" name="addItemName" id="addItemName" placeholder='Item Name' style={{ width: '100%' }} onChange={() => { searchProd() }} />
              <div className='searchRes hide' id='searchRes'>Searching......</div>
            </Col>
          </Row>
          <Row className='pendOrderRows'>
            <Col lg={12} md={12} xl={12} sm={12} xs={12}>
              <input type="text" name="addItemCode" id="addItemCode" placeholder='Item code' style={{ width: '100%' }} />
            </Col>
          </Row>
          <Row className='pendOrderRows'>
            <Col lg={12} md={12} xl={12} sm={12} xs={12}>
              <input type="text" name="addquantity" id="addquantity" placeholder='Quantity' defaultValue={1} style={{ width: '100%' }} />
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={6} xl={6} sm={12} xs={12} >
            </Col>
            <Col lg={6} md={6} xl={6} sm={12} xs={12} >
              <button type='submit' className='btn btn-primary btn-sm' onClick={() => { addProdToClientOrder() }}>Submit</button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default DispOrderDetailsAdmin