import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";
// import { browserHistory } from "react-router";
import { FaUser } from "react-icons/fa";
import { AiFillProduct } from "react-icons/ai";
import { BiSolidCategoryAlt } from "react-icons/bi";
import { MdLogout } from "react-icons/md";


ReactSession.setStoreType("localStorage");

const DashPanel = () => {
  const navigate = useNavigate();


  function ToggleDropDown(dropdownID) {
    var dropdown = document.getElementById(dropdownID)
    var dropdownContent = dropdown.nextElementSibling;
    if (dropdown.classList.contains("active")) {
      dropdownContent.classList.replace("show", "hide");
      dropdown.classList.remove("active");
      dropdown.firstElementChild.classList.replace("fa-caret-left", "fa-caret-down")
    }
    else {
      dropdownContent.classList.replace("hide", "show");
      dropdown.classList.add("active");
      dropdown.firstElementChild.classList.replace("fa-caret-down", "fa-caret-left")
    }
    return true;
  }

  function adminLogout() {
    ReactSession.remove("adminsession");
    ReactSession.remove("adminSheet360ERP");

    navigate("/Admin");
    window.location.reload(true);
  }

  function toggleSidePanel() {
    let dashPanel = document.querySelector(".dashPanel");
    let dashContents = document.querySelector(".dashContents");
    let dispOrderDetailsHead = document.querySelector(".dispOrderDetailsHead");
    let pendOrders = document.querySelector(".pendOrders");
    let orderRows = document.getElementsByClassName("orderRows");

    dashPanel.classList.toggle("closePanel");

    if(dashPanel.classList.contains("closePanel")){
      dashContents.classList.add("dashContentClose");
      if(dispOrderDetailsHead){
        dispOrderDetailsHead.style.width = "100%";
      }
      if(pendOrders){
        pendOrders.style.width = "100%";
      }
      if(orderRows){
        Array.from(orderRows).forEach((e,i)=>{
          e.style.width = "90vw"
        })
      }
    }
    else{
      dashContents.classList.remove("dashContentClose");
      if(dispOrderDetailsHead){
        dispOrderDetailsHead.style.width = "100%";
      }
      if(pendOrders){
        pendOrders.style.width = "100%";
      }
      if(orderRows){
        Array.from(orderRows).forEach((e,i)=>{
          e.style.width = "80vw"
        })
      }
    }

  }

  useEffect(() => {    
    let dashContents = document.querySelector(".dashContents");
    let dashPanel = document.querySelector(".dashPanel");

    if(dashPanel.classList.contains("closePanel")){
      dashContents.classList.add("dashContentClose");
    }
    else{
      dashPanel.classList.add("closePanel")
      dashContents.classList.add("dashContentClose");
    }
  }, [])
  

  return (
    <div style={{ fontSize: '90%' }} className='adminDashPanel'>

      <div className='dashPanelTopHead'>
        <h3>Hi, Admin</h3>
        <img src={require("../../static/DPImages/Icons/menu-open.png")} alt="" onClick={toggleSidePanel} />
      </div>

      <button className="dropdown" id="UserDropDown" onClick={() => ToggleDropDown("UserDropDown")}>
        <div className='dropDownFlex'>
          <i><FaUser /></i>
          <span>User Reports</span>
        </div>
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="dropdowncontainer hide">
        <Link to='/UserReportAdmin'>Registered Users</Link>
        <a href={() => false} onClick={() => navigate('/UserReportAdmin?pendVeri=1')}>Pending Verifications</a>
      </div>

      <div className="dashPanelLink" onClick={() => { navigate('/ViewAllOrders?orderType=all') }}>
        <img src={require("../../static/AdminPanelImages/orders.png")} alt="" />
        <a href={() => false}>Orders</a>
      </div>


      <button className="dropdown" id="ProductDropDown" onClick={() => ToggleDropDown("ProductDropDown")}>
        <div className="dropDownFlex">
          <i><AiFillProduct /></i>
          <span>Products</span>
        </div>
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="dropdowncontainer hide">
        <Link to='/ManageSaltGroups'>Substitute Group</Link>
        <Link to='/Products'>Manage Products</Link>
        <a onClick={()=>{navigate("/productAvailability")}}>Product Availability</a>
        <Link to='/CategoryDiscount'>Category Wsie Discount</Link>
        <Link to='/ManageCoupons'>Manage Coupons</Link>
        <a href={() => false}>Product Details</a>
        <a href={() => false}>Max Buying Quantity</a>
        <a href={() => false}>Service Tax</a>
        <a href={() => false}>Online Discounts</a>
        <a href={() => false}>Product Report</a>
        <a href={() => false}>Notification reports</a>
        <a href={() => false}>Single Click Product</a>
      </div>

      <div className="dashPanelLink" onClick={() => navigate("/ManageCategories")}>
        <i><BiSolidCategoryAlt /></i>
        <a href={() => false}>Manage Categories</a>
      </div>

      <div className="dashPanelLink" onClick={() => navigate("/ManageComp")}>
        <img src={require("../../static/AdminPanelImages/companies.png")} alt="" />
        <a href={() => false}>Manage Companies</a>
      </div>

      <div className="dashPanelLink" onClick={() => navigate("/ManageStockist")}>
        <img src={require("../../static/AdminPanelImages/trend.png")} alt="" />
        <a href={() => false}>Manage Stockist</a>
      </div>

      <div className="dashPanelLink" onClick={() => navigate("/ManageSubscriptionPlans")}>
        <img src={require("../../static/images/Icons/subscription.png")} alt="" />
        <a href={() => false}>Manage Subscriptions</a>
      </div>

      <div className="dashPanelLink">
        <img src={require("../../static/AdminPanelImages/group.png")} alt="" />
        <Link to='/manageDigitalPartners'>Digital Partner</Link>
        {/* <a href={() => false}>Digital Partner</a> */}

      </div>

      <div className="dashPanelLink">
        <img src={require("../../static/AdminPanelImages/electric-truck.png")} alt="" />
        <a href={() => false}>Delivery Charges</a>
      </div>

      <button className="dropdown" id="ContentDropDown" onClick={() => ToggleDropDown("ContentDropDown")}>
        <div>
          <img src={require("../../static/AdminPanelImages/content.png")} alt="" />
          <span>{"(Sheet360) Content"}</span>
        </div>
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="dropdowncontainer hide">
        <a onClick={()=>{navigate("/addAboutUsContent")}}>About Us</a>
        <a onClick={()=>{navigate("/termsAndConditionContent")}}>Terms & Conditions</a>
        <a onClick={()=>{navigate("/faqsContent")}}>Faqs</a>
        <a onClick={()=>{navigate("/privacyPolicyContent")}}>Privacy Policy</a>
      </div>


      <button className="dropdown" id="Content2DropDown" onClick={() => ToggleDropDown("Content2DropDown")}>
        <div>
          <img src={require("../../static/AdminPanelImages/content.png")} alt="" />
          <span>{"(Sheet360ERP) Content"}</span>
        </div>
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="dropdowncontainer hide">
        <a onClick={()=>{navigate("/addAboutUsContent")}}>About Us</a>
        <a onClick={()=>{navigate("/termsAndConditionContent")}}>Terms & Conditions</a>
        <a onClick={()=>{navigate("/faqsContent")}}>Faqs</a>
        <a onClick={()=>{navigate("/privacyPolicyContent")}}>Privacy Policy</a>
      </div>

      <div className="dashPanelLink">
        <img src={require("../../static/AdminPanelImages/testimonial.png")} alt="" />
        <a href={() => false}>Testimoials</a>
      </div>
      {/* <a href={() => false}>Doctors</a> */}

      <div className="dashPanelLink">
        <img src={require("../../static/AdminPanelImages/location.png")} alt="" />
        <a href={() => false}>Location</a>
      </div>

      <div className="dashPanelLink">
        <img src={require("../../static/AdminPanelImages/payment-protection.png")} alt="" />
        <a onClick={() => {navigate("/citywisePayments")}}>City Wise Payments</a>
      </div>

      <div className="dashPanelLink">
        <img src={require("../../static/AdminPanelImages/wallet.png")} alt="" />
        <a href={() => false}>Add E-wallet Amount</a>
      </div>

      <div className="dashPanelLink">
        <img src={require("../../static/AdminPanelImages/gallery.png")} alt="" />
        <a href={() => false}>Manage Slide Show</a>
      </div>

      <button className="dropdown" id="SliderDropDown" onClick={() => ToggleDropDown("SliderDropDown")}>
        <div className="dropDownFlex">
          <i><AiFillProduct /></i>
          <span>Manage Slide Show</span>
        </div>
        <i className="fa fa-caret-down"></i>
      </button>
      <div className="dropdowncontainer hide">
        <Link to='/manageSlider/app'>Application Slider</Link>
        <Link to='/manageSlider/sheet'>SheetERP Slider</Link>
      </div>

      {/* <a href={() => false}>Send Bulk SMS/E-Mail</a> */}
      {/* <a href={() => false}>System</a> */}

      <div className="dashPanelLink">
        <img src={require("../../static/AdminPanelImages/mobile-store.png")} alt="" />
        <a href={() => false}>Delivery App</a>
      </div>

      <div className="dashPanelLink">
        <i><MdLogout /></i>
        <a href={() => false} onClick={() => adminLogout()}>Logout</a>
      </div>
    </div>
  )
}

export default DashPanel