import Logo from '../Containers/Logo'
import Footer from '../Containers/Footer'
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';

const MyOrders = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);;

    const [pagesize, setPageSize] = useState(25);
    const [noofRec, setNoofRec] = useState(0);
    const [myOrderData, setMyOrderData] = useState(null);

    const topPage = () => {
        if (page > 1) {
            setPage(1)
        }
    }

    const prevPage = () => {
        // console.log("Revious Clicked :" + page);
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const nextPage = () => {
        // console.log("Next Clicked ...");
        if (page <= parseInt(noofRec / pagesize)) {
            setPage(page + 1)
        }
    }

    const lastPage = () => {
        if (page < parseInt(noofRec / pagesize)) {
            setPage(parseInt(noofRec / pagesize) + 1)
        }
    }

    const GetOrders = () => {
        fetch("http://43.205.199.21/ordersAPI?userId=" + ReactSession.get('id_eu') + "&page=" + String(page) + "&pagesize=" + String(pagesize), {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        }).then((response) => {
            return response.json()
        }).then((res) => {
            setMyOrderData(res.data);
            setNoofRec(res.noofRec);
            document.getElementById('pageNo').placeholder = page;
        });
    }

    useEffect(() => {
        GetOrders();
    }, [page]);

    return (
        <>
            <Logo />
            {/* <NavSearchBar /> */}
            <p className="MyHeader">My Orders</p>
            <hr width="80%" size="8" className="MyDivider" style={{ marginLeft: '10%' }} />

            <div className="OrderPagination">
                <button className="pageNav" onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
                <button className="pageNav" onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
                <input size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
                <button className="pageNav " onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
                <button className="pageNav" onClick={() => lastPage()} >Bottom</button>
            </div>


            <div className='MyOrdersL' id='MyOrdersL'>
                {myOrderData ?
                    myOrderData.map((myOrder) => {
                        // var orderDate = (myOrder[3]).substring(0, 10).split("-");
                        var deDate = myOrder[12] !== null ? (myOrder[12]).substring(0, 10).split("-") : ['', '', ''];

                        return <div className='MyOrderList' onClick={() => { navigate('/OrderSummary?orderId=' + myOrder[0]) }}>
                            <Row>
                                <Col lg={2} md={2} xl={2} sm={4} xs={4}>
                                    {/* <div style={{fontSize:'80%'}} >Order Date : {orderDate[2]}/{orderDate[1]}/{orderDate[0]}</div> */}
                                    <div style={{ fontSize: '80%' }} >Order Date : {new Date(myOrder[3]).toLocaleString("en-GB")}</div>
                                </Col>
                                <Col lg={1} md={1} xl={2} sm={4} xs={4}>
                                    <div style={{ fontSize: '80%', textAlign: 'right' }} >Quantity : {myOrder[4]}</div>
                                </Col>
                                <Col lg={2} md={2} xl={2} sm={4} xs={4}>
                                    <div style={{ fontSize: '80%', textAlign: 'right' }} > Total Billing : {Number(myOrder[10]).toFixed(2)}</div>
                                </Col>
                                <Col lg={2} md={2} xl={2} sm={4} xs={4}>
                                    <div style={{ fontSize: '80%' }} > Order Status : {myOrder[11]}</div>
                                </Col>
                                <Col lg={2} md={2} xl={2} sm={4} xs={4}>
                                    <div style={{ fontSize: '80%', textAlign: 'right' }} > Expected Delivery : {deDate[2]}/{deDate[1]}/{deDate[0]}</div>
                                </Col>
                                <Col lg={2} md={2} xl={2} sm={4} xs={4}>
                                    <div className="OrderPagination" style={{ marginBottom: '0px', marginTop: '.5rem' }}><button>Open</button></div>
                                </Col>
                            </Row>
                            {/* <Row >
                        <Col lg={1} md={1} xl={1} sm={1} xs={1}>
                            <div style={{fontSize:'80%',textAlign:'right'}} > Delivery Charges : {Number(myOrder[8]).toFixed(2)}</div>
                        </Col>    
                    </Row> */}
                        </div>
                    })
                    : ''
                }
            </div>
            <Footer />
        </>
    )
}

export default MyOrders