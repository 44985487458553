import React from 'react'
import DigiPartNavBar from '../DigitalPartner/DigiPartnerContainers/DigiPartNavBar'

const NoInternet = () => {
  return (
  <>
    <DigiPartNavBar/>
    <div className="container noInternet" style={{height:"100vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
     <img src={require("../../static/images/Icons/no-signal.png")} style={{width:120,height:120}} alt="" />
     <h3 style={{paddingTop:15,borderTop:"1px solid #000",marginTop:15,width:"400px",textAlign:"center"}}>No Internet</h3>
     
     <ul>
      <p style={{margin:0,padding:0,color:"gray"}}>Try</p>
      <li style={{marginLeft:0,color:"gray"}}>Checking the network cables, modem, and router</li>
      <li style={{marginLeft:0,color:"gray"}}>Reconnecting to Wi-Fi</li>    
     </ul>
    </div>
  </>
  )
}

export default NoInternet