import React, { useEffect, useRef, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { ReactSession } from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import { LuSearch } from 'react-icons/lu';
import Loader from '../Containers/Loader';

const ManageSubscriptionPlans = () => {
  const navigate = useNavigate();
  // Select Styles

  const [isLoading, setIsLoading] = useState(false);

  const selectStyle = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      background: "white",
      border: '1px solid gray',
      lineHeight: '.5rem',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      paddingTop: '-20px',
      height: '20px',
      marginTop: '.4rem',
      minHeight: '30px',
      border: '1px solid gray',
    }),

    input: (provided, state) => ({
      ...provided,
    }),
    placeholder: (provided, state) => ({
      ...provided,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginTop: '-5px',
    }),
  }

  const [selectedCate, setSelectedCate] = useState(null);
  const [subsCategories, setSubsCategories] = useState([
    { value: 1, label: "Pharmacy" },
    { value: 2, label: "Distributer" },
    { value: 3, label: "Retail + Distributer" },
    { value: 4, label: "Virtual Pharmacy" },
  ]);

  const [allSubscriptions, setAllSubscriptions] = useState(null);
  const [manageSubsModal, setManageSubsModal] = useState(false);
  const setManageSubsModalShow = () => setManageSubsModal(true);
  const setManageSubsModalClose = () => setManageSubsModal(false);
  const [editSubscription, setEditSubscription] = useState(null);

  const grpRef = useRef(0);

  const handleSubsCategory = (e) => {
    removeErrorMessage('category');
    const selectedOption = subsCategories.find((option) => option.value === e.value);
    setSelectedCate(selectedOption);
  }

  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  const validateManageSubsForm = () => {
    // category subsName sysAccess validity subsCharges
    var errorElements = [];
    if (selectedCate === null)
      errorElements.push("category");

    if (document.getElementById("subsName").value === "")
      errorElements.push("subsName");
    
    if (document.getElementById("sysAccess").value === "")
      errorElements.push("sysAccess");
    else
      if (isNaN(document.getElementById("sysAccess").value)) {
        errorElements.push("sysAccess");
        document.getElementById("errorMessage").innerHTML += "<br />System Access must contain only digits";
      }
    
    if (document.getElementById("validity").value === "")
      errorElements.push("validity");
    else
      if (isNaN(document.getElementById("validity").value)) {
        errorElements.push("validity");
        document.getElementById("errorMessage").innerHTML += "<br />Validity must contain only digits";
      }

    if (document.getElementById("subsCharges").value === "")
      errorElements.push("subsCharges");
    else
      if (isNaN(document.getElementById("subsCharges").value)) {
        errorElements.push("subsCharges");
        document.getElementById("errorMessage").innerHTML += "<br />Subscription charges must contain only digits";
      }

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      saveSubscription();
    }
  }

  const saveSubscription = () =>{
    // selectedCate, subsName dashboard sysAccess billingSoftware loAppVisi panInAppVisi loStoreVisi loUserOrder loPharOrder loOrderPickDel panIndStoreVisi panIndUserOrder panIndPharOrder panIndOrderPickDeli salesStockState expiryMgmt nonMovProMgmt transactions reports custSupport isActive validity subsCharges, prev_id, reusable
    setIsLoading(true);
    fetch('http://43.205.199.21/manageSubscriptionsAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        subs_plan_id : editSubscription && editSubscription !== null ? editSubscription[0] : null,
        prev_plan_id : editSubscription && editSubscription !== null ? editSubscription[28] : null,
        category: selectedCate.label,
        subsName: document.getElementById('subsName').value,
        dashboard: document.getElementById('dashboard').checked ? "1" : "0",
        sysAccess: document.getElementById('sysAccess').value,
        billingSoftware: document.getElementById('billingSoftware').checked ? "1" : "0",
        loAppVisi: document.getElementById('loAppVisi').checked ? "1" : "0",
        panInAppVisi: document.getElementById('panInAppVisi').checked ? "1" : "0",
        loStoreVisi: document.getElementById('loStoreVisi').checked ? "1" : "0",
        loUserOrder: document.getElementById('loUserOrder').checked ? "1" : "0",
        loPharOrder: document.getElementById('loPharOrder').checked ? "1" : "0",
        loOrderPickDel: document.getElementById('loOrderPickDel').checked ? "1" : "0",
        panIndStoreVisi: document.getElementById('panIndStoreVisi').checked ? "1" : "0",
        panIndUserOrder: document.getElementById('panIndUserOrder').checked ? "1" : "0",
        panIndPharOrder: document.getElementById('panIndPharOrder').checked ? "1" : "0",
        panIndOrderPickDeli: document.getElementById('panIndOrderPickDeli').checked ? "1" : "0",
        salesStockState: document.getElementById('salesStockState').checked ? "1" : "0",
        expiryMgmt: document.getElementById('expiryMgmt').checked ? "1" : "0",
        nonMovProMgmt: document.getElementById('nonMovProMgmt').checked ? "1" : "0",
        transactions: document.getElementById('transactions').checked ? "1" : "0",
        reports: document.getElementById('reports').checked ? "1" : "0",
        custSupport: document.getElementById('custSupport').checked ? "1" : "0",
        isActive: document.getElementById('isActive').checked ? "1" : "0",
        reusable: document.getElementById('reusable').checked ? "1" : "0",
        validity: document.getElementById('validity').value,
        subsCharges: document.getElementById('subsCharges').value,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (editSubscription !== null)
          alert("Subscription Successfully Updated");
        else
          alert("Subscription Successfully Added");
        setEditSubscription(null);
        setSelectedCate(null);
        setManageSubsModalClose();
        GetSubscriptions();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  }

  const deleteSubscription = (subsId) =>{
    var confDel = prompt("Enter admin password for confirm deletion :");
    if (confDel === 'secret') {
      fetch('http://43.205.199.21/manageSubscriptionsAPI/', {
        mode: 'cors',
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          subsId: subsId,
        })
      }).then((response) => {
        response.json();
        GetSubscriptions();
      })
        .then((data) => {
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Deletion request denied..!")
  }

  const GetSubscriptions = () => {
    fetch("http://43.205.199.21/manageSubscriptionsAPI?getSubscriptions=1", {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "success") {
        setAllSubscriptions(res.allSubscriptions);
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  useEffect(() => {
    if (!ReactSession.get('adminSheet360ERP')) {
      navigate('/Admin');
    }
    else {
      GetSubscriptions();
    }
  }, []);

  return (
    <div>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents' id='dashContents'>
          { isLoading ? <Loader /> : null}
          <h3 style={{ fontSize: 30, fontWeight: 600, marginLeft: 20 }}>Manage Subscriptions</h3>

          <div className='subscriptionRow'>
            {/* Vertical Header */}
            <div style={{ fontWeight: 'bold', width:'40vw' }}>
              {/* subs_plan_id, subs_category, subs_name, dashboard, sys_access, billing_software, lo_app_visibility, pan_in_app_visibility, lo_store_visibility, lo_users_orders, lo_phar_order, lo_order_pick_del, store_vis_pan_in, user_order_pan_india, phar_order_pan_india, pan_in_order_pick_del, sales_stock_state, exp_management, non_mov_prod_mana, transactions, reports_PnL_Led_CA, cust_sup, validityDays, charges, is_active, created_at, updated_at */}
              <div style={{}} className="subscriptionCells">Categories</div>
              <div style={{}} className="subscriptionCells">Subscription Plans</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">DashBoard</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">System Access</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Billing Software</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Local application visibility</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Pan india application visibility</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Local store visibility</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Local users orders</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Local pharmacy order</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Local order pickup and delivery</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Store visibility pan india</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">User order pan india</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Pharmacy order pan india</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Pan india order pickup and delivery</div>
              {/* , non_mov_prod_mana, transactions, reports_PnL_Led_CA, cust_sup, validityDays, charges, is_active, created_at, updated_at */}
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Sales and Stock Statements</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Expirty management</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Non movable product management</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Transactions</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Reports P&L, Ledgers and CA reports</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Customer Support</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Validity Days</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Subscription Charges</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Is Active</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Reusable</div>
              <div style={{ justifyContent: 'flex-start' }} className="subscriptionCells">Action</div>
            </div>

            {
              allSubscriptions && allSubscriptions !== null ?
                allSubscriptions.map((subscription) => {
                  return (
                    <div className='subscriptionCol'>
                      <div style={{ fontWeight: 'bold' }} className="subscriptionCells">{subscription[1]}</div>
                      <div style={{ fontWeight: 'bold' }} className="subscriptionCells">{subscription[2]}</div>
                      {parseInt(subscription[3]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      <div style={{}} className="subscriptionCells">{subscription[4]}</div>
                      {parseInt(subscription[5]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[6]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[7]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[8]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[9]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[10]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[11]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[12]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[13]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[14]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[15]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[16]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[17]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[18]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[19]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[20]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[21]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      <div style={{ fontWeight: 'bold' }} className="subscriptionCells">{subscription[22]}</div>
                      <div style={{ fontWeight: 'bold' }} className="subscriptionCells">{subscription[23]}</div>
                      {parseInt(subscription[24]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      {parseInt(subscription[29]) === 1 ? <div style={{ color: 'green', }} className="subscriptionCells">Yes</div> : <div style={{ color: 'orange', }} className="subscriptionCells">No</div>}
                      
                      <div style={{ fontWeight: 'bold', justifyContent:'space-between'}} className="subscriptionCells">
                      &nbsp;&nbsp;&nbsp;
                      <img onClick={() => {
                        const selectedOption = subsCategories.find((option) => option.label === subscription[1]);
                        setSelectedCate(selectedOption);
                        setEditSubscription(subscription);
                        setManageSubsModalShow();
                      }} className="catActionBtn" src={require('../../static/images/edit.png')} alt="" data-toggle="tooltip" data-placement="top" title="Edit"/>
                      &nbsp;&nbsp;&nbsp;
                      <img className="catActionBtn" onClick={() => deleteSubscription(subscription[0])} src={require('../../static/images/trash.png')} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                      &nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  )
                })
                : null
            }
          </div>

          <div className='addNewCat' style={{ border: 0, outline: 0 }} onClick={() => {
            setEditSubscription(null);
            setManageSubsModalShow();
          }}>
            <img style={{ width: "70px", height: "70px", borderRadius: "50%" }} src={require('../../static/AdminPanelImages/add.gif')} alt="" />
          </div>
          <div className='copyRight'>
            Sheet360ERP Copyright &copy; 2023:  All Right Reserved
          </div>
        </div>
      </div>

      {/* Add/Edit Stockist Modal */}
      <Modal show={manageSubsModal} onHide={setManageSubsModalClose} size="xl" backdrop="static" keyboard={false} >
        <Modal.Header closeButton className='purchModalheader'>
          <Modal.Title>{editSubscription ? 'Edit' : 'Add'} Subscription Plans</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <form name="addStockist" action="" method="post"> */}
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          { isLoading ? <Loader /> : null}

          <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
            <div className="col-6 col-md-6 col-lg-6 col-sm-6">
              <div className="dpLogin" style={{ display: 'flex', alignItems:'center', justifyContent:'space-between' }}>
                {/* <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/user.png')} alt="" /> */}
                <div>Subscription Category</div>
                <div id="category">
                  <Select 
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Tab") {
                        console.log("Creating ");
                      }
                    }} 
                    options={subsCategories} ref={grpRef}
                    value={selectedCate}
                    onChange={handleSubsCategory}
                    isSearchable={true} placeholder={"Select Category"} className='select' />
                </div>
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-6 col-sm-6">
              <div className="dpLogin" style={{ display: 'flex', alignItems:'center', justifyContent:'space-between' }}>
                <div>Subscription Plan</div>
                <div>
                  <input className="digiPartLoginInput" name="subsName" id="subsName" type="text" style={{ width: '25vw', border: '1px solid gray', borderRadius: '3px' }}
                    onKeyDown={(e) => {
                      removeErrorMessage('category')
                      if (e.key === "Enter") {
                        document.getElementById("dlNo").focus();
                      }
                    }} defaultValue={editSubscription ? editSubscription[2] : ''} onKeyPress={() => { removeErrorMessage("subsName") }} placeholder="Subscription Name" />
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: '.1vw' }}>
            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">

                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="dashboard">Dashboard</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[3] === 1 ? true : false} name="dashboard" id="dashboard" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <label>System Access</label>
                  </div>
                  <div>
                    <input style={{ width: '10vw', textAlign: 'right', border: '1px solid gray', borderRadius: '3px' }}
                      onKeyDown={()=>removeErrorMessage('sysAccess')}
                      className="digiPartLoginInput" defaultValue={editSubscription ? editSubscription[4] : ""} name="sysAccess" id="sysAccess" type="text" placeholder="System Access" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="billingSoftware">Billing Software</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[5] === 1 ? true : false} name="billingSoftware" id="billingSoftware" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="loAppVisi">Local Application Visibility</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[6] === 1 ? true : false} name="loAppVisi" id="loAppVisi" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">


                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="panInAppVisi">Pan india application visibility</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[7] === 1 ? true : false} name="panInAppVisi" id="panInAppVisi" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">

                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="loStoreVisi">Local Store Visibility</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[8] === 1 ? true : false} name="loStoreVisi" id="loStoreVisi" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">

                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="loUserOrder">Local User's Order</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[9] === 1 ? true : false} name="loUserOrder" id="loUserOrder" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">

                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="loPharOrder">Local pharmacy orders</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[10] === 1 ? true : false} name="loPharOrder" id="loPharOrder" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">

                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="loOrderPickDel">Local order pickup and delivery</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[11] === 1 ? true : false} name="loOrderPickDel" id="loOrderPickDel" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">

                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="panIndStoreVisi">Store visibility pan india</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[12] === 1 ? true : false} name="panIndStoreVisi" id="panIndStoreVisi" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="panIndUserOrder">User's order pan india</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[13] === 1 ? true : false} name="panIndUserOrder" id="panIndUserOrder" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="panIndPharOrder">Pharmacy order pan india</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[14] === 1 ? true : false} name="panIndPharOrder" id="panIndPharOrder" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="panIndOrderPickDeli">Pan india order pickup and delivery</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[15] === 1 ? true : false} name="panIndOrderPickDeli" id="panIndOrderPickDeli" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="salesStockState">Sales and Stock statements</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[16] === 1 ? true : false} name="salesStockState" id="salesStockState" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="expiryMgmt">Expiry management</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[17] === 1 ? true : false} name="expiryMgmt" id="expiryMgmt" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="nonMovProMgmt">Non movable product management</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[18] === 1 ? true : false} name="nonMovProMgmt" id="nonMovProMgmt" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="transactions">Transactions</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[19] === 1 ? true : false} name="transactions" id="transactions" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="reports">Reports, Profit & Loss, Ledgers & CA reports</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[20] === 1 ? true : false} name="reports" id="reports" type="checkbox" />
                  </div>
                </div>
              </div>

            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <label htmlFor="custSupport">Customer support</label>
                  </div>
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[21] === 1 ? true : false} name="custSupport" id="custSupport" type="checkbox" />
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6" style={{display:'flex'}}>
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent:'flex-start' }}>
                  <div>
                    <label htmlFor="isActive">Is Active</label>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[24] === 1 ? true : false} name="isActive" id="isActive" type="checkbox" />
                  </div>
                </div>

                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                  <div>
                    <label htmlFor="reusable">Is Reusable</label>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <input className="digiPartLoginInput" defaultChecked={editSubscription && editSubscription[29] === 1 ? true : false} name="reusable" id="reusable" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '.1vw', fontSize: '1vw' }}>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <label>Valid for no of days</label>
                  </div>
                  <div>
                    <input style={{ width: '10vw', textAlign: 'right', border: '1px solid gray', borderRadius: '3px' }} className="digiPartLoginInput" defaultValue={editSubscription ? editSubscription[22] : ''} name="validity" id="validity" type="text" 
                      onKeyDown={()=>removeErrorMessage('validity')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="dpLogin" style={{ paddingBlock: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <label htmlFor="loAppVisi">Subscription Charges</label>
                  </div>
                  <div>
                    <input style={{ width: '10vw', textAlign: 'right', border: '1px solid gray', borderRadius: '3px' }} className="digiPartLoginInput" defaultValue={editSubscription ? editSubscription[23] : ''} name="subsCharges" id="subsCharges" type="text" 
                      onKeyDown={()=>removeErrorMessage('subsCharges')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="loginLogo" style={{ marginTop: '1.5vw' }}>
            <div className="row">
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="digiSiginBtn" id="digiSiginBtn"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validateManageSubsForm();
                    }
                  }}
                  onClick={validateManageSubsForm}> {editSubscription ? "Update" : "Create"}</div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="digiSiginBtn" id="digiSiginBtn"
                  onClick={()=>{
                    setEditSubscription(null);
                    setSelectedCate(null);
                    setManageSubsModalClose();
                  }}>Cancel</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ManageSubscriptionPlans
