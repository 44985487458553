import './App.css';
import './AppMSS.css';
import './AppMSM.css';
import Router from './Components/Router';
import { useNavigate ,Navigate} from 'react-router-dom';
import { useEffect } from 'react';
import './Components/admin/admin.css';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    //Disable right click

    // Function to handle offline event
    const handleOffline = () => {
      navigate('/noInternet');
    };

    // Function to handle online event
    const handleOnline = () => {
      const currentUrl = window.location.href;
      var CurURL = (currentUrl.split('//')[1]).substring(0,15);
      
      if  (CurURL === "sheet360erp.com" || CurURL === "localhost:3000/" || CurURL === "sheet360erp.org")
        navigate("/DigitalPartner");
      else
        navigate('/');
    };

    // Add event listeners for online and offline events
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    // Check initial online status
    if (!navigator.onLine) {
      navigate('/noInternet');
    }

    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu);
    // return function cleanup() {
    //     document.removeEventListener('contextmenu', handleContextmenu);
    // }

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, [navigate]);

  return (
    <div className='MyContainer'>
      <Router />
    </div>
  );
}

export default App;
