import React from 'react'
import DigiPartNavbar from "../DigitalPartner/DigiPartnerContainers/DigiPartNavBar"
import DigiPartFooter from "../DigitalPartner/DigiPartnerContainers/DigiPartFooter"

const AboutUs = () => {
  return (
  <>

  <DigiPartNavbar/>

  <div className="aboutUsImg">
    <img src={require("../../static/DPImages/aboutbanner.jpg")} alt="" />
  </div>

    <div className="aboutUs">

    <b>About Us</b>

    <p>Welcome to Sheet360 ERP, where innovation meets efficiency in enterprise resource planning solutions. With a passion for
      streamlining business processes and empowering organizations to reach their full potential, we stand at the forefront of the digital
      transformation era.</p>

    <p>At Sheet360 ERP, we understand the complexities of modern business operations. Our mission is to simplify these complexities
      through cutting-edge technology, intuitive design, and unparalleled support. Whether you&#39;re a small startup or a multinational
      corporation, our scalable ERP solutions are tailored to meet your unique needs.</p>

    <p>Driven by a team of industry experts and seasoned software developers, we combine years of experience with a forward-thinking
      approach to deliver solutions that not only meet but exceed expectations. From finance and accounting to supply chain
      management and human resources, our comprehensive suite of modules ensures that every aspect of your business is seamlessly
      integrated and optimized for success.</p>


    <p>What sets us apart is our commitment to customer satisfaction. We believe that our success is measured by the success of our
      clients, which is why we go above and beyond to provide personalized support and guidance every step of the way. From
      implementation and training to ongoing maintenance and updates, we&#39;re dedicated to helping you maximize the value of your
      investment.</p>

    <p>Join the thousands of businesses worldwide who have chosen Sheet360 ERP to drive their growth and stay ahead of the
      competition. Experience the power of simplicity with our innovative ERP solutions.</p>

    

   

    </div>

    <DigiPartFooter/>
  </>
  )
}

export default AboutUs