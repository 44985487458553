import React, { useEffect, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import DashHome from '../DashContainers/DashHome'
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

const Admin = () => {
  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  function CheckLogin() {
    var errorElements = [];
    var loginID = document.getElementById("adminId");
    var pwd = document.getElementById("adminPwd");
    // var apiURL = 'http://43.205.199.21/userAPI?loginID=' + loginID.value + '&password=' + pwd.value;

    // verLogState(JSON.parse(null));

    if (loginID.value === "")
      errorElements.push("adminId");

    if (pwd.value === "")
      errorElements.push("adminPwd");

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      if (loginID.value === "admin" && pwd.value === "secret") {
        document.getElementById('dashboard').classList.replace("hide", "show")
        document.getElementById('adminLoginMaincon').classList.replace("show", "hide")
        ReactSession.set("adminsession", true);
        ReactSession.set("adminSheet360ERP", true);
      }
      else {
        document.getElementById("errorMessage").innerHTML = "Invalid Credentials........!";
        document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
        loginID.focus();
        return false;
      }
    }
  }

  // const Admin = () =>

  const OnWindowLoad = () => {
    if (ReactSession.get('adminSheet360ERP')) {
      document.getElementById('dashboard').classList.replace("hide", "show");
      document.getElementById('adminLoginMaincon').classList.replace("show", "hide");
    }
    else
      document.getElementById('adminLoginMaincon').classList.replace("hide", "show");
    return true;
  }

  useEffect(() => {
    OnWindowLoad();
  }, []);

  const [isOpenPanel, setisOpenPanel] = useState(false);


  return (
    <>

      <div className="adminLoginMaincon hide" id="adminLoginMaincon">

        <div className='adminLogin divshadow MyHeader' id="adminLogin">
          <h4>Login Credentials</h4>
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
          <input className="LoginInput" type="text" name="adminId" id="adminId" onKeyPress={() => { removeErrorMessage("adminId") }} placeholder='Admin ID' autoFocus />
          <input className="LoginInput" type="password" name="adminPwd" id="adminPwd" onKeyPress={() => { removeErrorMessage("adminPwd") }} placeholder='Admin Secret' />

          <div className="flexDiv">
            <div>
              <input type="checkbox" />
              <p>Remember Me</p>
            </div>

            <a >Forgot Password?</a>
          </div>

          <div className="loginLogo">
            <button className="adminLoginBtn" onClick={() => { CheckLogin() }} >Sign In</button>
            <br />
          </div>
        </div>

        <div style={{ position: "absolute", bottom: "10px", fontSize: 14 }}>
          <p>www.Sheet360Erp.com &#169; 2024 | All Right Reserved</p>
        </div>

      </div>


      <div className='hide' id='dashboard'>
        <DashNavbar />
        <div className='dashContainer'>
          <div className={`dashPanel ${isOpenPanel === false ? "closePanel" : ""}`}>
            <DashPanel />
          </div>
          <div className='dashContents' style={{background:"#C5C5C5"}}>
            <DashHome />
          </div>
        </div>
      </div>
    </>
  )
}

export default Admin