import React from 'react'
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <div align="center">
      <Link to="/">
        <img src={require("../../static/images/Pillsit_Logo.png")} className = "logoImg" alt="jsx-a11y/img-redundant-alt"/>
      </Link>
    </div>
  )
}

export default Logo