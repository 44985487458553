import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';

const Brands = () => {
  return (
    <>
      <div className="brandsSlider">
        <h1>Affiliation With Top Brands</h1>

        <Swiper
        slidesPerView={5}
        spaceBetween={30}
        freeMode={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          412: {
            slidesPerView: 1,
            spaceBetween:0
          },
          300: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView:1,
          },
          1024: {
            slidesPerView: 5,
          },
        }}

        modules={[Autoplay, FreeMode, Pagination]}
        className="brandsSliderContainer"
      >
        <SwiperSlide>
        <img src={require("../../static/DPImages/Baidyanath_logo.png")} alt="" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../../static/DPImages/Dabur-Logo.wine.png")} alt="" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../../static/DPImages/Cipla_logo.svg.png")} alt="" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../../static/DPImages/Logo_Bayer.svg.png")} alt="" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={require("../../static/DPImages/Macleod.png")} alt="" />
        </SwiperSlide>

        <SwiperSlide>
        <img src={require("../../static/DPImages/Merck_&_Co.-Logo.wine.png")} alt="" />
        </SwiperSlide>

        <SwiperSlide>
        <img src={require("../../static/DPImages/P&G_logo.png")} alt="" />
        </SwiperSlide>

        <SwiperSlide>
        <img src={require("../../static/DPImages/Sanofi-Logo-2011.png")} alt="" />
        </SwiperSlide>

        <SwiperSlide>
        <img src={require("../../static/DPImages/NVO_BIG-f838aae1.png")} alt="" />
        </SwiperSlide>

        <SwiperSlide>
        <img src={require("../../static/DPImages/emami-group3970.png")} alt="" />
        </SwiperSlide>


        <SwiperSlide>
        <img src={require("../../static/DPImages/nestle-logo-black-and-white.png")} alt="" />
        </SwiperSlide>

        <SwiperSlide>
        <img src={require("../../static/DPImages/Pfizer_logo.svg.png")} alt="" />
        </SwiperSlide>
      </Swiper>

      </div>
    </>
  )
}

export default Brands