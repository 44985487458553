import React from 'react'
import DigiPartNavbar from "../DigitalPartner/DigiPartnerContainers/DigiPartNavBar"
import DigiPartFooter from "../DigitalPartner/DigiPartnerContainers/DigiPartFooter"

const TermsConditions = () => {
  return (
    <>
      <DigiPartNavbar />
      <div className="aboutUsImg">
        <div className="center">
          <img src={require("../../static/DPImages/terms-and-conditions.png")} alt="" />
          <h3>Terms & Conditions</h3>
        </div>
        <img src={require("../../static/DPImages/termsBg.jpg")} alt="" />
      </div>

      <div className="aboutUs">
        <div className="termsCon">
          <b>Terms &amp; Conditions</b>

          <p>These terms and conditions (&quot;Terms&quot;) govern your use of Sheet360 ERP (&quot;the Software&quot;), provided by Sheet360 ERP (&quot;the
            Company&quot;). By accessing or using the Software, you agree to be bound by these Terms. If you do not agree with any part of these
            Terms, you may not use the Software.</p>

          <p>1. License Grant <br />
            The Company grants you a non-exclusive, non-transferable, revocable license to use the Software solely for your internal business
            purposes, subject to these Terms.</p>


          <p>2. User Accounts <br />
            You may be required to create an account to access certain features of the Software. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities that occur under your account.</p>

          <p>3. Intellectual Property <br />
            The Software, including all related intellectual property rights, is and will remain the property of the Company. You may not
            reproduce, modify, distribute, or create derivative works based upon the Software.</p>

          <p>4. Data Privacy <br />
            The Company respects your privacy and is committed to protecting your personal data. Our Privacy Policy outlines how we collect,
            use, and disclose your information when you use the Software. By using the Software, you consent to the terms of our Privacy
            Policy.</p>

          <p>5. Support and Maintenance <br />
            The Company may provide support and maintenance services for the Software, subject to additional terms and fees as agreed upon
            between the parties.</p>

          <p>6. Limitation of Liability <br />
            To the extent permitted by law, the Company shall not be liable for any direct, indirect, incidental, special, or consequential damages
            arising out of or in any way connected with your use of the Software.</p>

          <p>7. Termination <br />
            The Company may suspend or terminate your access to the Software at any time, with or without cause, and without notice. Upon
            termination, your license to use the Software shall cease, and you must cease all use of the Software.</p>

          <p>8. Governing Law <br />
            These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of
            law principles.</p>

          <p>9. Modifications <br />
            The Company reserves the right to modify these Terms at any time, effective upon posting the modified Terms on the Company&#39;s
            website or within the Software. Your continued use of the Software after such modifications shall constitute your acceptance of the
            modified Terms.</p>

          <p>10. Entire Agreement <br />
            These Terms constitute the entire agreement between you and the Company regarding your use of the Software and supersede all
            prior and contemporaneous agreements, proposals, or representations, whether oral or written, relating to such subject matter.</p>

          <p>If you have any questions about these Terms, please Email us at <a href="">info@sheet360erp.com</a></p>

        </div>

        <div className="disclaimer">
          <b>Disclaimer</b>

          <p>Sheet360 Technology Private Limited (www.Sheet360Erp.com) provides this Enterprise Resource Planning (ERP) software solely
            for informational and educational purposes. By accessing or using our software, you agree to abide by the following disclaimer.</p>

          <ol type='1'>
            <li>No Sale of Goods or Services : <br />
              Sheet360 Technology Private Limited (www.Sheet360Erp.com) is not a seller of goods or services. Our ERP software is a tool
              designed to streamline business operations, enhance efficiency, and facilitate management processes. We do not engage in the
              sale, distribution, or procurement of any physical or digital products.</li>

            <li>Informational Purposes Only : <br />
              The information contained within our ERP software, including but not limited to data, reports, analyses, and recommendations, is
              provided for informational purposes only. It should not be construed as professional advice, financial guidance, or a substitute for
              independent business judgment.</li>

            <li>No Warranties : <br />
              While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or
              implied, regarding the completeness, accuracy, reliability, suitability, or availability of our ERP software or the information contained
              therein. Any reliance you place on such information is strictly at your own risk.</li>

            <li>Limitation of Liability : <br />
              In no event shall Sheet360 Technology Private Limited (www.Sheet360Erp.com) be liable for any direct, indirect, incidental, special,
              or consequential damages arising out of or in any way connected with the use of our ERP software, whether based on contract, tort,
              strict liability, or otherwise, even if advised of the possibility of such damages.</li>

            <li>Third-Party Links : <br />
              Our ERP software may contain links to third-party websites or resources. These links are provided solely as a convenience to you
              and do not imply any endorsement, sponsorship, or affiliation by Sheet360 Technology Private Limited (www.Sheet360Erp.com).
              We have no control over the content or availability of these sites and shall not be responsible or liable for any damages or losses
              arising from their use.</li>

            <li>Modification of Terms : <br />
              Sheet360 Technology Private Limited (www.Sheet360Erp.com) reserves the right to modify, amend, or update this disclaimer at any
              time without prior notice. Your continued use of our ERP software after any such changes constitutes acceptance of the revised
              terms.</li>

            <li>Governing Law : <br />
              This disclaimer shall be governed by and construed in accordance with the laws of Bhopal. Any disputes arising under this
              disclaimer shall be subject to the exclusive jurisdiction of the courts in Bhopal.</li>


          </ol>
          <p>By using our ERP software, you acknowledge that you have read, understood, and agreed to be bound by this disclaimer.</p>

          <p>If you have any questions or concerns regarding this disclaimer, please Email us at <a href="">info@sheet360erp.com</a></p>

        </div>

      </div>

      <DigiPartFooter />
    </>
  )
}

export default TermsConditions