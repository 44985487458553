import React, { useEffect, useState} from 'react';
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from '../DigitalPartner/DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from '../DigitalPartner/DigiPartnerContainers/DigiPartnerSidePanel';
import { useNavigate } from "react-router-dom";

const ContactUs = () => {

  const navigate = useNavigate();

  const [contactLeft, setContactLeft] = useState(false);
  
  let sidePanel = document.querySelector(".DigiDashPanel");


  // if(sidePanel.style.width === "30vw"){
  //   setContactLeft(true)
  // }
  // else{
  //   setContactLeft(false)
  // }

  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  const verifyContacts = () => {
    var errorElements = [];

    var neccessaryFields = [];

    neccessaryFields = ["f_name", "l_name", "email", "number", "msg"];
    for (let i = 0; i < neccessaryFields.length; i++) {
      if (document.getElementById(neccessaryFields[i]).value === "") {
        errorElements.push(neccessaryFields[i]);
        document.getElementById(neccessaryFields[i]).classList.add("errorField");
      }
    }

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!document.getElementById("email").value.match(validRegex)) {
      errorElements.push("email");
      document.getElementById("errorMessage").innerHTML = "Please enter a vallid E-mail ID";
    }

    if (document.getElementById("number").value.length < 10) {
      errorElements.push("number");
      document.getElementById("errorMessage").innerHTML += "<br />Mobile must contain 10 digits";
    }

    if (isNaN(document.getElementById("number").value)) {
      errorElements.push("number");
      document.getElementById("errorMessage").innerHTML += "<br />Mobile must contain only digits";
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      createInquiry();
    }
  }

  const createInquiry = () => {
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        inquiry: 1,
        fname: document.getElementById('f_name').value,
        lname: document.getElementById('l_name').value,
        email: document.getElementById('email').value,
        mobile: document.getElementById('number').value,
        msg: document.getElementById('msg').value,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          alert("Thanks for contacting SheetERP, we will get you back soon.");
          navigate('/DigitalPartner');
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(()=>{
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    // console.log("Use Effect running");
  }, [])

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent' id='DigiDashContent' style={{background:"#fff"}}>
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
          {/* style={{left:contactLeft === false ? "50vw" : "60vw"}} */}
            <div className={contactLeft === false ? "contactUsform" : "active contactUsform"} >
              <div className="contactInputs">
                <h2>Send a Message</h2>
  
                <div className="flex">
                  <input className='contactInputsinput' type="text" placeholder='First Name' name='f_name' id='f_name' onKeyPress={() => { removeErrorMessage("f_name") }} />
                  <input className='contactInputsinput' type="text" placeholder='Last Name' name='l_name' id='l_name' onKeyPress={() => { removeErrorMessage("l_name") }} />
                </div>

                <div className="flex" style={{marginTop:"10px",marginBottom:"10px"}}>
                  <input className='contactInputsinput' type="email" placeholder='Email Address' name='email' id='email' onKeyPress={() => { removeErrorMessage("email") }} />
                  <input className='contactInputsinput' type="number" placeholder='Mobile Number' name='number' id='number' onKeyPress={() => { removeErrorMessage("number") }} />
                </div>

                <textarea className='contactInputstextarea' name="msg" id="msg" placeholder='Write your message here...' onKeyPress={() => { removeErrorMessage("msg") }}></textarea>

                <button className="sendContactForm" onClick={() => verifyContacts()}>Send</button>
              </div>
            </div>

            {/* Info */}

            <div className="contactInfo">
              <h2>Contact Info</h2>
              <hr />

              <p>EMAIL <br /> sheet360.india@gmail.com</p>
              <p>CALL | WHATSAPP <br /> 9111749346 </p>
              <p>LANDLINE <br /> 0755 - 3138448</p>

              <hr style={{width:"10px"}}/>

              <p style={{ textTransform: "uppercase" }} className='socialNetHead'>Social Network</p>

              <div className="flex gap-3 socialNet">
                <img src={require("../../static/images/SocialNetworks/facebook2.png")} alt="" />
                <img src={require("../../static/images/SocialNetworks/instagram1.png")} alt="" />
                <img src={require("../../static/images/SocialNetworks/twitter1.png")} alt="" />
                <img src={require("../../static/images/SocialNetworks/linkedin1.png")} alt="" />
                <img src={require("../../static/images/SocialNetworks/youtube2.png")} alt="" />
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs