import React from 'react'
import DigiPartNavbar from "../DigitalPartner/DigiPartnerContainers/DigiPartNavBar"
import DigiPartFooter from "../DigitalPartner/DigiPartnerContainers/DigiPartFooter"

const PrivacyPolicy = () => {
  return (
    <>
      <DigiPartNavbar />
      <div className="aboutUsImg">

        <img src={require("../../static/DPImages/privacypolicy.jpg")} alt="" />

      </div>

      <div className="aboutUs">
        <b>Privacy Policy</b>

        <p>Sheet360 ERP (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) is committed to protecting the privacy of our users. This Privacy Policy explains how we collect,
          use, and disclose personal information when you use our ERP software (&quot;the Software&quot;).</p>

        <p>1. Information We Collect <br />
          We may collect personal information when you use our Software, including but not limited to :</p>

        <ul>
          <li>Contact Information: such as your name, email address, phone number, and company name.</li>
          <li>User Account Data: such as login credentials and account preferences.</li>
          <li>Usage Data: such as information about how you use the Software, including log data and analytics.</li>
          <li>Customer Support Data: such as inquiries and support tickets you submit to us.</li>
        </ul>

        <p>3. Data Sharing and Disclosure <br />
          We may share your personal information with third parties in the following circumstances:</p>

        <ul>
          <li>With service providers who assist us in operating the Software and providing services to you.</li>
          <li>With our affiliates and business partners for marketing and promotional purposes, with your consent.</li>
          <li>In response to a legal request or to comply with applicable laws and regulations.</li>
          <li>In connection with a merger, acquisition, or sale of assets, if applicable.</li>
        </ul>

        <p>4. Data Retention <br />
          We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a
          longer retention period is required or permitted by law.</p>

        <p>5. Data Security <br />
          We implement appropriate technical and organizational measures to protect the security of your personal information and prevent
          unauthorized access, disclosure, alteration, or destruction.</p>

        <p>6. Your Rights <br />
          You may have certain rights regarding your personal information, including the right to access, correct, or delete your information.
          Please contact us using the information provided below to exercise these rights.</p>

        <p>7. Updates to this Privacy Policy <br />
          We may update this Privacy Policy from time to time to reflect changes in our data practices or legal requirements. We will notify you
          of any material changes by posting the updated Privacy Policy on our website or within the Software.</p>

        <p>8. Contact Us <br />
          If you have any questions or concerns about this Privacy Policy or our data practices, please Email us at <a href="">info@sheet360erp.com</a></p>

      </div>

      <DigiPartFooter />
    </>
  )
}

export default PrivacyPolicy