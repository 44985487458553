import React from 'react'

const Loader = () => {
  return (
    <div className='loaderComponent'>
      <img style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -70%)', width:100}} src={require('../../static/AdminPanelImages/loader.gif')} alt=""/>
    </div>
  )
}

export default Loader