import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Modal from 'react-bootstrap/Modal';
import Loader from '../Containers/Loader';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../DatePicker.css';

ReactSession.setStoreType("localStorage");

const DPDashboard = () => {
  const navigate = useNavigate();
  // const [stockistData, setStockistData] = useState(null);
  // const [stockistFillData, setStockistFillData] = useState(null);
  // totSales allOrders pendingOrders yearMonthSales
  const [nonMoFillter, setNonMoFillter] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [loader, setLoader] = useState(false);

  const [viewProdModal, setViewProdModal] = useState(false)
  const viewProdModalClose = () => {
    setNonMoFillter(false);
    setViewProdModal(false);
  }

  const viewProdModalShow = (nonMov = false) => {
    setNonMoFillter(nonMov);
    setViewProdModal(true);
  }

  const [originalDate, setOriginalDate] = useState(new Date());
  const [expProdCo, setExpProdCo] = useState(null);
  const [nonMovableProdCo, setNonMovableProdCo] = useState(null);

  const [totSales, setTotSales] = useState(null);
  const [allOrders, setAllOrders] = useState(null);
  const [pendingOrders, setPendingOrders] = useState(null);
  const [yearMonthSales, setYearMonthSales] = useState(null);
  const [incomeExpenses, setIncomeExpenses] = useState(null);

  const [maxSale, setMaxSale] = useState(null);
  const [minSale, setMinSale] = useState(null);

  const [maxIncExp, setMaxIncExp] = useState(null);
  const [minIncExp, setMinIncExp] = useState(null);

  const [totInv, setTotInv] = useState(null);
  const [totSa, setTotSa] = useState(null);
  const [allOrd, setAllOrd] = useState(null);
  const [pendingOrd, setPendingOrd] = useState(null);
  const [stockData, setStockData] = useState(null);
  const [DPProfile, setDPProfile] = useState(null);
  const [messages, setMessages] = useState(null);

  const [filltered, setFilltered] = useState(false);


  const [totalSalesStartDate, setTotalSalesStartDate] = useState((new Date()));
  const [totalSalesEndDate, setTotalSalesEndDate] = useState((new Date()));

  const [totalInveStartDate, setTotalInveStartDate] = useState((new Date()));
  const [totalInveEndDate, setTotalInveEndDate] = useState((new Date()));

  const [totalOrderstartDate, setTotalOrdersStartDate] = useState((new Date()));
  const [totalOrdersEndDate, setTotalOrdersEndDate] = useState((new Date()));
  
  const [totalPendOrderStartDate, setTotalPendOrdersStartDate] = useState((new Date()));
  const [totalPendOrdersEndDate, setTotalPendOrdersEndDate] = useState((new Date()));

  // getFilteredData("TotalSales TotalInventory TotalOrders PendingOrders");
  const getFilteredData = (cardName) =>{
    // setLoader(true);
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?dpDashCardData=' + cardName + '&user_Id=' + ReactSession.get('id')
    
    // console.log("Year :", totalSalesStartDate.getFullYear(),"  Month:", totalSalesStartDate.getMonth()+1,"  Day:",totalSalesStartDate.getDate())
    // console.log("Date :",totalSalesStartDate.toLocaleDateString());
    var dtFrom = ''
    var dtTo = ''
    if (cardName === "TotalSales") {
      dtFrom=totalSalesStartDate.toLocaleDateString();
      dtTo=totalSalesEndDate.toLocaleDateString();
    }
    else if (cardName === "TotalInventory"){
      dtFrom=totalInveStartDate.toLocaleDateString();
      dtTo=totalInveEndDate.toLocaleDateString();
    }
    else if (cardName === "TotalOrders"){
      dtFrom=totalOrderstartDate.toLocaleDateString();
      dtTo=totalOrdersEndDate.toLocaleDateString();
    }
    else{
      dtFrom=totalPendOrderStartDate.toLocaleDateString();
      dtTo=totalPendOrdersEndDate.toLocaleDateString();
    }

    var dateFrom = dtFrom.split('/')[2]+'-'+dtFrom.split('/')[0]+'-'+dtFrom.split('/')[1]
    var dateTo = dtTo.split('/')[2]+'-'+dtTo.split('/')[0]+'-'+dtTo.split('/')[1]
    
    // console.log("Date From :",dateFrom, "\nTo :",dateTo);
    
    apiURL += '&dtFrom=' + dateFrom + '&dtTo=' + dateTo;
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
        setLoader(false);
      }

      if (resp.status === "success") {
        if (cardName === "TotalSales") {
          setTotSales(resp.cardFilteredData);
          var totS = 0;
          for (let i = 0; i < resp.cardFilteredData.length; i++)
            totS += parseFloat(resp.cardFilteredData[i][2]);
          setTotSa(totS);
        }
        else if (cardName === "TotalOrders"){
          setAllOrders(resp.cardFilteredData);
          var totO = 0;
          for (let i = 0; i < resp.cardFilteredData.length; i++)
            totO += parseFloat(resp.cardFilteredData[i][7]);
          setAllOrd(totO);
        }
        else if (cardName === "PendingOrders"){
          setPendingOrders(resp.cardFilteredData);
          var totPD = 0;
          for (let i = 0; i < resp.cardFilteredData.length; i++)
            totPD += parseFloat(resp.cardFilteredData[i][7]);
          setPendingOrd(totPD);
        }
        else { 
          var col4 = 0;
          for (let i = 0; i < resp.cardFilteredData.length; i++) {
            var op_st = filltered ? Number(resp.cardFilteredData[i][5]) + Number(resp.cardFilteredData[i][6]) + Number(resp.cardFilteredData[i][7]) - Number(resp.cardFilteredData[i][8]) : resp.cardFilteredData[i][5];
            var st_recv = filltered ? Number(resp.cardFilteredData[i][16]) + Number(resp.cardFilteredData[i][17]) : resp.cardFilteredData[i][6];
            var st_iss = filltered ? Number(resp.cardFilteredData[i][18]) : resp.cardFilteredData[i][7];

            var st_clos = filltered ? Number(op_st) + Number(st_recv) - Number(st_iss) : Number(resp.cardFilteredData[i][8]);
            var st_val = filltered ? Number(Number(st_clos) * (Number(resp.cardFilteredData[i][13]) === 0 ? Number(resp.cardFilteredData[i][19]) : Number(resp.cardFilteredData[i][13]))).toFixed(2) : Number(resp.cardFilteredData[i][13]).toFixed(2);
            col4 += Number(st_val);
          }
          setTotInv(col4);
        }
        
        setLoader(false);
      }
      else {
        setLoader(false);
      }
    }).catch((Err)=>{
      console.log("Error :",Err);
    });
  }


  const executeQry = () => {
    setFilltered(true);
    var dtFrom = document.getElementById("dateFrom");
    var dtTo = document.getElementById("dateTo");
    if (String(dtFrom.value).length < 8) {
      alert("Please enter a valid date :");
      dtFrom.focus();
    }
    else
      if (String(dtTo.value).length < 8) {
        alert("Please enter a valid date :");
        dtTo.focus();
      }
      else {
        // console.log("Executing query");
        var st_date = "" + dtFrom.value + "' and u.created_at <= '" + dtTo.value + "'";
        getStockData(dtFrom.value, dtTo.value);
      }
  }


  const getStockData = (dtFrom = null, dtTo = null) => {
    setLoader(true);
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?stockState=1&user_Id=' + ReactSession.get('id');

    if (dtFrom != null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;

    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      setLoader(false);
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }
      else if (resp.status === "success") {
        setStockData(resp.stockData);
        setDPProfile(resp.dp_profile[0]);
        ReactSession.set('dpType', resp.dp_profile[0][16]);
        // console.log("User Type :",resp.dp_profile[0][16]);

        const expIn120 = new Date(originalDate);
        expIn120.setDate(originalDate.getDate() + 120);

        const expIn60 = new Date(originalDate);
        expIn60.setDate(originalDate.getDate() + 60);

        const expIn30 = new Date(originalDate);
        expIn30.setDate(originalDate.getDate() + 30);

        const nonMovIn120 = new Date(originalDate);
        nonMovIn120.setDate(originalDate.getDate() - 120);

        const nonMovIn60 = new Date(originalDate);
        nonMovIn60.setDate(originalDate.getDate() - 60);

        const nonMovIn30 = new Date(originalDate);
        nonMovIn30.setDate(originalDate.getDate() - 30);

        var coExp120 = 0;
        var coExp60 = 0;
        var coExp30 = 0;

        var coNonMov120 = 0;
        var coNonMov60 = 0;
        var coNonMov30 = 0;

        var col4 = 0;
        for (let i = 0; i < resp.stockData.length; i++) {
          var op_st = filltered ? Number(resp.stockData[i][5]) + Number(resp.stockData[i][6]) + Number(resp.stockData[i][7]) - Number(resp.stockData[i][8]) : resp.stockData[i][5];
          var st_recv = filltered ? Number(resp.stockData[i][16]) + Number(resp.stockData[i][17]) : resp.stockData[i][6];
          var st_iss = filltered ? Number(resp.stockData[i][18]) : resp.stockData[i][7];

          var st_clos = filltered ? Number(op_st) + Number(st_recv) - Number(st_iss) : Number(resp.stockData[i][8]);
          var st_val = filltered ? Number(Number(st_clos) * (Number(resp.stockData[i][13]) === 0 ? Number(resp.stockData[i][19]) : Number(resp.stockData[i][13]))).toFixed(2) : Number(resp.stockData[i][13]).toFixed(2);
          col4 += Number(st_val);

          const dateExpiry = new Date(`${resp.stockData[i][12]}-${resp.stockData[i][11]}-${'28'}`);

          // console.log("ExpDate :",resp.stockData[i][11], resp.stockData[i][12], "exp date :",dateExpiry);

          if (dateExpiry < expIn120)
            coExp120 += 1
          else if (dateExpiry < expIn60)
            coExp60 += 1
          else if (dateExpiry < expIn30)
            coExp30 += 1
          setExpProdCo([coExp120, coExp60, coExp30]);

          if (resp.stockData[i][12] < nonMovIn120)
            coNonMov120 += 1
          else if (resp.stockData[i][12] < nonMovIn60)
            coNonMov60 += 1
          else if (resp.stockData[i][12] < nonMovIn30)
            coNonMov30 += 1;
          setNonMovableProdCo([coNonMov120, coNonMov60, coNonMov30])
        }

        setLoader(false);
        setTotInv(col4);
      }
      else {
        setLoader(false);
      }
    }).catch((error)=>{
      setLoader(false);
      console.log("Error :"+error);
    });
  }


  const initialize = () => {
    fetch("http://43.205.199.21/digiPartnerAPI?dashBoardData=1&user_Id=" + ReactSession.get("id"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }
      if(res.status === "success")
      {
        setTotSales(res.totSales);
        setAllOrders(res.allOrders);
        setPendingOrders(res.pendingOrders);
        if (res.yearMonthSales.length > 0) {
          setYearMonthSales(res.yearMonthSales);
        }
        if (res.incomeExpenses.length > 0) {
          setIncomeExpenses(res.incomeExpenses);
        }
        // minimum maximum sales for graph
        const columnValues = res.yearMonthSales.map(row => row[4]);
        const maxColumnValue = Math.max.apply(null, columnValues);
        const minColumnValue = Math.min.apply(null, columnValues);
        setMaxSale(maxColumnValue + 500);
        setMinSale(minColumnValue - 500);

        const incExp = res.incomeExpenses.map(row => row.slice(4, 5 + 1));
        const flattenedValues = incExp.flat();
        const maxIncExp = Math.max.apply(null, flattenedValues);
        const minIncExp = Math.min.apply(null, flattenedValues);
        setMaxIncExp(maxIncExp + 500);
        setMinIncExp(minIncExp - 100);

        var totS = 0;
        for (let i = 0; i < res.totSales.length; i++)
          totS += parseFloat (res.totSales[i][2]);
        setTotSa(totS);

        var totO = 0;
        for (let i = 0; i < res.allOrders.length; i++)
          totO += parseFloat(res.allOrders[i][7]);
        setAllOrd(totO);

        var totPD = 0;
        for (let i = 0; i < res.pendingOrders.length; i++)
          totPD += parseFloat(res.pendingOrders[i][7]);
        setPendingOrd(totPD);

        var prevDate = ReactSession.get("pharNotiStatusRead");
        // console.log("Date Diff :",new Date(new Date() - new Date(prevDate)));

        const date1_ms = new Date().getTime();
        const date2_ms = new Date(prevDate).getTime();
        const difference_ms = date1_ms - date2_ms;
        // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
        const differenceInHrs = difference_ms / (1000 * 60 * 60);
        
        if (differenceInHrs > 2 && res.pharmacyOrders > 0)
        {
          setMessages("You have "+String(res.pharmacyOrders) + " New Pharmacy Orders");
          ReactSession.set("pharNotiStatusRead", new Date());
          document.getElementById("orderNotificationDialogue").classList.replace("hide", "show");
        } 

      }
      // calculate column wise sum in double dimensional array
      // const sum = res.totSales.reduce((acc, row) =>{
      //   return parseFloat(acc) + parseFloat(row[2]);
      // });
      // console.log(sum);
    }).catch((err) => {
      console.log("Error :", err.message);
    })
  }

  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.")
      navigate('/DigitalPartner');
    }
    else
      if (String(ReactSession.get('is_verified')) === "0") {
        alert("PLease complete your profile before search...")
        navigate('/DigiPartnerProfile');
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
          navigate('/DigitalPartner');
        }
        else {
          initialize();
          getStockData();
        }
  }, []);
  

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>

        <div className='Dialogue hide' id="orderNotificationDialogue">
          <div className='DialogueMSG' style={{ width: "45vw", height: "60vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center" }}>
            <img style={{ width: 150, height: 150, position: "relative"}} src={require("../../static/DPImages/balloons.gif")} alt="" />
            <h3 className='congHead' style={{ textAlign: "center", fontWeight: "700", color: "#00BAFF" }}>Congratulation</h3>
            <p style={{ textAlign: "center", fontWeight: "700" }}>{ messages && messages !== null ? messages : null}</p>
            {
              /* { messages.length > 0 ?
                messages.map((message, index) => (
                  <li style={{textAlign:"center"}} key={index}>{message}</li>
              ))
              :''
              } */
            }

            <button onClick={() => {{ 
                document.getElementById("orderNotificationDialogue").classList.replace("show", "hide");
                messages && String(messages).substring(0, 16) !== "Profile verified" ? navigate('/DigiPartnerPharmacyOrders') : navigate('/DPDashboard');
              }}} 
            className='btnPrimary1' style={{ width: "50%", margin: "0px auto", border: 0, outline: 0, height: 35, borderRadius: 5 }}>
            {messages && String(messages).substring(0, 16) !== "Profile verified" ? "View Orders" : "Continue"}</button>

          </div>

          <div style={{ position: 'absolute', backgroundColor: "white", right: '29vw', zIndex: 99, top: '11vw', cursor: "pointer", borderRadius: '50%' }} onClick={() => {
            document.getElementById("orderNotificationDialogue").classList.replace("show", "hide");
          }}>
            <img src={require('../../static/images/Icons/close.png')} alt="" />
          </div>
        </div>

        <div className='DigiDashContent' id='DigiDashContent'>
          {String(ReactSession.get('is_verified')) === "1" ?
            <>
              <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
                {menuCollapse ?
                  <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
                  :
                  <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
                }
              </div>

              {
                DPProfile ?
                  <h3>Dashboard - <span> {DPProfile[2]}, {DPProfile[5]} {"(Madhya Pradesh)"}</span></h3>
                  :
                  null
              }

              <div className='digiContentR1'>
                <div className='digiContentR1Cards'>

                  <div className='digiContentR1Card'>
                    <div style={{ display: "flex", marginTop: "10px", alignItems: "center", justifyContent: "space-between", height: 25, padding: "5px 15px" }}>
                      <p style={{ margin: 0, padding: 0 }}>Total Sales</p>
                      <label className='bx bx-dots-horizontal-rounded' onClick={() => {
                        document.getElementById('totalSalesDate').classList.toggle("hide");
                      }}></label>

                    </div>
                    <div className='totalSalesDate hide' id='totalSalesDate'>
                      <div className='dateCont'>
                        <div>From&nbsp;:&nbsp;</div>
                        <DatePicker selected={totalSalesStartDate} onChange={(date) => {
                          if (totalSalesEndDate && (new Date(date) > totalSalesEndDate) )
                          {
                            alert("Start date must smaller than End date");
                          }
                          else
                          {
                            setTotalSalesStartDate(new Date(date)); 
                          }
                        }} />
                      </div>

                      <div className='dateCont'>
                        <div>&nbsp;&nbsp;To&nbsp;:&nbsp;</div>
                        <DatePicker style={{width:'5vw'}} selected={totalSalesEndDate} 
                        onChange={(date) => {
                          if (totalSalesStartDate && (new Date(date) < totalSalesStartDate) )
                          {
                            alert("End Date must bigger than start date");
                          }
                          else
                          {
                            setTotalSalesEndDate(new Date(date));
                            document.getElementById('totalSalesDate').classList.add('hide');
                            getFilteredData("TotalSales");
                          }
                        }} 
                        />
                      </div>
                    </div>

                    <div style={{ height: "27%" }}>
                      <h3>{totSa !== null ? Math.round(totSa) : null}</h3>
                    </div>

                    <div className='digiContentDashBottom'>
                      <p>From<br /> {totalSalesStartDate === null ? "---" : totalSalesStartDate.toLocaleDateString()} - {totalSalesEndDate === null ? "---" : totalSalesEndDate.toLocaleDateString()}
                      </p>
                      <img src={require("../../static/DPImages/DigiDashCard1.png")} alt="" />
                    </div>
                  </div>

                  <div className='digiContentR1Card'>
                    <div style={{ display: "flex", marginTop: "10px", alignItems: "center", justifyContent: "space-between", height: 25, padding: "5px 15px" }}>
                      <p style={{ margin: 0, padding: 0 }}>Total Inventory</p>
                      <label className='bx bx-dots-horizontal-rounded' onClick={() => {
                        document.getElementById('totalInventoryDate').classList.toggle("hide");
                      }}></label>
                    </div>

                    <div className='totalSalesDate hide' id='totalInventoryDate'>
                      <div className='dateCont'>
                        <div>From&nbsp;:&nbsp;</div>
                        <DatePicker selected={totalInveStartDate} onChange={(date) => {
                          if (totalInveEndDate && (new Date(date) > totalInveEndDate) )
                          {
                            alert("Start date must smaller than End date");
                          }
                          else
                          {
                            setTotalInveStartDate(new Date(date)); 
                          }
                        }} />
                      </div>

                      <div className='dateCont'>
                        <div>&nbsp;&nbsp;To&nbsp;:&nbsp;</div>
                        <DatePicker style={{width:'5vw'}} selected={totalInveEndDate} 
                        onChange={(date) => {
                          if (totalInveStartDate && (new Date(date) < totalInveStartDate) )
                          {
                            alert("End Date must bigger than start date");
                          }
                          else
                          {
                            setTotalInveEndDate(new Date(date));
                            document.getElementById('totalInventoryDate').classList.add('hide');
                            getFilteredData("TotalInventory");
                          }
                        }} />
                      </div>
                    </div>

                    <div style={{ height: "27%" }} >
                      <h3>{totInv !== null ? Math.round(totInv) : null}</h3>
                    </div>

                    <div className='digiContentDashBottom'>
                      <p>From<br /> {totalInveStartDate === null ? "---" : totalInveStartDate.toLocaleDateString()} - {totalInveEndDate === null ? "---" : totalInveEndDate.toLocaleDateString()}</p>
                      <img src={require("../../static/DPImages/DigiDashCard2.png")} alt="" />
                    </div>
                  </div>

                  <div className='digiContentR1Card'>
                    <div style={{ display: "flex", marginTop: "10px", alignItems: "center", justifyContent: "space-between", height: 25, padding: "5px 15px" }}>
                      <p style={{ margin: 0, padding: 0 }}>Total Orders</p>
                      <label className='bx bx-dots-horizontal-rounded' onClick={() => {
                        document.getElementById('totalOrdersDate').classList.toggle("hide");
                      }}></label>
                    </div>

                    <div className='totalSalesDate hide' id='totalOrdersDate'>
                      <div className='dateCont'>
                        <div>From&nbsp;:&nbsp;</div>
                        <DatePicker selected={totalOrderstartDate} onChange={(date) => {
                          if (totalOrdersEndDate && (new Date(date) > totalOrdersEndDate) )
                          {
                            alert("Start date must smaller than End date");
                          }
                          else
                          {
                            setTotalOrdersStartDate(new Date(date)); 
                          }
                        }} />
                      </div>

                      <div className='dateCont'>
                        <div>&nbsp;&nbsp;To&nbsp;:&nbsp;</div>
                        <DatePicker style={{width:'5vw'}} selected={totalOrdersEndDate} 
                        onChange={(date) => {
                          if (totalOrderstartDate && (new Date(date) < totalOrderstartDate) )
                          {
                            alert("End Date must bigger than start date");
                          }
                          else
                          {
                            setTotalOrdersEndDate(new Date(date));
                            document.getElementById('totalOrdersDate').classList.add('hide');
                            getFilteredData("TotalOrders");
                          }
                        }} />
                      </div>
                    </div>

                    
                    <div style={{ height: "27%", display: "flex", alignItems: "center" }}>
                      <div><h3>{allOrders !== null ? allOrders?.length : ''}</h3></div>
                      <div>Average<br /> Order Value : INR <span style={{ color: "#2d2dff" }}>{(allOrd / (allOrders?.length)).toFixed(2)}</span></div>
                    </div>

                    <div className='digiContentDashBottom'>
                      <p>From<br /> {totalOrderstartDate === null ? "---" : totalOrderstartDate.toLocaleDateString()} - {totalOrdersEndDate === null ? "---" : totalOrdersEndDate.toLocaleDateString()}</p>
                      <img src={require("../../static/DPImages/DigiDashCard3.png")} alt="" />
                    </div>
                  </div>

                  <div className='digiContentR1Card'>
                    <div style={{ display: "flex", marginTop: "10px", alignItems: "center", justifyContent: "space-between", height: 25, padding: "5px 15px" }}>
                      <p style={{ margin: 0, padding: 0 }}>Pending Orders</p>
                      <label className='bx bx-dots-horizontal-rounded' onClick={() => {
                        document.getElementById('totalPendOrdersDate').classList.toggle("hide");
                      }}></label>
                    </div>

                    <div className='totalSalesDate hide' id='totalPendOrdersDate'>
                      <div className='dateCont'>
                        <div>From&nbsp;:&nbsp;</div>
                        <DatePicker selected={totalPendOrderStartDate} onChange={(date) => {
                          if (totalPendOrdersEndDate && (new Date(date) > totalPendOrdersEndDate) )
                          {
                            alert("Start date must smaller than End date");
                          }
                          else
                          {
                            setTotalPendOrdersStartDate(new Date(date)); 
                          }
                        }} />
                      </div>

                      <div className='dateCont'>
                        <div>&nbsp;&nbsp;To&nbsp;:&nbsp;</div>
                        <DatePicker style={{width:'5vw'}} selected={totalPendOrdersEndDate} 
                        onChange={(date) => {
                          if (totalPendOrderStartDate && (new Date(date) < totalPendOrderStartDate) )
                          {
                            alert("End Date must bigger than start date");
                          }
                          else
                          {
                            setTotalPendOrdersEndDate(new Date(date));
                            document.getElementById('totalPendOrdersDate').classList.add('hide');
                            getFilteredData("PendingOrders");
                          }
                        }} />
                      </div>
                    </div>

                    <div style={{ height: "27%", display: "flex", alignItems: "center" }}>
                      <div><h3 >{pendingOrders !== null ? pendingOrders?.length : null}</h3></div>
                      <div>Orders<br /> Estimated Value : INR <span style={{ color: "#2d2dff" }}>{(pendingOrd)?.toFixed(2)}</span></div>
                    </div>

                    <div className='digiContentDashBottom'>
                      <p>From<br /> {totalPendOrderStartDate === null ? "---" : totalPendOrderStartDate.toLocaleDateString()} - {totalPendOrdersEndDate === null ? "---" : totalPendOrdersEndDate.toLocaleDateString()}</p>
                      <img src={require("../../static/DPImages/DigiDashCard4.png")} alt="" />
                    </div>
                  </div>

                </div>

                <div className='digiContentR1Graph'>
                  <div key={1} style={{ display: 'flex', height: '10%', justifyContent: 'space-around', alignItems: 'center', marginTop: "20px" }}>
                    <div style={{ fontSize: '90%', fontWeight: 'bold' }}>Sales Analytics</div>
                    <div style={{ fontSize: '70%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <div style={{ width: '30px', height: '10px', backgroundColor: '#78deff' }}></div>
                      <div>Last Year</div>
                    </div>

                    <div style={{ fontSize: '70%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <div style={{ width: '30px', height: '10px', backgroundColor: '#2d2dff' }}></div>
                      <div>Current Year</div>
                    </div>
                  </div>

                  {/* var barPer = String(((parseFloat(data[4]) - minSale) / (maxSale - minSale)) * 100)+'%'; */}
                  {/* yearMonthSales.map((data, index)=>{
                    var num = data[4] - minSale;
                    var deno = maxSale - minSale;
                    var barPer = ((num / deno) * 100).toFixed(0);
                    return (
                      <div style={{height:'100%', marginInline:'auto'}}> 
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-end', height:'90%'}}>
                          <div style={{ width:'30px', backgroundColor: data[0] === 2023 ? '#78deff' :'#2d2dff', minHeight:`${barPer}%`}}>{barPer}</div>
                        </div>

                        <div style={{ fontSize:'80%', fontWeight:'bold', height:'10%'}}>{data[7].substring(0, 3)}</div>
                      </div>
                    )
                  })  */}

                  <div className='mainGraph' >
                    {
                      yearMonthSales && yearMonthSales !== null ?
                        (() => {
                          const result = [];
                          for (let index = 0; index < yearMonthSales.length; index++) {
                            const data = yearMonthSales[index];
                            const barValue = data[4];
                            const num = data[4] - minSale;
                            const deno = maxSale - minSale;
                            const barPer = ((num / deno) * 100).toFixed(0);

                            var bar2Per = 0;
                            if (yearMonthSales[index + 1] && yearMonthSales[index][7] === yearMonthSales[index + 1][7]) {
                              const num = yearMonthSales[index + 1][4] - minSale;
                              bar2Per = ((num / deno) * 100).toFixed(0);
                              index++;
                            }

                            result.push(
                              <div className='salesGraphCont'>
                                <div className='salesGraphBars'>
                                  <div className='graphBar'>
                                    <div style={{ textAlign: 'center', fontSize: '1vw', width: '30px', backgroundColor: '#78deff', minHeight: `${barPer}%`, color: data[0] !== 2023 ? "white" : '' }}></div>
                                    <div className='toolTip' style={{ bottom: `${barPer - 5}%` }}>
                                      {barValue}
                                    </div>
                                  </div>
                                  <div style={{ marginLeft: bar2Per !== 0 ? '50%' : '0px', fontSize: '1vw', fontWeight: 'bold', height: '10%' }}>{data[7].substring(0, 3)}</div>
                                </div>
                                {
                                  bar2Per !== 0 ?
                                    <div className='salesGraphBars'>
                                      <div className='graphBar'>
                                        <div style={{ textAlign: 'center', fontSize: '1vw', width: '30px', backgroundColor: '#2d2dff', minHeight: `${bar2Per}%`, color: 'white', }}></div>
                                        <div className='toolTip' style={{ bottom: `${bar2Per - 5}%` }}>
                                          {yearMonthSales[index][4]}
                                        </div>
                                      </div>
                                    </div>
                                    : ''
                                }
                              </div>
                            );
                          }
                          return result;
                        })()

                        : 
                        <div className='NoRecordsFound'>No Records Found</div>
                    }
                  </div>
                  {/* <img src={require("../../static/DPImages/digiDashGraph1.png")}/> */}
                </div>
              </div>

              {/* row 2 */}
              <div className='digiContentR2'>
                <div className='digiContentR2Graph'>
                  <div style={{ marginInline: '20px', marginTop: "20px", fontSize: '90%', fontWeight: 'bold' }}>Expenses, Income & Net Profit Financial Graph</div>
                  <div style={{ marginInline: '20px', display: 'flex', gap: "15px", alignItems: 'center' }}>
                    <div style={{ fontSize: '70%', display: 'flex', gap: '10px', justifyContent: 'space-around', alignItems: 'center' }}>
                      <div style={{ width: '30px', height: '10px', backgroundColor: '#fe8197' }}></div>
                      <div>Expenses</div>
                    </div>

                    <div style={{ marginInline: '10px', fontSize: '70%', gap: '10px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <div style={{ width: '30px', height: '10px', backgroundColor: '#78deff' }}></div>
                      <div>Income</div>
                    </div>

                    <div style={{ marginInline: '10px', fontSize: '70%', gap: '10px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <div style={{ width: '30px', height: '10px', backgroundColor: '#0072ea' }}></div>
                      <div>Net Profit</div>
                    </div>

                    <div style={{ marginInline: '10px', fontSize: '70%', gap: '10px', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <div style={{ width: '30px', height: '10px', backgroundColor: '#FF9800' }}></div>
                      <div>Net Loss</div>
                    </div>
                  </div>

                  <div className='mainGraph' style={{ marginInline: '20px', display: 'flex', height: '85%' }}>
                    {
                      incomeExpenses && incomeExpenses !== null ?
                        incomeExpenses.map((data) => {
                          var num = data[4] - minIncExp;
                          var numExp = data[5] - minIncExp;
                          var deno = maxIncExp - minIncExp;
                          var incbarPer = ((num / deno) * 100).toFixed(0);
                          var ExpbarPer = ((numExp / deno) * 100).toFixed(0);
                          
                          if (ExpbarPer < incbarPer)
                            var profitPer = incbarPer - ExpbarPer;
                          else
                            var profitPer = ExpbarPer - incbarPer;

                          {/* console.log("IN :",incbarPer, "   Exp :",ExpbarPer, "   prfo :",profitPer); */}
                          
                          return (
                            <div style={{ textAlign: 'center', marginInline: 'auto' }} className='secondGraph'>
                              <div style={{ display: 'flex', height: '100%', marginInline: '5px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '90%' }}>
                                  <div style={{ width: '43px', backgroundColor: '#fe8197', minHeight: `${ExpbarPer}%` }}></div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '90%' }}>
                                  <div style={{ width: '43px', backgroundColor: '#78deff', minHeight: `${incbarPer}%` }}></div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '90%' }}>
                                  <div style={{ width: '43px', backgroundColor: ExpbarPer < incbarPer ? '#0072ea' : '#FF9800', minHeight: `${profitPer}%` }}></div>
                                </div>
                              </div>

                              <div className='secondGraphBottom' style={{ fontSize: '80%', fontWeight: 'bold', marginTop: "-30px" }}>{data[8].substring(0, 3)}</div>
                            </div>
                          )
                        })
                        : 
                        <div className='NoRecordsFound'>No Records Found</div>
                    }
                  </div>
                  {/* <img src={require("../../static/DPImages/digiDashGraph2.png")}/> */}
                </div>

                <div className='digiDashManage'>

                  <div className='digiDashManageCard'>
                    <h3>Expire Management</h3>
                    <div className='digiDashCircle1 circle'>
                      {expProdCo !== null ? expProdCo[0] : ''} Items <br /> Expire within <br /> 120 Days
                    </div>
                    <div className='digiDashCircle2 circle'>
                      {expProdCo !== null ? expProdCo[1] : ''} Items <br /> Expire within <br /> 60 days
                    </div>
                    <div className='digiDashCircle3 circle'>
                      {expProdCo !== null ? expProdCo[2] : ''} Items <br /> Expire within <br /> 30 Days
                    </div>

                    <div className='cardLink' onClick={() => viewProdModalShow(false)}>Click to view items list</div>
                  </div>

                  <div className='digiDashManageCard'>
                    <h3>Non-Movable Items</h3>
                    <div className='digiDashCircle1 circle'>
                      {nonMovableProdCo !== null ? nonMovableProdCo[2] : ''} Items <br /> not moved For <br /> 30 Days
                    </div>
                    <div className='digiDashCircle2 circle'>
                      {nonMovableProdCo !== null ? nonMovableProdCo[1] : ''} Items <br /> not moved for <br /> 60 Days
                    </div>
                    <div className='digiDashCircle3 circle'>
                      {nonMovableProdCo !== null ? nonMovableProdCo[0] : ''} Items <br /> not moved for <br /> 120 days
                    </div>
                    <div className='cardLink' onClick={() => viewProdModalShow(true)}>Click to view items list</div>
                  </div>
                </div>
              </div>
            </>
            :
            <div style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <img style={{ width: '10vw', height: '10vw' }} src={require('../../static/DPImages/Icons/notfound.png')} alt="" />
              <div style={{ fontSize: '2vw' }} >Oops.!</div>
              <div>Record not found..!</div>
              <div>PLease complete your profile at : <span className='completeProfileLink' onClick={() => navigate('/DigiPartnerProfile')}>Edit Profile</span> </div>
            </div>
          }
        </div>
        {loader ? <Loader /> : ''}

      </div>

      {/* View product Modal */}
      <Modal show={viewProdModal} onHide={viewProdModalClose} size="xl" backdrop="static" >
        <Modal.Header closeButton className='purchModalheader'>
          <Modal.Title>Viewing Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div style={{ fontSize: '90%', fontWeight: 'bold' }}>
              {nonMoFillter ? "Non Movable Products" : "Expiring Products"}
            </div>
            <div style={{ display: 'flex', width: '100%', paddingRight: '12px', fontWeight: 'bold', border: '1px solid black' }}>
              <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '8%' }}>S.No</div>
              <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '12%' }}>Expiry Date</div>
              <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '25%' }}>Name</div>
              <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '19%' }}>Company</div>
              <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '12%' }}>Unit</div>
              <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '8%' }}>Batch</div>
              <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '8%' }}>Price</div>
              <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '8%' }}>Rack No</div>
            </div>
            <div className='viewExpProd customScroll' style={{ paddingRight: '5px', width: '100%' }}>
              {
                stockData && stockData !== null ?
                  stockData.map((data, index) => {

                    const dateLimit = new Date(originalDate);
                    if (nonMoFillter) {
                      var dateEvent = new Date(originalDate.getDate() - 120);
                      dateLimit.setDate(data[17]);
                    }
                    else {
                      var dateEvent = new Date(`${data[12]}-${data[11]}-${'28'}`);
                      dateLimit.setDate(originalDate.getDate() + 120);
                    }
                    {/* console.log(index,"Date exp/sale : ",dateEvent.toDateString(), "    Date limit",dateLimit.toDateString(), " Data[17]",data[17]); */ }
                    if (dateEvent < dateLimit) {
                      return (
                        <div key={index} style={{ display: 'flex', width: '100%', border: '1px solid black' }}>
                          <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '8%' }}>{index + 1}</div>
                          <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '12%' }}>{data[11]}{"/"}{data[12]}</div>
                          <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '25%' }}>{data[3]}</div>
                          <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '19%' }}></div>
                          <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '12%' }}>{data[4]}</div>
                          <div style={{ fontSize: '80%', textAlign: 'center', borderRight: '1px solid gray', width: '8%' }}>{data[15]}</div>
                          <div style={{ fontSize: '80%', textAlign: 'right', borderRight: '1px solid gray', width: '8%' }}>{Number(data[10]).toFixed(2)}</div>
                          <div style={{ fontSize: '80%', textAlign: 'center', width: '8%' }}>{data[16]}</div>
                        </div>
                      )
                    }
                    else
                      return null
                  })
                  : ''
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DPDashboard