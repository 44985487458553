"use client"
import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
// import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { IoIosArrowDown, IoMdClose } from "react-icons/io";

// import Form from 'react-bootstrap/Form';
import { MdKeyboardArrowDown } from "react-icons/md";
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import { useReactToPrint } from 'react-to-print';
import Loader from '../../Containers/Loader';
import { IoIosArrowUp } from "react-icons/io";
import { color, motion } from "framer-motion";
import '../../../billPrint.css';
import '../../../stockPrint.css';

ReactSession.setStoreType("localStorage");


const DigiPartnerSidePanel = () => {

  const navigate = useNavigate();
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [loader, setLoader] = useState(false);

  const [showMobileModal, setShowMobileModal] = useState(false)

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const [selCustID, setSelCustID] = useState(null);

  const CollapseMenu = (value) => {
    var digiPanel = document.getElementById('DigiDashPanel');
    var digiContent = document.getElementById('DigiDashContent');
    let manageProducts = document.querySelector(".manageProducts");
    if (value) {
      digiContent.style.width = '95vw';
      digiPanel.style.width = '5vw';
      if(manageProducts){
        manageProducts.classList.remove("active");
      }
    }
    else {
      digiContent.style.width = '80vw';
      digiPanel.style.width = '25vw';
      if(manageProducts){
        manageProducts.classList.add("active");
      }
    }
    setMenuCollapse(value);
  }

  // const [item1Images, setItem1Images] = useState(`${process.env.PUBLIC_URL}/static/DPImages/Icons/userW.png`);
  // ordersW.png stock-rotationW.png transferW.png reportW.png contactW.png logoutW.png
  const [item0Images, setItem0Images] = useState([require('../../../static/DPImages/Icons/vendSerW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item1Images, setItem1Images] = useState([require('../../../static/DPImages/Icons/userW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item11Images, setItem11Images] = useState([require('../../../static/DPImages/Icons/manageProductW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item12Images, setItem12Images] = useState([require('../../../static/DPImages/Icons/productDiscountW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item2Images, setItem2Images] = useState([require('../../../static/DPImages/Icons/ordersW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item3Images, setItem3Images] = useState([require('../../../static/DPImages/Icons/purchaseW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item4Images, setItem4Images] = useState([require('../../../static/DPImages/Icons/transferW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item5Images, setItem5Images] = useState([require('../../../static/DPImages/Icons/reportW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item6Images, setItem6Images] = useState([require('../../../static/DPImages/Icons/contactW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item7Images, setItem7Images] = useState([require('../../../static/DPImages/Icons/logoutW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item8Images, setItem8Images] = useState([require('../../../static/DPImages/Icons/transactionW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  const [item9Images, setItem9Images] = useState([require('../../../static/DPImages/Icons/stock-rotationW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);

  const [totals, setTotals] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [purBookTot, setPurBookTot] = useState([0, 0, 0, 0, 0]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [tmpInvoiceItems, setTmpInvoiceItems] = useState([]);
  const expMo = useRef();
  const expYr = useRef();
  const payModeRef = useRef();
  const custDateRef = useRef();
  const billRangeRef = useRef();

  const [purchaseBookData, setPurchaseBookData] = useState(null);
  const [purchBookQueryToggle, setPurBkQrToggle] = useState(true);
  const [invHeads, setInvHeads] = useState(null);
  const [salesBillVendor, setSalesBillVendor] = useState(null);

  const [pendingBills, setPendingBills] = useState([]);
  const [pendingBillDetails, setPendingBillDetails] = useState(null);

  // Handle Printer
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getTransactionDetails = (transID, sales) => {
    setLoader(true);
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?user_Id=' + dp_id;
    if (sales === 0)
      apiURL += '&purcBookId=' + transID;
    else
      apiURL += '&sales_bill=' + transID;
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }
      else if (resp.status === "success") { 
        setSalesBillVendor(resp.vendorDet[0]);
        setInvHeads(resp.invoiceHeads[0]);
        setPayMode(resp.invoiceHeads[0][4]);
        setBillingCat(resp.invoiceHeads[0][31]);
        
        // setSelStockist([resp.invoiceHeads[0][7], resp.invoiceHeads[0][8], resp.invoiceHeads[0][9], resp.invoiceHeads[0][10], resp.invoiceHeads[0][11], resp.invoiceHeads[0][12], resp.invoiceHeads[0][13], resp.invoiceHeads[0][14], resp.invoiceHeads[0][15], resp.invoiceHeads[0][16], resp.invoiceHeads[0][17], resp.invoiceHeads[0][18], resp.invoiceHeads[0][19]]);
        var filteredStock = stockistData.filter(stockistDa =>
          stockistDa[0] === resp.invoiceHeads[0][2]
        );
        setSelStockist(filteredStock[0]);
        
        setTotals([resp.invoiceHeads[0][20], resp.invoiceHeads[0][21], resp.invoiceHeads[0][22], resp.invoiceHeads[0][23], resp.invoiceHeads[0][24], resp.invoiceHeads[0][25], resp.invoiceHeads[0][26], resp.invoiceHeads[0][27]]);
        setInvoiceItems(resp.invoiceItems);

        
        if (sales === 0) {
          setVendorType(0);
          setPurchaseModal(true);
        }
        else {
          setVendorType(1);
          setSalesModal(true);
        }
      }
      setLoader(false);
    }).catch((Error)=>{
      alert("Request Fails");
      setLoader(false);
      console.log("Error :",Error);
    });
  }


  const execPurQuery = () => {
    var invEle = document.getElementById('serInvoiceName');
    if (purchBookQueryToggle === true) {
      purchBookQuery();
    }
    else {
      setPayMode('Both');
      setSelStockist(null);
      setInvAmtFill(null);
      setDateFill(null);
      setPurBookTot([0, 0, 0, 0, 0]);
      setPurchaseBookData(null);
      if (invEle !== null)
        invEle.value = '';
    }
    setPurBkQrToggle(!purchBookQueryToggle);
  }


  const [selStockistQry, setSelStockistQry] = useState(null);

  //Purchase Book query method
  const purchBookQuery = (reExec = null) => {
    var invNoEle = document.getElementById("serInvoiceName");
    // var invNo = '';
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?user_Id=' + dp_id + '&purchaseBook='
    if (purchBookModal === true) {
      apiURL += '1';
    }

    if (salesBookModal === true) {
      apiURL += '2';
    }
    apiURL += '&payMode=' + payMode;

    if (selStockistQry !== null)
      apiURL += '&stockistqry=' + selStockistQry[0];

    if (invNoEle !== null && invNoEle.value.length > 0)
      apiURL += '&invNo=' + invNoEle.value;

    if (dateFill !== null)
      apiURL += '&dateFill=' + dateFill;

    if (reExec !== null)
    {
      var dateFrom = document.getElementById('dateFromPSB');
      var dateTo = document.getElementById('dateToPSB');
      // console.log("From ", dateFrom.value, "\To :", dateTo.value);
      if (dateFrom.value === null || dateTo.value === null || dateFrom.value === "" || dateTo.value === "")
      {
        alert("Please select From and To Dates...!");
        return false;
      }

      if (new Date(dateFrom.value) > new Date(dateTo.value))
      {
        // console.log("From Greater");
        alert("From date must smaller than To date..!");
        return false;
      }

      apiURL += '&dateFill=' + dateFrom.value + "to" +dateTo.value;
    } 

    if (invAmtFill !== null)
      apiURL += '&invAmtFill=' + invAmtFill;

    setLoader(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      else if (resp.status === "success") { 
        setPurchaseBookData(resp.purchBookData);
        setPurBookTot(resp.purBookTot);
        document.getElementById("purcRecDiv").focus();
      }
      setLoader(false);
    });
  }

  //Utility Methods
  function inWords(n) {
    if (n < 0)
      return false;

    var single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    var double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    var below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
    if (n === 0) return ''
    function translate(n) {
      var word = "";
      var rem;
      if (n < 10) {
        word = single_digit[n] + ' '
      }
      else if (n < 20) {
        word = double_digit[n - 10] + ' '
      }
      else if (n < 100) {
        rem = translate(n % 10)
        word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
      }
      else if (n < 1000) {
        word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
      }
      else if (n < 1000000) {
        word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
      }
      else if (n < 1000000000) {
        word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
      }
      else {
        word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
      }
      return word
    }
    var result = translate(n)
    return result.trim() + '.'
  }

  //Add new Product
  //newProdName newProdUnit newProdComp newProdMRP
  const addNewProd = () => {
    setLoader(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        dp_id:ReactSession.get("id"),
        newProdName: document.getElementById('newProdName').value,
        newProdUnit: document.getElementById('newProdUnit').value,
        newProdComp: document.getElementById('newProdComp').value,
        newProdMRP: document.getElementById('newProdMRP').value,
      })
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
        }
        else if (resp.status === "success") { 
          alert("Product Successfully Added");
        }
        setLoader(false);
        AddProdModalClose();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  // Stockist/CFA create
  // stockName gstin dlNo dlNo2 eMail mobile mobile2 address1 address2 city state pincode
  const addStockist = () => {
    setLoader(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        stockName: document.getElementById('stockName').value,
        dp_id : ReactSession.get("id"),
        gstin: document.getElementById('gstin').value,
        dlNo: vendorType === 0 ? document.getElementById('dlNo').value : '',
        dlNo2: vendorType === 0 ? document.getElementById('dlNo2').value : '',
        email: document.getElementById('eMail').value,
        mobile: document.getElementById('mobile').value,
        mobile2: document.getElementById('mobile2').value,
        address1: document.getElementById('address1').value,
        address2: document.getElementById('address2').value,
        city: document.getElementById('city').value,
        state: document.getElementById('state').value,
        pincode: document.getElementById('pincode').value,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
          navigate("/Subscriptions");
        }
        else if (data.status === "success") { 
          var msg = (vendorType === 0 ? "Stockist/PFA" : "Customer") + " Successfully Added";
          alert(msg);
          initialize();
        }
        stockistModalClose();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  //Validation add stockist/pfs name form  
  const validatRegForm = () => {
    var errorElements = [];
    var dlNo = vendorType === 0 ? document.getElementById("dlNo").value : '';
    var dlNo2 = vendorType === 0 ? document.getElementById("dlNo2").value : '';
    var gstNo = document.getElementById("gstin").value;

    var apiURL = 'http://43.205.199.21/digiPartnerAPI?dlno=' + dlNo + '&dlNo2=' + dlNo2 + '&gstNo=' + gstNo;

    fetch(apiURL, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((resp) => {
      // stockName gstin dlNo dlNo2 mobile address1
      if (document.getElementById("stockName").value === "")
        errorElements.push("stockName");

      if (vendorType === 0) {
        // if (document.getElementById("gstin").value === "")
        //   errorElements.push("gstin");

        if (document.getElementById("dlNo").value === "")
          errorElements.push("dlNo");

        if (document.getElementById("dlNo2").value === "")
          errorElements.push("dlNo2");
      }
      
      if (document.getElementById("address1").value === "")
        errorElements.push("address1");

      if (document.getElementById("city").value === "")
        errorElements.push("city");

      if (document.getElementById("state").value === "")
        errorElements.push("state");
      
      if (document.getElementById("mobile").value === "")
        errorElements.push("mobile");
      else
        if (document.getElementById("mobile").value.length < 10 || isNaN(document.getElementById("mobile").value)) {
          errorElements.push("mobile");
          document.getElementById("errorMessage2").innerHTML += "<br />Mobile must contain 10 digits";
        }
  
      if (resp.dlno > 0 && vendorType === 0) {
        errorElements.push("dlNo");
        errorElements.push("dlNo2");
        document.getElementById("errorMessage2").innerHTML += "<br />Drug License number already exist..!";
      }

      if (resp.gstNo > 0) {
        errorElements.push("gstin");
        document.getElementById("errorMessage2").innerHTML += "<br />GSTIN already exist..!";
      }

      errorElements.forEach(showError);
      function showError(item) {
        document.getElementById(item).classList.add("errorField");
      }

      if (errorElements.length > 0) {
        document.getElementById("errorMessage2").classList.replace("errorMessageHide", "errorMessage");
        document.getElementById(errorElements[0]).focus();
        return false;
      }
      else {
        document.getElementById("errorMessage2").classList.replace("errorMessage", "errorMessageHide");
        addStockist();
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  //Validation new prod form
  // newProdName newProdUnit newProdComp newProdMRP
  const validatRegProdForm = () => {
    setLoader(true);

    var errorElements = [];

    if (document.getElementById("newProdName").value === "")
      errorElements.push("newProdName");

    if (document.getElementById("newProdUnit").value === "")
      errorElements.push("newProdUnit");

    if (document.getElementById("newProdComp").value === "")
      errorElements.push("newProdComp");

    if (document.getElementById("newProdMRP").value === "")
      errorElements.push("newProdMRP");

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    setLoader(false);
    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      addNewProd();
    }
  }


  // Add item to invoice
  const addItemToInvoice = (sales) => {
    //prodId prodName prodUnit prodComp quantity freeQuantity SGST CGST IGST exMonth expiryYear batchNo HSNCode prodMRP prodRate prodDis Amount landMark pincode city state
    var prodId = document.getElementById('prodId');
    var pName = document.getElementById('prodName');
    var pUnit = document.getElementById('prodUnit');
    var pComp = document.getElementById('prodComp');
    var qty = document.getElementById('quantity');
    var freeqty = document.getElementById('freeQuantity');
    var SGST = document.getElementById('SGST');
    var CGST = document.getElementById('CGST');
    var IGST = document.getElementById('IGST');
    var batchNo = document.getElementById('batchNo');
    var HSNCode = document.getElementById('HSNCode');
    var prodRate = document.getElementById('prodRate');
    var prodRack = document.getElementById('prodRack');
    var landMark = document.getElementById('landMark');
    var pincode = document.getElementById('pincode');
    var city = document.getElementById('city');
    var state = document.getElementById('state');
    var dpMRP = document.getElementById('dpMRP');
    var invoiceNo = document.getElementById('invoiceNo');
    var invoiceDate = document.getElementById('invoiceDate');
    var errorElements = [];

    if (salesModal && (billingCat === "retCus" || billingCat === "dealCust")) {
      if (document.getElementById('serStockName').value === "")
        errorElements.push("serStockName");
      if (document.getElementById('address1')?.value === "")
        errorElements.push("address1");
      if (document.getElementById('contact1')?.value === "")
        errorElements.push("contact1");
      if (document.getElementById('contact1')?.value === "")
        errorElements.push("contact1");
      if (document.getElementById('pincode')?.value === "")
        errorElements.push("pincode");
      if (document.getElementById('city')?.value === "")
        errorElements.push("city");
      if (document.getElementById('state')?.value === "")
        errorElements.push("state");
    }

    if (invoiceDate.value === null || invoiceDate.value === "")
      errorElements.push("invoiceDate");

    // if (selStockist === null)
    //   errorElements.push("StockSearch");
    if (pName.value === "")
      errorElements.push("ProdNameDiv");

    if (pUnit.value === "")
      errorElements.push("prodUnit");

    if (pComp.value === "")
      errorElements.push("prodComp");

    if (qty.value === "" || isNaN(qty.value))
      errorElements.push("quantity");

    if (IGST.value === "") {
      if (SGST.value === "" || isNaN(SGST.value))
        errorElements.push("SGST");
      if (CGST.value === "" || isNaN(CGST.value))
        errorElements.push("CGST");
    }

    if ((IGST.value === "" || isNaN(IGST.value)) && (SGST.value === "" && CGST.value === ""))
      errorElements.push("IGST");
    
    if (exMonth === null)
      errorElements.push("expMonth");
    
    if (expiryYear === null)
      errorElements.push("exp_Year");
    
    if (batchNo.value === "")
      errorElements.push("batchNo");
    
    if (HSNCode.value === "")
      errorElements.push("HSNCode");
    
    if (prodRate.value === "" || isNaN(prodRate.value))
      errorElements.push("prodRate");
    
    if (invoiceNo.value === "")
      errorElements.push("invoiceNo");

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      // var prodMRP = 0;
      if (sales === 0) {
        var prodMRP = document.getElementById('prodMRP');
      }
      else {
        var prodMRP = document.getElementById('dpMRP');
      }

      var prodDis = document.getElementById('prodDis');
      var Amount = document.getElementById('Amount');

      var sgstPer = 0, cgstPer = 0, igstPer = 0, pRate = 1, pDis = 0, fQty = 0;

      if (freeqty.value.length > 0)
        if (isNaN(freeqty.value))
          fQty = 0;
        else
          fQty = parseInt(freeqty.value);

      if (SGST.value.length > 0)
        if (isNaN(SGST.value))
          sgstPer = 0;
        else
          sgstPer = parseFloat(SGST.value);

      if (CGST.value.length > 0)
        if (isNaN(CGST.value))
          cgstPer = 0;
        else
          cgstPer = parseFloat(CGST.value);

      if (IGST.value.length > 0)
        if (isNaN(IGST.value))
          igstPer = 0;
        else
          igstPer = parseFloat(IGST.value);

      if (prodRate.value.length > 0)
        if (isNaN(prodRate.value))
          pRate = 1;
        else
          pRate = parseFloat(prodRate.value);

      // if (sales === 1)
      var gamt = (parseFloat(qty.value) * pRate);
      // else
      //   var gamt = (parseFloat(qty.value) * parseFloat(prodMRP.value));

      if (prodDis.value.length > 0)
        if (isNaN(prodDis.value))
          pDis = 0;
        else
          // if (sales === 1)
          pDis = gamt * parseFloat(prodDis.value) / 100;
          // else
          //   pDis = parseFloat(prodDis.value);

      var SGSTV = (gamt - pDis) * sgstPer / 100;
      var CGSTV = (gamt - pDis) * cgstPer / 100;
      var IGSTV = (gamt - pDis) * igstPer / 100;

      if (sales === 1)
        if (billingCat === "retRet" || billingCat === "dealRet" || billingCat === "dealDeal")
          var totBillAmt = parseFloat(totals[7]) + parseFloat(Amount.value) + SGSTV + CGSTV + IGSTV;
        else
          var totBillAmt = parseFloat(totals[7]) + parseFloat(Amount.value);
      else
        var totBillAmt = parseFloat(totals[7]) + parseFloat(Amount.value);

      // Saving Invoice into session for recovery
      ReactSession.set(sales === 0 ? "invNoDatePur" : "invNoDateSales", JSON.stringify([invoiceNo.value, invoiceDate.value]));
      // id, invoice_no, dist_id, invoice_amt, payment_mode, inv_date, opr, vend_id, dist_name, dist_address1, dist_address2, city, state, country, pincode, contact1, contact2,
      //  e_mail, dl_no, gstin, itm_qty, free_qty, sgst, cgst, igst, Tcdis, gross_amt, taxable_amount, narration, drug_license_number, landmark, billType
      
      var invHeadfSes = [0, invoiceNo?.value, selCustID, totBillAmt, payMode, invoiceDate?.value, 'null', 'null', 
        document.getElementById('serStockName').value,
        document.getElementById('address1')?.value,
        document.getElementById('address2')?.value,
        document.getElementById('city')?.value,
        document.getElementById('state')?.value, 'null',
        document.getElementById('pincode')?.value,
        document.getElementById('contact1')?.value,
        document.getElementById('contact2')?.value, 'email', 'dl', 'gst', 'qty', 'fqty', 'sgst' , 'cgst', 'igst', 'tcdis', 'gamt', 'taxAmt','narra', 'dln', 
        document.getElementById('landMark')?.value, billingCat
      ]
      ReactSession.set("custDetSales", JSON.stringify(invHeadfSes));
            
      if (invItemEdit === true) {
        var tempInvoices = [];
        for (let i = 0; i < tmpInvoiceItems.length; i++) {
          if (String(tmpInvoiceItems[i][0]) === String(prodId.value)) {
            setTotals([totals[0] + parseInt(qty.value), totals[1] + fQty, totals[2] + SGSTV, totals[3] + CGSTV, totals[4] + IGSTV, totals[5] + parseFloat(pDis), totals[6] + gamt, totBillAmt]);
            ReactSession.set(sales === 0 ? "totalsPur" : "totalsSales", JSON.stringify([totals[0] + parseInt(qty.value), totals[1] + fQty, totals[2] + SGSTV, totals[3] + CGSTV, totals[4] + IGSTV, totals[5] + parseFloat(pDis), totals[6] + gamt, totBillAmt]));
            tempInvoices.push([prodId.value, pName.value, pUnit.value, pComp.value, qty.value, fQty, SGST.value, CGST.value, IGST.value, exMonth, expiryYear, batchNo.value, HSNCode.value, prodMRP?.value, prodRate.value, prodDis.value, Amount.value, prodRack.value, dpMRP?.value]);
          }
          else {
            tempInvoices.push(tmpInvoiceItems[i]);
          }
        }
        setInvoiceItems(tempInvoices);
        ReactSession.set(sales === 0 ? "invoiceItemsPur" : "invoiceItemsSales", JSON.stringify(tempInvoices));
        setTmpInvoiceItems([]);
        setInvItemEdit(false);
      }
      else {
        setTotals([totals[0] + parseInt(qty.value), totals[1] + fQty, totals[2] + SGSTV, totals[3] + CGSTV, totals[4] + IGSTV, totals[5] + parseFloat(pDis), totals[6] + gamt, totBillAmt]);
        ReactSession.set(sales === 0 ? "totalsPur" : "totalsSales", JSON.stringify([totals[0] + parseInt(qty.value), totals[1] + fQty, totals[2] + SGSTV, totals[3] + CGSTV, totals[4] + IGSTV, totals[5] + parseFloat(pDis), totals[6] + gamt, totBillAmt]));    
        setInvoiceItems([...invoiceItems, [prodId.value, pName.value, pUnit.value, pComp.value, qty.value, fQty, SGST.value, CGST.value, IGST.value, exMonth, expiryYear, batchNo.value, HSNCode.value, prodMRP?.value, prodRate.value, prodDis.value, Amount.value, prodRack.value, dpMRP?.value]]);
        ReactSession.set(sales === 0 ? "invoiceItemsPur" : "invoiceItemsSales", JSON.stringify([...invoiceItems, [prodId.value, pName.value, pUnit.value, pComp.value, qty.value, fQty, SGST.value, CGST.value, IGST.value, exMonth, expiryYear, batchNo.value, HSNCode.value, prodMRP?.value, prodRate.value, prodDis.value, Amount.value, prodRack.value, dpMRP?.value]]));
      }

      prodId.value = "";
      pName.value = "";
      setSalesProdName('');
      pUnit.value = "";
      pComp.value = "";
      qty.value = "";
      freeqty.value = "";
      SGST.value = "";
      CGST.value = "";
      IGST.value = "";
      batchNo.value = "";
      HSNCode.value = "";
      // if (sales === 1)
      //   dpMRP.value = "";
      // else
      
      prodRate.value = "";
      prodDis.value = "";
      Amount.value = "";
      prodRack.value = "";
      pName.focus();
      if (billingCat === "retRet" || billingCat === "dealRet" || sales == 0)
      prodMRP.value = "";

      setdefExMonth(null);
      setdefExpiryYear(null);
    }
  }

  const editInvoiceItem = (invItem, sales) => {
    // [prodId.value, pName.value, pUnit.value, pComp.value, qty.value, fQty, SGST.value, CGST.value, IGST.value, exMonth, expiryYear, batchNo11.value, HSNCode.value, prodMRP.value, prodRate.value, prodDis.value, Amount.value]
    // Doc ID's [prodId prodName prodUnit prodComp quantity freeQuantity SGST CGST IGST expMo expYr batchNo HSNCode prodMRP prodRate prodDis Amount]
    setTmpInvoiceItems(invoiceItems);
    setInvItemEdit(true);
    document.getElementById('prodId').value = invItem[0];
    setSalesProdName(invItem[1]);
    document.getElementById('prodName').value = invItem[1];
    document.getElementById('prodUnit').value = invItem[2];
    document.getElementById('prodComp').value = invItem[3];
    document.getElementById('quantity').value = invItem[4];
    document.getElementById('freeQuantity').value = invItem[5];
    document.getElementById('SGST').value = invItem[6];
    document.getElementById('CGST').value = invItem[7];
    document.getElementById('IGST').value = invItem[8];

    
    // exMonth expiryYear
    const filExpMo = expMoOptions.find(option => option.value === String(invItem[9]));
    // console.log(filExpMo);
    setExMonth(filExpMo.value);
    setdefExMonth(filExpMo);
    
    const filExpYr = expYearOptions.find(option => option.value === String(invItem[10]));
    setexpiryYear(filExpYr.value);
    setdefExpiryYear(filExpYr);
    
    document.getElementById('batchNo').value = invItem[11];
    document.getElementById('HSNCode').value = invItem[12];
    
    var dpMRP = document.getElementById('dpMRP');
    if (dpMRP !== null) 
      dpMRP.value = invItem[13];
    
    document.getElementById('prodRate').value = invItem[14];
    document.getElementById('prodDis').value = invItem[15];
    document.getElementById('Amount').value = invItem[16];
    document.getElementById('prodRack').value = invItem[17];
    
    document.getElementById('prodName').focus();
    if (purchBookModal === true || purchaseModal === true) {
      document.getElementById('prodMRP').value = invItem[13];
      removeItemToInvoice(invItem[0], 0);
    }
    else
      removeItemToInvoice(invItem[0], 1);
  }


  const removeItemToInvoice = (itmCode, sales) => {
    const fillItems = invoiceItems.filter(invitem => invitem[0] !== itmCode);
    var qty = 0, sgst = 0, cgst = 0, igst = 0, cdis = 0, gamount = 0, amount = 0;
    // var frqty = 0;
    
    var Tqty = 0, Tfrqty = 0, Tsgst = 0, Tcgst = 0, Tigst = 0, Tcdis = 0, Tgamount = 0, Tamount = 0;
    for (let i = 0; i < fillItems.length; i++) {
      qty = parseInt(fillItems[i][4]);
      gamount = (qty * parseFloat(fillItems[i][14]));

      if (String(fillItems[i][15]).length < 1)
        cdis = 0;
      else
        // if (sales === 1)
        cdis = gamount * parseFloat(fillItems[i][15]) / 100;
        // else
        //   cdis = parseFloat(fillItems[i][15]);

      if (String(fillItems[i][6]).length < 1)
        sgst = 0;
      else
        sgst = ((gamount - cdis) * parseInt(fillItems[i][6])) / 100;

      if (String(fillItems[i][7]).length < 1)
        cgst = 0;
      else
        cgst = ((gamount - cdis) * parseFloat(fillItems[i][7])) / 100;

      if (String(fillItems[i][8]).length < 1)
        igst = 0;
      else
        igst = ((gamount - cdis) * parseFloat(fillItems[i][8])) / 100;

      if (sales === 1)
        if (billingCat === "retRet" || billingCat === "dealRet" || billingCat === "dealDeal")
          amount = gamount - cdis + sgst + cgst + igst;
        else
          amount = gamount - cdis;
      else
        amount = gamount - cdis;


      Tqty += qty;

      if (String(fillItems[i][5]).length > 0)
        Tfrqty += parseInt(fillItems[i][5]);

      Tsgst += sgst;
      Tcgst += cgst;
      Tigst += igst;
      Tcdis += cdis;
      Tgamount += gamount;
      Tamount += amount;
      // to round floating points value in integer use "Math.round(7.8)""
    }

    setTotals([Tqty, Tfrqty, Tsgst, Tcgst, Tigst, Tcdis, Tgamount, Tamount]);
    ReactSession.set(purchaseModal === true ? "totalsPur" : "totalsSales", JSON.stringify([Tqty, Tfrqty, Tsgst, Tcgst, Tigst, Tcdis, Tgamount, Tamount]));
    
    // setInvoiceItems([]);
    setInvoiceItems(fillItems);
    ReactSession.set( purchaseModal === true ? "invoiceItemsPur" : "invoiceItemsSales", JSON.stringify(fillItems));
  }


  // Calculating amount after discount
  function calculateAmount(sales) {
    // quantity freeQuantity SGST CGST prodRate prodDis Amount
    // var freeQuantity = parseFloat(document.getElementById('freeQuantity').value);
    var quantity = parseFloat(document.getElementById('quantity').value);
    var SGST = document.getElementById('SGST');
    var CGST = document.getElementById('CGST');
    var IGST = document.getElementById('IGST');
    var dpMRP = document.getElementById('dpMRP');
    var prodRate = document.getElementById('prodRate');
    var prodMRP = document.getElementById('prodMRP');
    var prodDis = document.getElementById('prodDis');
    var sgstPer = 0.00, cgstPer = 0.00, igstPer = 0.00, pRate = 1.00, pDis = 0.00;

    if (SGST.value.length > 0)
      if (isNaN(SGST.value))
        sgstPer = 0.00;
      else
        sgstPer = parseFloat(SGST.value);

    if (CGST.value.length > 0)
      if (isNaN(CGST.value))
        cgstPer = 0.00;
      else
        cgstPer = parseFloat(CGST.value);

    if (IGST.value.length > 0)
      if (isNaN(IGST.value))
        igstPer = 0.00;
      else
        igstPer = parseFloat(IGST.value);

    if (sales === 1)
    {
      if (billingCat === "retCus" || billingCat === "dealCust")
        var PTR = parseFloat(prodRate.value);
      else if ((sgstPer + cgstPer) >= 18 || (igstPer) >= 18)
        var PTR = parseFloat(dpMRP?.value) - (parseFloat(dpMRP?.value) * 32.20 / 100);
      else if ((sgstPer + cgstPer) >= 12 || (igstPer) >= 12)
        var PTR = parseFloat(dpMRP?.value) - (parseFloat(dpMRP?.value) * 28.57 / 100);
      else if ((sgstPer + cgstPer) >= 5 || (igstPer) >= 5)
        var PTR = parseFloat(dpMRP?.value) - (parseFloat(dpMRP?.value) * 23.20 / 100);
      else if ((sgstPer + cgstPer) >= 0 || (igstPer) >= 0)
        var PTR = parseFloat(dpMRP?.value) - (parseFloat(dpMRP?.value) * 13.80 / 100);
    }
    else
    {
      var PTR = parseFloat(prodRate.value);
      // if ((sgstPer + cgstPer) >= 18 || (igstPer) >= 18)
      //   var PTR = parseFloat(prodMRP.value) - (parseFloat(prodMRP.value) * 32.20 / 100);
      // else if ((sgstPer + cgstPer) >= 12 || (igstPer) >= 12)
      //   var PTR = parseFloat(prodMRP.value) - (parseFloat(prodMRP.value) * 28.57 / 100);
      // else if ((sgstPer + cgstPer) >= 5 || (igstPer) >= 5)
      //   var PTR = parseFloat(prodMRP.value) - (parseFloat(prodMRP.value) * 23.20 / 100);
      // else if ((sgstPer + cgstPer) >= 0 || (igstPer) >= 0)
      //   var PTR = parseFloat(prodMRP.value) - (parseFloat(prodMRP.value) * 13.80 / 100);
    }

    if (billingCat === "retRet" || billingCat === "dealRet")
      prodRate.value = Number(PTR).toFixed(2);
 
    if (prodRate.value.length > 0)
      if (isNaN(prodRate.value))
        pRate = 1.00;
      else
        pRate = PTR;
    
    var gamt = (quantity * pRate);
    
    if (prodDis.value.length > 0)
      if (isNaN(prodDis.value))
        pDis = 0;
      else
        pDis = gamt * parseFloat(prodDis.value) / 100;
    
    var Amount = document.getElementById('Amount');
    var amt = (gamt - pDis);
    
    // console.log("Purch rate :", pRate,"\n gross Amt", gamt,"\n Discount",prodDis.value,"-Value:",pDis);

    // if (billingCat === "retCus" || billingCat === "dealCust" || purchaseModal === true)
      Amount.value = Number(amt).toFixed(2);
    // else
    //   Amount.value = Number(amt + sgstPer + cgstPer + igstPer).toFixed(2);
    // Amount.value = Math.round(amt);
  }


  function ToggleDropDown(dropdownID) {
    var dropdown = document.getElementById(dropdownID)
    // console.log("Next Sibling ID :", dropdown.nextElementSibling.getAttribute("id"));

    var dropdownContent = dropdown.nextElementSibling;
    if (dropdown.classList.contains("active")) {
      dropdown.classList.remove("active");
      dropdownContent.classList.replace("showSub", "hide");
      // dropdown.firstElementChild.classList.replace("fa-caret-left","fa-caret-down")
    }
    else {
      dropdown.classList.add("active");
      dropdownContent.classList.replace("hide", "showSub");
    }
  }

  const mouseOverEffect0 = () => {
    setItem0Images([require('../../../static/DPImages/Icons/vendSer.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect0 = () => {
    setItem0Images([require('../../../static/DPImages/Icons/vendSerW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  const mouseOverEffect = () => {
    setItem1Images([require('../../../static/DPImages/Icons/user.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect = () => {
    setItem1Images([require('../../../static/DPImages/Icons/userW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  const mouseOverEffect11 = () => {
    setItem11Images([require('../../../static/DPImages/Icons/manageProduct.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect11 = () => {
    setItem11Images([require('../../../static/DPImages/Icons/manageProductW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  const mouseOverEffect12 = () => {
    setItem12Images([require('../../../static/DPImages/Icons/productDiscount.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect12 = () => {
    setItem12Images([require('../../../static/DPImages/Icons/productDiscountW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  const mouseOverEffect2 = () => {
    setItem2Images([require('../../../static/DPImages/Icons/orders.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect2 = () => {
    setItem2Images([require('../../../static/DPImages/Icons/ordersW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }


  const mouseOverEffect3 = () => {
    setItem3Images([require('../../../static/DPImages/Icons/purchase.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect3 = () => {
    setItem3Images([require('../../../static/DPImages/Icons/purchaseW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }


  const mouseOverEffect4 = () => {
    setItem4Images([require('../../../static/DPImages/Icons/transfer.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect4 = () => {
    setItem4Images([require('../../../static/DPImages/Icons/transferW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  const mouseOverEffect5 = () => {
    setItem5Images([require('../../../static/DPImages/Icons/report.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect5 = () => {
    setItem5Images([require('../../../static/DPImages/Icons/reportW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  const mouseOverEffect6 = () => {
    setItem6Images([require('../../../static/DPImages/Icons/contact.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect6 = () => {
    setItem6Images([require('../../../static/DPImages/Icons/contactW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  const mouseOverEffect7 = () => {
    setItem7Images([require('../../../static/DPImages/Icons/logout.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect7 = () => {
    setItem7Images([require('../../../static/DPImages/Icons/logoutW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }


  const mouseOverEffect8 = () => {
    setItem8Images([require('../../../static/DPImages/Icons/transaction.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect8 = () => {
    setItem8Images([require('../../../static/DPImages/Icons/transactionW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  const mouseOverEffect9 = () => {
    setItem9Images([require('../../../static/DPImages/Icons/stock-rotation.png'), require('../../../static/DPImages/Icons/down-arrow.png')]);
  }

  const mouseLeaveEffect9 = () => {
    setItem9Images([require('../../../static/DPImages/Icons/stock-rotationW.png'), require('../../../static/DPImages/Icons/down-arrowW.png')]);
  }

  // window.onclick = function (e) {
  //   if (!e.target.matches('#StockDropDown') && !e.target.matches('#stockSpan')  && !e.target.matches('#stockImage') && !e.target.matches('#stockArrow')) {
  //   var dropdown = document.getElementById('StockDropDown');
  //   if (dropdown != null)
  //   {
  //   var dropdownContent = dropdown.nextElementSibling;
  //   if (dropdown.classList.contains("active")) {
  //   dropdownContent.classList.replace("showSub", "hide");
  //   dropdown.classList.remove("active");
  //   }  
  //   }
  //   }
  // }

  const [billingOptions, setBillingOptions] = useState([
    { value: 'retCus', label: 'To Customer' },
    { value: 'retRet', label: 'To Retail' },
  ]);

  const [billingOptions2, setBillingOptions2] = useState([
    { value: 'dealRet', label: 'To Retail' },
    { value: 'dealDeal', label: 'To Wholesale' },
  ]);

  const [billingOptions3, setBillingOptions3] = useState([
    { value: 'dealCust', label: 'To Customer' },
    { value: 'dealRet', label: 'To Retail' },
    { value: 'dealDeal', label: 'To Wholesale' },
  ]);

  const [billingCat, setBillingCat] = useState("retCus");
  const handleBillingOptions = e => {
    console.log(e.value);
    setBillingCat(e.value);
  };

  const [payOptions, setPayOptions] = useState([
    { value: 'Cash', label: 'Cash' },
    { value: 'Credit', label: 'Credit' },
    { value: 'UPI', label: 'UPI' },
  ])

  const [custDateOptions, setCustDateOptions] = useState([
    { value: "today", label: 'Today' },
    { value: "yesterday", label: 'Yesterday' },
    { value: "days7", label: '7 Days' },
    { value: "days15", label: '15 Days' },
    { value: "days30", label: '30 Days' },
    { value: "all", label: 'All' },
  ])

  const [expMoOptions, setExpMoOptions] = useState([
    { value: '1', label: 'Jan' },
    { value: '2', label: 'Feb' },
    { value: '3', label: 'Mar' },
    { value: '4', label: 'Apr' },
    { value: '5', label: 'May' },
    { value: '6', label: 'Jun' },
    { value: '7', label: 'Jul' },
    { value: '8', label: 'Aug' },
    { value: '9', label: 'Sep' },
    { value: '10', label: 'Oct' },
    { value: '11', label: 'Nov' },
    { value: '12', label: 'Dec' },
  ]);

  const [expYearOptions, setexpYearOptions] = useState([
    { value: '2023', label: '2023' },
    { value: '2024', label: '2024' },
    { value: '2025', label: '2025' },
    { value: '2026', label: '2026' },
    { value: '2027', label: '2027' },
    { value: '2028', label: '2028' },
    { value: '2029', label: '2029' },
    { value: '2030', label: '2030' },
    { value: '2031', label: '2031' },
    { value: '2032', label: '2032' },
    { value: '2033', label: '2033' },
    { value: '2034', label: '2034' },
    { value: '2035', label: '2035' },
    { value: '2036', label: '2036' },
    { value: '2037', label: '2037' },
    { value: '2038', label: '2038' },
    { value: '2039', label: '2039' },
    { value: '2040', label: '2040' },
    { value: '2041', label: '2041' },
    { value: '2042', label: '2042' },
    { value: '2043', label: '2043' },
    { value: '2044', label: '2044' },
    { value: '2045', label: '2045' },
    { value: '2046', label: '2046' },
    { value: '2047', label: '2047' },
    { value: '2048', label: '2048' },
    { value: '2049', label: '2049' },
    { value: '2050', label: '2050' },
  ]);

  const [narrationDef, setNarrationDef] = useState("Being Goods sold in cash");

  const [payMode, setPayMode] = useState('Both');
  const [exMonth, setExMonth] = useState(null);
  const [expiryYear, setexpiryYear] = useState(null);
  const [defExMonth, setdefExMonth] = useState(null);
  const [defExpiryYear, setdefExpiryYear] = useState(null);
  const [dateFill, setDateFill] = useState(null);
  const [invAmtFill, setInvAmtFill] = useState(null);

  const [invItemEdit, setInvItemEdit] = useState(false);

  const handleCustDate = e => {
    setDateFill(e.value);
  }

  const handleInvoiceRange = e => {
    setInvAmtFill(e.value);
  }

  const handlePayMode = e => {
    setPayMode(e.value);
    if (e.value !== "Cash") {
      setNarrationDef("Being Goods sold to Mr/Ms :" + document.getElementById('serStockName').value);
    }
    else {
      setNarrationDef("Being Goods sold in cash :");
    }
  }

  const handleMonth = e => {
    setExMonth(e.value);
    setdefExMonth(e);
  }

  const handleYear = e => {
    setexpiryYear(e.value);
    setdefExpiryYear(e);
  }


  const [purchBookModal, setPurchBookModal] = useState(false);
  const purchBookModalClose = () => {
    setPurchBookModal(false);
    setSelStockistQry(null);
  }

  const purchBookModalOpen = () => {
    if (String(ReactSession.get('is_verified')) === "0") {
      alert("PLease complete your profile before search...")
      navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
        setPayMode('Both');
        setSelStockist(null);
        setInvAmtFill(null);
        setDateFill(null);
        setPurchaseBookData(null);
        setPurBkQrToggle(true);
        setPurchBookModal(true);
      }
  };


  const [salesBookModal, setSalesBookModal] = useState(false);
  const salesBookModalClose = () => {
    setInvHeads(null);
    setSalesBookModal(false);
    setSelStockistQry(null);
  }

  const salesBookModalOpen = () => {
    if (String(ReactSession.get('is_verified')) === "0") {
      alert("PLease complete your profile before search...")
      navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
        setPayMode('Both');
        setSelStockist(null);
        setInvAmtFill(null);
        setDateFill(null);
        setPurchaseBookData(null);
        setPurBkQrToggle(true);
        setSalesBookModal(true);
      }
  };


  const [notFoundModal, setNotFoundModal] = useState(false);
  const notFoundModalClose = () => setNotFoundModal(false);
  const notFoundModalShow = () => setNotFoundModal(true);

  const [invoiceNewNo, setInvoiceNewNo] = useState('');
  const [salesProdName, setSalesProdName] = useState('');
  const [salesModal, setSalesModal] = useState(false);
  const salesModalClose = () => {
    setSalesModal(false);
    setSelectedCust(null);
    setPayMode("Both");
    setSelCustID(null);
    setSelStockist(null);
    document.getElementById("errorMessage2")?.classList.replace("errorMessage", "errorMessageHide");
    document.getElementById("errorMessage")?.classList.replace("errorMessage", "errorMessageHide");
    if (salesBookModal === true)
      purchBookQuery();
    ReactSession.remove("custDetSales");  
    ReactSession.remove("stockistSales");
    ReactSession.remove("totalsSales");
    ReactSession.remove("invoiceItemsSales");
    ReactSession.remove("invNoDateSales");
    setInvHeads(null);
    setSalesProdName('');
  }

  const salesModalShow = () => {
    setPayMode("Cash");
    if (String(ReactSession.get('is_verified')) === "0") {
      alert("PLease complete your profile before search...")
      navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
        if (invoiceNewNo === '') {
          setLoader(true);
          fetch("http://43.205.199.21/digiPartnerAPI?getInvNo=1&user_Id=" + ReactSession.get("id"), {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          }).then((response) => {
            return response.json();
          }).then((resp) => {
            setLoader(false);
            setVendorType(1);
            if (resp.status === "success") {
              setInvoiceNewNo(resp.invLastRec);
              if (parseInt(ReactSession.get('dpType')) > 1) {
                setBillingCat('dealRet');
              }
              setPendingBills(resp.pendingBills);
            }
            setSalesModal(true);
          }).catch((err) => {
            console.log(err.message);
          });
        }
        else {
          setSalesModal(true);
        }

        setInvoiceItems([]);
        setTotals([0, 0, 0, 0, 0, 0, 0, 0]);
        setExMonth(null);
        setexpiryYear(null);
        setSelStockist(null);
        try {
          // "customerSales", "stockistSales", "totalsSales", "invoiceItemsSales"
          // console.log("Sales Invoice :",JSON.parse(ReactSession.get("invoiceItemsSales")));
          if (ReactSession.get("totalsSales") !== null)
            setTotals(JSON.parse(ReactSession.get("totalsSales")));
          
          if (ReactSession.get("invoiceItemsSales") !== null)
            setInvoiceItems(JSON.parse(ReactSession.get("invoiceItemsSales")));
          
          // ReactSession.set("custDetSales", JSON.stringify(invHeadfSes));
          // stockistSales
          setPayMode(JSON.parse(ReactSession.get("custDetSales"))[4]);
          setBillingCat(JSON.parse(ReactSession.get("custDetSales"))[31]);
          // console.log(JSON.parse(ReactSession.get("custDetSales")));
          setSelCustID(JSON.parse(ReactSession.get("custDetSales"))[2])
    
          try {
            setInvHeads(JSON.parse(ReactSession.get("custDetSales")));
            if (JSON.parse(ReactSession.get("custDetSales"))[31] == "retRet" || JSON.parse(ReactSession.get("custDetSales"))[31] === "dealRet" || JSON.parse(ReactSession.get("custDetSales"))[31] === "dealDeal")
            {
              setSelStockist(JSON.parse(ReactSession.get("stockistSales")));
              setSelCustID(JSON.parse(ReactSession.get("stockistSales"))[1]);
              // console.log(JSON.parse(ReactSession.get("custDetSales"))[31]);
            }
          }
          catch (error) {
            console.log("Sales Modal Session Error reading customer details last incomplete invoice");
          }  
        }
        catch (error) {
          console.log("Sales Modal Session Error");
        }
      }
  }


  const getPendingOrderItems = (dpPendingOrderID) =>{
    if (document.getElementById('dpPendOrderDetails').classList.contains("hide"))
    {
      setLoader(true);
      fetch("http://43.205.199.21/digiPartnerAPI?dpPendingOrderID=" + dpPendingOrderID + "&user_Id=" + ReactSession.get("id"), {
        method: 'GET',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      }).then((response) => {
        return response.json();
      }).then((resp) => {
        setLoader(false);
        if (resp.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
        }
        else if (resp.status === "success") {
          document.getElementById('dpPendOrderDetails').classList.remove("hide");
          setPendingBillDetails(resp.pendingBillsDetails);
        }
      }).catch((err) => {
        console.log(err.message);
      });
    }
    else
    {
      document.getElementById('dpPendOrderDetails').classList.add("hide");
    }
  }

  const [customerData, setCustomerData] = useState(null);
  const [selectedCust, setSelectedCust] = useState(null);
  const [addProdModal, setAddProdModal] = useState(false);
  const AddProdModalClose = () => setAddProdModal(false);
  const AddProdModalShow = () => setAddProdModal(true);

  const [vendorType, setVendorType] = useState(1);
  const [stockistModal, setStockistModal] = useState(false);
  const stockistModalClose = () => setStockistModal(false);
  const stockistModalShow = (vnType) => {
    if (String(ReactSession.get('is_verified')) === "0") {
      alert("PLease complete your profile before search...")
      navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
        setVendorType(vnType);
        setStockistModal(true);
      }
  }


  const [productsData, setProductsData] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const PurchaseModalClose = () => {
    setStockistFillData(stockistData);
    setInvoiceItems([]);
    setInvHeads(null);
    setTotals([0, 0, 0, 0, 0, 0, 0, 0]);
    setSelStockist(null);
    setPurchaseModal(false);
    if (purchBookModal === true)
      purchBookQuery();

    ReactSession.remove("totalsPur");
    ReactSession.remove("invoiceItemsPur");
    ReactSession.remove("stockistPur");
    ReactSession.remove("invNoDatePur");
  }

  const PurchaseModalShow = () => {

    if (String(ReactSession.get('is_verified')) === "0") {
      alert("PLease complete your profile before search...")
      navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
        setPayMode('Cash');
        setVendorType(0);
        setExMonth(null);
        setexpiryYear(null);
        setSelStockist(null);
        setPurchaseModal(true);
      
        try {
          if (ReactSession.get("totalsPur") !== null)
          {
            setTotals(JSON.parse(ReactSession.get("totalsPur")));
            // console.log("Totals Found :",JSON.parse(ReactSession.get("totalsPur")));
          }
          
          if (ReactSession.get("invoiceItemsPur") !== null)
            setInvoiceItems(JSON.parse(ReactSession.get("invoiceItemsPur")));
  
          if (ReactSession.get("stockistPur") !== null)
            setSelStockist(JSON.parse(ReactSession.get("stockistPur")));
        }
        catch (error) {
          console.log("Purchase Modal Session Error");
        }
      }
  }

  const selectStyle1 = {
    // options: (baseStyles, state) => ({
    //   ...baseStyles,
    //   fontSize: '1vw',
    //   width: '6vw',
    //   border: "none",
    //   marginTop: "10px",
    // }),

    // menu: (baseStyles, state) => ({
    //   ...baseStyles,
    //   fontSize: '1vw',
    //   background: "white",
    //   border: '1px solid gray',
    //   width: '6vw',
    //   lineHeight: '.5vw',
    //   marginTop: "10px"
    // }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      // border: '1px solid black',
      borderRadius: '0px',
      fontSize: '.7vw',
      height: '2.9vw',
      width: '7vw',
      marginTop:1,
      "@media (max-width: 512px)": {
        fontSize: '5vw',
        marginTop: "10px",
        width: '10px',
        border: "none",
      }
    }),

    input: (provided, state) => ({
      ...provided,
      "@media (max-width: 512px)": {
        fontSize: '10px',
        marginTop: "10px",
        width: '10px',
        border: "none",
      }
    }),

    placeholder: (provided, state) => ({
      ...provided,
      fontSize: "10px",
      "@media (max-width: 512px)": {
        fontSize: '15px',
        border: "none",
        marginTop: "10px",
        width: '10px',
      }
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      "@media (max-width: 512px)": {
        fontSize: '20px',
        border: "none",
      }
      // marginTop: '-1vw',
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      // marginTop:-5,
      width: 36,  // Set the desired width
      height: 36, // Set the desired height
      // Add any other styles you want for the dropdown indicator
      "@media (max-width: 512px)": {
        fontSize: '20px',
        display: "none"
      }

    }),
  }


  const selectStyle2 = {
    // options: (baseStyles, state) => ({
    //   ...baseStyles,
    //   border: '1px solid black',
    //   borderRadius: '0px',
    //   width: '100%',
    //   marginTop: "3vw",
    //   minHeight: '100%',
    //   "@media (max-width: 512px)": {
    //     fontSize: '20px',
    //   }
    // }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      minHeight: "100px",
      maxHeight: "100px",  // Ensure the menu doesn't grow beyond 200px
      // overflowY: "auto",   // Allow scrolling if items exceed 200px
      alignItems: 'center',
      border: 'none',
      borderBottom: "1px solid #ccc",
      borderRadius: '0px',
      fontSize: '13px',
      "@media (max-width: 512px)": {
        fontSize: '4vw',
        lineHeight: '3vw',
        height: "50vh",
      }
    }),
  
    menuList: (baseStyles, state) => ({
      ...baseStyles,
      minHeight:"100px",
      maxHeight: "100px",  // Set the max height of the items container
      // overflowY: "auto",   // Ensure items scroll within this container
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      border: '1px solid black',
      borderRadius: '0px',
      fontSize:"13px",
      "@media (max-width: 512px)": {
        fontSize: '13px',
        lineHeight: '3vw',
        height: "30px",
      }
    }),

    input: (provided, state) => ({
      ...provided,
      fontSize: '1vw',
      marginTop: "-10px",
      "@media (max-width: 512px)": {
        fontSize: '16px',
      }
    }),

    placeholder: (provided, state) => ({
      ...provided,
      marginTop:8,
      fontSize: '13px',
      alignItems:'center',
      "@media (max-width: 512px)": {
        fontSize: '20px',
      }
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginTop:8,
      // display:"flex",
      color:"red",
      alignItems:"center",
      fontSize: '13px',
      // background:"green",
      "@media (max-width: 512px)": {
        fontSize: '12px',
      }
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      width: 36,  // Set the desired width
      height: 36, // Set the desired height
      // Add any other styles you want for the dropdown indicator
    }),

  }

  window.onclick = function (e) {
    if (!e.target.matches('.searchBtn')) {
      clearSearch();
    }

    if (!e.target.matches('#serStockName') && !e.target.matches('#serCusNameElement')) {
      var dropdown = document.getElementById('stockistList');
      if (dropdown != null) {
        dropdown.classList.replace("show", "hide");
      }
    }

    if (!e.target.matches('#purDropDown') && !e.target.matches('#purchSpan') && !e.target.matches('#purchImage1') && !e.target.matches('#purchImage2')) {
      var dropdown1 = document.getElementById('purDropDown');
      if (dropdown1 != null) {
        var dropdownContent1 = dropdown1.nextElementSibling;
        if (dropdown1.classList.contains("active")) {
          dropdownContent1.classList.replace("showSub", "hide");
          dropdown1.classList.remove("active");
        }
      }
    }

    if (!e.target.matches('#UserDropDown') && !e.target.matches('#userSpan') && !e.target.matches('#userImage') && !e.target.matches('#userImage2')) {
      var dropdown2 = document.getElementById('UserDropDown');
      if (dropdown2 != null) {
        var dropdownContent2 = dropdown2.nextElementSibling;
        if (dropdown2.classList.contains("active")) {
          dropdownContent2.classList.replace("showSub", "hide");
          dropdown2.classList.remove("active");
        }
      }
    }

    // orderImage2 orderSpan OrdersDropDown
    if (!e.target.matches('#OrdersDropDown') && !e.target.matches('#orderSpan') && !e.target.matches('#orderImage') && !e.target.matches('#orderImage2')) {
      var dropdown3 = document.getElementById('OrdersDropDown');
      if (dropdown3 != null) {
        var dropdownContent3 = dropdown3.nextElementSibling;
        if (dropdown3.classList.contains("active")) {
          dropdownContent3.classList.replace("showSub", "hide");
          dropdown3.classList.remove("active");
        }
      }
    }

    //billingDropDown salesSpan salesImage2
    if (!e.target.matches('#billingDropDown') && !e.target.matches('#salesSpan') && !e.target.matches('#salesImage') && !e.target.matches('#salesImage2')) {
      var dropdown4 = document.getElementById('billingDropDown');
      if (dropdown4 != null) {
        var dropdownContent4 = dropdown4.nextElementSibling;
        if (dropdown4.classList.contains("active")) {
          dropdownContent4.classList.replace("showSub", "hide");
          dropdown4.classList.remove("active");
        }
      }
    }

    //stockDropDown stockSpan stockImage2
    if (!e.target.matches('#stockDropDown') && !e.target.matches('#stockSpan') && !e.target.matches('#stockImage') && !e.target.matches('#stockImage2')) {
      var dropdown5 = document.getElementById('stockDropDown');
      if (dropdown5 != null) {
        var dropdownContent5 = dropdown5.nextElementSibling;
        if (dropdown5.classList.contains("active")) {
          dropdownContent5.classList.replace("showSub", "hide");
          dropdown5.classList.remove("active");
        }
      }
    }

    //tranDropDown tranSpan tranImage2
    if (!e.target.matches('#tranDropDown') && !e.target.matches('#tranSpan') && !e.target.matches('#tranImage') && !e.target.matches('#tranImage2')) {
      var dropdown6 = document.getElementById('tranDropDown');
      if (dropdown6 != null) {
        var dropdownContent6 = dropdown6.nextElementSibling;
        if (dropdown6.classList.contains("active")) {
          dropdownContent6.classList.replace("showSub", "hide");
          dropdown6.classList.remove("active");
        }
      }
    }

    //reportsDropDown reportSpan reportImage2
    if (!e.target.matches('#reportsDropDown') && !e.target.matches('#reportSpan') && !e.target.matches('#reportImage') && !e.target.matches('#reportImage2')) {
      var dropdown7 = document.getElementById('reportsDropDown');
      if (dropdown7 != null) {
        var dropdownContent7 = dropdown7.nextElementSibling;
        if (dropdown7.classList.contains("active")) {
          dropdownContent7.classList.replace("showSub", "hide");
          dropdown7.classList.remove("active");
        }
      }
    }

  }

  const [stockistData, setStockistData] = useState(null);
  // const [stockist, setStockist] = useState([{ value: '', label: 'Select Stockist' },]);
  const [selStockist, setSelStockist] = useState(null);
  const [stockistFillData, setStockistFillData] = useState()

  const filterStockist = () => {
    var sertext = document.getElementById("serStockName").value;
    const filteredData = stockistData.filter(stockistDa =>
      String(stockistDa[0]).toLowerCase().includes(sertext.toLowerCase()) ||
      stockistDa[1].toLowerCase().includes(sertext.toLowerCase())
    );
    setStockistFillData(filteredData);
  }

  // For Filter array of object Single Selection List
  // const handleSelect = e => {
  //   const fillStock = stockistData.filter(stock => {
  //   return e.value === stock[0];
  //   });
  //   if (fillStock.length > 0) {
  //   setSelStockist(fillStock);
  //   }
  // }

  // Cancel Invoice
  const cancelInvoice = (invId, invNo) =>{
    // alert(invId+" Invoice Cancelled...!");
    const con = window.confirm("Are you sure");
    if (con)
    {
      fetch('http://43.205.199.21/digiPartnerAPI/', {
        mode: 'cors',
        method: 'PUT',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        body: JSON.stringify({
          cancelInvId: invId,
          invoiceNo : invNo,
          user_Id: ReactSession.get("id"),
        })
      })
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
          navigate("/Subscriptions");
        }
        else if (data.status === "success") {
          alert("Invoice Successfully Cancelled");
          setSelStockist(null);
          setInvoiceItems([]);
          setInvHeads(null);
          salesModalClose();
          setTotals([0, 0, 0, 0, 0, 0, 0, 0]);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err.message);
      });
    }
  }

  //Handle purchase request and perform purchase bill and stock inventory entry
  const handleSubmit = () => {
    if (invoiceItems.length < 1)
    {
      alert("Please add one or more products....!");
      return false;
    }
    setLoader(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        purchase: purchBookModal === true ? "2" : "1",
        invBkId: purchBookModal === true ? invHeads[0] : "null",
        dp_id: ReactSession.get("id"),
        dist_id: selStockist ? selStockist[0] : '',
        invoice_no: document.getElementById('invoiceNo').value,
        inv_date: document.getElementById('invoiceDate').value,
        totals_inv: totals,
        payment_mode: payMode,
        invoiceItems_det: invoiceItems,
        dist_name: selStockist ? selStockist[1] : '',
      })
    }).then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
        }
        else if (data.status === "success") {
          alert("Product Successfully Added");
          // PurchaseModalClose();
          setSelStockist(null);
          setInvoiceItems([]);
          setTotals([0, 0, 0, 0, 0, 0, 0, 0]);
          document.getElementById('invoiceNo').value = '';
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err.message);
      });
  }

  //Handle sales request and perform sales bill and stock inventory entry
  const handleInvoiceSubmit = () => {
    if (invoiceItems.length < 1)
    {
      alert("Please add one or more products");
      return false;
    }
    setLoader(true);
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        salesbill: salesBookModal === true ? "2" : "1",
        invBkId: salesBookModal === true ? invHeads[0] : "null",
        dp_id: ReactSession.get("id"),
        dist_id: selStockist ? selStockist[0] : selCustID ? selCustID : null,
        dist_name: selStockist ? selStockist[1] : document.getElementById('serStockName').value.split('-')[0],
        serStockName: document.getElementById('serStockName').value.split('-')[0],
        address1: document.getElementById('address1')?.value,
        address2: document.getElementById('address2')?.value,
        mobile: document.getElementById('contact1')?.value,
        mobile2: document.getElementById('contact2')?.value,
        landMark: document.getElementById('landMark')?.value,
        pincode: document.getElementById('pincode')?.value,
        city: document.getElementById('city')?.value,
        state: document.getElementById('state')?.value,
        invoice_no: document.getElementById('invoiceNo').value,
        inv_date: document.getElementById('invoiceDate').value,
        totals_inv: totals,
        payment_mode: payMode,
        invoiceItems_det: invoiceItems,
        narration: document.getElementById('narration').value,
        billType: billingCat,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        setLoader(false);
        if (data.status === "validityExpired") {
          alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
        }
        else if (data.status === "success") { 
          alert("Invoice successfully created");
          // setInvoiceNewNo('');
          fetch("http://43.205.199.21/digiPartnerAPI?getInvNo=1&user_Id=" + ReactSession.get("id"), {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          }).then((response) => {
            return response.json();
          }).then((resp) => {
            setVendorType(1);
            if (resp.status === "success") {
              setInvoiceNewNo(resp.invLastRec);
              var invNoEl = document.getElementById('invoiceNo');
              if (invNoEl != null)
                invNoEl.value = resp.invLastRec;
              // if (parseInt(ReactSession.get('dpType')) > 1) {
              //   setBillingCat('dealRet');
              // }
              // setPendingBills(resp.pendingBills);
            }
            // setSalesModal(true);
          }).catch((err) => {
            console.log(err.message);
          });
          setSelCustID(null);
          setInvoiceItems([]);
          setTotals([0, 0, 0, 0, 0, 0, 0, 0]);
          setExMonth(null);
          setexpiryYear(null);
          setSelStockist(null);
          if (billingCat === "retCus" || billingCat ==="dealCust")
          {
            document.getElementById('address1').value = '';
            document.getElementById("address2").value = '';
            document.getElementById("landMark").value = '';
            document.getElementById("serStockName").value = '';
            document.getElementById("contact1").value = '';
            document.getElementById("contact2").value = '';
            document.getElementById("pincode").value = '';
            document.getElementById("city").value = '';
            document.getElementById("state").value = '';
          }
        }
        // salesModalClose();
      })
      .catch((err) => {
        setLoader(false);
        // alert("Error in creating Invoice");
        console.log(err.message);
      });
  }


  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }


  function clearSearch(searchCont = "searchProList") {
    var ser = document.getElementById("searchStock");
    var cleanSer = document.getElementById("cleanSearch");
    // var cleanCustSer = document.getElementById("cleanSearchCust")
    if (ser !== null) {
      ser.value = '';
    }
    if (cleanSer != null) {
      cleanSer.classList.replace("show", "hide");
      document.getElementById(searchCont)?.classList.replace("show", "hide");
    }
    setProductsData(null);
  }

  function populateStockist() {
    var StockistContainer = document.getElementById("stockistList");
    StockistContainer.classList.replace("hide", "show");
  }

  function populateSearch(sales, searchEle = "prodName", searchCont = "searchProList") {
    var searchURL = "";
    var searchText = document.getElementById(searchEle);
    var ProductContainer = document.getElementById(searchCont);
    document.getElementById("cleanSearch").classList.replace("hide", "show");
    if (searchText.value.length > 1) {
      setTimeout(
        function () {
          if (sales === 1)
          { 
            searchURL = "http://43.205.199.21/selectProduct?dp_id=" + ReactSession.get('id') + "&inSales=1&sertext=" + searchText.value;
          }
          else 
            if (sales === 0)
              searchURL = "http://43.205.199.21/selectProduct?purch=0&dp_id=" + ReactSession.get('id') + "&sertext=" + searchText.value;
            else
              searchURL = "http://43.205.199.21/selectProduct?sertext=" + searchText.value;

          ProductContainer.classList.replace("hide", "show");
          // setLoader(true);
          fetch(searchURL, {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          }).then((response) => {
            return response.json();
          }).then((resp) => {
            // setLoader(false);
            setProductsData(resp.data);
            ProductContainer.focus();
          }).catch((err) => {
            console.log(err.message);
          });
        }, 2000);
    }
    else {
      ProductContainer.classList.replace("show", "hide");
    }
  }

  const searchCustomer = () => {
    var searchText = document.getElementById("serStockName");
    var customerListContainer = document.getElementById("searchCustList");
    // document.getElementById("cleanSearch").classList.replace("hide", "show");
    if (searchText.value.length > 2) {
      setTimeout(
        function () {
          setLoader(true);
          var searchURL = "http://43.205.199.21/digiPartnerAPI?searchCust=" + searchText.value + "&user_Id=" + ReactSession.get('id');
          fetch(searchURL, {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          }).then((response) => {
            return response.json();
          }).then((resp) => {
            if (resp.status === "validityExpired")
            {
              alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
            }
            else if (resp.customerData.length > 0) {
              customerListContainer.classList.replace("hide", "show");
              setCustomerData(resp.customerData);
              customerListContainer.focus();
            }
            else{
              setSelCustID(null);
            }
            setLoader(false);
          }).catch((err) => {
            console.log(err.message);
          });
        }, 2000);
    }
    else {
      customerListContainer.classList.replace("show", "hide");
    }
  }

  const initialize = () => {
    fetch("http://43.205.199.21/digiPartnerAPI?stockist=1", {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      if (res.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
        navigate("/Subscriptions");
      }
      else if (res.status === "success") {
        // setStockist(res.stockistD);
        setStockistData(res.distributers);
        setStockistFillData(res.distributers);
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }


  const Sheet360ERPLogout = () => {
    // verLogState(false);
    ReactSession.remove("dpsession");
    ReactSession.remove("id");
    ReactSession.remove("username");
    ReactSession.remove("email");
    ReactSession.remove("mobile");
    navigate('/DigitalPartner');
  }


  // useEffect(() => {
  // }, []);

  const [isPendingBillModelShow, setIsPendingBillModelShow] = useState(true);
  const pendingBillsRef = useRef(null);

  function togglePendingBillModel() {
    setIsPendingBillModelShow(!isPendingBillModelShow);
  }

  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  // Update window dimensions on mount and on resize
  useEffect(() => {
    // CollapseMenu(!menuCollapse);
    if (ReactSession.get('pendingBill')) {
      ReactSession.remove('pendingBill');
      salesModalShow();
    }
    initialize();
    
    const updateWindowSize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    updateWindowSize(); // Initial call
    window.addEventListener("resize", updateWindowSize); // Resize event listener
    return () => window.removeEventListener("resize", updateWindowSize); // Cleanup
  }, []);


  // useEffect(() => {
  //   console.log("Pending Bills : ", document.querySelector(".pendingBills"))

  //   if (".pendingBills") {
  //     Draggable.create(".pendingBills", {
  //       type: 'x,y',
  //       edgeResistance: 0.65,
  //       bounds: document.body,
  //       inertia: true
  //     });
  //   }


  //   if (".dpPendOrderDetails") {
  //     Draggable.create(".dpPendOrderDetails", {
  //       type: 'x,y',
  //       edgeResistance: 0.65,
  //       bounds: document.body,
  //       inertia: true
  //     });
  //   }
  // }, []);

  
  const billPrintInlineStyle = `@media print {
    @page {
      size: A5 landscape;
      margin-inline: 1cm;
    } 
    }
    `;


  return (
    <div style={{display:'block', position:'relative'}} ref={pendingBillsRef}>
      {loader ? <Loader /> : null}
                        
      <div className='row' >
        <div style={{ borderBottom: '3px solid rgb(57, 164, 226)' }} className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          {
            window.innerWidth <=512 ?
            <>
                <span onClick={() => {
                  navigate('/DigiPartnerProfile');
                }}>
                  <img className="dpSidePanelimage" src={require('../../../static/DPImages/Icons/userW.png')} alt="" />
                </span>
                <span>{ReactSession.get('busi_name') !== '' ? ReactSession.get('busi_name') : 'Profile'}</span>
            </>
            :''
          }
          {
            !menuCollapse ?
              <>
                <span onClick={() => {
                  navigate('/DigiPartnerProfile');
                }}>
                  <img className="dpSidePanelimage" src={require('../../../static/DPImages/Icons/userW.png')} alt="" />
                </span>
                {/* <span>Profile</span> */}
                  <span style={{ fontSize: '1.25vw' }}>{ReactSession.get('busi_name') !== null ? ReactSession.get('busi_name') : 'Profile'}</span>
                  <p  onClick={() => {
                  navigate('/DigiPartnerProfile');
                }} className='dpSidePanelEditBtn' style={{ margin: 0, padding: 0, marginLeft: 49, color: "#00BAFF", fontSize: "14px", cursor: "pointer" }}>Edit Profile</p>
            
                {
                  window.innerWidth > 512 ? 
                  <span onClick={() => CollapseMenu(!menuCollapse)} ><img className='dpSidePanelimage' style={{float:'right',marginTop:-50}} src={require('../../../static/DPImages/Icons/menu-collapse.png')} alt="" /></span>
                :null
                }
              </>
              :
                window.innerWidth > 512 ? 
                  <span onClick={() => CollapseMenu(!menuCollapse)} ><img className='dpSidePanelimage' style={{float:'right'}} src={require('../../../static/DPImages/Icons/menu-open.png')} alt="" /></span>
                :null
              
          }
        </div>
      </div>

      <div className='DigiPanelItem row' onMouseOver={mouseOverEffect0} onMouseLeave={mouseLeaveEffect0} onClick={() => navigate('/SearchMedVenAvl')}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="dpSidePanelimage" className="dpSidePanelimage" src={item0Images[0]} alt="" />
          {
            !menuCollapse ?
              <>
                <span>Search Stockist/Medicine</span>
                <img id="dpSidePanelimage2" className='DigiPanelImage' src={item0Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span>Search Stockist/Medicine</span>
                </>
                :
                null
          }
        </div>
      </div>

      {/* <div className='DigiPanelItem row' id="UserDropDown" onClick={() => ToggleDropDown("UserDropDown")} onMouseOver={mouseOverEffect} onMouseLeave={mouseLeaveEffect}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="userImage" className="dpSidePanelimage" alt="" src={item1Images[0]} />
          {
            !menuCollapse ?
              <>
                <span id="userSpan">User</span>
                <img id="userImage2" className='DigiPanelImage' src={item1Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="userSpan">User</span>
                </>
                :
                null
          }
        </div>
      </div> */}

      {/* <div className="DigiDashDropDown hide">
        <div className='DigiDashDropDownItem' onClick={() => { navigate('/UserReport') }}>User Report</div>
      </div> */}


      <div className='DigiPanelItem row' id="myOnlineShop" onClick={() => ToggleDropDown("myOnlineShop")} onMouseOver={mouseOverEffect11} onMouseLeave={mouseLeaveEffect11}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="manageProductsImage" className="dpSidePanelimage" alt="" src={item11Images[0]} />
          {
            !menuCollapse ?
              <>
                <span id="manageProductsSpan">My Online Shop</span>
                <img id="manageProductsImage2" className='DigiPanelImage' src={item11Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="manageProductsSpan">My Online Shop</span>
                </>
                :
                null
          }
        </div>
      </div>

      <div className="DigiDashDropDown hide">
        <div className='DigiDashDropDownItem' onClick={() => { navigate('/UserReport') }}>User Report</div>
        { ReactSession.get("partnerType") === "1" || ReactSession.get("partnerType") === "3"? 
          <>
            <div className='DigiDashDropDownItem' onClick={() => { navigate('/ForwardedOrderDetails') }}>Forwarded Orders</div>
            <div className='DigiDashDropDownItem' onClick={() => { navigate('/dpDashManageProducts')}}>Manage Products</div>
            <div className='DigiDashDropDownItem' onClick={() => { navigate('/dpDashManageCateWiseDisco') }}>Category Wise Discount</div>
            <div className='DigiDashDropDownItem' onClick={() => { navigate('/DigiPartPersonalization') }}>Personalization</div>
          </>
          : null
        }
      </div>

      {/* <div className='DigiPanelItem row' onClick={()=> {navigate("/dpDashManageCateWiseDisco")}} onMouseOver={mouseOverEffect12} onMouseLeave={mouseLeaveEffect12}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="CatediscImage" className="dpSidePanelimage" alt="" src={item12Images[0]} />
          {
            !menuCollapse ?
              <>
                <span id="CatedisSpan">Category Wise Discount</span>
                <img id="CatedisImage2" className='DigiPanelImage' src={item12Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="CatedisSpan">Category Wise Discount</span>
                </>
                :
                null
          }
        </div>
      </div> */}


      <div className='DigiPanelItem row' id="OrdersDropDown" onClick={() => ToggleDropDown("OrdersDropDown")} onMouseOver={mouseOverEffect2} onMouseLeave={mouseLeaveEffect2}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="orderImage" className="dpSidePanelimage" alt="" src={item2Images[0]} />
          {
            !menuCollapse ?
              <>
                <span id="orderSpan">Orders</span>
                <img id="orderImage2" className='DigiPanelImage' src={item2Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="orderSpan">Orders</span>
                </>
                :
                null}

        </div>
      </div>
      <div className="DigiDashDropDown hide">
        <div className='DigiDashDropDownItem' onClick={() => navigate('/DigiPartneRetailOrders')}>My Orders</div>
        <div className='DigiDashDropDownItem' onClick={() => navigate('/DigiPartnerPharmacyOrders')}>{parseInt(ReactSession.get('dpType')) > 1 ? 'Pharmacy Orders' : 'User Orders'}  </div>
        {/* <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>{parseInt(ReactSession.get('dpType')) > 1 ? 'Pharmacy' : 'User'} - Pending Orders</div> */}
        {/* <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()} style={{ border: 'none' }}>Cancelled</div> */}
      </div>


      <div className='DigiPanelItem row' id="purDropDown" onClick={() => ToggleDropDown("purDropDown")} onMouseOver={mouseOverEffect3} onMouseLeave={mouseLeaveEffect3}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="purchImage1" className="dpSidePanelimage" alt="" src={item3Images[0]} />
          {
            !menuCollapse ?
              <>
                <span id="purchSpan">Purchase</span>
                <img id="purchImage2" className='DigiPanelImage' src={item3Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="purchSpan">Purchase</span>
                </>
                :
                null}
        </div>
      </div>

      <div className="DigiDashDropDown hide" id="bb">
        {/* <Link to='/ManageSaltGroups'>Substitute Group</Link>   */}
        <div className='DigiDashDropDownItem' onClick={() => PurchaseModalShow(true)}>Purchase Bill</div>
        {/* <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Purchase Challan</div> */}
        <div className='DigiDashDropDownItem' onClick={() => purchBookModalOpen()} style={{ border: 'none' }}>Purchase Book</div>
      </div>

      <div className='DigiPanelItem row' id="billingDropDown" onClick={() => ToggleDropDown("billingDropDown")} onMouseOver={mouseOverEffect4} onMouseLeave={mouseLeaveEffect4}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12' >
          <img id="salesImage" className="dpSidePanelimage" alt="" src={item4Images[0]} />
          {
            !menuCollapse ?
              <>
                <span id="salesSpan">Biling</span>
                <img id="salesImage2" className='DigiPanelImage' src={item4Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="salesSpan">Biling</span>
                </>
                :
                null}
        </div>
      </div>
      <div className="DigiDashDropDown hide" id="aa">
        {/* <Link to='/ManageSaltGroups'>Substitute Group</Link>   */}
        <div className='DigiDashDropDownItem' onClick={() => salesModalShow()}>Sales Bill</div>
        <div className='DigiDashDropDownItem' onClick={() => navigate('/SalesChallan')}>Sales Challan</div>
        <div className='DigiDashDropDownItem' onClick={() => salesBookModalOpen()}>Sales Book</div>
        <div className='DigiDashDropDownItem' onClick={() => navigate('/ProductWisePnLSummary')} style={{ border: 'none' }}>Product Wise P&L</div>
        
      </div>

      <div className='DigiPanelItem row' id="stockDropDown" onClick={() => ToggleDropDown("stockDropDown")} onMouseOver={mouseOverEffect9} onMouseLeave={mouseLeaveEffect9}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="stockImage" className="dpSidePanelimage" src={item9Images[0]} alt="" />
          {
            !menuCollapse ?
              <>
                <span id="stockSpan">Stock</span>
                <img id="stockImage2" className='DigiPanelImage' src={item9Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="stockSpan">Stock</span>
                </>
                :
                null}
        </div>
      </div>
      <div className="DigiDashDropDown hide">
        {/* <Link to='/ManageSaltGroups'>Substitute Group</Link>   */}
        {/* SalesStockReport */}
        {/* <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Stock Status</div> */}
        <div className='DigiDashDropDownItem' onClick={() => navigate('/DPSalesStockState')}>Sales & Stock Statement </div>
        <div className='DigiDashDropDownItem' onClick={() => navigate('/NearExpiryExpiredProducts')}>Near Expiry & Expired Products </div>
        <div className='DigiDashDropDownItem' onClick={() => navigate('/DigitalPartnerNonMovableProducts')} style={{ border: 'none' }}>Non-Movable Stock</div>
      </div>


      <div className='DigiPanelItem row' id="tranDropDown" onClick={() => ToggleDropDown("tranDropDown")} onMouseOver={mouseOverEffect8} onMouseLeave={mouseLeaveEffect8}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="tranImage" className="dpSidePanelimage" src={item8Images[0]} alt="" />
          {
            !menuCollapse ?
              <>
                <span id="tranSpan">Transactions</span>
                <img id="tranImage2" className='DigiPanelImage' src={item8Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="tranSpan">Transactions</span>
                </>
                :
                null}
        </div>
      </div>
      <div className="DigiDashDropDown hide">
        {/* <Link to='/ManageSaltGroups'>Substitute Group</Link>   */}
        <div className='DigiDashDropDownItem' onClick={() => navigate('/VoucherEntry')}>Voucher Entry</div>
        <div className='DigiDashDropDownItem' onClick={() => navigate('/CreateLedger')}>Create Ledger</div>
        <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Receipt</div>
        <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Payments </div>
        <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Cash & Bank Book </div>
        <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Ledger Account</div>
      </div>

      <div className='DigiPanelItem row' id="reportsDropDown" onClick={() => ToggleDropDown("reportsDropDown")} onMouseOver={mouseOverEffect5} onMouseLeave={mouseLeaveEffect5}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img id="reportImage" className="dpSidePanelimage" src={item5Images[0]} alt="" />
          {
            !menuCollapse ?
              <>
                <span id="reportSpan">Reports</span>
                <img id="reportImage2" className='DigiPanelImage' src={item5Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span id="reportSpan">Reports</span>
                </>
                :
                null}
        </div>
      </div>
      <div className="DigiDashDropDown hide">
        {/* <Link to='/ManageSaltGroups'>Substitute Group</Link>   */}
        <div className='DigiDashDropDownItem' onClick={() => navigate("/partyWiseSalesReport")}>Partywise Reports</div>
        {/* <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Productwise Reports </div> */}
        {/* <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Weekly Sales Products </div> */}
        <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()}>Month wise Sales Products </div>
        <div className='DigiDashDropDownItem' onClick={() => navigate("/ProfitAndLoss")}>Profit & Loss {'('}P&L{')'} Report </div>
        <div className='DigiDashDropDownItem' onClick={() => navigate('/outStandingReports')} style={{ border: 'none' }}>Outstanding</div>
        {/* <div className='DigiDashDropDownItem' onClick={() => navigate('/SalesStockReport')}>Sales Analysis</div> */}
        {/* <div className='DigiDashDropDownItem' onClick={() => notFoundModalShow()} style={{ border: 'none' }}>Daily Expenses Reports</div> */}
      </div>

      <div className='DigiPanelItem row' onMouseOver={mouseOverEffect6} onMouseLeave={mouseLeaveEffect6}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img className="dpSidePanelimage" src={item6Images[0]} alt="" />
          {
            !menuCollapse ?
              <>
                <Link style={{ textDecoration: "none" }} to="/ContactUs"><span>Contact us</span></Link>
                <img className='DigiPanelImage' src={item6Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <Link style={{ textDecoration: "none" }} to="/ContactUs"><span>Contact us</span></Link>
                </>
                :
                null}
        </div>
      </div>

      <div className='DigiPanelItem row' onMouseOver={mouseOverEffect7} onMouseLeave={mouseLeaveEffect7} onClick={Sheet360ERPLogout}>
        <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12'>
          <img className="dpSidePanelimage" src={item7Images[0]} alt="" />
          {
            !menuCollapse ?
              <>
                <span>Logout</span>
                <img className='DigiPanelImage' src={item7Images[1]} alt="" />
              </>
              : window.innerWidth < 513 ?
                <>
                  <span>Logout</span>
                </>
                :
                null}
        </div>
      </div>

      {/* Purchase Modal */}
      <Modal className='purchModal' show={purchaseModal} onHide={PurchaseModalClose} size="xl" backdrop="static" keyboard={false} >
        {/* <Modal.Header closeButton className='purchModalheader'>
        <Modal.Title>Purchase Entry</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {loader ? <Loader /> : null}
          <div className='purchModalHeader'>
            <h2>Purchase {purchBookModal === true ? "Edit" : "Entry Form"}</h2>
            <div style={{ display: 'flex' }}>
              <span style={{ fontSize: '14px', marginTop: '.3vw',color:"gray" }}>Shortcut keys :  F2 = New |  End = Save  |  Esc = Back &nbsp;&nbsp;&nbsp;&nbsp;</span>
              <div className='purchModalCloseBTN' onClick={PurchaseModalClose}>
                <img style={{ width: '2vw', height: '2vw' }} src={require('../../../static/images/Icons/close1.png')} alt="" />
              </div>
            </div>
          </div>

          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
          {/* <Form onSubmit={handleSubmit} > */}
          <div style={{ margin: '0px', minWidth: '95%', marginRight: '.5vw', padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
            <div className='StockSearch' style={{ minWidth: '40%', marginLeft: '.5vw', borderRadius: '0px', justifyContent: 'flex-start' }} id="serCusNameElement">
              <img style={{ minWidth: '1vw', height: '1vw',marginRight:5 }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
              <input autoComplete="off" style={{ minWidth: '92%', fontSize: '13px', fontStyle: 'italic', paddingLeft: '2px', margin: '0px', lineHeight: '.5px'}} onFocus={populateStockist}
                onKeyDown={(e) => {
                  if (e.keyCode === 113) {
                    stockistModalShow(0);
                  }
                  if (e.key === "Enter" || e.key === "ArrowDown") {
                    const searchPopup = document.getElementById("stockistList");
                    if (searchPopup.firstChild) {
                      searchPopup.firstChild.focus();
                    }
                  }
                }}
                type='text' name="serStockName" id="serStockName" className="serStockName" onChange={() => {
                  filterStockist();
                }} placeholder='Search Stockist/CFA by Name' tabIndex={0} />
              <span style={{ minWidth: '8%', margin: '0px', fontSize: '1.2vw' }}>| + </span>
            </div>
            {/* <div className="addProddropDown" id="selectCat">
          <Select options={stockist} isSearchable={true} id="stockID" onChange={handleSelect} placeholder="Select Stockist" styles={selectStyle} />
          </div> */}
            <span id="payMode" style={{ minWidth: '10%', marginLeft: '.5vw' }}>
              <Select styles={selectStyle1} ref={payModeRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById("invoiceNo").focus();
                  }
                }} options={payOptions} isSearchable={true}
                defaultValue={invHeads ? invHeads[4] === "Credit" ? { value: 'Credit', label: 'Credit' } : invHeads[4] === "UPI" ? { value: 'UPI', label: 'UPI' } : { value: 'Cash', label: 'Cash' } : { value: 'Cash', label: 'Cash' }}
                onChange={handlePayMode} placeholder="Pay Mode"/>
            </span>
                
            <input style={{ width: '20%', paddingLeft: '2px', }} className="purchaseInputs" type="text" onKeyPress={() => { removeErrorMessage("invoiceNo") }} name="invoiceNo" id="invoiceNo" placeholder='InvoiceNo' tabIndex={1}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  document.getElementById('invoiceDate').focus();
                }
              }} defaultValue={invHeads ? invHeads[1] : ReactSession.get("invNoDatePur") ? JSON.parse(ReactSession.get("invNoDatePur"))[0] : null}
            />

            <input style={{ width: '20%', paddingLeft: '2px',textTransform:"uppercase" }} className="purchaseInputs" type="date" onKeyPress={() => { removeErrorMessage("invoiceDate") }} name="invoiceDate" id="invoiceDate" placeholder='Invoice Date' tabIndex={2}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  document.getElementById("prodName").focus();
                }
              }} defaultValue={invHeads ? new Date(invHeads[5]).toLocaleDateString('en-CA') : ReactSession.get("invNoDatePur") ? JSON.parse(ReactSession.get("invNoDatePur"))[1] :''} />
          </div>

          <div style={{ backgroundColor: 'rgb(252, 252, 252)', zIndex: '1', width: '48.5vw', height: '300px', overflowX: 'scroll', border: '1px solid gray', position: 'absolute',left:"-9.7vw" }} className="stockistList customScroll hide" id="stockistList">
            {
              stockistFillData ?
                stockistFillData.map((stockist, index) =>
                  <div key={index} style={{ margin: '0px', paddingLeft: '.5vw' }} className='searchResModal' tabIndex="3"
                    onClick={() => {
                      setSelStockist(stockist);
                      ReactSession.set("stockistPur", JSON.stringify(stockist));
                      document.getElementById("stockistList").classList.replace("show", "hide");
                    }}

                    onKeyDown={(e) => {
                      if (e.keyCode === 113) {
                        stockistModalShow(0);
                      }

                      if (e.key === "Enter") {
                        setSelStockist(stockist);
                        ReactSession.set("stockistPur", JSON.stringify(stockist));
                        document.getElementById("stockistList").classList.replace("show", "hide");
                        payModeRef.current.focus();
                      }
                      else
                        if (e.key === "ArrowUp") {
                          const focusedInput = document.activeElement;
                          if (focusedInput.previousElementSibling) {
                            focusedInput.previousElementSibling.focus();
                          }
                        }
                        else
                          if (e.key === "ArrowDown") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.nextElementSibling) {
                              focusedInput.nextElementSibling.focus();
                            }
                          }
                    }}
                  >
                    {"("} {stockist[0]} {")"} - {stockist[1]}
                  </div>
                )
                : null
            }
          </div>

          <div style={{ overflowX: 'hidden', height: '65vh', overflowY: 'scroll', marginBottom: '4vw', marginTop: 5 }} className='customScroll'>
            <Row className='salesBillCenterFlexInputs' style={{ marginRight: '.5vw', marginBottom: '.5vw',marginTop:".5vw",display:selStockist ? "flex" : "none"}} >
              {selStockist ?
                <>
                  <Col lg={4} md={4} xl={4} sm={6} xs={12}>
                    <span style={{fontSize: '1.5vw',marginLeft:6,textWrap:"nowrap" }}>{selStockist[1]} - <span style={{color:"gray"}}>{"("} {selStockist[0]} {")"}</span></span>
                    <br />
                    <div style={{ fontSize: '13px', lineHeight: '1vw', color: 'gray',marginLeft:6,marginTop:5 }}>
                      {selStockist[2]}
                      {' '}{selStockist[3]}
                      <br />
                      {' '}{selStockist[4]}
                      {' '}{selStockist[5]}
                      {selStockist[6]}
                      {selStockist[7]}
                    </div>
                  </Col>
                  <Col lg={4} md={4} xl={4} sm={6} xs={12}>
                    <br />
                    <div style={{ fontSize: '13px', lineHeight: '1vw', color: 'gray',marginTop:"1vw" }}>
                      GSTIN Number {selStockist[12]} <br />
                      Drug License Number {selStockist[11] ? selStockist[11].split('___')[0] + "  |  " + selStockist[11].split('___')[1] : selStockist[11]}
                    </div>
                  </Col>
                  <Col lg={4} md={4} xl={4} sm={6} xs={12}>
                    <br />
                    <div style={{ fontSize: '13px', lineHeight: '1vw', color: 'gray', textAlign: 'right',marginTop:-10 }}>
                     <span>Contact Details</span> <br />
                      {selStockist[8]}, {selStockist[9]}
                      <br />E-Mail :{selStockist[10]}
                    </div>
                  </Col>
                </>
                : <br />
              }
            </Row>

            <Row className='salesBillCenterFlexInputs' style={{ paddingTop: '.5vw', paddingBottom: '.5vw' }}>
              <Col lg={12} md={12} xl={12} sm={12} xs={12}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', marginLeft: '.5vw', }}>
                    <div className='StockSearch' id="ProdNameDiv" style={{ width: '40vw', backgroundColor: 'white',marginLeft:-5, borderRadius: '0px', justifyContent: 'flex-start' }}>
                      <img style={{ width: '1.5vw', height: '1.5vw' }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
                      <input autoComplete="off" style={{ width: '36vw', fontSize: '13px', fontStyle: 'italic', paddingLeft: '2px', margin: '0px', lineHeight: '.5px' }} type="text" className="purchaseInputs" name="prodName" id="prodName" placeholder='Search medicenes/health care products by name'
                        // defaultValue={selectedProduct !== null ? selectedProduct[1] : ""}
                        onChange={() => {
                          populateSearch(0, "prodName", "searchProListPurModal");
                          removeErrorMessage('ProdNameDiv');
                        }} tabIndex={4}
                        onKeyDown={(e) => {
                          if (e.keyCode === 113) {
                            AddProdModalShow();
                          }

                          if (e.keyCode === 35) {
                            handleSubmit();
                          }

                          if (e.key === "Enter" || e.key === "ArrowDown") {
                            const searchPopup = document.getElementById("searchProListPurModal");
                            if (searchPopup.firstChild) {
                              searchPopup.firstChild.focus();
                            }
                          }
                        }}
                      />
                      <span style={{ width: '2vw', margin: '0px', fontSize: '1.2vw' }}>| + </span>
                    </div>

                    <div className="cleanSearch hide" id="cleanSearch" onClick={()=>clearSearch("searchProListPurModal")}>
                      <img style={{ width: '2vw' }} src={require("../../../static/images/Icons/close.png")} alt="" />
                    </div>
                  </div>

                  <div className="searchProListPurModal customScroll hide" id="searchProListPurModal" style={{ marginLeft: '.2vw',width:"68vw" }}>
                  {
                    productsData ?
                    productsData.map((product) =>
                    <>{
                      String(product[7]) === "0" ?
                        <div className='searchPurchResModal topPartition' tabIndex="5"
                          onClick={() => {
                            setSelectedProduct(product);
                            document.getElementById('prodName').value = product[1];
                            document.getElementById('prodUnit').value = product[2];
                            document.getElementById('prodComp').value = product[8];
                            document.getElementById('prodId').value = product[0];
                            document.getElementById('quantity').focus();
                            clearSearch("searchProListPurModal");
                          }}

                          onKeyDown={(e) => {
                            if (e.keyCode === 113) {
                              AddProdModalShow();
                            }

                            if (e.key === "Enter") {
                              setSelectedProduct(product);
                              document.getElementById('prodName').value = product[1];
                              document.getElementById('prodUnit').value = product[2];
                              document.getElementById('prodComp').value = product[8];
                              document.getElementById('prodId').value = product[0];
                              document.getElementById('quantity').focus();
                              clearSearch("searchProListPurModal");
                            }
                            else
                              if (e.key === "ArrowUp") {
                                const focusedInput = document.activeElement;
                                if (focusedInput.previousElementSibling) {
                                  focusedInput.previousElementSibling.focus();
                                }
                              }
                              else
                                if (e.key === "ArrowDown") {
                                  const focusedInput = document.activeElement;
                                  if (focusedInput.nextElementSibling) {
                                    focusedInput.nextElementSibling.focus();
                                  }
                                }
                            }}
                            >
                              <div className='row'>
                                <div className='col-5' style={{ fontSize: '14px' }} >{"("} {product[0]} {")"} -  {product[1]}</div>
                                <div className='col-5' style={{ fontSize: '14px' }} >{product[2]}</div>
                                <div className='col-2' style={{ fontSize: '14px' }} >MRP : {product[4]}</div>
                              </div>
                        </div>
                        : null
                    }</>
                    )
                    : null
                  }
                  </div>
                  <input style={{ width: '20vw' }} className="purchaseInputs" type="text" name="prodUnit" id="prodUnit"
                    onKeyPress={() => { removeErrorMessage("prodUnit") }} placeholder='Unit' tabIndex="6" />

                  <input style={{ width: '20vw' }} className="purchaseInputs" type="text" name="prodComp" id="prodComp"
                    onKeyPress={() => { removeErrorMessage("prodComp") }} placeholder='Company Name' tabIndex="7" />
                </div>

                <div className='flexInputs' style={{ width: '90%', marginTop: '.5vw', display: 'flex', justifyContent: 'flex-start' }}>
                  <input hidden type="text" name="prodId" id="prodId" />
                  <input className="purchaseInputs2"
                    onKeyDown={(e) => {
                      calculateAmount(0); removeErrorMessage("quantity");
                      if (e.key === "Enter") {
                        document.getElementById("freeQuantity").focus();
                      }
                    }}
                    style={{ textAlign: 'center', width: '4vw' }} type="text" name="quantity" id="quantity" placeholder='Quantity' tabIndex="8" />

                  
                  <input className="purchaseInputs2"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        document.getElementById("SGST").focus();
                      }
                    }} style={{ width: '3vw', textAlign: 'center' }} type="text" name="freeQuantity" id="freeQuantity" placeholder='Free' tabIndex="9" />
                  
                  <input onKeyDown={(e) => {
                    calculateAmount(0); removeErrorMessage("SGST");
                    if (e.key === "Enter") {
                      document.getElementById("CGST").focus();
                    }
                  }} style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" name="SGST" id="SGST" placeholder='SGST' tabIndex="10" />
                  
                  <input
                    onKeyDown={(e) => {
                      calculateAmount(0); removeErrorMessage("SGST");
                      if (e.key === "Enter") {
                        document.getElementById("IGST").focus();
                      }
                    }} style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" name="CGST" id="CGST" placeholder='CGST' tabIndex="11" />
                  
                  <input onKeyDown={(e) => {
                    calculateAmount(0); removeErrorMessage("SGST");
                    if (e.key === "Enter") {
                      expMo.current.focus();
                    }
                  }} style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" name="IGST" id="IGST" placeholder='IGST' tabIndex="12" />

                  <span id="expMonth" className="expMonth">
                    <Select ref={expMo} value={defExMonth}
                      onKeyDown={(e) => {
                        removeErrorMessage('expMonth');
                        if (e.key === "Enter") {
                          expYr.current.focus();
                        }
                      }} options={expMoOptions} isSearchable={true} onChange={handleMonth} placeholder="Expiry Month" styles={selectStyle1} tabIndex="13" />
                  </span>
                  <span id="exp_Year" className="exp_Year">
                    <Select ref={expYr} value={defExpiryYear}
                      onKeyDown={(e) => {
                        removeErrorMessage('exp_Year');
                        if (e.key === "Enter") {
                          document.getElementById("batchNo").focus();
                        }
                      }} options={expYearOptions} isSearchable={true} onChange={handleYear} placeholder="Expiry Year" styles={selectStyle1} tabIndex="14" />
                  </span>

                  <input className="purchaseInputs2" onKeyDown={(e) => {
                    removeErrorMessage("batchNo");
                    if (e.key === "Enter") {
                      document.getElementById("HSNCode").focus();
                    }
                  }} style={{ width: '5vw', textAlign: 'center' }} type="text" name="batchNo" id="batchNo" placeholder='Batch No' tabIndex="15" />

                  <input className="purchaseInputs2" onKeyDown={(e) => {
                    removeErrorMessage("HSNCode");
                    if (e.key === "Enter") {
                      document.getElementById("prodMRP").focus();
                    }
                  }} style={{ width: '5vw', textAlign: 'center' }} type="text" name="HSNCode" id="HSNCode" placeholder='HSN Code' tabIndex="16" />

                  <input className="purchaseInputs2" onKeyDown={(e) => {
                    removeErrorMessage("prodMRP");
                    if (e.key === "Enter") {
                      document.getElementById("prodRate").focus();
                    }
                  }} style={{ textAlign: 'center' }} type="text" name="prodMRP" id="prodMRP" placeholder='MRP' tabIndex="17" />

                  <input className="purchaseInputs2" onKeyDown={(e) => {
                    removeErrorMessage("prodRate");
                    calculateAmount(0);
                    if (e.key === "Enter") {
                      document.getElementById("prodDis").focus();
                    }
                  }} style={{ textAlign: 'center' }} type="text" name="prodRate" id="prodRate" placeholder='Rate' tabIndex="18" />

                  <input className="purchaseInputs2" onKeyDown={(e) => {
                    calculateAmount(0);
                    if (e.key === "Enter") {
                      document.getElementById("prodRack").focus();
                    }
                  }} style={{ textAlign: 'center' }} type="text" name="prodDis" id="prodDis" placeholder='Discount' tabIndex="19" />

                  <input className="purchaseInputs2" onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addItemToInvoice(0);
                    }
                  }} style={{ textAlign: 'center' }} type="text" name="prodRack" id="prodRack" placeholder='Rack No' tabIndex="20" />

                  <input className="purchaseInputs2" style={{ width: '6vw', textAlign: 'center' }} type="text" name="Amount" id="Amount" placeholder='Amount' disabled />

                  <span tabIndex={20} onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addItemToInvoice(0);
                    }
                  }} style={{minWidth:"3.3vw",minHeight:40}} onClick={() => addItemToInvoice(0)} className='addInvItemBtn'>+</span>
                </div>

              </Col>
            </Row>
            <Row style={{ paddingTop: '.5vw' }}>
              <Col style={{ color: 'black', fontWeight: 'bold' }}>
                Invoice Details
              </Col>
            </Row>
            {
              invoiceItems && invoiceItems.length > 0 ?
                invoiceItems.map((invItem) => {
                  return (
                    <div style={{ borderBottom: '5px solid lightgray', paddingTop: '.2vw', paddingBottom: '.5vw' }}>
                      <div>
                        <li key={invItem[0]} style={{ listStyle: 'none' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <input style={{ width: '37vw', fontSize: '1.2vw', margin: '0px', lineHeight: '.5px',padding:7 }} type="text"
                              defaultValue={invItem[1]} disabled className="purchaseInputs" />
                            <input style={{ width: '20vw' }} className="purchaseInputs" type="text" defaultValue={invItem[2]} disabled />
                            <input style={{ width: '20vw' }} className="purchaseInputs" type="text" defaultValue={invItem[3]} disabled />
                          </div>

                          <div style={{ marginTop: '.5vw', display: 'flex', justifyContent: 'space-between' }}>
                            <input style={{ width: '4vw', textAlign: 'center' }} className="purchaseInputs2" type="text" defaultValue={invItem[4]} disabled />
                            <input style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" defaultValue={invItem[5]} disabled />
                            <input style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" defaultValue={invItem[6]} disabled />
                            <input style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" defaultValue={invItem[7]} disabled />
                            <input style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" defaultValue={invItem[8]} disabled />

                            <input style={{ width: '5vw' }} className="purchaseInputs2" type="text" defaultValue={invItem[9] + "/" + invItem[10]} disabled />
                            {/* <input style={{ width: '3vw' }} className="purchaseInputs2" type="text" defaultValue={"Year"} disabled /> */}

                            <input className="purchaseInputs2" style={{ width: '6vw', textAlign: 'center' }} type="text" defaultValue={invItem[11]} disabled />
                            <input className="purchaseInputs2" style={{ width: '6vw', textAlign: 'center' }} type="text" defaultValue={invItem[12]} disabled />
                            <input className="purchaseInputs2" style={{ textAlign: 'center' }} type="text" defaultValue={invItem[13]} disabled />
                            <input className="purchaseInputs2" style={{ textAlign: 'center' }} type="text" defaultValue={invItem[14]} disabled />
                            <input className="purchaseInputs2" style={{ textAlign: 'center' }} type="text" defaultValue={invItem[15]} disabled />
                            <input className="purchaseInputs2" style={{ textAlign: 'center' }} type="text" defaultValue={invItem[17]} disabled />
                            <input className="purchaseInputs2" style={{ width: '6vw', textAlign: 'center' }} type="text" defaultValue={invItem[16]} disabled />
                            <img onClick={() => editInvoiceItem(invItem, 0)} style={{ cursor: 'pointer', width: '1.8vw', height: '1.8vw', marginTop: '.3vw' }} src={require('../../../static/images/Icons/edit.png')} alt='' />
                            <span onClick={() => removeItemToInvoice(invItem[0], 0)} className='removeInvItemBtn'>X</span>
                          </div>
                        </li>
                      </div>
                    </div>
                  )
                }
                )
                :
                <Row style={{ borderBottom: '5px solid lightgray', }}>
                  <Col lg={12} md={12} xl={12} sm={12} xs={12}>
                    <table>
                      <div style={{display:"flex",alignItems:"center",width:"74vw",background:"erd"}} >
                        <div style={{width:"50%",padding:7,border:"1px solid #000"}}>
                          <input style={{outline:0,border:0}} className="purchaseInputs" type="text" disabled />
                        </div>
                        <div style={{width:"20%",padding:7,border:"1px solid #000",marginLeft:10}}>
                          <input style={{outline:0,border:0}} className="purchaseInputs" type="text" disabled />
                        </div>
                        <div style={{width:"25%",padding:7,border:"1px solid #000",marginLeft:10}}>
                          <input style={{outline:0,border:0}} className="purchaseInputs" type="text" disabled />
                        </div>
                      </div>
                      </table>

                      <div style={{display:"flex",alignItems:"center",gap:3,marginBottom:10}}>
                        <div>
                          <input style={{ marginTop: '.5vw',padding:7,border:"1px solid #000",marginLeft:0 }} className="purchaseInputs2" type="text" disabled />
                          <input className="purchaseInputs2" type="text" disabled style={{padding:7,border:"1px solid #000",marginLeft:10}}/>
                          <input style={{ width: '3vw',padding:7,border:"1px solid #000",marginLeft:10 }} className="purchaseInputs2" type="text" disabled />
                          <input style={{ width: '3vw',padding:7,border:"1px solid #000",marginLeft:10 }} className="purchaseInputs2" type="text" disabled />
                          <input style={{ width: '3vw',padding:7,border:"1px solid #000",marginLeft:10 }} className="purchaseInputs2" type="text" disabled />
                          {/* <input className="purchaseInputs2" type="text" onKeyPress={() => { removeErrorMessage("CESS") }} name="CESS" id="CESS" placeholder='CESS' /> */}
                        </div>
                        <div>
                          <input style={{ width: '3.5vw', marginTop: '.5vw',padding:7,border:"1px solid #000",marginLeft:10 }} className="purchaseInputs2" type="text" disabled />
                        </div>
                        <div>
                          <input style={{ width: '4vw', marginTop: '.5vw',padding:7,border:"1px solid #000",marginLeft:10 }} className="purchaseInputs2" type="text" disabled />
                        </div>
                        <div>
                          <input className="purchaseInputs2" style={{ marginTop: '.5vw', width: '6vw',padding:7,border:"1px solid #000",marginLeft:10 }} type="text" disabled />
                          <input className="purchaseInputs2" style={{ width: '8vw',padding:7,border:"1px solid #000",marginLeft:10 }} type="text" disabled />
                          <input className="purchaseInputs2" type="text" disabled style={{padding:7,border:"1px solid #000",marginLeft:10}}/>
                          <input className="purchaseInputs2" type="text" disabled style={{padding:7,border:"1px solid #000",marginLeft:10}}/>
                          <input className="purchaseInputs2" type="text" disabled style={{padding:7,border:"1px solid #000",marginLeft:10}}/>
                          <input className="purchaseInputs2" style={{ width: '7vw',padding:7,border:"1px solid #000",marginLeft:10 }} type="text" disabled />
                        </div>
                      </div>
                  </Col>
                </Row>
            }

            <div style={{ marginRight: '.5vw', display: 'flex', justifyContent: 'flex-end',marginTop: '.5vw' }} >
              <div style={{ fontSize: '12px', lineHeight: '1.1vw', width: '20vw' }}>
                <Row>
                  <Col sm={8} lg={8} md={8} xl={8}>
                    Total Price
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                    {Number(totals[6]).toFixed(2)}
                  </Col>
                </Row>

                <Row>
                  <Col sm={8} lg={8} md={8} xl={8}>
                    Cash Discount
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                    {Number(totals[6] - totals[7]).toFixed(2)}
                  </Col>
                </Row>
                {/* setTotals([Tqty, Tfrqty, Tsgst, Tcgst, Tigst, Tcdis, Tgamount, Tamount]); */}

                <Row>
                  <Col sm={8} lg={8} md={8} xl={8}>
                    {vendorType === 0 ? 'Taxable Amount' : 'MRP Total'}
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                    {Number(totals[7]).toFixed(2)}
                  </Col>
                </Row>

                <Row>
                  <Col sm={8} lg={8} md={8} xl={8}>
                    SGST
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                    {Number(totals[2]).toFixed(2)}
                  </Col>
                </Row>
                <Row>
                  <Col sm={8} lg={8} md={8} xl={8}>
                    CGST
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                    {Number(totals[3]).toFixed(2)}
                  </Col>
                </Row>
                <Row>
                  <Col sm={8} lg={8} md={8} xl={8}>
                    IGST
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                    {Number(totals[4]).toFixed(2)}
                  </Col>
                </Row>

                <Row>
                  <Col sm={8} lg={8} md={8} xl={8}>
                    GST Totals
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                    {Number(totals[2] + totals[3] + totals[4]).toFixed(2)}
                  </Col>
                </Row>

                <Row>
                  <Col sm={8} lg={8} md={8} xl={8}>
                    Round Off
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                    {((Math.round(totals[6] - totals[5] + totals[2] + totals[3] + totals[4]).toFixed(2)) - (Number(totals[6] - totals[5] + totals[2] + totals[3] + totals[4]).toFixed(2))).toFixed(2)}
                  </Col>
                </Row>

                <Row style={{ marginTop: '.5vw', paddingTop: '.5vw', borderTop: '2px solid black' }}>
                  <Col sm={8} lg={8} md={8} xl={8} style={{ fontWeight: 'bold' }}>
                    Grand Total 
                  </Col>
                  <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {Math.round(totals[6] - totals[5] + totals[2] + totals[3] + totals[4]).toFixed(2)}
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <div style={{
            borderBottom: '3px solid rgb(61, 197, 238)', display: 'flex',
            width: '100%', backgroundColor: 'rgb(12, 12, 12)', position: 'absolute', left: '0px', bottom: '0px', paddingTop: '1vw', paddingBottom: '1vw'
          }}>

            <div style={{ width: '67%', display: 'flex', justifyContent: 'left' }} lg={12} md={12} xl={12} sm={12} xs={12} >
              <button style={{ fontSize: '14px', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '5px' }} type='submit' onClick={PurchaseModalClose} className='btn btn-primary btn-sm' tabIndex="21">BACK</button>
              {purchBookModal === true ?
              <>
                <button style={{ fontSize: '14px', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '5px' }} type='submit' onClick={handlePrint} className='btn btn-primary btn-sm' tabIndex="21">PRINT</button>
                {/* <button style={{ fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={() => alert("Printing")} className='btn btn-primary btn-sm' tabIndex="21">DELETE</button>
                <button style={{ fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={() => alert("Printing")} className='btn btn-primary btn-sm' tabIndex="21">PREVIOUS</button>
                <button style={{ fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={() => alert("Printing")} className='btn btn-primary btn-sm' tabIndex="21">NEXT</button> */}
              </>
              : null
              }
              <button style={{ fontSize: '14px', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '5px' }} type='submit' onClick={handleSubmit} className='btn btn-primary btn-sm' tabIndex="21">Save</button>
            </div>

            <div style={{ width: '33%', textAlign: 'right', color: 'white', fontWeight: 'bold',marginRight:30 }}>
              {vendorType === 0 ? 'Grand ' : null}Total&nbsp;&nbsp;:&nbsp;&nbsp; 
              {Math.round(totals[6] - totals[5] + totals[2] + totals[3] + totals[4]).toFixed(2)}  
              {/* {Number(totals[6] - totals[5] + totals[2] + totals[3] + totals[4]).toFixed(2)}  */}
              &nbsp;
            </div>
          </div>

          {/* Print Purchase Bill div*/}
          <div ref={componentRef} className='printable-document'>
          <style>{billPrintInlineStyle}</style>
          {
            invoiceItems && invoiceItems.length > 0 ?
            invoiceItems.map((invItem, index) => {
            return (
            <>
              {index % 9 === 0 && index > 9 ?
                <div style={{ marginLeft: '30%' }}>to be continued on next page......</div>
                : null
              }

              {
              index % 9 === 0 && salesBillVendor !== null ?
                <div className='page-break' style={{ width: '100%', marginLeft: '3%', marginRight: '3%', display: 'flex', justifyContent: 'flex-start' }}>
                  <div style={{ width: '50%', marginTop: '4%', lineHeight: '95%' }}>
                    <div style={{ fontSize: '130%', fontWeight: 'bold', lineHeight: '100%' }}>{salesBillVendor[2]}</div>
                    <div>{salesBillVendor[3]}</div>
                    <div>{salesBillVendor[6]}</div>
                    <div>{salesBillVendor[4]}, {salesBillVendor[5]}</div>
                    <div>Phone {salesBillVendor[7]}, {salesBillVendor[8]} , Email: {salesBillVendor[9]}</div>
                  </div>

                  <div style={{ width: '50%', marginTop: '4%', lineHeight: '95%' }}>
                  {
                    (billingCat === "retCus" || billingCat === "dealCust")  && invHeads != null ?
                      <div >
                        <div> Mr./Mrs.{invHeads[8]} </div>
                        <div> {invHeads[9]} </div>
                        <div> {invHeads[10]} </div>
                        <div> {invHeads[15] !== null || invHeads[16] !== null ? 'Contact' : null} { invHeads[15] !== null ? invHeads[15] : null}, {invHeads[16] !== null ? invHeads[16] : null} </div>
                      </div>
                      :
                      selStockist && (billingCat === "retCus" || billingCat === "dealCust") ?
                      <div style={{ fontSize: '90%', lineHeight: '95%', marginLeft: '3%', marginRight: '3%' }}>
                        <span style={{ fontWeight: 'bold' }}>Ms. {selStockist[1]}</span> <br />
                        {selStockist[2]} <br />
                        {' '}{selStockist[3]}
                        {' '}{selStockist[4]}
                        {' '}{selStockist[5]}
                        {selStockist[6]}
                        {selStockist[7]} <br />
                        {selStockist[12]!== null ? "GSTIN Number " +selStockist[12] : null} <br />
                        {selStockist[11] ? "Drug License Number "+selStockist[11].split('___')[0] + "  |  " + selStockist[11].split('___')[1] : null}
                        {selStockist[8]}, {selStockist[9]}
                        {/* <br />E-Mail :{selStockist[10]} */}
                      </div>
                      : null
                  }
                  </div>

                </div>
              : null
              }

              {index % 9 === 0 && salesBillVendor != null ?
                <div style={{ fontSize: '90%', marginTop: '2%', paddingBlock: '.5%', border: '1px solid black', lineHeight: '94%', display: 'flex', width: '95%', marginLeft: '3%', marginRight: '3%' }}>
                  <div style={{ width: '50%' }}>
                    <div>
                      GSTIN: {salesBillVendor[12]}
                    </div>
                    <div>
                      FSSAI No:
                    </div>

                    <div>
                      D.L.No: {salesBillVendor[11]}
                    </div>
                  </div>

                  <div>
                    {invHeads ?
                      <>
                        <div>Invoice No : {invHeads[1]} </div>
                        <div>Date :{invHeads[5]?.substring(0, 10)}</div>
                      </> : null
                    }
                    <div>Bill: {payMode} </div>
                  </div>

                </div>
                : null
              }

              {index % 9 === 0 ?
                <div style={{ fontSize: '90%', display: 'flex', minWidth: '95%', justifyContent: 'flex-start', marginLeft: '3%', marginRight: '3%' }}>
                  <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>S.N</div>
                  <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>PRODUCT NAME</div>
                  <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>PACKING</div>
                  <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>HSN</div>
                  <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>BATCH NO</div>
                  <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>EXP</div>
                  <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>QTY</div>
                  <div style={{ width: '5%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>RATE</div>
                  <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>DISC.</div>
                  <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>SGST</div>
                  <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>CGST</div>
                  <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>IGST</div>
                  <div style={{ width: '10%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>AMOUNT</div>
                </div>
                :
                null
              }

              <div style={{
                fontSize: '90%', display: 'flex', minWidth: '95%', justifyContent: 'flex-start', marginLeft: '3%',
                marginRight: '3%', borderBottom: (index + 1) % 15 === 0 || (index + 1) === invoiceItems.length ? '1px solid black' : ''
              }}>
                <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {index + 1} </div>
                <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[1].length <= 25 ? `${invItem[1]}` : `${invItem[1].substring(0, 22)}...`} </div>
                <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[2].length <= 15 ? `${invItem[2]}` : `${invItem[2].substring(0, 12)}...`} </div>
                <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[12]} </div>
                <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[11]} </div>
                <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[9]}/{(String(invItem[10])).substring(2, 4)}</div>
                <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', textAlign: 'right' }}> {invItem[4]} </div>
                <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', textAlign: 'right' }}> {invItem[14]} </div>
                <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', textAlign: 'right' }}> {invItem[15]} </div>
                <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', textAlign: 'right' }}> {invItem[6]} </div>
                <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', textAlign: 'right' }}> {invItem[7]} </div>
                <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', textAlign: 'right' }}> {invItem[8]} </div>
                <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', textAlign: 'right', borderRight: '1px solid gray' }}> {invItem[16]} </div>
              </div>
              {
              (index + 1) === invoiceItems.length && salesBillVendor !== null ?
                <div style={{ width: '95%', marginRight: '3%', marginLeft: '3%' , position:'fixed', bottom:'0px', borderTop: '1px solid black',}}>
                  <div style={{ marginRight: '.5vw', display: 'flex', justifyContent: 'flex-start', marginTop: '.3vw' }} >
                    <div style={{ width: '45%', fontSize: '80%', lineHeight: '90%' }}>
                      <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Terms & Conditions</div>
                      <div>1. Transactions, subject to {salesBillVendor[4]} Jurisdiction.</div>
                      <div>2. Goods once sold will not be taken back.</div>
                      <div>3. We will not reponsible for any demage of loss in transit.</div>
                      <div>4. Interest: @2% per month will be charged over due date.</div>
                      <div>NOTE:</div>
                    </div>

                    <div style={{ paddingLeft: '1%', width: '20%', textAlign: 'right', paddingRight: '.5%', fontSize: '80%', lineHeight: '80%' }}>
                      <div>for {String(salesBillVendor[2]).toUpperCase()}</div>
                      <br /><br /><br /><br />
                      <div>Authorised Signatory</div>
                    </div>

                    <div style={{ fontSize: '90%', lineHeight: '90%', width: '18%', marginRight: '.5%' }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          SGST
                        </div>
                        <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          {Number(totals[2]).toFixed(2)}
                        </div>
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          CGST
                        </div>
                        <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          {Number(totals[3]).toFixed(2)}
                        </div>
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          IGST
                        </div>
                        <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          {Number(totals[4]).toFixed(2)}
                        </div>
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          GST Total
                        </div>
                        <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          {Number(totals[2] + totals[3] + totals[4]).toFixed(2)}
                        </div>
                      </div>
                    </div>

                    <div style={{ fontSize: '1vw', lineHeight: '1vw', width: '20%' }}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          {vendorType === 0 ? 'Sub Total' : 'MRP Total'}
                        </div>
                        <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          {Number(totals[6]).toFixed(2)}
                        </div>
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          Cash Discount
                        </div>
                        <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          {Number(totals[5]).toFixed(2)}
                        </div>
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          Debit / Credit
                        </div>
                        <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          { }
                        </div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          Others
                        </div>
                        <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          { }
                        </div>
                      </div>

                      <div style={{ display: 'flex' }}>
                        <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                          Grand Total 
                        </div>
                        <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                          {Number(totals[6] - totals[5]).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ fontSize: '1vw' }}>
                    <div style={{ fontSize: '.9vw', color: 'gray' }}>Amount in words Rupees</div>
                    <div>{inWords(Math.round(parseInt(totals[6] - totals[5])))}</div>
                  </div>
                </div>
              : null
              }
            </>
            )})
            :
            null
          }
          </div>
        </Modal.Body>
      </Modal>


      {/* Add new Vendor Modal */}
      <Modal show={stockistModal} onHide={stockistModalClose} size="md" backdrop="static">
        <Modal.Header closeButton className='purchModalheader'>
          <Modal.Title>{vendorType === 0 ? 'Add Stockist/CFA' : 'Add Customer'} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader ? <Loader /> : null}
          <div className="errorMessageHide"  id="errorMessage2">Red marked fields are mandatory</div>
          {/* <form name="addStockist" action="" method="post"> */}
          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/user.png')} alt="" />
                <input className="digiPartLoginInput" name="stockName" id="stockName" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("gstin").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("stockName") }} placeholder="Full Name...." autoFocus />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/gstin.png')} alt="" />
                <input className="digiPartLoginInput" name="gstin" id="gstin" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (vendorType === 0)
                        document.getElementById("dlNo").focus();
                      else
                        document.getElementById("eMail").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("gstin") }} placeholder="GSTIN...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/druglicense.png')} alt="" />
                <input className="digiPartLoginInput" name="dlNo" id="dlNo" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("dlNo2").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("dlNo") }} placeholder="Drug License No...." /> {'|'}
                <input className="digiPartLoginInput" name="dlNo2" id="dlNo2" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("eMail").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("dlNo2") }} placeholder="Drug License No...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/email.png')} alt="" />
                <input className="digiPartLoginInput" name="eMail" id="eMail" type="email"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("mobile").focus();
                    }
                  }}
                  placeholder="E-mail...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../../static/DPImages/Icons/smartphone.png')} alt="" />
                <input style={{ float: "left" }} className="digiPartLoginInput mobileN" name="mobile" id="mobile" type="text" size="4" maxLength="10"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("mobile2").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("mobile") }} placeholder="Primary Contact Number...." /> {'|'}
                <input style={{ float: "left" }} className="digiPartLoginInput mobileN" name="mobile2" id="mobile2" type="text" size="4"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("address1").focus();
                    }
                  }}
                  maxLength="10" placeholder="Secondary Contact Number...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="address1" id="address1" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("address2").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("address1") }} placeholder="Address Line 1...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="address2" id="address2" type="text" placeholder="Address Line 2...."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("city").focus();
                    }
                  }} />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="city" id="city" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("state").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("city") }} placeholder="City...." />
                <input className="digiPartLoginInput" name="state" id="state" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("pincode").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("state") }} placeholder="State...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input style={{ float: "left" }} className="digiPartLoginInput mobileN" name="pincode" id="pincode" type="text" size="4" maxLength="6"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatRegForm();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("pincode") }} placeholder="Postal Code...." />
              </div>
            </div>
          </div>

          {/* </form> */}
          <p></p>
          <div className="loginLogo" style={{ marginTop: '1.5vw' }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiSiginBtn" id="digiSiginBtn"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatRegForm();
                    }
                  }}
                  onClick={validatRegForm}>Create</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Add new product Modal */}
      <Modal style={{background:"rgb(0, 0, 0, .3)"}} show={addProdModal} onHide={AddProdModalClose} size="md" backdrop="static" >
        <Modal.Header closeButton className='purchModalheader'>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{boxShadow:" 0 25px 50px -12px rgb(0 0 0 / 0.25)"}}>
          {loader ? <Loader /> : null}

          {/* <form name="addProd" action="" method="post"> */}
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input style={{fontSize:"14px"}} className="digiPartLoginInput" name="newProdName" id="newProdName" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("newProdUnit").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("newProdName") }} autoComplete="off" placeholder="Product Name...." autoFocus tabIndex={0}/>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="newProdUnit" id="newProdUnit" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("newProdComp").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("newProdUnit") }} placeholder="Unit...." tabIndex={1}/>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{width:'100%', display: 'flex' }}>
                <input style={{fontSize:"13px"}} className="digiPartLoginInput" name="newProdComp" id="newProdComp" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("newProdMRP").focus();
                    }
                  }}
                  onKeyPress={() => { removeErrorMessage("newProdComp") }} placeholder="Company Name...." tabIndex={2}/>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.2vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="digiLogin" style={{ width:'100%', display: 'flex' }}>
                <input className="digiPartLoginInput" name="newProdMRP" id="newProdMRP" type="text" placeholder="MRP...."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatRegProdForm();
                    }
                  }} onKeyPress={() => { removeErrorMessage("newProdMRP") }} tabIndex={3}/>
              </div>
            </div>
          </div>

          {/* </form> */}
          <p></p>
          <div className="loginLogo" style={{ marginTop: '1.5vw' }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-sm-12" >
                <div className="digiSiginBtn" id="addNewProdBTN"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatRegProdForm();
                    }
                  }}
                  onClick={validatRegProdForm}>Add Product</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* Create Sales Bill Modal */}
      {/* salesModal */}
      <Modal className='salesBillModal' show={salesModal} onHide={salesModalClose} size="xl" backdrop="static" >
        <Modal.Body className='salesBillModel'>
          {loader ? <Loader /> : null}
          <div className='purchModalHeader'>
            <h4>Sales Bill {salesBookModal === true ? "Edit" : ''}</h4>
            <div style={{ display: 'flex' }}>
              <span style={{ fontSize: '13px', marginTop: '.3vw',color:"gray" }}>Shortcut keys:  F2 = New  |  End = Save  |  Esc = Back &nbsp;&nbsp;&nbsp;&nbsp;</span>
              <div className='purchModalCloseBTN' onClick={salesModalClose}>
                <img style={{ width: '100%' }} src={require('../../../static/images/Icons/close1.png')} alt="" />
              </div>
            </div>
          </div>

          <div className="errorMessageHide" id="errorMessage" style={{bottom:'50px', top:'auto', zIndex:'9999999999999'}}>Red marked fields are mandatory</div>
          {/* ref={pendingBillsRef} */}
          {/* onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}  */}
          {/* left:`${position.x}px`, top: `${position.y}px`, */}
          <motion.div 
            // dragConstraints={pendingBillsRef} 
            drag style={{
             maxHeight: isPendingBillModelShow ? "40vh" : "40px", overflow: isPendingBillModelShow ? "auto" : "hidden" , width:isPendingBillModelShow ? "" : "35px",position: "absolute",}} 
             className={pendingBills && pendingBills?.length > 0 ? "pendingBills draggable customScroll" : "errorMessageHide draggable customScroll"} id="pendingBills">
            <div>
              <div className='pendingBillHeader' style={{ display: isPendingBillModelShow ? "" : "flex",alignItems: isPendingBillModelShow ? "" : "center",justifyContent: isPendingBillModelShow ? "" : "center",flexDirection: isPendingBillModelShow ? "" : "column"}}>
                <b style={{display:isPendingBillModelShow ? "block" : "none"}}>PENDING BILLS</b>
                <div className='pendingBillsIcons'>
                  <img src={require("../../../static/DPImages/notification-bell.gif")} onClick={togglePendingBillModel} alt="" />
                  {/* {
                    isPendingBillModelShow ?
                      <i onClick={togglePendingBillModel}><IoIosArrowUp /></i>
                      :
                      <i onClick={togglePendingBillModel}><IoIosArrowDown /></i>
                  } */}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', marginTop: 40 }}>
                <div>Order ID</div>
                <div>Date/Time</div>
                <div>Order From/ID</div>
                <div>Quantity</div>
                <div>Contact</div>
              </div>
            </div>
            {
              pendingBills && pendingBills.length > 0 ?
                pendingBills.map((item, idx) => {
                  return (
                    <div className='dpPendOrders'>
                      <div className='dpPendOrderHead' id='dpPendOrderHead'
                        onClick={()=>{getPendingOrderItems(item[2])}}
                        >
                        <div><b>{item[2]}</b></div>
                        <div>{item[5].split('T')[0]}/{item[5].split('T')[1]}</div>
                        <div> {"("}{item[1]}{")"} {item[6]?.length > 15 ? `${item[6].substring(0, 12)}...` : `${item[6]}`}</div>
                        <div>{item[3]}</div>
                        <div>{item[7]}</div>
                      </div>
                    </div>
                  )
                })
                :
                null
            }
          </motion.div>

          <div className='dpPendOrderDetails hide customScroll' id='dpPendOrderDetails'>
            <div className='pendingBillHeader'> <b>Order ID : {pendingBillDetails !== null ? pendingBillDetails[0][2] : ''}</b> 
              <span className='pendingBillsIcons' onClick={() => {
                  document.getElementById('dpPendOrderDetails').classList.add("hide");
                }} style={{ color: "#fff", fontSize: "20px" }}>
                <IoMdClose />
              </span>
            </div>
            <div style={{marginTop:'40px'}}>
              {
                pendingBillDetails && pendingBillDetails?.length > 0 ?
                  pendingBillDetails.map((product, index) => {
                    return (
                      <div className='dpPendOrdersDet' key={index}>
                        <div>{product[3]} - </div>
                        <div>{product[7].length <= 20 ? `${product[7]}` : `${product[7].substring(0, 18)}...`} - </div>
                        <div>{product[9].length <= 15 ? `${product[9]}` : `${product[9].substring(0, 12)}...`} - </div>
                        <div>{product[8].length <= 20 ? `${product[8]}` : `${product[8].substring(0, 18)}...`} - </div>
                        <div>{product[4]}</div>
                      </div>
                    )
                  })
                :
                null
              }
            </div>
          </div>

          <div className='flex salesBillTopInputs' >
            <span id="billMode" style={{ minWidth: '15%' }}>
              <Select autoFocus
                className='selectInput'
                onKeyDown={(e) => {
                  setSelStockist(null);
                  // setSelStockist(null);
                  if (e.key === "Enter") {
                    document.getElementById('serStockName').focus();
                  }
                }} options={parseInt(ReactSession.get('dpType')) === 1 ? billingOptions : parseInt(ReactSession.get('dpType')) === 2 ? billingOptions2 
                : parseInt(ReactSession.get('dpType')) === 3 ? billingOptions3 : null} defaultValue={parseInt(ReactSession.get('dpType')) > 1 ? billingOptions2[0] 
                : billingOptions[0]}
                isSearchable={true} onChange={handleBillingOptions} styles={selectStyle2}
              />
            </span>

            {billingCat !== "retCus" && billingCat !== "dealCust" ?
              <div className='StockSearch' id='StockSearch' style={{ minWidth: '40%', marginLeft: '.5vw', borderRadius: '0px', justifyContent: 'flex-start' }}>
                <img style={{ minWidth: '2%', height: '2%' }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
                <input style={{ minWidth: '92%', fontSize: '15px', fontStyle: 'italic', paddingLeft: '2px', margin: '0px', lineHeight: '.5px' }} onFocus={populateStockist}
                  onKeyDown={(e) => {
                    filterStockist();
                    if (e.keyCode === 113) {
                      stockistModalShow(1);
                    }
                    if (e.key === "Enter") {
                      document.getElementById("stockistList").classList.replace("show", "hide");
                      payModeRef.current.focus();
                    }
                    else
                      if (e.key === "ArrowDown") {
                        const searchPopup = document.getElementById("stockistList");
                        if (searchPopup.firstChild) {
                          searchPopup.firstChild.focus();
                        }
                      }
                  }}
                  defaultValue={invHeads ? invHeads[8] : ''}
                  type='text' name="serStockName" className="serStockName" id="serStockName" placeholder='Stockist/CFA Name' tabIndex={0} />
                <span style={{ minWidth: '8%', margin: '0px', fontSize: '1.2vw' }}>| + </span>
              </div>
              :
              <div className='StockSearch' id='StockSearch' style={{ minWidth: '40%', marginLeft: '.5vw', borderRadius: '0px', justifyContent: 'flex-start' }}>
                <img style={{ minWidth: '1vw', height: '1vw' }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
                <input style={{ minWidth: '92%', fontSize: '14px',marginLeft:5, fontStyle: 'italic', paddingLeft: '2px', margin: '0px', lineHeight: '.5px' }} defaultValue={invHeads ? invHeads[8] : ''}
                  onKeyDown={(e) => {
                    var serOpt = document.getElementById("searchCustList");
                    if (e.key === "Enter") {
                      serOpt.classList.replace('show', 'hide');
                      payModeRef.current.focus();
                    }
                    else if (e.key === "ArrowDown") {
                      serOpt.firstChild?.focus();
                    }
                    else {
                      removeErrorMessage('serStockName');
                      searchCustomer();
                    }
                  }}
                  type='text' autoComplete="off" name="serStockName" id="serStockName" className="serStockName" placeholder='Search Customer By Name / Mobile Number' tabIndex={0} />
              </div>
            }

            <div className="searchProList customScroll hide" id="searchCustList" style={{width:"53vw", marginLeft:"11.3vw"}}>
              {
                customerData ?
                  customerData.map((customer, index) =>
                    <div key={index} className='searchPurchResModal topPartition searchCust' tabIndex={1}
                      onClick={() => {
                        setSelCustID(customer[0]);
                        // ReactSession.set("customerSales", JSON.stringify(customer));
                        document.getElementById('serStockName').value = customer[1] + "-(" + customer[0] + ")";
                        document.getElementById('address1').value = customer[2];
                        document.getElementById('address2').value = customer[3];
                        document.getElementById('contact1').value = customer[4];
                        document.getElementById('contact2').value = customer[5];
                        document.getElementById('landMark').value = customer[6];
                        document.getElementById('pincode').value = customer[7];
                        document.getElementById('city').value = customer[8];
                        document.getElementById('state').value = customer[9];
                        payModeRef.current.focus();
                        document.getElementById("searchCustList").classList.replace("show", "hide");
                      }}

                      onKeyDown={(e) => {
                        // if (e.keyCode === 113) {
                        //   AddProdModalShow();
                        // }
                        if (e.key === "Enter") {
                          setSelCustID(customer[0]);
                          // ReactSession.set("customerSales", JSON.stringify(customer));
                          document.getElementById('serStockName').value = customer[1] + " - (" + customer[0] + ")";
                          document.getElementById('address1').value = customer[2];
                          document.getElementById('address2').value = customer[3];
                          document.getElementById('contact1').value = customer[4];
                          document.getElementById('contact2').value = customer[5];
                          document.getElementById('landMark').value = customer[6];
                          document.getElementById('pincode').value = customer[7];
                          document.getElementById('city').value = customer[8];
                          document.getElementById('state').value = customer[9];
                          payModeRef.current.focus();
                          document.getElementById("searchCustList").classList.replace("show", "hide");
                        }
                        else
                          if (e.key === "ArrowUp") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.previousElementSibling) {
                              focusedInput.previousElementSibling.focus();
                            }
                          }
                          else
                            if (e.key === "ArrowDown") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.nextElementSibling) {
                                focusedInput.nextElementSibling.focus();
                              }
                            }
                      }}
                    >
                      <div className='row'>
                        <div className='col-5' style={{ fontSize: '1vw' }} > {customer[1]}{" - ("}{customer[0]} {")"}</div>
                        <div className='col-5' style={{ fontSize: '1vw' }} >{customer[2]}</div>
                        <div className='col-2' style={{ fontSize: '1vw' }} >{customer[4]}</div>
                      </div>
                    </div>
                  )
                  : null
              }
            </div>

            <span id="payMode" style={{ minWidth: '15%', marginLeft: '.5vw' }}>
              <Select ref={payModeRef}
                className='payModeSelect select'
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    document.getElementById('invoiceDate').focus();
                  }
                }} options={payOptions} isSearchable={true} styles={selectStyle2}
                defaultValue={invHeads ? invHeads[4] === "Credit" ? { value: 'Credit', label: 'Credit' } : invHeads[4] === "UPI" ? { value: 'UPI', label: 'UPI' } : { value: 'Cash', label: 'Cash' } : { value: 'Cash', label: 'Cash' }}
                onChange={handlePayMode} placeholder="Pay Mode " tabIndex={2}/>
            </span>
            <p className='togglePlaceHolder'>Invoice Number</p>
            <input style={{ minWidth: '10%' }} className="purchaseInputs invoiceNo" type="text" defaultValue={ReactSession.get("invNoDateSales") ? JSON.parse(ReactSession.get("invNoDateSales"))[0] : invHeads ? invHeads[1] : invoiceNewNo} name="invoiceNo" id="invoiceNo" placeholder='InvoiceNo' disabled />
            
            <div className="date">
              <input style={{ minWidth: '10%', textTransform: "uppercase" }} className="purchaseInputs" type="date"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (billingCat === "retCus" || billingCat === "dealCust")
                      document.getElementById('address1').focus();
                    else
                      document.getElementById('prodName').focus();
                  }
                }}
                // defaultValue={ invHeads ? new Date(invHeads[5]).toISOString().split('T')[0]  : ''}
                defaultValue={ReactSession.get("invNoDateSales") ? JSON.parse(ReactSession.get("invNoDateSales"))[1] : invHeads ? new Date(invHeads[5]).toLocaleDateString('en-CA') : ''}
                onKeyPress={() => { removeErrorMessage("invoiceDate") }} name="invoiceDate" id="invoiceDate" placeholder='Invoice Date' tabIndex={3}/>
              <img src={require("../../../static/DPImages/calendar.png")} alt="" />
            </div>
          </div>

          { billingCat === "retCus" || billingCat === "dealCust" ?
            <div key={1}>
              <div style={{ margin: '0px', marginTop: "10px", marginBottom: "10px", minWidth: '95%', marginRight: '.5vw', padding: '0px', display: 'flex' }}>
                <div className="digiLogin" style={{ minWidth: '47%', marginLeft: '.5vw' }}>
                  <input className='digiPartLoginInput' style={{ minWidth: '47%', marginLeft: '.5vw', paddingTop: '0px', paddingBottom: '0px',fontSize:"14px" }}
                    onKeyDown={(e) => {
                      removeErrorMessage('address1');
                      if (e.key === "Enter")
                        document.getElementById("address2").focus();
                    }} defaultValue={invHeads ? invHeads[9] : ''}
                    type='text' name="address1" id="address1" placeholder='Address Line 1' tabIndex={4} />
                </div>

                <div className="digiLogin" style={{ minWidth: '47%', marginLeft: '.5vw' }}>
                  <input className='digiPartLoginInput' style={{ minWidth: '47%', marginLeft: '.5vw', paddingTop: '0px', paddingBottom: '0px',fontSize:"14px" }}
                    onKeyDown={(e) => {
                      removeErrorMessage('address2');
                      if (e.key === "Enter")
                        document.getElementById("landMark").focus();
                    }} defaultValue={invHeads ? invHeads[10] : ''}
                    type='text' name="address2" id="address2" placeholder='Address Line 2' tabIndex={5} />
                </div>
              </div>

              <div style={{ margin: '0px', minWidth: '95%', marginRight: '.5vw', marginBottom: "10px", padding: '0px', display: 'flex' }}>
                <div className="digiLogin" style={{ minWidth: '47%', marginLeft: '.5vw' }}>
                  <input className='digiPartLoginInput' style={{ minWidth: '47%', marginLeft: '.5vw', paddingTop: '0px', paddingBottom: '0px',fontSize:"14px" }}
                    type='text' name="landMark" id="landMark" placeholder='Landmark'
                    onKeyDown={(e) => {
                      if (e.key === "Enter")
                        document.getElementById("contact1").focus();
                    }} defaultValue={invHeads ? invHeads[30] : ''}
                    tabIndex={6} />
                </div>

                <div className="digiLogin" style={{ width: '23%', marginLeft: '.5vw' }}>
                  <input className='digiPartLoginInput' size="4" maxLength="10" style={{ marginLeft: '.5vw', paddingTop: '0px', paddingBottom: '0px',fontSize:"14px" }}
                    onKeyDown={(e) => {
                      removeErrorMessage('contact1');
                      if (e.key === "Enter")
                        document.getElementById("contact2").focus();
                    }} defaultValue={invHeads ? invHeads[15] : ''}
                    type='text' name="contact1" id="contact1" placeholder='Primary Contact' tabIndex={7} />
                </div>

                <div className="digiLogin" style={{ width: '23%', marginLeft: '.5vw' }}>
                  <input className='digiPartLoginInput' size="4" maxLength="10" style={{ marginLeft: '.5vw', paddingTop: '0px', paddingBottom: '0px',fontSize:"14px" }}
                    onKeyDown={(e) => {
                      removeErrorMessage('contact2');
                      if (e.key === "Enter")
                        document.getElementById("pincode").focus();
                    }} defaultValue={invHeads ? invHeads[16] : ''}
                    type='text' name="contact2" id="contact2" placeholder='Secondary Contact' tabIndex={8} />
                </div>
              </div>

              <div style={{ margin: '0px', minWidth: '95%', marginBottom: "10px", marginRight: '.5vw', padding: '0px', display: 'flex' }}>
                <div className="digiLogin" style={{ maxWidth: '10%', marginLeft: '.5vw' }}>
                  <input className='digiPartLoginInput' size="4" maxLength="6" style={{ marginLeft: '.5vw', paddingTop: '0px', paddingBottom: '0px',fontSize:"14px" }}
                    type='text' name="pincode" id="pincode" placeholder='Pincode' defaultValue={invHeads ? invHeads[14] : ''}
                    onKeyDown={(e) => {
                      removeErrorMessage('pincode');
                      if (e.key === "Enter") {
                        var locateURL = "http://43.205.199.21/location?pincode=";
                        if (Number(String(e.target.value).length) > 5) {
                          fetch(locateURL + e.target.value, {
                            method: 'GET',
                          }).then((response) => {
                            return response.json()
                          }).then(async (res) => {
                            if (res.status === "validityExpired") {
                              alert("You have no active subscription plan. please subscribe to continue.");
                              navigate("/Subscriptions");
                            }
                            else if (res.status === "success") {
                              var state = (res.state).substring(0, ((res.state).length - 1));
                              var city = (res.city);
                              document.getElementById("city").value = res.city;
                              document.getElementById("state").value = state;
                            }
                          }).catch((err) => {
                            console.log("Error :", err.message);
                          });
                        }
                        document.getElementById("city").focus();
                      }
                    }}
                    // defaultValue={invHeads ? invHeads[16] : ''} 
                    tabIndex={9} />
                </div>

                <div className="digiLogin" style={{ maxWidth: '36.5%', marginLeft: '.5vw' }}>
                  <input className='digiPartLoginInput' style={{ marginLeft: '.5vw', paddingTop: '0px', paddingBottom: '0px',fontSize:"14px" }}
                    type='text' name="city" id="city" placeholder='City' defaultValue={invHeads ? invHeads[11] : ''}
                    onKeyDown={(e) => {
                      removeErrorMessage('city');
                      if (e.key === "Enter")
                        document.getElementById("state").focus();
                    }}
                    // defaultValue={invHeads ? invHeads[16] : ''} 
                    tabIndex={10} />
                </div>

                <div className="digiLogin" style={{ minWidth: '47%', marginLeft: '.5vw' }}>
                  <input className='digiPartLoginInput' size="4" style={{ marginLeft: '.5vw', paddingTop: '0px', paddingBottom: '0px',fontSize:"14px" }}
                    type='text' name="state" id="state" placeholder='State' defaultValue={invHeads ? invHeads[12] : ''}
                    onKeyDown={(e) => {
                      removeErrorMessage('state');
                      if (e.key === "Enter")
                        document.getElementById("prodName").focus();
                    }}
                    // defaultValue={invHeads ? invHeads[16] : ''} 
                    tabIndex={11} />
                </div>
              </div>

            </div>
            : null
          }

          <div className="stockistList hide customScroll" id="stockistList" style={{height:'50vh', overflowY:'scroll'}}>
            {
              stockistFillData ?
                stockistFillData.map((stockist) =>
                  <div style={{ margin: '0px', paddingLeft: '.5vw' }} className='searchResModal' tabIndex="3"
                    onClick={() => {
                      setSelStockist(stockist);
                      ReactSession.set("stockistSales", JSON.stringify(stockist));
                      document.getElementById('serStockName').value = stockist[1];
                      document.getElementById("stockistList").classList.replace("show", "hide");
                      payModeRef.current.focus();
                    }}

                    onKeyDown={(e) => {
                      if (e.keyCode === 113) {
                        stockistModalShow(1);
                      }

                      if (e.key === "Enter") {
                        setSelStockist(stockist);
                        ReactSession.set("stockistSales", JSON.stringify(stockist));
                        document.getElementById('serStockName').value = stockist[1];
                        document.getElementById("stockistList").classList.replace("show", "hide");
                        payModeRef.current.focus();
                      }
                      else
                        if (e.key === "ArrowUp") {
                          const focusedInput = document.activeElement;
                          if (focusedInput.previousElementSibling) {
                            focusedInput.previousElementSibling.focus();
                          }
                        }
                        else
                          if (e.key === "ArrowDown") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.nextElementSibling) {
                              focusedInput.nextElementSibling.focus();
                            }
                          }
                  }}>
                    {"("} {stockist[0]} {")"}{stockist[1]}
                  </div>
                )
                : null
            }
          </div>

          <div style={{ overflowX: 'hidden', height: '58vh', overflowY: 'scroll', marginBottom: '39vw',paddingTop:"10px",paddingBottom:"10px" }} className='customScroll'>
            <Row style={{ marginRight: '.5vw', marginBottom: '.5vw' }} >
              {selStockist && (billingCat !== "retCus" || billingCat !== "dealCust") ?
                <>
                  <Col lg={4} md={4} xl={4} sm={6} xs={12}>
                    <span style={{ fontSize: '1.5vw' }}>{selStockist[1]}</span>
                    <br />
                    <div style={{ fontSize: '13px', lineHeight:"1vw", color: 'gray' }}>
                      {selStockist[2]}
                      {' '}{selStockist[3]}
                      <br />
                      {' '}{selStockist[4]}
                      {' '}{selStockist[5]}
                      {selStockist[6]}
                      {selStockist[7]}
                    </div>
                  </Col>
                  <Col lg={4} md={4} xl={4} sm={6} xs={12}>
                    <br />
                    <div style={{ fontSize: '13px',lineHeight:"1vw", color: 'gray',marginTop:10 }}>
                      GSTIN Number {selStockist[12]} <br />
                      Drug License Number {selStockist[11] ? selStockist[11].split('___')[0] + "  |  " + selStockist[11].split('___')[1] : ''}
                    </div>
                  </Col>
                  <Col lg={4} md={4} xl={4} sm={6} xs={12}>
                    <br />
                    <div style={{ fontSize: '13px',lineHeight:"1vw", color: 'gray', textAlign: 'right',marginTop:10 }}>
                      {selStockist[8]}, {selStockist[9]}
                      <br />E-Mail :{selStockist[10]}
                    </div>
                  </Col>
                </>
                : null
              }
            </Row>

            <Row className='flex salesBillCenterFlexInputs' >
              <Col lg={12} md={12} xl={12} sm={12} xs={12}>
                <div className='manyInputs flex'>
                  <div style={{ fontSize: '1vw', display: 'flex', marginLeft: '.5vw', }}>
                    <div className='StockSearch' id="ProdNameDiv" style={{ backgroundColor: 'white', borderRadius: '0px', justifyContent: 'flex-start' }}>
                      <img style={{ width: '1.5vw', height: '1.5vw' }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
                      <input style={{ width: '36vw', fontStyle: 'italic', padding: '0px', margin: '0px', lineHeight: '.5px' }} type="text" 
                        className="purchaseInputs" name="prodName" autoComplete="off" id="prodName" placeholder='Product Name' 
                        tabIndex={12} value={salesProdName}
                        // defaultValue={selectedProduct !== null ? selectedProduct[1] : ""}
                        onChange={(e) => {
                          setSalesProdName(e.target.value);
                          populateSearch(1, "prodName", "searchProListSalesModal");
                          removeErrorMessage('ProdNameDiv');
                        }} 
                        onKeyDown={(e) => {
                          // F2 Key Event
                          if (e.keyCode === 113) {
                            AddProdModalShow();
                          }

                          // End Key Event
                          if (e.keyCode === 35) {
                            handleInvoiceSubmit();
                          }

                          if (e.key === "Enter" || e.key === "ArrowDown") {
                            const searchPopup = document.getElementById("searchProListSalesModal");
                            if (searchPopup.firstChild) {
                              searchPopup.firstChild.focus();
                            }
                          }
                        }}
                      />
                    </div>

                    <div className="cleanSearch hide" id="cleanSearch" onClick={()=>clearSearch("searchProListSalesModal")}>
                      <img style={{ width: '2vw' }} src={require("../../../static/images/Icons/close.png")} alt="" />
                    </div>
                  </div>

                  <div className="searchProListSalesModal customScroll hide" id="searchProListSalesModal" style={{ marginLeft: '.5vw',marginTop:32, maxHeight:'50vh',width:"58vw" }}>
                    {
                      productsData ?
                        productsData.map((product) =>
                          {
                            if (billingCat === "retCus" || billingCat === "dealCust")
                              var PTR = product[18];
                            else if ((parseFloat(product[13]) + parseFloat(product[14])) >= 18)
                              var PTR = parseFloat(product[18]) - (parseFloat(product[18]) * 32.20 / 100);
                            else if ((parseFloat(product[13]) + parseFloat(product[14])) >= 12)
                              var PTR = parseFloat(product[18]) - (parseFloat(product[18]) * 28.57 / 100);
                            else if ((parseFloat(product[13]) + parseFloat(product[14])) >= 5)
                              var PTR = parseFloat(product[18]) - (parseFloat(product[18]) * 23.20 / 100);
                            else if ((parseFloat(product[13]) + parseFloat(product[14])) >= 0)
                              var PTR = parseFloat(product[18]) - (parseFloat(product[18]) * 13.80 / 100);
                            
                            return (
                            <>
                            {
                              String(product[7]) === "0" ?
                                <div className='searchPurchResModal topPartition' tabIndex={5}
                                  onClick={() => {
                                    // st.batch_no9, pu.hsn_no0, pu.exp_month1, pu.exp_year2, pu.sgst3, pu.cgst4, pu.igst5, pu.cust_discount6, st.stock_qty7, pu.pro_mrp8
                                    // quantity SGST CGST IGST batchNo HSNCode prodMRP prodRate prodDis
                                    setSelectedProduct(product);
                                    setSalesProdName(product[1]);
                                    // document.getElementById('prodName').value = product[1];
                                    document.getElementById('prodUnit').value = product[2];
                                    document.getElementById('prodComp').value = product[8];
                                    document.getElementById('prodId').value = product[0];
                                    document.getElementById('quantity').value = product[17];
                                    document.getElementById('SGST').value = product[13];
                                    document.getElementById('CGST').value = product[14];
                                    document.getElementById('IGST').value = product[15];
                                    document.getElementById('batchNo').value = product[9];
                                    document.getElementById('HSNCode').value = product[10];
                                    
                                    if (billingCat === "retRet" || billingCat === "dealRet" || billingCat === "dealDeal")
                                    {
                                      document.getElementById('dpMRP').value = product[18] != null ? Number(product[18]).toFixed(2) : Number(product[5]).toFixed(2);
                                    }

                                    if (billingCat === "retRet" || billingCat === "dealRet" || billingCat === "dealDeal")
                                      document.getElementById('prodRate').value = product[18] != null ? Number(PTR).toFixed(2) : Number(product[5]).toFixed(2);
                                    else
                                      document.getElementById('prodRate').value = product[4];

                                    document.getElementById('prodDis').value = product[6];

                                    if (product[11] !== 'null' && product[11] !== null) {
                                      const filExpMo = expMoOptions.find(option => option.value === String(product[11]));
                                      // console.log(filExpMo);
                                      setExMonth(filExpMo.value);
                                      setdefExMonth(filExpMo);
                                    }

                                    if (product[12] !== 'null' && product[12] !== null) {
                                      const filExpYr = expYearOptions.find(option => option.value === String(product[12]));
                                      setexpiryYear(filExpYr.value);
                                      setdefExpiryYear(filExpYr);
                                    }

                                    document.getElementById('quantity').focus();
                                    calculateAmount(1);
                                    clearSearch("searchProListSalesModal");
                                  }}

                                  onKeyDown={(e) => {
                                    if (e.keyCode === 113) {
                                      AddProdModalShow();
                                    }
                                    if (e.key === "Enter") {
                                      setSelectedProduct(product);
                                      setSalesProdName(product[1]);
                                      // document.getElementById('prodName').value = product[1];
                                      document.getElementById('prodUnit').value = product[2];
                                      document.getElementById('prodComp').value = product[8];
                                      document.getElementById('prodId').value = product[0];
                                      document.getElementById('quantity').value = product[17];
                                      document.getElementById('SGST').value = product[13];
                                      document.getElementById('CGST').value = product[14];
                                      document.getElementById('IGST').value = product[15];
                                      document.getElementById('batchNo').value = product[9];
                                      document.getElementById('HSNCode').value = product[10];
                                      
                                      // if (billingCat === "retCus" || billingCat === "dealCust")
                                      //   console.log();
                                      // else
                                      //   document.getElementById('dpMRP').value = product[18] != null ? Number(product[18]).toFixed(2) : Number(product[5]).toFixed(2);

                                      // document.getElementById('prodRate').value =  product[18] != null ? Number(PTR).toFixed(2) : Number(product[5]).toFixed(2);
                                      if (billingCat === "retRet" || billingCat === "dealRet" || billingCat === "dealDeal")
                                      {
                                        document.getElementById('dpMRP').value = product[18] != null ? Number(product[18]).toFixed(2) : Number(product[5]).toFixed(2);
                                      }

                                      if (billingCat === "retRet" || billingCat === "dealRet" || billingCat === "dealDeal")
                                        document.getElementById('prodRate').value = product[18] != null ? Number(PTR).toFixed(2) : Number(product[5]).toFixed(2);
                                      else
                                        document.getElementById('prodRate').value = product[4];

                                      document.getElementById('prodDis').value = product[16];

                                      if (product[11] !== 'null' && product[11] !== null) {
                                        const filExpMo = expMoOptions.find(option => option.value === String(product[11]));
                                        // console.log(filExpMo);
                                        setExMonth(filExpMo.value);
                                        setdefExMonth(filExpMo);
                                      }

                                      if (product[12] !== 'null' && product[12] !== null) {
                                        const filExpYr = expYearOptions.find(option => option.value === String(product[12]));
                                        setexpiryYear(filExpYr.value);
                                        setdefExpiryYear(filExpYr);
                                      }

                                      document.getElementById('quantity').focus();
                                      calculateAmount(1);
                                      clearSearch("searchProListSalesModal");
                                    }
                                    else
                                      if (e.key === "ArrowUp") {
                                        const focusedInput = document.activeElement;
                                        if (focusedInput.previousElementSibling) {
                                          focusedInput.previousElementSibling.focus();
                                        }
                                      }
                                      else
                                        if (e.key === "ArrowDown") {
                                          const focusedInput = document.activeElement;
                                          if (focusedInput.nextElementSibling) {
                                            focusedInput.nextElementSibling.focus();
                                          }
                                        }
                                  }}
                                >
                                  <div className='row'>
                                    <div class="col-1" style={{ fontSize: '14px' }} >{product[0]}</div>
                                    <div class="col-4" style={{ fontSize: '14px' }} >{product[1]}</div>
                                    <div class="col-2" style={{ fontSize: '14px' }} >{product[2]}</div>
                                    <div class="col-1" style={{ fontSize: '14px' }} >Exp : {product[11]}/{product[12]}</div>
                                    <div class="col-1" style={{ fontSize: '14px' }} >Qty : {product[17]}</div>
                                    <div class="col-1" style={{ fontSize: '14px' }} >Dis : {product[6]}</div>
                                    <div class="col-2" style={{ fontSize: '14px' }} >MRP : {product[18] != null ? product[18] : product[5]}</div>
                                  </div>
                                </div>
                                : null
                            }
                            </>
                          )}

                        )
                        : null
                    }
                  </div>

                  <p className='togglePlaceHolder' style={{ marginBottom: "-28px" }}>Product Unit</p>
                  <input style={{ width: '20vw' }} className="purchaseInputs" type="text" name="prodUnit" id="prodUnit"
                    onKeyPress={() => { removeErrorMessage("prodUnit") }} placeholder='Product Unit' tabIndex={13} />

                  <p className='togglePlaceHolder' style={{ marginBottom: "-28px" }}>Company Name</p>
                  <input style={{ width: '20vw' }} className="purchaseInputs" type="text" name="prodComp" id="prodComp"
                    onKeyPress={() => { removeErrorMessage("prodComp") }} placeholder='Company Name' tabIndex={14} />
                </div>

                {
                  showMobileModal === false ?
                    <div className='flexInputs' style={{ fontSize: '1vw', marginTop: '.5vw', display: 'flex', justifyContent: 'space-between' }}>
                      <input hidden type="text" name="prodId" id="prodId" />
                      
                      <p className='togglePlaceHolder' style={{ marginBottom: "-14px", marginTop: "3px" }}>Quantity</p>
                      <input onKeyDown={(e) => {
                          calculateAmount(1);
                          removeErrorMessage("quantity");
                          if (e.key === "Enter") {
                            document.getElementById("freeQuantity").focus();
                          }
                        }} className="purchaseInputs2"
                        style={{ fontSize: '1vw', textAlign: 'center' }} type="text" name="quantity" id="quantity" placeholder='Quantity' tabIndex={15} />
                      
                      <p className='togglePlaceHolder' style={{ marginTop: "5px" }}>Free Quantity</p>
                      <input onKeyDown={(e) => {
                          removeErrorMessage("freeQuantity");
                          if (e.key === "Enter") {
                            document.getElementById("SGST").focus();
                          }
                        }} style={{ textAlign: 'center' }} className="purchaseInputs2" type="text"  name="freeQuantity" id="freeQuantity" placeholder='Free' tabIndex={16} />

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>SGST %</p>
                      <input onKeyDown={(e) => {
                        calculateAmount(1); 
                        removeErrorMessage("SGST");
                        if (e.key === "Enter") {
                          document.getElementById("CGST").focus();
                        }
                      }} style={{ fontSize: '1vw', width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" name="SGST" id="SGST" placeholder='SGST' tabIndex={17} />

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>CGST %</p>
                      <input
                        onKeyDown={(e) => {
                          calculateAmount(1); 
                          removeErrorMessage("CGST");
                          if (e.key === "Enter") {
                            document.getElementById("IGST").focus();
                          }
                        }} style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" name="CGST" id="CGST" placeholder='CGST' tabIndex={18} />

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>IGST %</p>
                      <input onKeyDown={(e) => {
                        calculateAmount(1); 
                        removeErrorMessage("IGST");
                        if (e.key === "Enter") {
                          expMo.current.focus();
                        }
                      }} style={{ width: '3vw', textAlign: 'center' }} className="purchaseInputs2" type="text" name="IGST" id="IGST" placeholder='IGST' tabIndex={19} />

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>Expiry Month</p>
                      <span id="expMonth" className="expMonth">
                        <Select ref={expMo} value={defExMonth}
                          onKeyDown={(e) => {
                            removeErrorMessage("expMonth");
                            if (e.key === "Enter") {
                              expYr.current.focus();
                            }
                          }} options={expMoOptions} isSearchable={true} onChange={handleMonth} placeholder="Expiry Month" styles={selectStyle1} tabIndex={20} />
                      </span>


                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>Expiry Year</p>
                      <span id="exp_Year" className="exp_Year">
                        <Select ref={expYr} value={defExpiryYear}
                          onKeyDown={(e) => {
                            removeErrorMessage("exp_Year");
                            if (e.key === "Enter") {
                              document.getElementById("batchNo").focus();
                            }
                          }} options={expYearOptions} isSearchable={true} onChange={handleYear} placeholder="Expiry Year" styles={selectStyle1} tabIndex={21} />
                      </span>

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>Batch Number</p>
                      <input className="purchaseInputs2" onKeyDown={(e) => {
                        removeErrorMessage("batchNo");
                        if (e.key === "Enter") {
                          document.getElementById("HSNCode").focus();
                        }
                      }} style={{ width: '6vw', textAlign: 'center' }} type="text" name="batchNo" id="batchNo" placeholder='Batch No' tabIndex={22} />

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>HSN Code</p>
                      <input className="purchaseInputs2" onKeyDown={(e) => {
                        removeErrorMessage("HSNCode");
                        if (e.key === "Enter" )
                        {
                          if (billingCat === "retRet" || billingCat === "dealRet")
                          {
                            document.getElementById("dpMRP").focus();
                          }
                          else
                          {
                            document.getElementById("prodRate").focus();
                          }
                        }
                      }} style={{ width: '6vw', textAlign: 'center' }} type="text" name="HSNCode" id="HSNCode" placeholder='HSN Code' tabIndex={23} />

                      { billingCat === "retCus" || billingCat === "dealCust" ? 
                      null
                      :
                        <div className="purchaseInputs" style={{width: '4vw'}}>
                          <div style={{ marginTop: "5px", fontSize:'.8vw' }}>MRP</div>
                          <input onKeyDown={(e) => {
                            calculateAmount(1);
                          if (e.key === "Enter") {
                            document.getElementById("prodRate").focus();
                          }
                        }}
                        style={{width: '3.5vw', textAlign: 'center', border:'none', outline:'none' }} type="text" name="dpMRP" id="dpMRP" tabIndex={23.1}/>
                        </div>
                      }

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>{billingCat === "retCus" || billingCat === "dealCust" ? "MRP" : "PTR" }</p>
                      <input className="purchaseInputs2" onKeyDown={(e) => {
                        calculateAmount(1); 
                        removeErrorMessage("prodRate");
                        if (e.key === "Enter") {
                          document.getElementById("prodDis").focus();
                        }
                      }} style={{ textAlign: 'center' }} type="text"  name="prodRate" id="prodRate" placeholder={billingCat === "retCus" || billingCat === "dealCust" ? "MRP" : "PTR" } tabIndex={24} />

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>Discount %</p>
                      <input className="purchaseInputs2" onKeyDown={(e) => {
                        calculateAmount(1);
                        if (e.key === "Enter") {
                          document.getElementById("narration").focus();
                        }
                      }} style={{ textAlign: 'center' }} type="text" name="prodDis" id="prodDis" placeholder={billingCat == "retCus" || billingCat == "dealCust" ? 'Discount' :'CD %'} tabIndex={25} />

                      <p className="togglePlaceHolder" style={{ marginTop: "5px" }}>Amount</p>
                      <div className="flex" style={{ flexDirection: "row", minWidth: "10px" }}>

                        <input className="purchaseInputs2" style={{ textAlign: 'center' }} type="text" onKeyPress={() => { removeErrorMessage("Amount") }} name="Amount" id="Amount" placeholder='Amount' disabled />

                        {/* it will show only mobile screen */}
                        <span onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            addItemToInvoice(1);
                          }
                        }} onClick={() => addItemToInvoice(1)} className='addInvItemBtn' id='addInvItemBtn' tabIndex={26}>+</span>
                      </div>

                      <input hidden type="text" name="Amount" id="prodRack" disabled />

                    </div> : null
                }
              </Col>
            </Row>

            <div className='invoiceDetailsDiv'>
              <Row style={{ paddingTop: '.5vw' }}>
                <Col style={{ color: 'black', fontWeight: 'bold' }}>
                  Invoice Details 
                </Col>
              </Row>
              {
                invoiceItems && invoiceItems.length > 0 ?
                  invoiceItems.map((invItem) => {
                    return (
                      <div key={invItem[0]} style={{ width: "100%", borderBottom: '5px solid lightgray', paddingTop: '.2vw', paddingBottom: '.5vw' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <input style={{ width: '37vw', fontSize: '14px',margin: '0px', lineHeight: '.5px' }} type="text"
                            defaultValue={invItem[1]} disabled className="purchaseInputs" />
                          <input style={{ width: '20vw' }} className="purchaseInputs" type="text" defaultValue={invItem[2]} disabled />
                          <input style={{ width: '20vw' }} className="purchaseInputs" type="text" defaultValue={invItem[3]} disabled />
                        </div>

                        <div style={{ marginTop: '.5vw', display: 'flex', justifyContent: 'space-between',marginLeft:-4 }}>
                          <input style={{ textAlign: 'center', fontSize:"14px" }} className="purchaseInputs2" type="text" defaultValue={invItem[4]} disabled />
                          <input style={{ textAlign: 'center', fontSize:"14px" }} className="purchaseInputs2" type="text" defaultValue={invItem[5]} disabled />
                          <input style={{ width: '3vw', textAlign: 'center', fontSize:"14px" }} className="purchaseInputs2" type="text" defaultValue={invItem[6]} disabled />
                          <input style={{ width: '3vw', textAlign: 'center', fontSize:"14px" }} className="purchaseInputs2" type="text" defaultValue={invItem[7]} disabled />
                          <input style={{ width: '3vw', textAlign: 'center', fontSize:"14px" }} className="purchaseInputs2" type="text" defaultValue={invItem[8]} disabled />

                          <input style={{ width: '5vw',fontSize:"14px" }} className="purchaseInputs2" type="text" defaultValue={invItem[9] + "/" + invItem[10]} disabled />

                          <input className="purchaseInputs2" style={{ width: '6vw', textAlign: 'center',fontSize:"14px" }} type="text" defaultValue={invItem[11]} disabled /> 
                          <input className="purchaseInputs2" style={{ width: '6vw', textAlign: 'center',fontSize:"14px" }} type="text" defaultValue={invItem[12]} disabled />
                          <input className="purchaseInputs2" style={{ textAlign: 'center',fontSize:"14px" }} type="text" defaultValue={invItem[13]} disabled />
                          <input className="purchaseInputs2" style={{ textAlign: 'center',fontSize:"14px" }} type="text" defaultValue={invItem[14]} disabled />
                          <input className="purchaseInputs2" style={{ textAlign: 'center',fontSize:"14px" }} type="text" defaultValue={invItem[15]} disabled />
                          <input className="purchaseInputs2" style={{ width: '6vw', textAlign: 'center',fontSize:"14px" }} type="text" defaultValue={invItem[16]} disabled />
                          <img onClick={() => editInvoiceItem(invItem, 1)} style={{ cursor: 'pointer', width: '1.8vw', height: '1.8vw', marginTop: '.3vw' }} src={require('../../../static/images/Icons/edit.png')} alt='' />
                          <span onClick={() => removeItemToInvoice(invItem[0], 1)} className='removeInvItemBtn'>X</span>
                        </div>
                      </div>
                    )
                  }
                  )
                  :
                  <Row style={{ borderBottom: '5px solid lightgray', }}>
                    <Col style={{ height: 83, display: "flex", marginBottom: "10px" }} lg={12} md={12} xl={12} sm={12} xs={12}>
                      <table>
                        <tr style={{ marginBottom: "10px" }}>
                          <td colSpan={2}>
                            <input style={{ width: "30vw" }} className="purchaseInputs" type="text" disabled />
                          </td>
                          <td >
                            <input className="purchaseInputs" type="text" disabled />
                          </td>
                          <td>
                            <input style={{ width: "24vw" }} className="purchaseInputs" type="text" disabled />
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2}>
                            <input style={{ width: "30vw" }} className="purchaseInputs" type="text" disabled />
                          </td>
                          <td >
                            <input className="purchaseInputs" type="text" disabled />
                          </td>
                          <td>
                            <input style={{ width: "24vw" }} className="purchaseInputs" type="text" disabled />
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
              }

              {/* <div className="youtubeLink">
                <img src={require("../../../static/DPImages/youtubeLogo.png")} alt="" />
              </div> */}


              <div style={{ marginTop: "20px", marginRight: '.5vw', display: 'flex', justifyContent: 'flex-end' }} >
                <div style={{ marginRight: '14vw', width: '25%',alignSelf:"flex-start" }}>
                  <textarea style={{ resize: 'none', fontSize: '14px', width: '100%', paddingLeft: '2px', paddingRight: '2px',background:"#E6E6E6",border:"none",outline:0,padding:10,borderRadius:5,fontStyle:"italic" }} name="narration" id="narration" className='narration' rows={3}
                    value={invHeads ? invHeads[33] : narrationDef} placeholder='Narration ' tabIndex={26}
                    onKeyDown={(e) => {
                      setNarrationDef(e.value);
                      removeErrorMessage('narration');
                      if (e.key === "Enter") {
                        document.getElementById("addInvItemBtn").focus();
                      }
                    }}></textarea>
                </div>
                <div style={{ fontSize: '.8vw', lineHeight: '.9vw', width: '15vw', marginRight: '3vw' }}>
                  <Row>
                    <Col style={{ height: "30px", display: "flex", alignItems: "center", border: '1px solid gray' }} sm={8} lg={8} md={8} xl={8}>
                      SGST
                    </Col>
                    <Col style={{ border: '1px solid gray', display: "flex", alignItems: "center", justifyContent:'end' }} sm={4} lg={4} md={4} xl={4}>
                      {Number(totals[2]).toFixed(2)}
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{  height: "30px", display: "flex", alignItems: "center", border: '1px solid gray' }} sm={8} lg={8} md={8} xl={8}>
                      CGST
                    </Col>
                    <Col style={{ display: "flex", alignItems: "center", border: '1px solid gray', justifyContent:'end' }} sm={4} lg={4} md={4} xl={4}>
                      {Number(totals[3]).toFixed(2)}
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ height: "30px", display: "flex", alignItems: "center", border: '1px solid gray' }} sm={8} lg={8} md={8} xl={8}>
                      IGST
                    </Col>
                    <Col style={{ display: "flex", alignItems: "center", border: '1px solid gray', justifyContent:'end'}} sm={4} lg={4} md={4} xl={4}>
                      {Number(totals[4]).toFixed(2)}
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ height: "30px", display: "flex", alignItems: "center", border: '1px solid gray' }} sm={8} lg={8} md={8} xl={8}>
                      GST Total
                    </Col>
                    <Col style={{ display: "flex", alignItems: "center", border: '1px solid gray', justifyContent:'end' }} sm={4} lg={4} md={4} xl={4}>
                      {Number(totals[2] + totals[3] + totals[4]).toFixed(2)}
                    </Col>
                  </Row>
                </div>

                <div style={{ fontSize: '.8vw', lineHeight: '1vw', width: '20vw' }}>
                  <Row>
                    <Col sm={8} lg={8} md={8} xl={8}>
                      {vendorType === 0 ? 'Sub Total' : 'MRP Total'}
                    </Col>
                    <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                      {Number(totals[6]).toFixed(2)}
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5px" }}>
                    <Col sm={8} lg={8} md={8} xl={8}>
                      Discount
                    </Col>
                    <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                      {Number(totals[5]).toFixed(2)}
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5px" }}>
                    <Col sm={8} lg={8} md={8} xl={8}>
                      Wallet Discount
                    </Col>
                    <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                      0.00
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5px" }}>
                    <Col sm={8} lg={8} md={8} xl={8}>
                      Shipping Charges
                    </Col>
                    <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                      0.00
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "5px" }}>
                    <Col sm={8} lg={8} md={8} xl={8}>
                      Debit / Credit
                    </Col>
                    <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                      0.00
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "5px" }}>
                    <Col sm={8} lg={8} md={8} xl={8}>
                      Others
                    </Col>
                    <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right' }}>
                      0.00
                    </Col>
                  </Row>

                  {/* setTotals([Tqty, Tfrqty, Tsgst, Tcgst, Tigst, Tcdis, Tgamount, Tamount]); */}
                  <Row style={{ marginTop: '.5vw', paddingTop: '.5vw', borderTop: '2px solid black' }}>
                    <Col sm={8} lg={8} md={8} xl={8} style={{ fontWeight: 'bold' }}>
                      Payable Value
                    </Col>
                    <Col sm={4} lg={4} md={4} xl={4} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                      {Math.round(totals[7]).toFixed(2)}
                    </Col>
                  </Row>
                </div>
              </div>

            </div>

            <div className='amountInWordsDiv' style={{marginLeft:"2vw",marginBottom:"5vh",marginTop:"20px"}}>
              <div style={{ fontSize: '14px', color: 'gray' }}>Amount in words Rupees</div>
              <div>{inWords(Math.round(totals[7]).toFixed(0))}</div>
            </div>
          </div>

          {/* main modal bottom */}
          {
            showMobileModal === false ?
              <>
                <div className="invoiceDetailsMobileBtn" onClick={() => {
                  document.querySelector(".invoiceDetailsMobileModal").classList.add("active");
                  setShowMobileModal(true);
                }}>
                  <h3>Invoice Details</h3>
                  <img src={require("../../../static/DPImages/up-arrow.png")} alt="" />
                </div>
                <div className='salesBillBottom salesBillMainModelBottom' style={{ width: '67%', display: 'flex', justifyContent: 'flex-start' }} lg={12} md={12} xl={12} sm={12} xs={12} >
                  <button style={{ fontSize: '14px', minWidth: "191px", backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '5px' }} type='submit' className='Disabled btn btn-primary btn-sm' tabIndex="27" >Invoice Value : 0.00</button>
                  <button style={{ fontSize: '14px', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '5px' }} type='submit' onClick={handleInvoiceSubmit} className='Disabled btn btn-primary btn-sm' tabIndex="27" >Save Invoice</button>
                </div>
              </> : null
          }

          {/* invoice modal in mobile screen */}
          <div className="invoiceDetailsMobileModal">
            <div className="invoiceModelHeader">
              <div style={{ display: "flex", alignItems: "center" }}>
                <i class="ri-arrow-left-line backBtnInvoiceMobileModal" onClick={() => {
                  document.querySelector(".invoiceDetailsMobileModal").classList.remove("active");
                  setShowMobileModal(false);
                }}></i>
                <h3 style={{ color: "#000" }}>Invoice Details</h3>
              </div>
              <i class="ri-close-fill closeBtn"></i>
            </div>

            <p className='blueText'>Customer Details</p>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h2 style={{ margin: "0px", padding: "0px" }}>{document.getElementById('serStockName')?.value}</h2>
              <img src={require("../../../static/DPImages/edit.png")} width={20} alt="" />
            </div>
            <p style={{ margin: "0px", padding: "0px" }}>{document.getElementById('contact1')?.value}</p>
            <p style={{ margin: "0px", padding: "0px" }}>{document.getElementById('address1')?.value}  <br /> {document.getElementById('address2')?.value}</p>

            <div className="flex" style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>

              <div className="floting-input">
                <p className="floatingInput">Invoice No</p>
                <input type="text" value={invHeads ? invHeads[1] : invoiceNewNo} />
              </div>

              <div className="floting-input">
                <p className="floatingInput">Date</p>
                <div className="flex" style={{ margin: "0px", padding: "0px" }}>
                  <input type="text" style={{ textTransform: "uppercase", width: '30vw' }} value={document.getElementById('invoiceDate')?.value} />
                  <img src={require("../../../static/DPImages/calendar.png")} alt="" />
                </div>
              </div>

            </div>

            <div className="selectedProducts">
              {
                invoiceItems && invoiceItems.length > 0 ?
                  invoiceItems.map((invItem) => {
                    return (
                      <div className="selectedProduct">
                        <div className="leftSection">
                          <h3 style={{ margin: "0px", padding: "0px", fontSize: "18px" }}>{invItem[1]}</h3>
                          <p style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>Unit : {invItem[2]}</p>
                          <p style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>Quantity : {invItem[4]}</p>
                          <p style={{ margin: "0px", padding: "0px", fontSize: "14px" }}>MRP : {invItem[14]} | <span>Discount : {invItem[17] !== '' ? invItem[17] : 'N/A'}</span></p>
                        </div>

                        <div className="rightSection">
                          <p className='delete' onClick={() => removeItemToInvoice(invItem[0], 1)}>Delete</p>
                          <p style={{ color: "black" }}>Total : {invItem[16]}</p>
                        </div>
                        {/* <img onClick={() => editInvoiceItem(invItem, 1)} style={{ cursor: 'pointer', width: '1.8vw', height: '1.8vw', marginTop: '.3vw' }} src={require('../../../static/images/Icons/edit.png')} alt='' />
                          */}
                      </div>

                    )
                  }
                  )
                  :
                  <Row style={{ borderBottom: '5px solid lightgray', }}>
                    <Col style={{ height: 83, display: "flex", marginBottom: "10px" }} lg={12} md={12} xl={12} sm={12} xs={12}>
                      <table>
                        <tr style={{ marginBottom: "10px" }}>
                          <td colSpan={2}>
                            <input style={{ width: "30vw" }} className="purchaseInputs" type="text" disabled />
                          </td>
                          <td >
                            <input className="purchaseInputs" type="text" disabled />
                          </td>
                          <td>
                            <input style={{ width: "24vw" }} className="purchaseInputs" type="text" disabled />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <input style={{ width: "30vw" }} className="purchaseInputs" type="text" disabled />
                          </td>
                          <td >
                            <input className="purchaseInputs" type="text" disabled />
                          </td>
                          <td>
                            <input style={{ width: "24vw" }} className="purchaseInputs" type="text" disabled />
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
              }
            </div>

            <div className="calculateDiv" >
              <div className="leftSection" style={{ width: "100%" }}>
                <h3 style={{ margin: "0px", padding: "0px", justifyContent: "space-between", display: "flex", alignItems: "center",fontSize:"14px" }}>MRP Total : <span>{Number(totals[6]).toFixed(2)}</span></h3>
                <h3 style={{ margin: "0px", padding: "0px", justifyContent: "space-between", display: "flex", alignItems: "center",fontSize:"14px" }}>Discount : <span>{Number(totals[5]).toFixed(2)}</span></h3>
                <h3 style={{ margin: "0px", padding: "0px", justifyContent: "space-between", display: "flex", alignItems: "center",fontSize:"14px" }}>Wallet Discount : <span>N/A</span></h3>
                <h3 style={{ margin: "0px", padding: "0px", justifyContent: "space-between", display: "flex", alignItems: "center",fontSize:"14px" }}>SGST Value : <span>{Number(totals[2]).toFixed(2)}</span></h3>
                <h3 style={{ margin: "0px", padding: "0px", justifyContent: "space-between", display: "flex", alignItems: "center",fontSize:"14px" }}>CGST Value : <span>{Number(totals[3]).toFixed(2)}</span></h3>
                <h3 style={{ margin: "0px", padding: "0px", justifyContent: "space-between", display: "flex", alignItems: "center",fontSize:"14px" }}>IGST Value : <span>{Number(totals[4]).toFixed(2)}</span></h3>
                <h3 style={{ margin: "0px", padding: "0px", justifyContent: "space-between", display: "flex", alignItems: "center",fontSize:"14px" }}>Shipping Charges : <span>Free</span></h3>
                <h3 style={{ margin: "0px", padding: "0px", justifyContent: "space-between", display: "flex", alignItems: "center", color: "black", fontWeight: "400",fontSize:"14px" }}>Paybale Vlaue : <span>{Number(totals[6] - totals[5]).toFixed(2)}</span></h3>
              </div>

            </div>

            <div className='salesBillBottom invoiceBottom' style={{ width: '56%', display: 'flex', justifyContent: 'flex-start' }} lg={12} md={12} xl={12} sm={12} xs={12} >
              <button style={{ border: "none", fontSize: '1vw', minWidth: "200px", backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' className='btn btn-primary btn-sm' tabIndex="21" >EDIT INVOICE</button>
              <button style={{ border: "none", fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' className='btn btn-primary btn-sm' onClick={handlePrint} tabIndex="21" >PRINT</button>
            </div>
          </div>

          <div className='salesBillBottomDiv' style={{
            borderBottom: '3px solid rgb(61, 197, 238)', display: 'flex',
            width: '100%', backgroundColor: 'rgb(12, 12, 12)', position: 'absolute', left: '0px', bottom: '-35px', paddingTop: '1vw', paddingBottom: '1vw',
            marginBottom: "30px"
          }}>

            {/* it will show only on desktop screen */}
            <div className='salesBillBottom onDestopScreen' style={{ width: '67%', display: 'flex', justifyContent: 'flex-start' }} lg={12} md={12} xl={12} sm={12} xs={12} >
              {/* <button style={{ fontSize: '1vw',minWidth:"191px", backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={PurchaseModalClose} className='Disabled btn btn-primary btn-sm' tabIndex="21" >Invoice Value : 0.00</button> */}
              {/* <button style={{ fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={handlePrint} className='btn btn-primary btn-sm' tabIndex="21">PRINT</button> */}
              {/* <button style={{ fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={() => alert("Printing")} className='btn btn-primary btn-sm' tabIndex="21">DELETE</button> 
              <button style={{ fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={() => alert("Printing")} className='btn btn-primary btn-sm' tabIndex="21">PREVIOUS</button>
              <button style={{ fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={() => alert("Printing")} className='btn btn-primary btn-sm' tabIndex="21">NEXT</button> * */}
              <button style={{ fontSize: '14px', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '5px' }} type='submit' onClick={handleInvoiceSubmit} className='Disabled btn btn-primary btn-sm' tabIndex="27" >Save Invoice</button>
              <button style={{ fontSize: '14px', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '5px' }} type='submit' onClick={()=>{
                if (invoiceItems.length < 1)
                  {
                    alert("PLease add mor or more products");
                    return false;
                  }
                else
                  handlePrint();
                }} className='Disabled btn btn-primary btn-sm' tabIndex="27" >Print</button>
              {
                salesBookModal && salesBookModal === true ?
                  <button style={{ fontSize: '14px', backgroundColor:'#dc2626', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '5px' }} type='submit'
                    onClick={()=>{cancelInvoice(invHeads[0], invHeads[1]);}} className='Disabled btn btn-primary btn-sm' tabIndex="27" >Cancel Invoice</button>
              :
                null
              }
            </div>

            <div className='salesBillBottomTotal' style={{ width: '33%', paddingRight: '2vw', textAlign: 'right', color: 'white', fontWeight: 'bold' }}>
              {vendorType === 0 ? 'Grand ' : ''}Total&nbsp;&nbsp;:&nbsp;&nbsp;{Math.round(totals[7]).toFixed(2)}
              {/* {Number(totals[6]).toFixed(2)} */}
              {/* {Number(totals[6] - totals[5]).toFixed(2)}  */}
            </div>
          </div>

          {/* [qty.value, fQty, SGSTV, CGSTV, IGSTV, pDis, gamt, Amount.value]
              [prodId, pName, pUnit, pComp, qty, fQty, SGST, CGST, IGST, exMonth, expiryYear, batchNo, HSNCode, prodMRP, prodRate, prodDis, Amount] */}

          {/* classes => printable-document page-break page */}
          <div ref={componentRef} className={'billPrint'} style={{minHeight:'12.8cm'}}>
            <style>{billPrintInlineStyle}</style>
            {
              invoiceItems && invoiceItems.length > 0 ?
                invoiceItems.map((invItem, index) => {
                  return (
                    <div>
                      {index % 8 === 0 && index > 7 ?
                        <div style={{ marginLeft: '30%' }}>to be continued on next page......</div>
                        : null
                      }

                      {
                        index % 8 === 0 && salesBillVendor !== null ?
                          <div className='page-break' style={{ width: '100%',  display: 'flex', justifyContent: 'flex-start' }}>
                            <div style={{ lineHeight: '95%', color: "gray", width:'100%' }}>
                              <div style={{ fontSize: 25, lineHeight: '100%', color: "#000" }}>{salesBillVendor[2]}</div>
                              <div>{salesBillVendor[3]}</div>
                              <div>{salesBillVendor[6]}</div>
                              <div>{salesBillVendor[4]}, {salesBillVendor[5]}, </div>
                              <div>{salesBillVendor[7] !== null || salesBillVendor[8] !== null ? 'Phone ' :null}{salesBillVendor[7] !== null ? salesBillVendor[7]+', ' : null}{salesBillVendor[8] !== null ? salesBillVendor[8] : null} <br />{salesBillVendor[9] !== null ? ' Email: '+salesBillVendor[9] : null}</div>
                            </div>
                            
                            <div style={{ width: '50%', marginTop: '4%', lineHeight: '95%' }}>
                              {
                                (billingCat === "retCus" || billingCat === "dealCust") && invHeads != null ?
                                  <div style={{ textAlign: "right", color: "gray", marginRight: 50 }}>
                                    <div> Mr./Mrs.{invHeads[8]} </div>
                                    <div> {String(invHeads[29]).split('___')[0]} | {String(invHeads[29]).split('___')[1]}</div>
                                    <div> {invHeads[30]} </div>
                                    <div> {invHeads[15] !== null || invHeads[16] !== null ? 'Contact ' :null}{invHeads[15] !== null ? invHeads[15] : null}, {invHeads[16] !== null ? invHeads[16] : null} </div>
                                  </div>
                                  : selStockist && billingCat !== "retCus" ?
                                    <div style={{ fontSize: '90%', lineHeight: '95%' }}>
                                      <span style={{ fontWeight: 'bold' }}>Ms. {selStockist[1]}</span> <br />
                                      {selStockist[2]} <br />
                                      {' '}{selStockist[3]}
                                      {' '}{selStockist[4]}
                                      {' '}{selStockist[5]}
                                      {selStockist[6]}
                                      {selStockist[7]} <br />
                                      {selStockist[12] !== null ? 'GSTIN Number '+selStockist[12] : null} <br />
                                      {selStockist[11] ? 'Drug License Number '+selStockist[11].split('___')[0] + "  |  " + selStockist[11].split('___')[1] : null}<br />
                                      {selStockist[8]}{selStockist[9] !==null ? ', '+selStockist[9] : null}
                                      {/* <br />E-Mail :{selStockist[10]} */}
                                    </div>
                                    : null
                              }
                            </div>

                          </div>
                          : null
                      }

                      {index % 8 === 0 && salesBillVendor !== null ?
                        <div style={{ fontSize: '90%', borderTop: "1px solid #000", borderBottom: "1px solid #000", lineHeight: '94%', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                          <div style={{ width: '40%', color: "gray" }}>
                            <div>
                              GSTIN: {salesBillVendor[12]}
                            </div>
                            {/* <div>
                              FSSAI No:
                            </div> */}

                            <div>
                              D.L.No: {salesBillVendor[11]}
                            </div>
                          </div>

                          <h2 style={{ fontSize: 20, marginLeft: -50, marginTop: 5 }}>Tax Invoice</h2>

                          <div style={{ textAlign: "right", color: "gray" }}>
                            {
                              invHeads ?
                                <>
                                  <div>Invoice No : {invHeads[1]} </div>
                                  <div>Date :{invHeads[5].substring(0, 10)}</div>
                                </>
                                : null
                            }
                            {/* <div>Bill: {payMode} </div> */}
                          </div>

                        </div>
                        : null
                      }

                      {index % 8 === 0 ?
                        <div style={{ fontSize: '90%', display: 'flex', justifyContent: 'flex-start'}}>
                          <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>S.N</div>
                          <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Particulars</div>
                          <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Packing</div>
                          <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Hsn</div>
                          <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Batch</div>
                          <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Exp</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Qty</div>
                          <div style={{ width: '5%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Rate</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Disc.</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>SGST</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>CGST</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>IGST</div>
                          <div style={{ width: '10%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Amoumt</div>
                        </div>
                        :
                        null
                      }

                        <div style={{
                          fontSize: '90%', display: 'flex', justifyContent: 'flex-start'
                        }}>
                          <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {index + 1} </div>
                          <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[1].length <= 25 ? `${invItem[1]}` : `${invItem[1].substring(0, 22)}...`} </div>
                          <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[2].length <= 15 ? `${invItem[2]}` : `${invItem[2].substring(0, 12)}...`} </div>
                          <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[12]} </div>
                          <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[11]} </div>
                          <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[9]}/{(String(invItem[10])).substring(2, 4)}</div>
                          <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray", textAlign: 'right' }}> {invItem[4]} </div>
                          <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray", textAlign: 'right' }}> {invItem[14]} </div>
                          <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray", textAlign: 'right' }}> {invItem[15]} </div>
                          <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray", textAlign: 'right' }}> {invItem[6]} </div>
                          <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray", textAlign: 'right' }}> {invItem[7]} </div>
                          <div style={{ width: '4%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray", textAlign: 'right' }}> {invItem[8]} </div>
                          <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "#000", textAlign: 'right' }}> {invItem[16]} </div>
                        </div>


                      {
                        (index + 1) === invoiceItems.length && salesBillVendor !== null ?
                          <div style={{ color:"gray", width:'100%', position:'fixed', bottom:'0px', borderTop:'1px solid black', paddingTop:'10px' }}>
                            <div style={{ marginRight: '.5vw', display: 'flex', justifyContent:'space-between'}} >
                              <div style={{ width: '45%', fontSize: '80%', lineHeight: '90%' }}>
                                <div style={{ textDecoration: 'underline', marginBottom:10 }}>Terms & Conditions</div>
                                <div>1. Transactions, subject to {salesBillVendor[4]} Jurisdiction.</div>
                                <div>2. Goods once sold will not be taken back.</div>
                                <div>3. We will not reponsible for any demage of loss in transit.</div>
                                <div>4. Interest: @2% per month will be charged over due date.</div>
                                {/* <div>NOTE:</div> */}
                              </div>

                              {/* <div style={{ paddingLeft: '3%', width: '20%', textAlign: 'right', paddingRight: '.5%', fontSize: '85%', lineHeight: '80%' }}>
                                <div>for {String(salesBillVendor[2]).toUpperCase()}</div>
                                <br /><br /><br /><br />
                                <div>Authorised Signatory</div>
                              </div> */}

                              <div style={{ fontSize: '90%', lineHeight: '90%', width: '18%',marginLeft: '10%' }}>
                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    SGST
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[2]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    CGST
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[3]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    IGST
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[4]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    GST Total
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[2] + totals[3] + totals[4]).toFixed(2)}
                                  </div>
                                </div>
                              </div>

                              <div style={{ fontSize: '14px',minWidth:"250px",lineHeight:1, width: '20%' }}>
                                <div style={{ display: 'flex'}}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    {vendorType === 0 ? 'Sub Total' : 'MRP Total'}
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[6]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex'}}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    Cash Discount
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[5]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex'}}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    Debit / Credit
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    0.00{ }
                                  </div>
                                </div>
                                <div style={{ display: 'flex'}}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    Others
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    0.00{ }
                                  </div>
                                </div>

                                <div style={{ display: 'flex',paddingTop:10,borderTop:"1px solid #000",marginTop:10,color:"#000"}}>
                                  <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '60%', }}>
                                    Payable
                                  </div>
                                  <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[6] - totals[5]).toFixed(2)}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ fontSize: '1vw',marginTop:-20 }}>
                              <div style={{ fontSize: '.9vw', }}>Amount in words Rupees</div>
                              <div style={{color: '#000'}}>{inWords( Math.round(totals[6] - totals[5]))}</div>
                            </div>
                          </div>
                          : null
                      }
                    </div>
                  )
                }
                )
                :
                null
            }
          </div>
        </Modal.Body>
      </Modal>

      {/* Not Found Modal */}
      <Modal show={notFoundModal} onHide={notFoundModalClose} size="lg" backdrop="static" >
        <Modal.Body>
          {loader ? <Loader /> : null}
          <div className='purchModalHeader'>
            <h3>Data Not Found</h3>
            <div style={{ display: 'flex' }}>
              <span style={{ fontSize: '1vw', marginTop: '.3vw' }}>Shortcut keys:  F2=New  |  F3-Edit  |  End=Save  |  Esc=Back &nbsp;&nbsp;&nbsp;&nbsp;</span>
              <div className='purchModalCloseBTN' onClick={notFoundModalClose}>
                <img style={{ width: '2vw', height: '2vw' }} src={require('../../../static/images/Icons/close1.png')} alt="" />
              </div>
            </div>
          </div>

          <div style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} onClick={notFoundModalClose}>
            <img style={{ width: '10vw', height: '10vw' }} src={require('../../../static/DPImages/Icons/notfound.png')} alt="" />
            <div style={{ fontSize: '2vw' }} >Oops.!</div>
            <div>Record not found..!</div>
          </div>
          <div style={{
            borderBottom: '3px solid rgb(61, 197, 238)', display: 'flex',
            width: '100%', backgroundColor: 'rgb(12, 12, 12)', position: 'absolute', left: '0px', bottom: '0px', paddingTop: '1vw', paddingBottom: '1vw'
          }}>

          </div>
        </Modal.Body>
      </Modal>

      {/* Purchase Book Modal */}
      <Modal className='purchaseBookModal' show={purchBookModal} onHide={purchBookModalClose} size="xl" backdrop="static" keyboard={false}>
        <Modal.Body>
          {loader ? <Loader /> : null}
          <div className='purchModalHeader'>
            <h5 style={{paddingTop:"5px"}}>Purchase Book</h5>
            <div style={{ display: 'flex', alignItems:'center' }}>
                {/* <span style={{ fontSize: '1vw', marginTop: '.3vw' }}>Date __/__/____ to __/__/____ &nbsp;&nbsp;&nbsp;&nbsp;</span> */}
              {purchBookQueryToggle === false ?
              <>
                <div><input type='date' name='dateFromPSB' id='dateFromPSB' style={{outline:0,width:"95px"}} className='removeDateIcon text-uppercase border-0' /></div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div><input type='date' name='dateToPSB' id='dateToPSB' style={{outline:0,width:"95px"}} className='removeDateIcon text-uppercase border-0' /></div>
                <div>&nbsp;&nbsp;&nbsp;</div>
                
                <div className='digiNavBtn2 mediumButtons' onClick={() => {
                  purchBookQuery(1);
                }}>Go</div>

              </>
              : null}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div className='purchModalCloseBTN' onClick={purchBookModalClose}>
                <img style={{ width: '2vw', height: '2vw' }} src={require('../../../static/images/Icons/close1.png')} alt="" />
              </div>
            </div>
          </div>

          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          {purchBookQueryToggle ?
            <>
              <div style={{ margin: '0px', minWidth: '95%', marginRight: '.5vw', padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
                <div className='StockSearch' style={{ minWidth: '60%', marginLeft: '.5vw', borderRadius: '0px', justifyContent: 'flex-start' }}>
                  <img style={{ minWidth: '2%', height: '2%' }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
                  <input style={{ minWidth: '89%', fontSize: '14px', fontStyle: 'italic', paddingLeft: '2px', margin: '0px', lineHeight: '.5px',padding: "4px" }} onFocus={populateStockist}
                    onKeyDown={(e) => {
                      // if (e.keyCode === 113) {
                      //   stockistModalShow(1);
                      // }
                      if (e.key === "Enter") {
                        document.getElementById("stockistList").classList.replace("show", "hide");
                        document.getElementById("serInvoiceName").focus();
                      }
                      // else
                      if (e.key === "ArrowDown") {
                        const searchPopup = document.getElementById("stockistList");
                        if (searchPopup.firstChild) {
                          searchPopup.firstChild.focus();
                        }
                      }
                    }}
                    onChange={filterStockist}
                    type='text' name="serStockName" id="serStockName" placeholder='Search by Stockist/CFA Name...' autoFocus />
                </div>

                <div className='StockSearch' style={{ minWidth: '30%', marginLeft: '.5vw', borderRadius: '0px', justifyContent: 'flex-start' }}>
                  <img style={{ minWidth: '3%', height: '2%' }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
                  <input style={{ minWidth: '88%', fontSize: '14px', fontStyle: 'italic', paddingLeft: '2px', margin: '0px', lineHeight: '.5px',padding: "4px" }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        document.getElementById("stockistList").classList.replace("show", "hide");
                        custDateRef.current.focus();
                      }
                      // if (e.key === "ArrowDown") {
                      //   const searchPopup = document.getElementById("stockistList");
                      //   if (searchPopup.firstChild) {
                      //   searchPopup.firstChild.focus();
                      //   }
                      // }
                    }}
                    // onChange={filterStockist}  value={selStockist[1]}
                    type='text' name="serInvoiceName" id="serInvoiceName" placeholder='Search by Invoice Number..' tabIndex={0} />
                </div>
              </div>
              <div style={{ backgroundColor: 'rgb(252, 252, 252)', zIndex: '1', width: '40vw', border: '1px solid gray', position: 'absolute' }} className="stockistList hide" id="stockistList">
                {
                  stockistFillData ?
                    stockistFillData.map((stockist) =>
                      <div style={{ margin: '0px', paddingLeft: '.5vw' }} className='searchResModal' tabIndex="3"
                        onClick={() => {
                          setSelStockistQry(stockist);
                          document.getElementById("serStockName").value = stockist[1];
                          document.getElementById("stockistList").classList.replace("show", "hide");
                        }}

                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setSelStockistQry(stockist);
                            document.getElementById("stockistList").classList.replace("show", "hide");
                            document.getElementById("serStockName").value = stockist[1];
                            document.getElementById("serInvoiceName").focus();
                          }
                          else
                            if (e.key === "ArrowUp") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.previousElementSibling) {
                                focusedInput.previousElementSibling.focus();
                              }
                            }
                            else
                              if (e.key === "ArrowDown") {
                                const focusedInput = document.activeElement;
                                if (focusedInput.nextElementSibling) {
                                  focusedInput.nextElementSibling.focus();
                                }
                              }
                        }}
                      >
                        {stockist[1]} {"-("} {stockist[0]} {")"}
                      </div>
                    )
                    : null
                }
              </div>
            </>
            : null
          }

          <div style={{ overflowX: 'hidden', height: '47vh', overflowY: 'scroll', marginBottom: '4vw', }} className='customScroll'>

            <div style={{ display: 'flex', marginTop: '.5vw', justifyContent: 'space-between' }}>
              {purchBookQueryToggle ?
                <>
                  <span id="custDate" style={{ minWidth: '30%', marginLeft: '.5vw' }}>
                    <Select ref={custDateRef}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          payModeRef.current.focus();
                        }
                      }} options={custDateOptions} onChange={handleCustDate} isSearchable={true} placeholder={"Custom Date"} />
                  </span>

                  <span id="payMode" style={{ minWidth: '30%', marginLeft: '.5vw' }}>
                    <Select ref={payModeRef}
                    
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          billRangeRef.current.focus();
                        }
                      }} options={[{ value: "Cash", label: 'Cash' }, { value: "Credit", label: 'Credit' }, { value: "Both", label: 'Both' }]} isSearchable={true}
                      defaultValue={{ value: "Both", label: 'Both' }} onChange={handlePayMode} placeholder="Pay Mode" className='select' />
                  </span>

                  <span id="billRange" style={{ minWidth: '30%', marginLeft: '.5vw' }}>
                    <Select ref={billRangeRef}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          execPurQuery();
                        }
                      }} options={[
                        { value: "f1_1k", label: 'From 1 - 1000' },
                        { value: "f1_5k", label: 'From 1001 - 5000' },
                        { value: "f5_10k", label: 'From 5001 - 10000' },
                        { value: "f10_25k", label: 'From 10000 - 25000' },
                        { value: "f25_50k", label: 'From 25000 - 50000' },
                        { value: "f50_1lac", label: 'From 50000 - 100000' },
                        { value: "f1lac", label: 'Above Rs 100000' },
                      ]} onChange={handleInvoiceRange} isSearchable={true} placeholder="Bill Range" />
                  </span>
                </>
                :
                <div style={{ minWidth: '100%' }}>
                  <div style={{ minWidth: '100%', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'flex-start' }}>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Date
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Invoice No
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Pay Mode
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '26%', textAlign: 'left',paddingLeft:"10px" }}>
                      Party Name
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '12%' }}>
                      Invoice Value
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '12%' }}>
                      Taxable Value
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Tax
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Tax Free
                    </div>
                  </div>

                  <div className='customScroll' id="purcRecDiv"
                    tabIndex={0} onKeyDown={(e) => {
                      if (e.key === "ArrowDown") {
                        const focusedInput = document.activeElement;
                        if (focusedInput.firstChild) {
                          focusedInput.firstChild.focus();
                        }
                      }
                    }} autoFocus>

                    {/* style={{background:index%2 === 1 ? "#f3f3f3" : "#fff"}} */}
                    {purchaseBookData ?
                      purchaseBookData.map((data, index) => {
                        return (
                          <div className={index % 2 === 1 ? 'purchBookRecordGray' : 'purchBookRecord'}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                getTransactionDetails(data[0], 0);
                              }
                              else
                                if (e.key === "ArrowUp") {
                                  const focusedInput = document.activeElement;
                                  if (focusedInput.previousElementSibling) {
                                    focusedInput.previousElementSibling.focus();
                                  }
                                }
                                else
                                  if (e.key === "ArrowDown") {
                                    const focusedInput = document.activeElement;
                                    if (focusedInput.nextElementSibling) {
                                      focusedInput.nextElementSibling.focus();
                                    }
                                  }
                            }} tabIndex={1} onClick={() => {
                              getTransactionDetails(data[0], 0);
                            }}>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {data[1] !== null ? data[1].substring(0, 10) : data[1]}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {data[3]}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {data[5]}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '26%', textAlign: 'left' }}>
                              {data[6]}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '12%' }}>
                              {Number(data[7]).toFixed(2)}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '12%' }}>
                              {Number(data[4]).toFixed(2)}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {Number(data[8]).toFixed(2)}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {'0'}
                            </div>
                          </div>
                        )
                      }
                      )
                      : null
                    }
                  </div>
                </div>
              }
            </div>
          </div>

          <div style={{
            borderBottom: '3px solid rgb(61, 197, 238)', display: 'flex',
            width: '100%', backgroundColor: 'rgb(12, 12, 12)', position: 'absolute', left: '0px', bottom: '0px', paddingTop: '1vw', paddingBottom: '1vw'
          }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingLeft:'5px' }} >
              {purchBookQueryToggle === false ?
                <>
                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Invoice Value</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>INR {Math.round(purBookTot[1])}</div>
                  </div>
                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Taxable Value</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>INR {Math.round(purBookTot[0])}</div>
                  </div>

                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Tax</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>INR {Math.round(purBookTot[2])}</div>
                  </div>
                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Tax Free Value</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>INR {Math.round(purBookTot[3])}</div>
                  </div>

                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Invoices</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>{purBookTot[4]}</div>
                  </div>
                </>
                : null}

              <button style={{ minWidth: '10%', backgroundColor: 'rgb(61, 197, 238)', paddingLeft: '4vw', paddingRight: '4vw', borderRadius: '5px',height:"40px" }} type='submit' onClick={execPurQuery} className='btn btn-primary btn-sm' tabIndex="21"> {purchBookQueryToggle ? "Go" : "Back"} </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* Sales Book Modal */}
      <Modal className='salesBookModal' show={salesBookModal} onHide={salesBookModalClose} size="xl" backdrop="static">
        <Modal.Body>
          {loader ? <Loader /> : null}

          <div className='purchModalHeader'>
            <h5 style={{marginTop:"5px"}}>Sales Book</h5>
            <div style={{ display: 'flex', alignItems:"center" }}>
              {purchBookQueryToggle === false ?
                <>
                  <div><input type='date' name='dateFromPSB' id='dateFromPSB' style={{outline:0,width:"95px"}} className='removeDateIcon text-uppercase border-0' /></div>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  <div><input type='date' name='dateToPSB' id='dateToPSB' style={{outline:0,width:"95px"}} className='removeDateIcon text-uppercase border-0' /></div>
                  <div>&nbsp;&nbsp;&nbsp;</div>
                  
                  <div className='digiNavBtn2 mediumButtons' onClick={() => {
                    purchBookQuery(1);
                  }}>Go</div>

                  {/* <span style={{ fontSize: '1vw', marginTop: '.3vw' }}>Date __/__/____ to __/__/____ &nbsp;&nbsp;&nbsp;&nbsp;</span> */}
                  </>
                : null}
                &nbsp;&nbsp;&nbsp;
              <div className='purchModalCloseBTN' onClick={salesBookModalClose}>
                <img style={{ width: '2vw', height: '2vw' }} src={require('../../../static/images/Icons/close1.png')} alt="" />
              </div>
            </div>
          </div>

          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          {purchBookQueryToggle ?
            <>
              <div className='flex' style={{display:"flex",alignItems:"center",gap:"10px"}}>
                <div className='StockSearch flex' style={{width:"49%"}}>
                  <img style={{ minWidth: '2%', height: '2%' }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
                  <input style={{ minWidth: '100%', fontSize: '14px', fontStyle: 'italic', paddingLeft: '2px', margin: '0px', lineHeight: '.5px',padding:"4px" }} onFocus={populateStockist}
                    onKeyDown={(e) => {
                      // if (e.keyCode === 113) {
                      //   stockistModalShow(1);
                      // }
                      filterStockist();
                      document.getElementById("stockistList").classList.replace("hide", "show");
                      if (e.key === "Enter") {
                        document.getElementById("stockistList").classList.replace("show", "hide");
                        document.getElementById("serInvoiceName").focus();
                      }
                      // else
                      if (e.key === "ArrowDown") {
                        const searchPopup = document.getElementById("stockistList");
                        if (searchPopup.firstChild) {
                          searchPopup.firstChild.focus();
                        }
                      }
                    }}
                    onChange={filterStockist}
                    type='text' name="serStockName" id="serStockName" placeholder='Search by Stockist/CFA Name...' autoFocus />
                  {/* <span style={{ minWidth: '8%', margin: '0px', fontSize: '1.2vw' }}>| + </span> */}
                </div>

                <div className='StockSearch flex mt-3 mb-3' style={{width:"49%"}}>
                  <img style={{ minWidth: '2%', height: '2%' }} src={require('../../../static/DPImages/Icons/search.png')} alt="" />
                  <input style={{ minWidth: '100%', fontSize: '14px', fontStyle: 'italic', paddingLeft: '2px', margin: '0px', lineHeight: '.5px',padding:"4px" }}
                    // onFocus={alert("Searching Invoice")}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        document.getElementById("stockistList").classList.replace("show", "hide");
                        custDateRef.current.focus();
                      }
                      // if (e.key === "ArrowDown") {
                      //   const searchPopup = document.getElementById("stockistList");
                      //   if (searchPopup.firstChild) {
                      //   searchPopup.firstChild.focus();
                      //   }
                      // }
                    }}
                    // onChange={filterStockist}  value={selStockist[1]}
                    type='text' name="serInvoiceName" id="serInvoiceName" placeholder='Search by Invoice Number..' />
                </div>
              </div>
              <div style={{ marginLeft: '1vw', backgroundColor: 'rgb(252, 252, 252)', zIndex: '1', width: '40vw', border: '1px solid gray', position: 'absolute' }} className="stockistList hide" id="stockistList">
                {
                  stockistFillData ?
                    stockistFillData.map((stockist) =>
                      <div style={{ margin: '0px', paddingLeft: '.5vw' }} className='searchResModal' tabIndex="3"
                        onClick={() => {
                          setSelStockistQry(stockist);
                          document.getElementById("serStockName").value = stockist[1];
                          document.getElementById("stockistList").classList.replace("show", "hide");
                        }}

                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setSelStockistQry(stockist);
                            document.getElementById("stockistList").classList.replace("show", "hide");
                            document.getElementById("serStockName").value = stockist[1];
                            document.getElementById("serInvoiceName").focus();
                          }
                          else
                            if (e.key === "ArrowUp") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.previousElementSibling) {
                                focusedInput.previousElementSibling.focus();
                              }
                            }
                            else
                              if (e.key === "ArrowDown") {
                                const focusedInput = document.activeElement;
                                if (focusedInput.nextElementSibling) {
                                  focusedInput.nextElementSibling.focus();
                                }
                              }
                        }}
                      >
                        {stockist[1]} {" -("} {stockist[0]} {")"}
                      </div>
                    )
                    : null
                }
              </div>
            </>
            : null
          }

          <div style={{ overflowX: 'hidden', height: '50vh', overflowY: 'scroll', marginBottom: '4vw', }} className='customScroll'>

            <div style={{ display: 'flex', marginTop: '.5vw', justifyContent: 'space-between',marginLeft:"-6px"}}>
              {purchBookQueryToggle ?
                <>
                  <span id="custDate" style={{ minWidth: '30%', marginLeft: '.5vw' }}>
                    <Select ref={custDateRef}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          payModeRef.current.focus();
                        }
                      }} options={custDateOptions} onChange={handleCustDate} isSearchable={true} placeholder={"Custom Date"} />
                  </span>

                  <span id="payMode" style={{ minWidth: '30%', marginLeft: '.5vw' }}>
                    <Select ref={payModeRef}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          billRangeRef.current.focus();
                        }
                      }} options={[{ value: "Cash", label: 'Cash' }, { value: "Credit", label: 'Credit' }, { value: "Both", label: 'Both' }]} isSearchable={true}
                      onChange={handlePayMode} placeholder="Pay Mode" defaultValue={{ value: "Both", label: 'Both' }} />
                  </span>

                  <span id="billRange" style={{ minWidth: '30%', marginLeft: '.5vw' }}>
                    <Select ref={billRangeRef}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          execPurQuery();
                        }
                      }} options={[
                        { value: "f1_1k", label: 'From 1 - 1000' },
                        { value: "f1_5k", label: 'From 1001 - 5000' },
                        { value: "f5_10k", label: 'From 5001 - 10000' },
                        { value: "f10_25k", label: 'From 10000 - 25000' },
                        { value: "f25_50k", label: 'From 25000 - 50000' },
                        { value: "f50_1lac", label: 'From 50000 - 100000' },
                        { value: "f1lac", label: 'Above Rs 100000' },
                      ]} onChange={handleInvoiceRange} isSearchable={true} placeholder="Bill Range" />
                  </span>
                </>
                :
                <div style={{ minWidth: '100%' }}>
                  <div style={{ minWidth: '100%', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'flex-start' }}>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Date
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Invoice No
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Pay Mode
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '26%', textAlign: 'left',paddingLeft:"10px" }}>
                      Party Name
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '12%' }}>
                      Invoice Value
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '12%' }}>
                      Taxable Value
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Tax
                    </div>
                    <div className='purchBookRowHead' style={{ minWidth: '10%' }}>
                      Tax Free
                    </div>
                  </div>

                  <div className='customScroll' id="purcRecDiv"
                    tabIndex={0} onKeyDown={(e) => {
                      if (e.key === "ArrowDown") {
                        const focusedInput = document.activeElement;
                        if (focusedInput.firstChild) {
                          focusedInput.firstChild.focus();
                        }
                      }
                    }} autoFocus>

                    {purchaseBookData ?
                      purchaseBookData.map((data, index) => {
                        return (
                          <div className={index % 2 === 1 ? 'purchBookRecordGray' : 'purchBookRecord'}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                // alert("Retrieving Sales invoice");
                                getTransactionDetails(data[0], 1);
                              }
                              else
                                if (e.key === "ArrowUp") {
                                  const focusedInput = document.activeElement;
                                  if (focusedInput.previousElementSibling) {
                                    focusedInput.previousElementSibling.focus();
                                  }
                                }
                                else
                                  if (e.key === "ArrowDown") {
                                    const focusedInput = document.activeElement;
                                    if (focusedInput.nextElementSibling) {
                                      focusedInput.nextElementSibling.focus();
                                    }
                                  }
                            }} tabIndex={1} onClick={() => getTransactionDetails(data[0], 1)}>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {data[1] !== null ? data[1].substring(0, 10) : data[1]}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {data[3]}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {data[5]}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '26%', textAlign: 'left' }}>
                              {data[6]}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '12%' }}>
                              {Number(data[7]).toFixed(2)}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '12%' }}>
                              {Number(data[4]).toFixed(2)}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {Number(data[8]).toFixed(2)}
                            </div>
                            <div className='purchBookRow' style={{ minWidth: '10%' }}>
                              {'0'}
                            </div>
                          </div>
                        )
                      }
                      )
                      : null
                    }
                  </div>
                </div>
              }
            </div>
          </div>

          <div style={{
            borderBottom: '3px solid rgb(61, 197, 238)', display: 'flex',
            width: '100%', backgroundColor: 'rgb(12, 12, 12)', position: 'absolute', left: '0px', bottom: '0px', paddingTop: '1vw', paddingBottom: '1vw'
          }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingInline:'5px' }} lg={12} md={12} xl={12} sm={12} xs={12} >
              {purchBookQueryToggle === false ?
                <>
                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Invoice Value</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>INR {Math.round(purBookTot[1])}</div>
                  </div>
                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Taxable Value</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>INR {Math.round(purBookTot[0])}</div>
                  </div>

                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Tax</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>INR {Math.round(purBookTot[2])}</div>
                  </div>
                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Tax Free Value</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>INR {Math.round(purBookTot[3])}</div>
                  </div>

                  <div style={{ minWidth: '17%', display: 'block' }}>
                    <div style={{ fontSize: '.9vw', color: 'white' }}>Total Invoices</div>
                    <div style={{ fontSize: '1.2vw', color: 'white' }}>{purBookTot[4]}</div>
                  </div>
                </>
                : null}
              <button style={{ minWidth: '10%', backgroundColor: 'rgb(61, 197, 238)', paddingLeft: '4vw', paddingRight: '4vw', borderRadius: '5px',height:"40px" }} type='submit' onClick={execPurQuery} className='btn btn-primary btn-sm' tabIndex="21"> {purchBookQueryToggle ? "Go" : "Back"} </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DigiPartnerSidePanel