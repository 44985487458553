import React from 'react'

const SocialNetworkLovedPlateform = () => {
  return (
    <>
      <div className='row pt-2 topPartition'>
        <div className="col-12 text-center socHead">
          <h4 style={{ color: 'grey' }}>Social Networks</h4>
        </div>
      </div>
      <div className='row'>
        <div className="col-12 text-center socTitle">
          <h5 style={{ color: 'grey' }}>Follow us and keep up to date with the freshest news!</h5>
        </div>
      </div>
      <div className="row d-flex mt-4">
        <div className="col-2 socNet">
          <img className="socImg1" src={require('../../static/images/SocialNetworks/facebook.png')} alt="..." />
          <h6>Facebook</h6>
        </div>
        <div className="col-2 socNet border border-2 border-top-0 border-bottom-0">
          <img className="socImg2" src={require('../../static/images/SocialNetworks/instagram.png')} alt="..." />
          <h6>Instagram</h6>
        </div>
        <div className="col-2 socNet">
          <img className="socImg3" src={require('../../static/images/SocialNetworks/linkedin.png')} alt="..." />
          <h6>Linkedin</h6>
        </div>
        <div className="col-2 socNet border border-2 border-top-0 border-bottom-0">
          <img className="socImg4" src={require('../../static/images/SocialNetworks/twitter.png')} alt="..." />
          <h6>Twitter</h6>
        </div>
        <div className="col-2 socNet">
          <img className="socImg5" src={require('../../static/images/SocialNetworks/youtube.png')} alt="..." />
          <h6>YouTube</h6>
        </div>
      </div>
      <div className='row text-center mediaCov pt-2 topPartition'>
        <h4>India's most loved healthcare Platform</h4>
      </div>
      <div className="socMedHandler row text-center mt-2 mb-2">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
          <b><h4>21K +</h4></b>
          <p>Pincode Reach</p>
        </div>
        <div className="border border-2 border-top-0 border-bottom-0 col-12 col-sm-12 col-md-4 col-lg-4">
          <b><h4>60K +</h4></b>
          <p>Order Delivered</p>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4">
          <b><h4>1600 +</h4></b>
          <p>City Coverage</p>
        </div>
      </div>
    </>
  )
}
export default SocialNetworkLovedPlateform