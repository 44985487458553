import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ startTime }) => {
  const [endTime, setEndTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [timerId, setTimerId] = useState(null);

  // Parse start time string into Date object
  useEffect(() => {
    const parsedStartTime = new Date(startTime);
    const end = new Date(parsedStartTime.getTime() + 10 * 60000); // 10 minutes in milliseconds
    setEndTime(end);

    // Clean up timeout when component unmounts or when start time changes
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [startTime]);

  // Update remaining time every second
  useEffect(() => {
    if (!endTime) return; // If endTime is not set yet, do nothing

    const updateRemainingTime = () => {
      const now = new Date();
      const timeDiff = endTime - now;

      if (timeDiff <= 0) {
        // Timer has ended
        setRemainingTime('Time Out');
        clearTimeout(timerId); // Clear the interval
      } else {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        setRemainingTime("Time left - "+formattedTime);
      }
    };

    // Initial call to updateRemainingTime to update immediately after mounting
    updateRemainingTime();

    // Set up interval to update remaining time every second
    const timer = setInterval(updateRemainingTime, 1000);
    setTimerId(timer);

    // Clean up interval on component unmount or when endTime changes
    return () => {
      clearInterval(timer);
    };
  }, [endTime, timerId]);

  return (
    <div>
      {remainingTime !== null ? (
        <p>{remainingTime}</p>
      ) : (
        <p>Calculating remaining time...</p>
      )}
    </div>
  );
};

export default CountdownTimer;
