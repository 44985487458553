import React, { useEffect, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { ReactSession } from 'react-client-session';
import { useNavigate } from 'react-router-dom';
import { LuSearch } from 'react-icons/lu';

const ManageStockist = () => {
  const navigate = useNavigate();

  // const [vendorType, setVendorType] = useState(1);
  const [distComp, setDistComp] = useState(null);
  const [stockistData, setStockistData] = useState(null);

  const [cities, setCities] = useState([{ key: 0, value: 0, label: 'None' },]);
  const [states, setStates] = useState([{ key: 0, value: 0, label: 'None' },]);

  const [filterCityPer, setFilterCityPer] = useState(null);
  const [selectedCityPer, setCityPer] = useState(cities.label);
  const handleSelectCity = e => {
    setCityPer(e.value);
    const optionToSelect = cities.find((option) => option.value === e.value);
    setFilterCityPer(optionToSelect);
    // console.log("Slected Option :",optionToSelect);
  }

  const [filterStatePer, setFilterStatePer] = useState(null);
  const [selectedStatePer, setStatePer] = useState(states.label);
  const handleSelectState = e => {
    setStatePer(e.value);
    const optionToSelect = states.find((option) => option.value === e.value);
    setFilterStatePer(optionToSelect);
    // console.log("Slected Option :",optionToSelect);
  }


  // Select Styles
  const selectStyle = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      background: "white",
      border: '1px solid gray',
      lineHeight: '.5rem',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      paddingTop: '-20px',
      height: '20px',
      marginTop: '.4rem',
      minHeight: '30px',
      border: filterCityPer !== null ? '1px solid gray' : '2px solid orangered',
    }),

    input: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginTop: '-5px',
    }),
  }

  // editRec
  const [editStockist, setEditStockist] = useState(null);

  const [linkProducts, setLinkProducts] = useState([]);

  const [linkModal, setLinkModal] = useState(false);
  const linkModalClose = () => {
    setEditStockist(null);
    setLinkModal(false);
    setLinkProducts([]);
    GetStockist();
  }

  const linkModalOpen = (stockist) => {
    setEditStockist(stockist);
    setDefCate(null);
    fetch("http://43.205.199.21/digiPartnerAPI?linkstockist=" + stockist[0], {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      setDefCate(res.link_comp);
      setLinkProducts(res.link_prod)
    }).then(() => {
      setLinkModal(true);
    }
    ).catch((err) => {
      console.log(err.message);
    });

  }

  const [defCate, setDefCate] = useState(null);

  //For Filter Array of object Multi Select List
  var [selectComp, setSelectComp] = useState([]);
  var handleSelect = (e) => {
    setSelectComp(Array.isArray(e) ? e.map(x => x.label) : []);
  }

  const [addStockistModal, setAddStockistModal] = useState(false);
  const addStockistModalClose = () => {
    setEditStockist(null);
    setAddStockistModal(false);
  }

  const addStockistModalOpen = (stockist = null) => {
    if (stockist !== null) {
      const cityD = cities.find((option) => option.label?.toLowerCase() === stockist[4]?.toLowerCase());
      setFilterCityPer(cityD);
      const stateD = states.find((option) => option.label?.toLowerCase() === stockist[5]?.toLowerCase());
      setFilterStatePer(stateD);
      setCityPer(stockist[28]);
      setStatePer(stockist[29]);
      setEditStockist(stockist);
    }
    setAddStockistModal(true);
  }

  const [page, setPage] = useState(1);;
  const [pagesize, setPageSize] = useState(25);
  const [noofRec, setNoofRec] = useState(0);

  const [searchProdData, setSearchProdData] = useState(null);

  // Utility Methods
  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  const removeSelectedLinkProd = (prodCode) => {
    const fillItems = linkProducts.filter(linkPro => linkPro[0] !== prodCode);
    setLinkProducts(fillItems);
  }

  function clearSearch() {
    var ser = document.getElementById("searchVenProd");
    if (ser !== null) {
      ser.value = '';
    }
    document.getElementById("cleanSearch").classList.replace("show", "hide");
    document.getElementById("searchProList2").classList.replace("show", "hide");
  }

  function populateSearch() {
    var searchText = document.getElementById("searchVenProd");
    var ProductContainer = document.getElementById("searchProList2");
    document.getElementById("cleanSearch").classList.replace("hide", "show");
    if (searchText.value.length > 2) {
      var serProdURL = "http://43.205.199.21/selectProduct?sertext=" + searchText.value
      setTimeout(
        function () {
          ProductContainer.classList.replace("hide", "show");
          fetch(serProdURL, {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          }).then((response) => {
            return response.json();
          }).then((resp) => {
            setSearchProdData(resp.data);
          });
        }, 2000)
    }
    else {
      ProductContainer.classList.replace("show", "hide");
    }
  }


  const LinkStockistProducts = () => {
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        link_stockist_id: editStockist ? editStockist[0] : null,
        selectComp: selectComp,
        linkProducts: linkProducts,
        freezProd: document.getElementById('freezProd').checked ? "Yes" : "No",
      })
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Companies/Products successfully linked");
        linkModalClose();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  const deleteRecord = (coCode) => {
    var confDel = prompt("Enter admin password for confirm deletion :");
    if (confDel === 'secret') {
      fetch('http://43.205.199.21/digiPartnerAPI/', {
        mode: 'cors',
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          stockist_id: coCode,
        })
      }).then((response) => {
        response.json();
      })
        .then((data) => {
          GetStockist();
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Deletion request denied..!")
  }

  const addStockist = () => {
    fetch('http://43.205.199.21/digiPartnerAPI/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        stockName: document.getElementById('stockName').value,
        stockist_id: editStockist ? editStockist[0] : null,
        gstin: document.getElementById('gstin').value,
        dlNo: document.getElementById('dlNo').value,
        dlNo2: document.getElementById('dlNo2').value,
        email: document.getElementById('eMail').value,
        mobile: document.getElementById('mobile').value,
        mobile2: document.getElementById('mobile2').value,
        address1: document.getElementById('address1').value,
        address2: document.getElementById('address2').value,
        city: editStockist && editStockist[0]?.substring(0, 2) === 'DP' ? filterCityPer.value : filterCityPer?.label,
        state: editStockist && editStockist[0]?.substring(0, 2) === 'DP' ? filterStatePer.value : filterStatePer?.label,
        pincode: document.getElementById('pincode').value,
        stdCode: document.getElementById('stdCode').value,
        landline1: document.getElementById('landline1').value,
        landline2: document.getElementById('landline2').value,
        landline3: document.getElementById('landline3').value,
        ctx1: document.getElementById('ctx1').value,
        ctx2: document.getElementById('ctx2').value,
        ctx3: document.getElementById('ctx3').value,
        fssai: document.getElementById('fssai').value,
      })
    })
      .then((response) => response.json())
      .then((data) => {
        if (editStockist !== null)
          alert("Stockist/PFA Successfully Updated");
        else
          alert("Stockist/PFA Successfully Added");
        setEditStockist(null);
        addStockistModalClose();
        GetStockist();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  //Validation add stockist/pfs name form  
  const validatRegForm = (stid = null) => {
    console.log("Validate Running...!");
    var errorElements = [];
    var dlNo = document.getElementById("dlNo").value;
    var dlNo2 = document.getElementById("dlNo2").value;
    var gstNo = document.getElementById("gstin").value;

    var apiURL = 'http://43.205.199.21/digiPartnerAPI?dlno=' + dlNo + '&dlNo2=' + dlNo2 + '&gstNo=' + gstNo;
    if (editStockist !== null)
      apiURL += '&stock_id='+String(stid);

    fetch(apiURL, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((resp) => {

      // stockName gstin dlNo dlNo2 mobile address1
      if (document.getElementById("stockName").value === "")
        errorElements.push("stockName");

      // if (document.getElementById("gstin").value === "")
      //   errorElements.push("gstin");

      if (document.getElementById("dlNo").value === "")
        errorElements.push("dlNo");

      if (document.getElementById("dlNo2").value === "")
        errorElements.push("dlNo2");

      if (document.getElementById("address1").value === "")
        errorElements.push("address1");

      if (document.getElementById("pincode").value === "")
        errorElements.push("pincode");

      if (document.getElementById("mobile").value === "")
        errorElements.push("mobile");
      else
        if (document.getElementById("mobile").value.length < 10 || isNaN(document.getElementById("mobile").value)) {
          errorElements.push("mobile");
          document.getElementById("errorMessage").innerHTML += "<br />Mobile must contain 10 digits";
        }

      // if (editStockist === null) {
      //   if (resp.dlno > 0) {
      //     errorElements.push("dlno");
      //     errorElements.push("dlno2");
      //     document.getElementById("errorMessage").innerHTML += "<br />Drug License number already exist..!";
      //   }
        
      //   if (resp.gstNo > 0) {
      //     errorElements.push("gstin");
      //     document.getElementById("errorMessage").innerHTML += "<br />GSTIN already exist..!";
      //   }
      // }

      if (resp.dlno > 0) {
        errorElements.push("dlNo");
        errorElements.push("dlNo2");
        document.getElementById("errorMessage").innerHTML += "<br />Drug License number already exist..!";
      }
      
      if (resp.gstNo > 0) {
        errorElements.push("gstin");
        document.getElementById("errorMessage").innerHTML += "<br />GSTIN already exist..!";
      }

      if (document.getElementById("pincode").value === "")
        errorElements.push("pincode");

      if (document.getElementById("mobile").value === "")
        errorElements.push("mobile");
      else
        if (document.getElementById("mobile").value.length < 10 || isNaN(document.getElementById("mobile").value)) {
          errorElements.push("mobile");
          document.getElementById("errorMessage").innerHTML += "<br />Mobile must contain 10 digits";
        }

      errorElements.forEach(showError);
      function showError(item) {
        console.log("Error Field :",item);
        document.getElementById(item).classList.add("errorField");
      }

      if (errorElements.length > 0) {
        document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
        document.getElementById(errorElements[0]).focus();
        return false;
      }
      else {
        document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
        addStockist();
      }
    });
  }

  // Page Navigattion Methods
  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
  }


  const GetStockist = () => {
    var stxt = document.getElementById('sertext').value;

    var getStoURL = "http://43.205.199.21/digiPartnerAPI?stockist=1&page=" + page + "&pagesize=" + pagesize;
    if (stxt.length > 2)
      getStoURL += "&searchStoTxt=" + stxt;

    fetch(getStoURL, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      setDistComp(res.dist_comp);
      setStockistData(res.distributers);
      // setStockistFillData(res.distributers);
      document.getElementById('pageNo').placeholder = page;
      setNoofRec(res.noofRec[0]);

      if (res.status == "success") {
        fetch("http://43.205.199.21/citiesAPI", {
          method: 'GET',
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
        }).then((respo) => {
          return respo.json()
        }).then((result) => {
          // console.log("No of Cities :",result.citiesD.length);
          setCities(result.citiesD);
          setStates(result.statesD);
        });
      }
    });
  }

  useEffect(() => {
    if (!ReactSession.get('adminSheet360ERP')) {
      navigate('/Admin');
    }
    else {
      GetStockist();
    }
  }, [page]);

  return (
    <div>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents' id='dashContents'>
          <div className='pendOrders' style={{ width: '100%', zIndex: '1', position: "unset" }}>
            <Row style={{ flexWrap: "nowrap",marginTop:10,padding:"10px 0px" }}>
              <Col style={{ width: "63.3%" }} xl={8} sm={6} xs={6}>
                <h3 style={{ fontSize: 30, fontWeight: 600, marginLeft: 20 }}>Manage Stockist</h3>
              </Col>
              <Col style={{ position: "relative", paddingLeft: 0, paddingRight: 0, marginBottom: 5, display: "flex", alignItems: "center", background: "#E8E8E8", height: "fit-content", marginRight: 50, borderRadius: 10, float: "right" }} md={5} lg={4} xl={4} sm={6} xs={6}>
                <input placeholder='Search Here' style={{ width: '75%', background: "#E8E8E8", border: "none", padding: "10px", flex: 1, border: 0, outline: 0, borderRadius: 10 }} type='text' name="sertext" id="sertext" defaultValue={''} onKeyDown={(e) => { if (e.key === "Enter") { GetStockist(); } }} />
                <i style={{ position: "absolute", fontSize: 20, display: "flex", alignItems: "center", justifyContent: "center", right: 0, top: 0, width: 70, minHeight: "100%", background: "#000", color: "#fff", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}><LuSearch /></i>
              </Col>
            </Row>
            
            <Row className="getDataHeader" style={{ width: "96%", margin: "0px auto", marginLeft: 18, paddingLeft: 15 }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={4} lg={4} xl={4} sm={6} xs={6}>
                Distributors Details
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={3} lg={3} xl={3} sm={6} xs={6}>
                Contact Details
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6} >
                Email Address
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} md={2} lg={2} xl={2} sm={6} xs={6} >
                <div>Companies | Products</div>
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }} md={1} lg={1} xl={1} sm={6} xs={6} >
                Action
              </Col>
            </Row>
          </div>
          <div className='allPenOrders couponContainer' id='couponContainer' style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
            {stockistData ?

              stockistData.map((stockist, index) => {
                {
                  return <>
                    <Row className='pendOrderRows' style={{ paddingBlock: '10px', backgroundColor:'white'}}>
                      <Col style={{ fontSize: '90%' }} md={4} lg={4} xl={4} sm={6} xs={6}>
                        {/* { page > 1 ?
                        index += (((page-1)*25) +1):
                        index+1 
                      } {': '}  */}
                        <div style={{ fontSize: '120%', fontWeight: 'bold',color:"#000" }}>
                          {stockist[1]}
                        </div>
                        <div>
                          {stockist[2]}, {stockist[3]}
                        </div>
                        <div>
                          {stockist[4]} , {stockist[5]} - {stockist[7]}
                        </div>
                      </Col>
                      <Col style={{ paddingLeft: 0, paddingRight: 0, fontSize: '90%', textAlign: "center" }} md={3} lg={3} xl={3} sm={6} xs={6}>
                        {stockist[8]}, {stockist[9]}
                      </Col>
                      <Col style={{ paddingLeft: 0, paddingRight: 0, fontSize: '90%', textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6} >
                        {stockist[10]}
                      </Col>
                      <Col style={{ paddingLeft: 0, paddingRight: 0, fontSize: '90%', textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6} >
                        {stockist[25]} | {stockist[26]}
                      </Col>
                      <Col style={{ paddingLeft: 0, paddingRight: 0, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6} >
                        <div style={{ textAlign: "center" }}>
                          <img style={{ width: "25px" }} className="catActionBtn2" onClick={() => { linkModalOpen(stockist) }} src={require('../../static/images/Icons/link.png')} alt="" data-toggle="tooltip" data-placement="top" title="Link" />
                          &nbsp;&nbsp;&nbsp;
                          <img style={{ width: "25px" }} className="catActionBtn2" onClick={() => { addStockistModalOpen(stockist) }} src={require('../../static/images/edit.png')} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                          &nbsp;&nbsp;&nbsp;
                          <img style={{ width: "25px" }} className="catActionBtn2" onClick={() => deleteRecord(stockist[0])} src={require('../../static/images/trash.png')} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                        </div>
                        <div style={{ fontSize: '70%', textAlign: "center", marginTop: 10 }}>Last Updated</div>
                        {
                          stockist[15] === null ?
                            <div style={{ fontSize: '60%', textAlign: "center" }}>{stockist[14]?.split('T')[0]} | {stockist[14]?.split('T')[1]} </div>
                            :
                            <div style={{ fontSize: '60%', textAlign: "center" }}>{stockist[15]?.split('T')[0]} | {stockist[15]?.split('T')[1]} </div>
                        }
                      </Col>
                    </Row>
                  </>
                }
              })
              : null
            }
          </div>

          <div className="OrderPagination prodPagination" style={{ fontSize: '115%', background: "#fff", marginTop: '.3rem', marginBottom: '.3rem', width: "96%", margin: "0px auto", marginLeft: 18, padding: "10px 0px" }}>
              <div style={{ width: '33%', display: 'flex', justifyContent: 'center', height: '2rem', margin: "0 auto", height: "60px" }}>
                <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} > Top </button>&nbsp;&nbsp;
                <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
                <input size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
                <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
                <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
              </div>
          </div>

          <div className='addNewCat' style={{ border: 0, outline: 0 }} onClick={() => { addStockistModalOpen() }}>
            <img style={{ width: "70px", height: "70px", borderRadius: "50%" }} src={require('../../static/AdminPanelImages/add.gif')} alt="" />
          </div>
          <div className='copyRight'>
            Sheet360ERP Copyright &copy; 2023:  All Right Reserved
          </div>
        </div>
      </div>

      {/* Add/Edit Stockist Modal */}
      <Modal show={addStockistModal} onHide={addStockistModalClose} size="lg" backdrop="static" keyboard={false} >
        <Modal.Header closeButton className='purchModalheader'>
          <Modal.Title>{editStockist ? 'Edit' : 'Add'} Stockist/CFA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <form name="addStockist" action="" method="post"> */}
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="dpLogin" style={{ display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/user.png')} alt="" />
                <input className="digiPartLoginInput" style={{fontSize:14}} name="stockName" id="stockName" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("gstin").focus();
                    }
                  }} defaultValue={editStockist ? editStockist[1] : ''}
                  onKeyPress={() => { removeErrorMessage("stockName") }} placeholder="Business Name...." autoFocus />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="dpLogin" style={{ display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/gstin.png')} alt="" />
                <input className="digiPartLoginInput" style={{fontSize:14}} name="gstin" id="gstin" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("dlNo").focus();
                    }
                  }}

                  defaultValue={editStockist ? editStockist[12] : ''}
                  onKeyPress={() => { removeErrorMessage("gstin") }} placeholder="GSTIN...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/druglicense.png')} alt="" />
                <input className="digiLogin digiPartLoginInput" style={{fontSize:14}} name="dlNo" id="dlNo" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("dlNo2").focus();
                    }
                  }}
                  defaultValue={editStockist ? String(editStockist[11]).split('___')[0] : null}
                  onKeyPress={() => { removeErrorMessage("dlNo") }} placeholder="Drug License No...." /> {'|'}

                <input style={{fontSize:14}} className="digiLogin digiPartLoginInput" name="dlNo2" id="dlNo2" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("eMail").focus();
                    }
                  }}
                  defaultValue={editStockist ? String(editStockist[11]).split('___')[1] : null}
                  onKeyPress={() => { removeErrorMessage("dlNo2") }} placeholder="Drug License No...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="dpLogin" style={{ display: 'flex' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/email.png')} alt="" />
                <input style={{fontSize:14}} className="digiPartLoginInput" name="eMail" id="eMail" type="email"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("stdCode").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[10] : ''}
                  placeholder="E-mail...." />
              </div>
            </div>
          </div>

          {/* Landline */}
          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="ss" style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/smartphone.png')} alt="" />

                <input style={{ float: "left", width: '10%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="stdCode" id="stdCode" type="text" size="4"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("landline1").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[17] : ''}
                  maxLength="10" placeholder="STD Code" /> :

                <input style={{ float: "left", width: '30%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="landline1" id="landline1" type="text" size="4" maxLength="10" inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("landline2").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[18] : ''}
                  onKeyPress={() => { removeErrorMessage("landline1") }} placeholder="Landline 1 Number...." /> {'|'}

                <input style={{ float: "left", width: '30%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="landline2" id="landline2" type="text" size="4" inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("landline3").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[19] : ''}
                  maxLength="10" placeholder="Landline 2 Number...." /> {'|'}

                <input style={{ float: "left", width: '30%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="landline3" id="landline3" type="text" size="4" inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("ctx1").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[20] : ''}
                  maxLength="10" placeholder="Landline 3 Number...." />
              </div>
            </div>
          </div>


          {/* CTX's */}
          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="ss" style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw' }} src={require('../../static/DPImages/Icons/smartphone.png')} alt="" />

                <input style={{ float: "left", width: '33%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="ctx1" id="ctx1" type="text" size="4" maxLength="10" inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("ctx2").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[21] : ''}
                  placeholder="CTX 1...." /> {'|'}

                <input style={{ float: "left", width: '33%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="ctx2" id="ctx2" type="text" size="4" inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("ctx3").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[22] : ''}
                  maxLength="10" placeholder="CTX 2...." />{'|'}

                <input style={{ float: "left", width: '33%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="ctx3" id="ctx3" type="text" size="4" inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("mobile").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[23] : ''}
                  maxLength="10" placeholder="CTX 3...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="ss" style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '1.5vw', height: '3vh', marginTop: '.70vw',fontSize:14 }} src={require('../../static/DPImages/Icons/smartphone.png')} alt="" />
                <input style={{ float: "left",fontSize:14 }} className="digiLogin digiPartLoginInput mobileN" name="mobile" id="mobile" type="text" size="4" maxLength="10" inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("mobile2").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[8] : ''}
                  onKeyPress={() => { removeErrorMessage("mobile") }} placeholder="Primary Contact Number...." /> {'|'}
                <input style={{ float: "left",fontSize:14 }} className="digiLogin digiPartLoginInput mobileN" name="mobile2" id="mobile2" type="text" size="4" maxLength="10" inputMode="numeric"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("address1").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[9] : ''}
                  placeholder="WhatsApp Number...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="" style={{ display: 'flex' }}>
                <input className="digiLogin digiPartLoginInput" style={{fontSize:14}} name="address1" id="address1" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("address2").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[2] : ''}
                  onKeyPress={() => { removeErrorMessage("address1") }} placeholder="Address Line 1...." />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="" style={{ display: 'flex' }}>
                <input style={{fontSize:14}} className="digiLogin digiPartLoginInput" name="address2" id="address2" type="text" placeholder="Address Line 2...."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("pincode").focus();
                    }
                  }} defaultValue={editStockist ? editStockist[3] : ''} />
              </div>
            </div>
          </div>


          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="dd" style={{ display: 'flex', alignItems: 'center' }}>
                <input style={{ float: "left", width: '49%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="pincode" id="pincode" type="text" size="4" maxLength="6"
                  onKeyDown={(e) => {
                    removeErrorMessage('pincode');
                    if (e.key === "Enter" || e.key === "Tab") {
                      document.getElementById("fssai").focus();
                      var locateURL = "http://43.205.199.21/location?pincode=";
                      if (Number(String(e.target.value).length) > 5) {
                        fetch(locateURL + e.target.value, {
                          method: 'GET',
                        }).then((response) => {
                          return response.json()
                        }).then(async (res) => {
                          if (res.status === "success") {
                            var state = (res.state).substring(0, ((res.state).length - 1));
                            // document.getElementById("city").value = res.city;
                            // document.getElementById("state").value = state;
                            const cityD = cities.find((option) => option.label?.toLowerCase() === res.city?.toLowerCase());
                            setFilterCityPer(cityD);
                            const stateD = states.find((option) => option.label?.toLowerCase() === state?.toLowerCase());
                            setFilterStatePer(stateD);
                            setCityPer(cityD.value);
                            setStatePer(stateD.value);
                          }
                        }).catch((err) => {
                          console.log("Error :", err.message);
                        });
                      }
                    }
                  }}

                  defaultValue={editStockist ? editStockist[7] : ''}
                  onKeyPress={() => { removeErrorMessage("pincode") }} placeholder="Postal Code...." /> &nbsp;&nbsp;

                <input style={{ float: "left", width: '49%',fontSize:14 }} className="digiLogin digiPartLoginInput" name="fssai" id="fssai" type="text" size="4"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("city").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[24] : ''}
                  placeholder="FSSAI number...." /> &nbsp;&nbsp;
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: '.1vw' }}>
            <div className="col-12 col-md-12 col-lg-12 col-sm-12">
              <div className="ss" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <input className="digiLogin digiPartLoginInput" name="city" id="city" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      document.getElementById("state").focus();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[4] : ''}
                  onKeyPress={() => { removeErrorMessage("city") }} placeholder="City...." /> */}

                <Select
                  options={cities}
                  id="LocationCity1"
                  // defaultValue={filterCityPer !== null ? filterCityPer :''}
                  value={filterCityPer !== null ? filterCityPer : ''}
                  onChange={handleSelectCity}
                  placeholder="Select City"
                  styles={selectStyle}
                // isDisabled={isEdit ? false : true}
                />

                <Select
                  options={states}
                  id="StateID1"
                  // defaultValue={filStateShip}
                  value={filterStatePer !== null ? filterStatePer : ''}
                  onChange={handleSelectState}
                  placeholder="Select State"
                  styles={selectStyle}
                // isDisabled={isEdit ? false : true}
                />

                {/* <input className="digiLogin digiPartLoginInput" name="state" id="state" type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatRegForm();
                    }
                  }}
                  defaultValue={editStockist ? editStockist[5] : ''}
                  onKeyPress={() => { removeErrorMessage("state") }} placeholder="State...." /> */}
              </div>
            </div>
          </div>

          {/* id0, dist_name1, dist_address1_2, dist_address2_3, city4, state5, country6, pincode7, contact1_8, contact2_9, e_mail10, dl_no11, 
          gstin12, dist_type13, created_at14, updated_at15, opr16 */}

          {/* </form> */}
          <p></p>
          <div className="loginLogo" style={{marginTop: '1.5vw'}}>
            <div className="row">
              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="digiSiginBtn" id="digiSiginBtn"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (editStockist !== null)
                        validatRegForm(editStockist[0])
                      else
                        validatRegForm();
                    }
                  }}
                  onClick={() => {
                      if (editStockist !== null)
                        validatRegForm(editStockist[0])
                      else
                        validatRegForm();
                    }}
                    > {editStockist ? "Update" : "Create"}</div>
              </div>

              <div className="col-6 col-md-6 col-lg-6 col-sm-6">
                <div className="digiSiginBtn" id="digiSiginBtn"
                  onClick={() => {
                      setEditStockist(null);
                      addStockistModalClose();
                    }}
                    > {"Cancel"}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* Link Companies with Stcokist Modal */}
      <Modal show={linkModal} onHide={linkModalClose} size="lg" backdrop="static" keyboard={false} >
        {/* <Modal.Header closeButton className='purchModalheader'>
          
        </Modal.Header> */}
        <Modal.Body>
          <div style={{ borderBottom: '2px solid gray' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3> {editStockist ? <>{editStockist[1]}, {editStockist[4]}</> : ''}</h3>
              <div onClick={() => { linkModalClose() }}><img src={require('../../static/images/Icons/close.png')} alt="" /></div>
            </div>
            <div style={{ marginTop: '-2%', marginBottom: '1%' }}>{editStockist ? <> {editStockist[2]}, {editStockist[3]} </> : ''}</div>
          </div>

          {/* <form name="addStockist" action="" method="post"> */}
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          <div>Linking Companies and Products</div>

          <div style={{ paddingBlock: '2%', borderBottom: '2px solid gray' }}>
            <div className="row" style={{ marginTop: '1vw' }}>
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="dpLogin">
                  <Select isMulti options={distComp} id="cat_idDD"
                    defaultValue={defCate}
                    onChange={handleSelect} placeholder="Select Companies"
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginBlock: '1%', fontSize: '90%' }}>
            Search & Choose Products
          </div>
          <div className="DPsearch" style={{ margin: '0px', width: '100%', display: 'flex', justifyContent: 'space-between',left:0 }}>
            <input type="text" placeholder={"Search Products by Name...!"} name="searchVenProd" id="searchVenProd"
              onKeyDown={(e) => {
                populateSearch();
                if (e.key === "Enter" || e.key === "ArrowDown") {
                  const searchPopup = document.getElementById("searchProList2");
                  if (searchPopup.firstChild) {
                    searchPopup.firstChild.focus();
                  }
                }
              }}
            />

            <div className="cleanSearch hide" id="cleanSearch" onClick={clearSearch}><img src={require("../../static/images/Icons/close.png")} alt="" /></div>

            <div className="searchProList2 divshadow hide" id="searchProList2">
              {
                searchProdData ?
                  searchProdData.map((product) =>
                    <div className='searchResModal topPartition' tabIndex={1} style={{ padding: '0px' }}
                      onClick={() => {
                        setLinkProducts([...linkProducts, [product[0], product[1], product[2], product[4]]]);
                        var serPro = document.getElementById("searchVenProd");
                        serPro.value = '';
                        document.getElementById("searchProList2").classList.replace("show", "hide");
                        serPro.focus();
                      }}

                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setLinkProducts([...linkProducts, [product[0], product[1], product[2], product[4]]]);
                          var serPro = document.getElementById("searchVenProd");
                          serPro.value = '';
                          document.getElementById("searchProList2").classList.replace("show", "hide");
                          serPro.focus();
                        }
                        else
                          if (e.key === "ArrowUp") {
                            const focusedInput = document.activeElement;
                            if (focusedInput.previousElementSibling) {
                              focusedInput.previousElementSibling.focus();
                            }
                          }
                          else
                            if (e.key === "ArrowDown") {
                              const focusedInput = document.activeElement;
                              if (focusedInput.nextElementSibling) {
                                focusedInput.nextElementSibling.focus();
                              }
                            }
                      }}>
                      <div style={{ display: 'flex', paddingInline: '.5%' }}>
                        <div style={{ width: '40%', fontSize: '1rem' }} >{product[1]}</div>
                        <div style={{ width: '40%', fontSize: '1rem' }} >{product[2]}</div>
                        <div style={{ width: '15%', fontSize: '1rem' }} >Price: {product[5]} </div>
                      </div>
                    </div>
                  )
                  : ''
              }
            </div>
            <button style={{ backgroundColor: "black" }} type="submit"><i className="fa fa-search"></i></button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBlock: '1%', fontSize: '90%' }}>
            <div>
              Selected Products
            </div>
            <div style={{ textAlign: 'center' }} >
              <input type='checkbox' name="freezProd" id="freezProd" />
              <label for="freezProd">Freez stockist with products</label>
            </div>
          </div>

          <div className='customScroll' style={{ overflowX: 'scroll', border: '1px solid gray', height: '200px' }}>
            {
              linkProducts && linkProducts.length > 0 ?
                linkProducts.map((product) => {
                  return (<>
                    <div style={{ fontSize: '85%', display: 'flex', justifyContent: 'space-between', marginInline: '5px' }}>
                      <div style={{ width: '40%' }}>{String(product[1]).length < 40 ? `${product[1]}` : `${product[1].substring(0, 37)}...`}</div>
                      <div style={{ width: '40%' }}>{String(product[2]).length < 35 ? `${product[2]}` : `${product[2].substring(0, 32)}...`}</div>
                      <div>{product[3]}</div>
                      <div onClick={() => { removeSelectedLinkProd(product[0]) }}>
                        <img src={require('../../static/images/Icons/close.png')} alt='' />
                      </div>
                    </div>
                  </>)
                }
                )
                : ''
            }
          </div>

          {/* </form> */}
          <p></p>
          <div className="loginLogo" style={{ marginTop: '1.5vw' }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-sm-12">
                <div className="digiSiginBtn" id="digiSiginBtn"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // alert("Linking");
                      LinkStockistProducts();
                    }
                  }}
                  onClick={() => { LinkStockistProducts() }}>Link</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ManageStockist
