import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import defProdImage from '../../static/images/Icons/defproducts.png';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ReactSession } from 'react-client-session';
import { LuSearch } from 'react-icons/lu';

const ManageComp = () => {
  const navigate = useNavigate();

  const [listRec, setListRec] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [currImageFront, setCurrImageFront] = useState(null);
  const [compData, setCompData] = useState(null);
  const [compImageF, setCompImageF] = useState(null);
  const [compImageB, setCompImageB] = useState(null);

  const [page, setPage] = useState(1);;
  const [pagesize, setPageSize] = useState(25);
  const [noofRec, setNoofRec] = useState(0);


  // Add category Modal Declaration
  const [addCompany, setShowAddCompany] = useState(false);
  const AddCompanyClose = () => setShowAddCompany(false);
  const AddCompanyShow = (listRecord = null, currimageF = null, currimageB = null) => {

    if (listRecord !== null) {
      if (currimageB !== null)
        setCurrImage(`data:image/jpeg;base64,${currimageB}`);
      else
        setCurrImage(null);

      if (currimageF !== null)
        setCurrImageFront(`data:image/jpeg;base64,${currimageF}`);
      else
        setCurrImageFront(null);

      setListRec(listRecord);
    }
    else {
      setSelectedFileF(null);
      setSelectedFileB(null);
      setCurrImageFront(null);
      setCurrImage(null);
      setListRec(null);
    }
    setShowAddCompany(true);
  }


  // Page Navigattion Methods
  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const prevPage = () => {
    // console.log("Revious Clicked :" + page);
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    // console.log("Next Clicked ...");
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
  }
  // Utility Methods

  const handleSubmit = (event) => {
    event.preventDefault();
    var responseMsg = "One company added successfully"

    // comp_Id , fileName, compName, compDispName, searkey, isActive
    let formData = new FormData();    //formdata object
    if (listRec !== null) {
      formData.append('comp_Id', listRec[0]);
      formData.append('fileNameF', listRec[3])
      formData.append('fileNameB', listRec[4])
      responseMsg = "One company updated successfully"
    }

    formData.append('FileF', selectedFileF);
    formData.append('FileB', selectedFileB);
    formData.append('compName', document.getElementById('compName').value);
    formData.append('compDispName', document.getElementById('compDispName').value);
    formData.append('searkey', document.getElementById('searkey').value);
    formData.append('isActive', document.getElementById('isActiveCatNew').checked ? "1" : "0");

    fetch('http://43.205.199.21/manageStockCompAPI/', {
      mode: 'cors',
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
      .then((result) => {
        // console.log('Success:', result);
        alert(responseMsg);
        AddCompanyClose();
        GetAllCompanies();
      }).catch((error) => {
        console.error('Error:', error);
      });
  }

  const [selectedFileF, setSelectedFileF] = useState(null);
  const [selectedFileB, setSelectedFileB] = useState(null);
  // const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {
    const file = event.target.files[0];
    // if (file.size > 524288)
    //   alert("File size cannot exceed more than 500 KB");
    // else {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('preview');
      output.src = reader.result;
    };
    reader.readAsDataURL(file);
    // }
    setSelectedFileB(file);
  };


  const changeHandlerFront = (event) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('previewFront');
      output.src = reader.result;
    };
    reader.readAsDataURL(file);
    setSelectedFileF(file);
  };


  const searchOrder = () => {
    GetAllCompanies();
  }

  const deleteRecord = (compId) => {
    var confDel = prompt("Enter admin password for confirm deletion :");
    if (confDel === 'secret') {
      fetch('http://43.205.199.21/manageStockCompAPI/', {
        mode: 'cors',
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          comp_Id: compId,
        })
      }).then((response) => {
        response.json();
        GetAllCompanies();
      })
        .then((data) => {
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Deletion request denied..!")
  }


  const GetAllCompanies = () => {
    var stxt = document.getElementById('sertext').value;
    fetch("http://43.205.199.21/manageStockCompAPI?page=" + page + "&pagesize=" + pagesize + "&SearText=" + stxt, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      setCompImageF(resp.front_image);
      setCompImageB(resp.back_image);
      setCompData(resp.data);
      setNoofRec(resp.noofRec);
      document.getElementById('pageNo').placeholder = page;
    });
  }


  useEffect(() => {
    if (!ReactSession.get('adminSheet360ERP')) {
      navigate('/Admin');
    }
    else {
      GetAllCompanies();
    }
  }, [page]);

  return (
    <div>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents' id='dashContents'>
          <div className='pendOrders' style={{ width: '100%', zIndex: '1', position: "unset" }}>
            <Row style={{ flexWrap: "nowrap", marginTop: 10,padding:"10px 0px" }}>
              <Col style={{ width: "63.2%" }} xl={8} sm={6} xs={6}>
                <h3 style={{ fontSize: 30, fontWeight: 600, marginLeft: 20 }}>Manage Companies</h3>
              </Col>
              <Col style={{ position: "relative", paddingLeft: 0, paddingRight: 0, marginBottom: 5, display: "flex", alignItems: "center", background: "#E8E8E8", height: "fit-content", marginRight: 50, borderRadius: 10, float: "right" }} xl={4} sm={6} xs={6}>
                <input placeholder='Search Here...' style={{ width: '75%', background: "#E8E8E8", border: "none", padding: "10px", flex: 1, border: 0, outline: 0, borderRadius: 10 }} type='text' name="sertext" id="sertext" defaultValue={''} onKeyDown={(e) => { if (e.key === "Enter") { searchOrder(); } }} />
                <i style={{ position: "absolute", fontSize: 20, display: "flex", alignItems: "center", justifyContent: "center", right: 0, top: 0, width: 70, minHeight: "100%", background: "#000", color: "#fff", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}><LuSearch /></i>
              </Col>
            </Row>

            <Row className="getDataHeader" style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2, textAlign: "left" }} md={2} lg={2} xl={2} sm={6} xs={6}>
                ID:&nbsp; display name
              </Col>
              <div style={{ paddingLeft: 2, paddingRight: 2,width:"20%",textAlign: 'left !important'}} md={2} lg={2} xl={2} sm={6} xs={6}>
                Comp. name
              </div>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} md={1} lg={1} xl={1} sm={6} xs={6} >
                Images Front
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} md={1} lg={1} xl={1} sm={6} xs={6} >
                Inside
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} md={2} lg={2} xl={2} sm={6} xs={6} >
                Search key
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} md={1} lg={1} xl={1} sm={6} xs={6}>
                IsActive
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} md={2} lg={2} xl={2} sm={6} xs={6} >
                Action
              </Col>
            </Row>
          </div>
          <div className='allPenOrders categoryContainer' id='categoryContainer' style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
            {compData ?
              compData.map((company, index) => {
                var base64Image1 = compImageF[company[3]];
                var base64Image2 = compImageB[company[4]];
                return <>
                  <Row className='pendOrderRows' style={{ marginTop: '.3rem' }}>
                    <Col style={{ paddingLeft: 2, paddingRight: 2, textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6}>
                      {company[0]} : {company[1]}
                    </Col>
                    <Col style={{ paddingLeft: 2, paddingRight: 2, textAlign: "left", color: "#000", fontWeight: "600",width:"20%" }} md={2} lg={2} xl={2} sm={6} xs={6}>
                      {company[2]}
                    </Col>
                    <Col style={{ paddingLeft: 2, paddingRight: 2, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6} >
                      <img src={`data:image/jpeg;base64,${base64Image1}`} style={{ width: '100%' }} onError={(e) => { e.target.src = defProdImage }} alt="" />
                    </Col>
                    <Col style={{ paddingLeft: 2, paddingRight: 2, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6} >
                      <img src={`data:image/jpeg;base64,${base64Image2}`} style={{ width: '100%' }} onError={(e) => { e.target.src = defProdImage }} alt="" />
                    </Col>
                    <Col style={{ paddingLeft: 2, paddingRight: 2, textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6} >
                      {company[5]}
                    </Col>
                    <Col style={{ paddingLeft: 2, paddingRight: 2, textAlign: "center" }} md={1} lg={1} xl={1} sm={6} xs={6}>
                      {String(company[6]) === '1' ?
                        <input type='checkbox' name='isActiveCat' id='isActiveCat' disabled defaultChecked />
                        :
                        <input type='checkbox' name='isActiveCat' id='isActiveCat' disabled />
                      }
                    </Col>
                    <Col style={{ paddingLeft: 2, paddingRight: 2, textAlign: "center" }} md={2} lg={2} xl={2} sm={6} xs={6} >
                      <img className="catActionBtn" onClick={() => { AddCompanyShow(company, compImageF[company[3]], compImageB[company[4]]) }} src={require('../../static/images/edit.png')} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                      &nbsp;&nbsp;&nbsp;&nbsp;<img className="catActionBtn" onClick={() => deleteRecord(company[0])} src={require('../../static/images/trash.png')} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                    </Col>
                  </Row>
                </>
              })
              : 'Companies'}
          </div>

          <div className="OrderPagination prodPagination" style={{ fontSize: '115%', background: "#fff", marginTop: '.3rem', marginBottom: '.3rem', width: "96%", margin: "0px auto", marginLeft: 18, padding: "10px 0px" }}>
            <div style={{ width: '33%', display: 'flex', justifyContent: 'center', height: '2rem', margin: "0 auto", height: "60px" }}>
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
              <input size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
            </div>
          </div>

          <div className='addNewCat' style={{ border: 0, outline: 0 }} onClick={() => { AddCompanyShow() }}>
            <img style={{ width: "70px", height: "70px", borderRadius: "50%" }} src={require('../../static/AdminPanelImages/add.gif')} alt="" />
          </div>
          <div className='copyRight'>
            Sheet360ERP Copyright &copy; 2023:  All Right Reserved
          </div>
        </div>
      </div>

      {/* Add category Modal */}

      <Modal show={addCompany} onHide={AddCompanyClose} size="lg" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>{listRec !== null ? "Editing " : "Add New"} Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form style={{padding:10}} onSubmit={handleSubmit} >
            {/* compName, compDispName, searkey, */}
            <Row style={{ marginBottom: '.3rem' }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} lg={6} md={6} xl={6} sm={12} xs={12}>
                <p style={{margin:0,padding:0,color:"gray",fontSize:14}}>Company Full Name</p>
                <input type="text"
                  defaultValue={listRec !== null ? listRec[2] : ""}
                  name="compName" id="compName" placeholder='Company Name' style={{ width: '100%',padding:5 }} />
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} lg={6} md={6} xl={6} sm={12} xs={12}>
                <p style={{margin:0,padding:0,color:"gray",fontSize:14}}>Display Name</p>
                <input type="text"
                  defaultValue={listRec !== null ? listRec[1] : ""}
                  name="compDispName" id="compDispName" placeholder='Company Display Name' style={{ width: '100%',padding:5 }} />
              </Col>
            </Row>
            <Row style={{ marginTop: '.3rem', marginBottom: '.3rem',flexWrap:"nowrap" }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} lg={6} md={6} xl={6} sm={6} xs={6}>
                <p style={{margin:0,padding:0,color:"gray",fontSize:14}}>Search Key</p>
                <input type="text"
                  defaultValue={listRec !== null ? listRec[5] : ""}
                  name="searkey" id="searkey" placeholder='Searchable Key' style={{ width: '100%',padding:5 }} />
              </Col>
              <Col style={{ paddingLeft: 2, paddingRight: 2, marginTop:"15px",marginLeft:10,display:"flex",alignItems:"center",gap:10 }} md={6} lg={6} xl={6} sm={6} xs={6}>
                <p style={{margin:0,padding:0,color:"gray",fontSize:14}}>Is Active</p>
                {listRec !== null ? String(listRec[6]) === '1' ?
                  <input type='checkbox' name='isActiveCatNew' id='isActiveCatNew' defaultChecked />
                  :
                  <input type='checkbox' name='isActiveCatNew' id='isActiveCatNew' />
                  :
                  <input type='checkbox' name='isActiveCatNew' id='isActiveCatNew' />
                }
              </Col>
            </Row>


            <Row style={{ marginTop: '.3rem', marginBottom: '.3rem' }}>
              <Col style={{ paddingLeft: 2, paddingRight: 2 }} lg={6} md={6} xl={6} sm={12} xs={12}>
                <label for="newFrontImage" className='selectPinBTN'>Select front image</label>
                <img style={{ maxWidth: 100, maxHeight: 100 }} id="previewFront" src={currImageFront !== null ? currImageFront : ''} alt="" />
                <input type="file" name="newFrontImage" id="newFrontImage" onChange={changeHandlerFront} accept="image/*" style={{ visibility: 'hidden' }} />
              </Col>

              <Col style={{ paddingLeft: 2, paddingRight: 2 }} lg={6} md={6} xl={6} sm={12} xs={12}>
                <label for="newCatImage" className='selectPinBTN'>Select inside image</label>
                <img style={{ minWidth: 100, minHeight: 100 }} id="preview" src={currImage !== null ? currImage : ''} alt="" />
                <input type="file" name="newCatImage" id="newCatImage" onChange={changeHandler} accept="image/*" style={{ visibility: 'hidden' }} />
              </Col>
            </Row>
            <Row style={{ marginBottom: '.3rem', textAlign: 'center'}}>
              <Col style={{width:"100%" }} lg={11} md={11} xl={11} sm={11} xs={11} >
                <button type='submit' style={{border:0,outline:0,background:"#00baff",padding:"10px 15px",borderRadius:8,width:"100%"}} className='btn btn-primary btn-sm'>{listRec !== null ? "Update " : "Add "}</button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default ManageComp