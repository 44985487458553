import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation, EffectCards, EffectCreative } from 'swiper/modules';
import Brands from '../DashContainers/Brands';
import DigiPartFooter from './DigiPartnerContainers/DigiPartFooter';
import ChatWithUs from '../DashContainers/ChatWithUs';

import defProdImage from '../../static/images/Banner3.jpeg';

ReactSession.setStoreType("localStorage");


const DigitalPartnerIndex = () => {

  const navigate = useNavigate();
  const [allSliders, setAllSliders] = useState(null);
  const [sliderImages, setSliderImages] = useState(null);

  const getAllSliders = () =>{
    fetch("http://43.205.199.21/manageSliderAPI?getSliders=0&caller=sheet", {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((respo) => {
      return respo.json()
    }).then((res) => {
      if (res.status === "success") {
        setAllSliders(res.allSliders);
        setSliderImages(res.sliderImages);
      }
    }).catch((error)=>{
      console.log("Error :",error);
    });
  }

  useEffect(()=>{
    getAllSliders();
  }, []);

  return (
    <div className='DigiMain'>
      <DigiPartNavBar />
      <div className='digiContents'>

        {/* Slider */}
        <div className='sliderDigiHome'>
          <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ delay: 2500, disableOnInteraction: false,}}
            pagination={{ clickable: true, }} navigation={true} modules={[Autoplay, Pagination, Navigation]} className="mySwiper">
              { 
                allSliders && allSliders.length > 0 && allSliders !== null ?
                  allSliders.map((slider)=>{
                    var base64Image = sliderImages[slider[1]];
                    return (
                    <SwiperSlide className='swiperSlide'>
                      <img src= {`data:image/jpeg;base64,${base64Image}`} onError={(e) => { e.target.src = defProdImage }} alt="" />
                    </SwiperSlide>
                    )
                  })
                :
                <>
                  <SwiperSlide className='swiperSlide'>
                    <img src={require('../../static/DPImages/banner1.png')} alt="" />
                  </SwiperSlide>
                  <SwiperSlide className='swiperSlide'>
                    <img src={require('../../static/DPImages/banner2.png')} alt="" />
                  </SwiperSlide>
                  <SwiperSlide className='swiperSlide'>
                    <img src={require('../../static/DPImages/banner3.png')} alt="" />
                  </SwiperSlide>
                  <SwiperSlide className='swiperSlide'>
                    <img src={require('../../static/DPImages/banner4.png')} alt="" />
                  </SwiperSlide>
                </>
              }
          </Swiper>
        </div>



        {/* Business Growth */}

        <div className='growthDigiHome' style={{ backgroundColor: "hsl(0deg 0% 87.84%)", margin: "auto", width: "100%", marginTop: 20 }}>
          <h1><span>4x </span>Business Growth</h1>
          <hr />

          <div className='businessGrowthCardsContainer'>

            <div onClick={() => navigate("/pharmacies")} className='businessGrowthCard'>
              <img src={require('../../static/DPImages/Business1.png')} alt="" />
              <h3>pharmacies</h3>
              <div>
              </div>
              <p>pan india online customers</p>
            </div>

            <div onClick={() => navigate("/distributors")} className='businessGrowthCard'>
              <img src={require('../../static/DPImages/Business2.png')} alt="" />
              <h3>distributors</h3>
              <div>
              </div>
              <p>7000+ pharmacies orders</p>
            </div>

            <div onClick={() => navigate("/groceries")} className='businessGrowthCard'>
              <img src={require('../../static/DPImages/Business3.png')} alt="" />
              <h3>groceries</h3>
              <div>
              </div>
              <p>4x growth of footfall customers</p>
            </div>

          </div>
        </div>

        {/* brands */}

        <Brands />

        <div className='customersComm' style={{ backgroundColor: "hsl(0deg 0% 87.84%)", margin: "auto", width: "100%" }}>
          <h3>Customer's Commendations</h3>

          <Swiper
            navigation={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]} className="customersCommSlider">

            <SwiperSlide>
              <img src={require("../../static/DPImages/444.png")} alt="" />
              <p>As a pharmacy owner, I can confidently say that implementing an Sheet360 ERP system has been one of the best decisions for my business. ERP solution has truly revolutionized the way we operate, providing us with a comprehensive platform to manage every aspect of our pharmacy efficiently and effectively. </p>

              <div className="customersCommSliderBottom">
                <img src={require("../../static/DPImages/testta1.jpg")} alt="" className="userImg" />
                <div>
                  <h3>MR. Shashank Dubey</h3>
                  <p>Galaxy Medical Store</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <img src={require("../../static/DPImages/444.png")} alt="" />
              <p>As a pharmacy owner, I can confidently say that implementing an Sheet360 ERP system has been one of the best decisions for my business. ERP solution has truly revolutionized the way we operate, providing us with a comprehensive platform to manage every aspect of our pharmacy efficiently and effectively. </p>

              <div className="customersCommSliderBottom">
                <img src={require("../../static/DPImages/testa4.jpg")} alt="" className="userImg" />
                <div>
                  <h3>MR. Amit Jain</h3>
                  <p>Jay Medical Store</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <img src={require("../../static/DPImages/444.png")} alt="" />
              <p>As a pharmacy owner, I can confidently say that implementing an Sheet360 ERP system has been one of the best decisions for my business. ERP solution has truly revolutionized the way we operate, providing us with a comprehensive platform to manage every aspect of our pharmacy efficiently and effectively. </p>

              <div className="customersCommSliderBottom">
                <img src={require("../../static/DPImages/testa3.jpg")} alt="" className="userImg" />
                <div>
                  <h3>MR. Mahendra Goyal</h3>
                  <p>Vibha Pharmacy</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <img src={require("../../static/DPImages/444.png")} alt="" />
              <p>As a pharmacy owner, I can confidently say that implementing an Sheet360 ERP system has been one of the best decisions for my business. ERP solution has truly revolutionized the way we operate, providing us with a comprehensive platform to manage every aspect of our pharmacy efficiently and effectively. </p>

              <div className="customersCommSliderBottom">
                <img src={require("../../static/DPImages/testa2.jpg")} alt="" className="userImg" />
                <div>
                  <h3>MR. Vikas Yadav</h3>
                  <p>Meera Pharmacy</p>
                </div>
              </div>
            </SwiperSlide>

          </Swiper>

        </div>


        <div className="subscriptionPlanCon">
          <h1>Subscription Plan</h1>

          <div className="flex">
            <div className="subsCard" onClick={() => navigate("/Subscriptions")}>
              <img src={require("../../static/DPImages/medical-cross.gif")} alt="" />
              <h4 style={{ margin: 0, padding: 0 }}>Pharmacy</h4>
            </div>

            <div className="subsCard" onClick={() => navigate("/Subscriptions")}>
              <img src={require("../../static/DPImages/warehouse.gif")} alt="" />
              <h4 style={{ margin: 0, padding: 0 }}>Distributor</h4>
            </div>

            <div className="subsCard" onClick={() => navigate("/Subscriptions")}>
              <img src={require("../../static/DPImages/24-hours.gif")} alt="" />
              <h4 style={{ margin: 0, padding: 0 }}>Pharmacy + Distributor</h4>
            </div>
          </div>
        </div>


        {/* FOOTER CONTENT */}

        <div className="footerContent">
          <h3>Welcome to Sheet360Erp..</h3>
          <p>Revolutionizing Business Management with Innovative ERP and CRM Solutions <br />
            At Sheet360ERP, we understand the complexities of managing a growing business. Our state-of-the-art ERP (Enterprise Resource Planning) and CRM (Customer <br />
            Relationship Management) solutions are designed to streamline your operations, boost efficiency, and enhance customer satisfaction.</p>

          <p>Why Choose Us? <br />
            Comprehensive Solutions <br />
            Our ERP and CRM systems integrate seamlessly to provide a holistic view of your business. From financial management to supply chain operations, our solutions cover every aspect <br />
            of your business needs.</p>

          <p>User-Friendly Interface <br />
            We prioritize ease of use. Our intuitive interface ensures that your team can quickly adapt to the system, minimizing downtime and maximizing productivity.</p>

          <p>
            Customizable Features <br />
            Every business is unique. That's why our ERP and CRM solutions are highly customizable to meet the specific needs of your industry and business processes</p>

          <p>Real-Time Data <br />
            Make informed decisions with real-time data analytics and reporting. Our systems provide you with actionable insights to stay ahead of the competition.
          </p>

          <p>Key Features <br />
            ERP Solutions <br />
            Financial Management: Gain complete control over your financial operations with real-time tracking and reporting. <br />
            Inventory Management: Efficiently manage your inventory levels to reduce costs and improve customer satisfaction. <br />
            Supply Chain Management: Optimize your supply chain operations to enhance productivity and reduce lead times.</p>

          <p>CRM Solutions <br />
            Customer Management: Build stronger relationships with your customers through detailed tracking and communication tools. <br />
            Sales Automation: Increase your sales team's efficiency with automated workflows and lead management. <br />
            Marketing Campaigns: Create, execute, and analyze marketing campaigns to attract and retain customers. <br />
          </p>
        </div>


        {/* footer */}
        <DigiPartFooter />
      </div>
      <ChatWithUs />
    </div>
  )
}

export default DigitalPartnerIndex