import React from 'react'
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
import Banner1 from '../Containers/Banner1';
import MyCatalogue from '../Containers/MyCatalogue';
import TrendCatalogue from '../Containers/TrendCatalogue';
import NewTips from '../Containers/NewTips';
import UsersComments from '../Containers/UsersComments';
import SocialNetworkLovedPlateform from '../Containers/SocialNetworkLovedPlateform';
import Footer from '../Containers/Footer';


const Home = () => {
  let user_session = false;

  return (
    <>
      <Logo />
      <NavSearchBar sess={user_session} />
      <Banner1 />
      <MyCatalogue />
      <TrendCatalogue/>
      <NewTips />
      <UsersComments />
      <SocialNetworkLovedPlateform/>
      <Footer />
    </>
  )
}

export default Home