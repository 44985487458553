import React, { useEffect } from 'react'
import DigiPartNavBar from '../DigitalPartner/DigiPartnerContainers/DigiPartNavBar';
import DigiPartFooter from '../DigitalPartner/DigiPartnerContainers/DigiPartFooter';


const Pharmacies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <DigiPartNavBar />
      <div className="aboutUsImg" style={{ minHeight: "400px" }}>
        <img src={require("../../static/DPImages/Pharmacy.png")} alt="" />
        <div className="center" style={{ overflow: "hidden" }}>
          <h1 data-aos="fade-up" data-aos-delay="400">Pharmacy</h1>
        </div>
      </div>

      <div className="aboutUs">
        {/* <b>Pharmacy</b> */}

        <ol type='1' style={{ marginTop: "30px" }}>
          <li><b>Extensive Product Range</b> <br />
            partnering with a Pan-India pharmacy provides access to an extensive range of pharmaceutical products, ensuring customers have
            access to a wide variety of medications and healthcare essentials from trusted brands.</li>

          <li><b>Nationwide Availability </b> <br />
            With a Pan-India partnership, customers can place orders from anywhere in the country, regardless of their location, ensuring
            accessibility to essential medications even in remote areas.</li>

          <li><b>Quick and Convenient Delivery</b> <br />
            Partnering with online ordering platforms enables pharmacies to offer quick and convenient delivery options, including same-day or
            next-day delivery services, ensuring timely access to medications for customers across India.</li>


          <li><b>Certified and Authentic Medications</b> <br />
            Pharmacies in the partnership guarantee the authenticity and quality of medications, sourcing products directly from reputable
            manufacturers and adhering to strict quality control standards to ensure customer safety and satisfaction.</li>

          <li><b>Professional Consultation Services</b> <br />
            Access to qualified pharmacists and healthcare professionals for consultation services, either through online chat, phone, or video
            calls, providing customers with expert guidance and advice on medication usage, dosage, and potential interactions.</li>

          <li><b>Secure Payment Options</b><br />
            Secure payment gateways and multiple payment options, including cash on delivery (COD), credit/debit cards, and digital wallets,
            ensure a seamless and hassle-free checkout experience for customers, enhancing trust and convenience.</li>

          <li><b>Personalized Medication Management</b> <br />
            Customized medication reminders, refill notifications, and dosage tracking features empower customers to manage their medication
            regimen effectively, promoting medication adherence and overall health outcomes.</li>

          <li><b>Comprehensive Health Information</b> <br />
            Access to reliable health information, including drug interactions, side effects, and dosage guidelines, through online portals or
            mobile apps, enables customers to make informed decisions about their healthcare needs.</li>

          <li><b>Privacy and Confidentiality</b> <br />
            Strict adherence to privacy regulations and confidentiality protocols ensures that customer information and medical records are
            handled with the utmost care and discretion, building trust and loyalty among customers..</li>4

          <li><b>Continuous Support and Follow-up</b> <br />
            Ongoing support and follow-up services, including post-purchase assistance, medication counseling, and wellness programs,
            demonstrate a commitment to customer care and long-term health management.</li>

        </ol>
      </div>
      <DigiPartFooter />
    </>
  )
}

export default Pharmacies