import React, { useEffect } from 'react'
import gsap from "gsap";
import DigiPartNavBar from '../DigitalPartner/DigiPartnerContainers/DigiPartNavBar';
import DigiPartFooter from '../DigitalPartner/DigiPartnerContainers/DigiPartFooter';

const Groceries = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
 
  return (
    <>
    <DigiPartNavBar/>
      <div className="aboutUsImg" style={{ minHeight: "400px" }}>
        <img src={require("../../static/DPImages/Groceries.png")} alt="" />
        <div className="center" style={{overflow:"hidden"}}>
          <h1 data-aos="fade-up" data-aos-delay="400">Groceries</h1>
        </div>
      </div>

      <div className="aboutUs">

        <ol type='1'>
          <li><b>Real-Time Footfall Tracking</b> <br />
            Integrate footfall tracking sensors or systems with the ERP software to capture real-time data on the number of visitors entering
            physical locations such as retail stores, offices, or event venues. This data can be used to analyze foot traffic patterns, identify peak
            hours, and optimize staffing and resource allocation accordingly.</li>

          <li><b>Inventory Management</b> <br />
            Utilize ERP&#39;s inventory management module to track the consumption of goods or resources in correlation with footfall data. By
            understanding how foot traffic impacts inventory levels and product demand, businesses can ensure adequate stock levels to meet
            customer needs during busy periods and avoid overstocking during slower times.</li>

          <li><b>Sales and Revenue Analysis</b> <br />
            Leverage ERP&#39;s reporting and analytics capabilities to correlate footfall data with sales performance and revenue generation. By
            analyzing footfall trends alongside sales data, businesses can identify opportunities to improve conversion rates, optimize pricing
            strategies, and maximize revenue potential.</li>

          <li><b>Customer Relationship Management</b> (CRM) <br />
            Integrate CRM functionalities into the ERP system to capture customer data and behavior patterns associated with footfall. This
            enables businesses to identify repeat visitors, track their preferences and purchase history, and personalize marketing campaigns or
            loyalty programs to enhance customer engagement and retention.</li>

          <li><b>Resource Planning and Allocation</b> <br />
            Use ERP&#39;s resource planning modules to optimize staffing levels, facility utilization, and operational workflows based on footfall
            projections and historical data. By aligning resources with anticipated foot traffic patterns, businesses can improve operational
            efficiency, minimize idle capacity, and enhance customer service levels.</li>

          <li><b>Marketing and Promotions</b> <br />
            Utilize ERP&#39;s marketing automation features to design targeted marketing campaigns and promotions tailored to footfall trends and
            customer segments. By leveraging insights derived from footfall data, businesses can deploy promotional offers, discounts, or
            events strategically to drive foot traffic, attract new customers, and stimulate sales growth.</li>

          <li><b>Forecasting and Demand Planning</b> <br />
            Leverage ERP&#39;s forecasting and demand planning tools to predict future footfall trends and anticipate fluctuations in customer
            demand. By analyzing historical footfall data alongside external factors such as seasonality, holidays, or marketing initiatives,
            businesses can make informed decisions about inventory stocking, staffing levels, and marketing strategies to capitalize on
            opportunities and mitigate risks.</li>

          <li><b>Operational Efficiency</b> <br />
            Streamline operational processes and workflows within the ERP system to accommodate fluctuations in footfall and ensure smooth
            business operations. By automating routine tasks, optimizing inventory replenishment, and synchronizing cross-functional activities,
            businesses can improve responsiveness to changing foot traffic patterns and deliver a seamless customer experience.</li>


          <li>
            <b>Scalability and Growth Management</b> <br />
            Utilize ERP&#39;s scalability features to support business growth and expansion initiatives driven by increasing footfall and user demand.
            Whether opening new locations, launching additional product lines, or entering new markets, businesses can leverage ERP&#39;s
            flexible architecture and modular design to adapt and scale their operations efficiently.
          </li>

          <li><b>Data-Driven Decision Making</b> <br />
            Empower decision-makers with actionable insights derived from footfall data integrated into the ERP system. By fostering a culture
            of data-driven decision-making, businesses can proactively identify opportunities, mitigate risks, and optimize resources to drive
            sustainable growth and competitive advantage in today&#39;s dynamic marketplace.</li>

        </ol>

      </div>

      <DigiPartFooter/>
    </>
  )
}

export default Groceries