import React, { useEffect, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom'
// import { createRoot } from 'react-dom/client'
import { ReactSession } from 'react-client-session';
import defProdImage from '../../static/images/Icons/defproducts.png';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { LuSearch } from 'react-icons/lu';

const ManageCategories = () => {
  const navigate = useNavigate();
  const [ref, setRef] = useState();
  const [listRec, setListRec] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [cateData, setCateData] = useState(null);
  const [cateImage, setCateImage] = useState(null);

  const [page, setPage] = useState(1);;
  const [pagesize, setPageSize] = useState(25);
  const [noofRec, setNoofRec] = useState(0);


  // Add category Modal Declaration
  const [addCategory, setShowAddCategory] = useState(false);
  const AddCategoryClose = () => setShowAddCategory(false);
  const AddCategoryShow = (listRecord = null, currimage = null) => {

    if (listRecord !== null) {
      if (currimage !== null)
        setCurrImage(`data:image/jpeg;base64,${currimage}`);
      else
        setCurrImage(null);
      setListRec(listRecord);
    }
    else {
      setCurrImage(null);
      setListRec(null);
    }
    setShowAddCategory(true);
  }


  // Page Navigattion Methods
  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const prevPage = () => {
    // console.log("Revious Clicked :" + page);
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    // console.log("Next Clicked ...");
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
  }

  // Utility Methods

  const handleSubmit = (event) => {
    event.preventDefault();
    var responseMsg = "One category added successfully"

    // newCatName newCatImage showCat isActiveCat
    let formData = new FormData();  //formdata object
    if (listRec !== null) {
      formData.append('cat_Id', listRec[0]);
      formData.append('fileName', listRec[2])
      responseMsg = "One category updated successfully"
    }

    formData.append('File', selectedFile);
    formData.append('newCatName', document.getElementById('newCatName').value);
    formData.append('isActiveCatNew', document.getElementById('isActiveCatNew').checked ? "1" : "0");
    formData.append('showCatNew', document.getElementById('showCatNew').checked ? "1" : "0");

    fetch('http://43.205.199.21/manageCategoryAPI/', {
      mode: 'cors',
      method: 'POST',
      body: formData,
    }).then((response) => response.json())
      .then((result) => {
        // console.log('Success:', result);
        alert(responseMsg);
        AddCategoryClose();
        GetAllCategories();
      }).catch((error) => {
        console.error('Error:', error);
      });
  }

  const [selectedFile, setSelectedFile] = useState(null);
  // const [isSelected, setIsSelected] = useState(false);

  const changeHandler = (event) => {
    const file = event.target.files[0];
    // if (file.size > 524288)
    //   alert("File size cannot exceed more than 500 KB");
    // else {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('preview');
      output.src = reader.result;
    };
    reader.readAsDataURL(file);
    // }
    setSelectedFile(file);
  };

  const searchOrder = () => {
    GetAllCategories();
  }

  const deleteRecord = (catId) => {
    var confDel = prompt("Enter admin password for confirm deletion :");
    if (confDel === 'secret') {
      fetch('http://43.205.199.21/manageCategoryAPI/', {
        mode: 'cors',
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          cat_Id: catId,
        })
      }).then((response) => {
        response.json();
        setRef(0);
        // navigate('/Products');
      })
        .then((data) => {
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Deletion request denied..!")
  }


  const GetAllCategories = () => {
    var stxt = document.getElementById('sertext').value;
    fetch("http://43.205.199.21/manageCategoryAPI?page=" + page + "&pagesize=" + pagesize + "&SearText=" + stxt, {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "success") {
        setCateImage(resp.files);
        setCateData(resp.data);
        setNoofRec(resp.noofRec);
        document.getElementById('pageNo').placeholder = page;
      }
    });
  }


  useEffect(() => {
    if (!ReactSession.get('adminSheet360ERP')) {
      navigate('/Admin');
    }
    else {
      GetAllCategories();
    }
  }, [page]);

  return (
    <div>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents' id='dashContents'>
          <div className='pendOrders' style={{ width: '100%', zIndex: '1', position: "unset" }}>
            <Row style={{ flexWrap: "nowrap", paddingTop: "10px",marginRight:14 }}>
              <Col style={{ width: "65%" }} xl={8} sm={6} xs={6}>
                <h3 style={{ fontSize: 30, fontWeight: 600, marginLeft: 20 }}>Manage Categories</h3>
              </Col>
              <Col style={{ position: "relative", paddingLeft: 0, paddingRight: 0, marginBottom: 5, display: "flex", alignItems: "center", background: "#E8E8E8", height: "fit-content", marginRight: 50, borderRadius: 10, float: "right" }} md={5} lg={4} xl={4} sm={6} xs={6}>
                <input placeholder='Search by Category Name...' style={{ width: '75%', background: "#E8E8E8", border: "none", padding: "10px", flex: 1, border: 0, outline: 0, borderRadius: 10 }} type='text' name="sertext" id="sertext" defaultValue={''} onKeyDown={(e) => { if (e.key === "Enter") { searchOrder(); } }} />
                <i style={{ position: "absolute", fontSize: 20, display: "flex", alignItems: "center", justifyContent: "center", right: 0, top: 0, width: 70, minHeight: "100%", background: "#000", color: "#fff", borderTopRightRadius: 10, borderBottomRightRadius: 10 }}><LuSearch /></i>
              </Col>
            </Row>

            <Row className="getDataHeader" style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
              <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                ID
              </Col>
              <Col md={5} lg={5} xl={5} sm={5} xs={5}>
                Category Name
              </Col>
              <Col md={1} lg={1} xl={1} sm={1} xs={1} >
                Image
              </Col>
              <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                Show
              </Col>
              <Col md={2} lg={2} xl={2} sm={2} xs={2}>
                Is Active
              </Col>
              <Col md={2} lg={2} xl={2} sm={2} xs={2}>
                Action
              </Col>
            </Row>
          </div>
          <div className='allPenOrders categoryContainer' id='categoryContainer' style={{ width: "96%", margin: "0px auto", marginLeft: 18 }}>
            {cateData && cateData != null ?
              cateData.map((category, index) => {
                {/* console.log("Image name :", category[2]); */}
                if (category[2] === null)
                  console.log("Image Null")
                else
                  var base64Image = cateImage[category[2]];

                return <>
                  <Row className='pendOrderRows' style={{ marginTop: '.3rem' }}>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1} style={{ textAlign: 'right' }}>
                      {category[0]}
                    </Col>
                    <Col md={5} lg={5} xl={5} sm={5} xs={5}>
                      {category[1]}
                    </Col>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1} >
                      <img src={`data:image/jpeg;base64,${base64Image}`} style={{ width: '100%', textAlign: "center" }} onError={(e) => { e.target.src = defProdImage }} alt="" />
                    </Col>
                    <Col style={{ textAlign: "center" }} md={1} lg={1} xl={1} sm={1} xs={1}>
                      {String(category[4]) === '1' ?
                        <input type='checkbox' name='showCat' id='showCat' disabled defaultChecked />
                        :
                        <input type='checkbox' name='showCat' id='showCat' disabled />
                      }
                    </Col>
                    <Col style={{ textAlign: "center" }} md={2} lg={2} xl={2} sm={2} xs={2}>
                      {String(category[3]) === '1' ?
                        <input type='checkbox' name='isActiveCat' id='isActiveCat' disabled defaultChecked />
                        :
                        <input type='checkbox' name='isActiveCat' id='isActiveCat' disabled />
                      }
                    </Col>
                    <Col style={{ textAlign: "center" }} md={2} lg={2} xl={2} sm={2} xs={2} >
                      <img className="catActionBtn" onClick={() => { AddCategoryShow(category, cateImage[category[2]]) }} src={require('../../static/images/edit.png')} alt="" data-toggle="tooltip" data-placement="top" title="Edit" />
                      &nbsp;&nbsp;&nbsp;&nbsp;<img className="catActionBtn" onClick={() => deleteRecord(category[0])} src={require('../../static/images/trash.png')} alt="" data-toggle="tooltip" data-placement="top" title="Delete" />
                    </Col>
                  </Row>
                </>
              })
              : "Populating categories...."
            }
          </div>
          
          <div className='addNewCat' style={{ border: 0, outline: 0 }} onClick={() => { AddCategoryShow() }}>
            <img style={{ width: "70px", height: "70px", borderRadius: "50%" }} src={require('../../static/AdminPanelImages/add.gif')} alt="" />
          </div>

          <div className="OrderPagination prodPagination" style={{ fontSize: '115%', background: "#fff", marginTop: '.3rem', marginBottom: '.3rem', width: "96%", margin: "0px auto", marginLeft: 18, padding: "10px 0px" }}>
            <div style={{ width: '33%', display: 'flex', justifyContent: 'center', height: '2rem', margin: "0 auto", height: "60px" }}>
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
              <input size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
            </div>
          </div>

          <div className='copyRight'>
            Sheet360ERP Copyright &copy; 2023:  All Right Reserved
          </div>
        </div>
      </div>

      {/* Add category Modal */}

      <Modal show={addCategory} onHide={AddCategoryClose} size="lg" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>{listRec !== null ? "Editing " : "Add New"} Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form onSubmit={handleSubmit} >
            <Row style={{ marginTop: '.3rem', marginBottom: '.3rem' }}>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <p style={{margin:0,padding:0,color:"gray",fontSize:14}}>Category Name</p>
                <input type="text"
                  defaultValue={listRec !== null ? listRec[1] : ""}
                  name="newCatName" id="newCatName" placeholder='Category Name' style={{ width: '100%',padding:5 }} />
              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <p style={{margin:0,padding:0,color:"gray",fontSize:14}}>Category Image</p>
                <input style={{padding:5,background:"#E5E5E5"}} type="file" name="newCatImage" id="newCatImage" onChange={changeHandler} accept="image/*" />
                <img id="preview" src={currImage !== null ? currImage : ''} alt="" />
              </Col>
            </Row>
            <Row style={{ marginTop: '.3rem', marginBottom: '.3rem' }}>
              <Col md={6} lg={6} xl={6} sm={6} xs={6}>
                {listRec !== null ? String(listRec[4]) === '1' ?
                  <input type='checkbox' name='showCatNew' id='showCatNew' defaultChecked />
                  :
                  <input type='checkbox' name='showCatNew' id='showCatNew' />
                  :
                  <input type='checkbox' name='showCatNew' id='showCatNew' />
                } Show
              </Col>
              <Col md={6} lg={6} xl={6} sm={6} xs={6}>
                {listRec !== null ? String(listRec[3]) === '1' ?
                  <input type='checkbox' name='isActiveCatNew' id='isActiveCatNew' defaultChecked />
                  :
                  <input type='checkbox' name='isActiveCatNew' id='isActiveCatNew' />
                  :
                  <input type='checkbox' name='isActiveCatNew' id='isActiveCatNew' />
                } Active
              </Col>
            </Row>
            <Row style={{ marginTop: '.3rem', marginBottom: '.3rem', textAlign: 'center' }}>
              <Col lg={12} md={12} xl={12} sm={12} xs={12} >
                <button style={{border:0,outline:0,background:"#00baff",padding:"5px 15px",borderRadius:8}} type='submit' className='btn btn-primary btn-sm'>{listRec !== null ? "Update " : "Add "} Category</button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default ManageCategories