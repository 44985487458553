import React, {useState, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';

import '../../stockPrint.css';
import Loader from '../Containers/Loader';

ReactSession.setStoreType("localStorage");


const SalesStockReport = () => {

  const navigate = useNavigate();

  const [originalDate, setOriginalDate] = useState(new Date());
  const [expProdCo, setExpProdCo] = useState(null);
  const [nonMovableProdCo, setNonMovableProdCo] = useState(null);
  
  const [totSales, setTotSales] = useState(null);
  
  const [loader, setLoader] = useState(false);
  const [stockData, setStockData] = useState(null);
  const [DPProfile, setDPProfile] = useState(null);
  const [allOrders, setAllOrders] = useState(null);
  const [pendingOrders, setPendingOrders] = useState(null);
  const [yearMonthSales, setYearMonthSales] = useState(null);
  const [incomeExpenses, setIncomeExpenses] = useState(null);

  const [maxSale, setMaxSale] = useState(null);
  const [minSale, setMinSale] = useState(null);
  
  const [maxIncExp, setMaxIncExp] = useState(null);
  const [minIncExp, setMinIncExp] = useState(null);
  
  const [totInv, setTotInv ] = useState(null);
  const [totSa, setTotSa] = useState(null);
  const [allOrd, setAllOrd] = useState(null);
  const [pendingOrd, setPendingOrd] = useState(null);

  const [filltered, setFilltered] = useState(false);

  // Handle Printer
  const printUserStockRef = React.useRef();
  const handlePrintUserStock = useReactToPrint({
  content: () => printUserStockRef.current,
  });

  // const [col1, setCol1] = useState(0);
  // const [col2, setCol2] = useState(0);
  // const [col3, setCol3] = useState(0);
  // const [col4, setCol4] = useState(0);
  // const [col5, setCol5] = useState(0);
  var col1 = 0;
  var col2 = 0;
  var col3 = 0;
  var col4 = 0;
  var col11 = 0;
  var col22 = 0;
  var col33 = 0;
  var col44 = 0;

  const executeQry = () => {
  setFilltered(true);
  var dtFrom = document.getElementById("dateFrom");
  var dtTo = document.getElementById("dateTo");
  if (String(dtFrom.value).length < 8) {
    alert("Please enter a valid date :");
    dtFrom.focus();
  }
  else
    if (String(dtTo.value).length < 8) {
    alert("Please enter a valid date :");
    dtTo.focus();
    }
    else {
    // console.log("Executing query");
    var st_date = "" + dtFrom.value + "' and u.created_at <= '" + dtTo.value + "'";
    getStockData(dtFrom.value, dtTo.value);
    }
  }

  const getStockData = (dtFrom = null, dtTo = null) => {
  var apiURL = 'http://43.205.199.21/digiPartnerAPI?stockState=1&user_Id=' + ReactSession.get('id')
  if (dtFrom != null)
    apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;

  setLoader(true);
  
  fetch(apiURL, {
    method: 'GET',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
  }).then((response) => {
    return response.json();
  }).then((resp) => {
    if (resp.status === "validityExpired")
    {
      alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
    }
    else if (resp.status === "accessError")
    {
      alert("Your current subscription doesnot support Sales & Stock Statement service, please contact Sheet360ERP sales team");
    }
    else if (resp.status === "success")
    {
    setStockData(resp.stockData);
    setDPProfile(resp.dp_profile[0]);
    ReactSession.set('dpType',resp.dp_profile[0][16]);
    // console.log("User Type :",resp.dp_profile[0][16]);

    const expIn120 = new Date(originalDate);
    expIn120.setDate(originalDate.getDate() + 120);
    
    const expIn60 = new Date(originalDate);
    expIn60.setDate(originalDate.getDate() + 60);

    const expIn30 = new Date(originalDate);
    expIn30.setDate(originalDate.getDate() + 30);


    const nonMovIn120 = new Date(originalDate);
    nonMovIn120.setDate(originalDate.getDate() - 120);
    
    const nonMovIn60 = new Date(originalDate);
    nonMovIn60.setDate(originalDate.getDate() - 60);

    const nonMovIn30 = new Date(originalDate);
    nonMovIn30.setDate(originalDate.getDate() - 30);


    var coExp120 = 0;
    var coExp60 = 0;
    var coExp30 = 0;
    
    var coNonMov120 = 0;
    var coNonMov60 = 0;
    var coNonMov30 = 0;
    
    var col4 =0;
    for(let i =0; i<resp.stockData.length; i++)
    {
      var op_st = filltered ? Number(resp.stockData[i][5]) + Number(resp.stockData[i][6]) + Number(resp.stockData[i][7]) - Number(resp.stockData[i][8]) : resp.stockData[i][5];
      var st_recv = filltered ? Number(resp.stockData[i][16]) + Number(resp.stockData[i][17]) : resp.stockData[i][6];
      var st_iss = filltered ? Number(resp.stockData[i][18]) : resp.stockData[i][7];

      var st_clos = filltered ? Number(op_st) + Number(st_recv) - Number(st_iss) : Number(resp.stockData[i][8]);
      var st_val = filltered ? Number(Number(st_clos) * (Number(resp.stockData[i][13]) === 0 ? Number(resp.stockData[i][19]) : Number(resp.stockData[i][13]))).toFixed(2) : Number(resp.stockData[i][13]).toFixed(2);
      col4 += Number(st_val);
      
      const dateExpiry = new Date(`${resp.stockData[i][12]}-${resp.stockData[i][11]}-${'28'}`);

      // console.log("ExpDate :",resp.stockData[i][11], resp.stockData[i][12], "exp date :",dateExpiry);

      if (dateExpiry < expIn120)
      coExp120 +=1
      else if (dateExpiry < expIn60)
      coExp60 +=1
      else if (dateExpiry < expIn30)
      coExp30 +=1
      setExpProdCo([coExp120, coExp60, coExp30]);

      if (resp.stockData[i][12] < nonMovIn120)
      coNonMov120 +=1
      else if (resp.stockData[i][12] < nonMovIn60)
      coNonMov60 +=1
      else if (resp.stockData[i][12] < nonMovIn30)
      coNonMov30 +=1;
      setNonMovableProdCo([coNonMov120, coNonMov60, coNonMov30])
      setLoader(false);
    }
    
    // console.log("Total Inventory :",col4);
    setTotInv(col4);  
    }
    else
    {
    setLoader(false);
    }
  });
  }


  const initialize = () => {
  setLoader(true);
  fetch("http://43.205.199.21/digiPartnerAPI?dashBoardData=1&user_Id="+ReactSession.get("id"), {
    method: 'GET',
    mode:'cors',
    headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
  }).then((response) => {
    return response.json()
  }).then((res) => {
    if (res.status === "validityExpired")
    {
      alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
    }
    else
    {
      setTotSales(res.totSales);
      setAllOrders(res.allOrders);
      setPendingOrders(res.pendingOrders);
      setYearMonthSales(res.yearMonthSales);
      setIncomeExpenses(res.incomeExpenses);
      
      // minimum maximum sales for graph
      const columnValues = res.yearMonthSales.map(row => row[4]);
      const maxColumnValue = Math.max.apply(null, columnValues);
      const minColumnValue = Math.min.apply(null, columnValues);
      setMaxSale(maxColumnValue+500);
      setMinSale(minColumnValue-500);

      // minimum maximum income & expenses for graph
      // const incExp = res.incomeExpenses.map(row => row[4]);
      // const maxincExp = Math.max.apply(null, incExp);
      // const minincExp = Math.min.apply(null, incExp);
      // setMaxSale(maxColumnValue+500);
      // setMinSale(minColumnValue-500);
      
      const incExp = res.incomeExpenses.map(row => row.slice(4, 5 + 1));
      const flattenedValues = incExp.flat();
      const maxIncExp = Math.max.apply(null, flattenedValues);
      const minIncExp = Math.min.apply(null, flattenedValues);
      setMaxIncExp(maxIncExp+500);
      setMinIncExp(minIncExp-100);
      
      var totS = 0;
      for (let i = 0; i<res.totSales.length; i++) 
      totS += parseInt(res.totSales[i][2]);
      setTotSa(totS);
      
      var totS = 0;
      for (let i = 0; i<res.allOrders.length; i++) 
      totS += parseInt(res.allOrders[i][7]);
      setAllOrd(totS);
      
      
      var totS = 0;
      for (let i = 0; i<res.pendingOrders.length; i++) 
      totS += parseInt(res.pendingOrders[i][7]);
      setPendingOrd(totS);
    }  
    setLoader(false);
  }).catch((err)=>{
    console.log("Error :",err.message);
    setLoader(false);
  })
  }



  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) =>{
  if (value)
    {
    document.getElementById('DigiDashPanel').style.display = 'block';
    document.getElementById('dpSidePanelBTN').style.left='75vw';
    }
  else
  {
    document.getElementById('DigiDashPanel').style.display = 'none';
    document.getElementById('dpSidePanelBTN').style.left='0px';
  }
  setMenuCollapse(value);
  }

  useEffect(() => {
  if (!ReactSession.get('dpsession')) {
    alert("You are not logged in.")
    navigate('/DigitalPartner');
  }
  else
    if (String(ReactSession.get('is_verified')) === "0") {
    alert("PLease complete your profile before Sales & Stock Statements...")
    navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
      setFilltered(false);
      initialize();
      getStockData();
      }
  }, []);

  return (
  <>
    { loader ? <Loader /> : ''} 
    <DigiPartNavBar />
    <div className='DigitalPartnerContainer' >
    <div className='DigiDashPanel' id='DigiDashPanel'>
      <DigiPartnerSidePanel />
    </div>
    <div className='DigiDashContent' id='DigiDashContent' style={{ backgroundColor: 'white' }}>

      <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={()=>CollapseMenu(!menuCollapse)}>
      { menuCollapse ? 
      <img src={require('../../static/images/Icons/left-arrow.png')} alt=''/>
      :
      <img src={require('../../static/images/Icons/right-arrow.png')} alt=''/>
      }
      </div>
      {
      DPProfile ? 
        <h3>Sales & Stock Analysis - <span> {DPProfile[2]}, {DPProfile[5]} {"(Madhya Pradesh)"}</span></h3>
      :
      ''
      }
      <div className='digiContentR1Graph' style={{marginBottom:'50vh'}}>
      <div style={{display:'flex', height:'10%', justifyContent:'space-around', alignItems:'center'}}>
        <div style={{fontSize:'90%', fontWeight:'bold'}}>Sales Analytics</div>
        <div style={{fontSize:'70%', display:'flex', justifyContent:'space-around', alignItems:'center'}}>
        <div style={{width:'30px', height:'10px', backgroundColor:'#78deff'}}></div>
        <div>Last Year</div>
        </div>

        <div style={{fontSize:'70%', display:'flex', justifyContent:'space-around', alignItems:'center'}}>
        <div style={{width:'30px', height:'10px', backgroundColor:'#2d2dff'}}></div>
        <div>Current Year</div>
        </div>
      </div>
      
      {/* var barPer = String(((parseInt(data[4]) - minSale) / (maxSale - minSale)) * 100)+'%'; */}
      <div style={{marginInline:'20px', display:'flex', border:'1px solid lightgray', padding:'15px', overflow:'scroll'}} className='customScroll'>
      {
        yearMonthSales && yearMonthSales!== null ?
        yearMonthSales.map((data)=>{
        var num = data[4] - minSale;
        var deno = maxSale - minSale;
        var barPer = ((num / deno) * 100).toFixed(0);
        return (
          <div style={{height:'100%', marginInline:'auto'}}> 
            <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-end', height:'70vh'}}>
              <div style={{borderTopLeftRadius:'3px', borderTopRightRadius:'3px', width:'20px', backgroundColor: data[0] === 2023 ? '#78deff' :'#2d2dff', minHeight:`${barPer}%`}}>{barPer}</div>
            </div>

            <div style={{ fontSize:'80%', fontWeight:'bold', height:'4vw', transform:'rotate(-90deg)'}}>{data[7].substring(0, 3)}</div>
          </div>
        )
        }) 
        :null
      }
      </div>
      {/* <img src={require("../../static/DPImages/digiDashGraph1.png")}/> */}
      </div>

       
    </div>
    </div>
  </>
  )
}

export default SalesStockReport
