import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import { useReactToPrint } from 'react-to-print';
import Oops from '../Containers/Oops';

ReactSession.setStoreType("localStorage");

const UserReport = () => {
  const navigate = useNavigate();
  const [queryText, setQueryText] = useState(null);
  const [userData, setUserData] = useState(null);

  const [userFillData, setUserFillData] = useState(null);
  const filterUsersData = () => {
    var sertext = document.getElementById("searchTxt").value;
    if (sertext !== null) {
      const filteredData = userData.filter(userDa =>
        userDa[1]?.toLowerCase().includes(sertext.toLowerCase()) ||
        userDa[2]?.toLowerCase().includes(sertext.toLowerCase()) ||
        userDa[3]?.toLowerCase().includes(sertext.toLowerCase())
      );
      setUserFillData(filteredData);
    }
  }


  // Handle Printer
  const printUserRef = React.useRef();
  const handlePrintUser = useReactToPrint({
    content: () => printUserRef.current,
  });


  //Utility Methods
  const executeQry = () => {
    var dtFrom = document.getElementById("dateFrom");
    var dtTo = document.getElementById("dateTo");
    if (String(dtFrom.value).length < 8) {
      alert("Please enter a valid date :");
      dtFrom.focus();
    }
    else
      if (String(dtTo.value).length < 8) {
        alert("Please enter a valid date :");
        dtTo.focus();
      }
      else {
        // console.log("Executing query");
        var qry = " u.created_at >= '" + dtFrom.value + "' and u.created_at <= '" + dtTo.value + "'";
        var apiURL = 'http://43.205.199.21/digiPartnerAPI?userState=1&user_Id=' + ReactSession.get('id') + '&qry=' + qry;
        fetch(apiURL, {
          method: 'GET',
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
        }).then((response) => {
          return response.json();
        }).then((resp) => {
          if (resp.status === "validityExpired")
          {
            alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
          }
          else
          {
            setUserData(resp.userData);
            setUserFillData(resp.userData);
            var dtFrom = document.getElementById("dateFrom");
            var dtTo = document.getElementById("dateTo");
            dtFrom.value = '';
            dtTo.value = '';
          }
        });

        // setQueryText(qry);
        // if (queryText !== null)
        //   {
        //     getUsers();
        //   }
      }
  }

  const getUsers = () => {
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?userState=1&user_Id=' + ReactSession.get('id') + '&qry=' + queryText;
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired")
      {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }

      if (resp.status === "success")
      {
        setUserData(resp.userData);
        setUserFillData(resp.userData);
        var dtFrom = document.getElementById("dateFrom");
        var dtTo = document.getElementById("dateTo");
        dtFrom.value = '';
        dtTo.value = '';
      }
    });
  }

  const [menuCollapse, setMenuCollapse] = useState(false);

  const CollapseMenu = (value) => {
    if (value) {
      document.getElementById('DigiDashPanel').style.display = 'block';
      document.getElementById('dpSidePanelBTN').style.left = '75vw';
    }
    else {
      document.getElementById('DigiDashPanel').style.display = 'none';
      document.getElementById('dpSidePanelBTN').style.left = '0px';
    }
    setMenuCollapse(value);
  }

  useEffect(() => {
    if (!ReactSession.get('dpsession')) {
      alert("You are not logged in.");
      navigate('/DigitalPartner');
    }
    else {
      if (String(ReactSession.get("is_verified")) === "1") {
        getUsers();
      }
      else 
        if (String(ReactSession.get('is_verified')) === "2") {
          alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
        }
        else {
          alert("Please complete your profile for User reports");
          navigate('/DigiPartnerProfile');
        }
    };
  }, []);

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent' id='DigiDashContent' style={{ backgroundColor: 'white' }}>
          <div className='dpSidePanelBTN' id='dpSidePanelBTN' onClick={() => CollapseMenu(!menuCollapse)}>
            {menuCollapse ?
              <img src={require('../../static/images/Icons/left-arrow.png')} alt='' />
              :
              <img src={require('../../static/images/Icons/right-arrow.png')} alt='' />
            }
          </div>

          <div style={{ margin: '.5%', width: '98%', fontSize: '120%', display: 'flex', justifyContent: 'space-between', borderBottom: '3px solid rgb(61, 197, 238)' }}>
            <div style={{ width: '40%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBlock: '.5vw' }}>
              <div style={{ fontWeight: '600', fontSize: '2vw', minWidth: '20%',textWrap:"nowrap" }} >
                User Report
              </div>
              <div style={{ width: '65%', marginLeft: '30%', display: "flex", alignItems: "center", minWidth: '30vw',position:"relative", border: "1.5px solid #000", borderRadius: 10 }}>
                <input style={{ flex: 1, fontSize: 14, border: 0, outline: 0, padding: 7, borderRadius: 10, background: "transperent" }} type='text' name='searchTxt' id='searchTxt' onChange={filterUsersData} placeholder='Search by Name/Email/Mobile :' />
                <button style={{ backgroundColor: "black", width: "80px",height:"37px",borderTopRightRadius:"10px",borderBottomRightRadius:"10px" }} type="submit"><i className="fa fa-search" style={{color:"#fff"}}></i></button>
              </div>
            </div>

            <div style={{ display: 'flex', fontSize: '75%', alignItems: 'center', justifyContent: 'flex-end' }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "16vw" }}>
                <div style={{ margin: 0, padding: 0 }}><input type='date' name='dateFrom' id='dateFrom' className='form-control form-control-sm removeDateIcon' style={{ width: "82%", textTransform:"uppercase" }} /></div>
                <p style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: 0, padding: 0, marginLeft: -15, marginRight: 10 }}>-</p>
                <div style={{ margin: 0, padding: 0 }}><input type='date' name='dateTo' id='dateTo' className='form-control form-control-sm removeDateIcon' style={{ width: "82%", textTransform:"uppercase" }} /></div>
              </div>
              <div>&nbsp;&nbsp;&nbsp;</div>
              <div className='digiNavBtn2' style={{ padding: 5, border: 0, outline: 0, color: "#fff", borderRadius: 5, background: "#00BAFF" }} onClick={() => executeQry()}>Go</div>
              <div className='digiNavBtn2' style={{ padding: 5, border: 0, outline: 0, color: "#fff", borderRadius: 5, background: "#00BAFF" }} onClick={handlePrintUser}>Print</div>
            </div>
          </div>

          <div style={{ marginInline: '.5%', width: '99%', display: 'flex', justifyContent: 'flex-start', background: "#F4F4F4" }}>
            <div style={{ width: '6%', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>S.NO</div>
            <div style={{ width: '22%', fontWeight: 'bold', padding: '.5%', fontSize: '90%' }}>Name/Mobile/Email</div>
            <div style={{ width: '8%', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Gender</div>
            <div style={{ width: '10%', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Wallet</div>
            <div style={{ width: '12%', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Registration</div>
            <div style={{ width: '10%', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }} >Role</div>
            <div style={{ width: '32%', fontWeight: 'bold', padding: '.5%', fontSize: '90%', textAlign: 'center' }}>Last Order {'(Amount/Date)'}</div>
            <div style={{ width: '.5rem', borderRight: '0px', borderBlock: '0px' }}></div>
          </div>

          <div className='stockDataPanel customScroll'>
            {
              userFillData ?
                userFillData.map((item, index) => {
                  var regDate = Date(String(item[11]).split('T')[0]);
                  var orders = item[21]?.split(',');
                  return (
                    <div style={{ marginInline: '.5%', minHeight: '10vh', width: '99%', display: 'flex', justifyContent: 'flex-start', borderBlock: '1px solid rgb(61, 197, 238)' }}>
                      <div style={{ width: '6%', borderInline: '1px solid rgb(61, 197, 238)', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{index + 1}</div>
                      <div style={{ width: '22%', paddingInline: '.5%', fontSize: '90%' }}>
                        <div>{item[1]}</div>
                        <div style={{ fontSize: '80%' }}>{item[2]}</div>
                        <div style={{ fontSize: '80%' }}>{item[3]}</div>
                      </div>
                      <div style={{ width: '8%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{item[5]}</div>
                      <div style={{ width: '10%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}> 0 </div>
                      <div style={{ width: '12%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{String(item[11]).split('T')[0]}<br /> {String(item[11]).split('T')[1]}</div>
                      <div style={{ width: '10%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>{item[20] === 0 ? "User" : item[20] === 1 ? "Retailer" : item[20] === 2 ? "Whole Seller" : 'Retailer + Whole Seller'}</div>
                      <div style={{ width: '32%', paddingInline: '.5%', fontSize: '90%', textAlign: 'center' }}>
                        {
                          orders?.length > 0 ?
                            orders.map((order, index) => {
                              return (
                                index < 3 ?
                                  <>
                                    {Number(order.split('=>')[0]).toFixed(2)}&nbsp;&nbsp; |&nbsp;&nbsp; {new Date(String(order.split('=>')[1]).substring(0, 10)).toDateString()}<br />
                                  </> : ''
                              )
                            })
                            : ''
                        }
                      </div>
                    </div>
                  )
                }
                )
                :
                <Oops title={"No  Records Found"}/>
            }
          </div>
        </div>
      </div>

      {/* Print Component */}
      <div ref={printUserRef} className='printable-document'>
        this will be printed
      </div>
    </>
  )
}

export default UserReport