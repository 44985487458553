import React, { useEffect, useState } from 'react'
// import axios from 'axios';


const WhatsAppMSG = () => {
  const [ resData, setResData] =useState(null);
  

  // Webhook ID : https://webhook.site/642808ea-6aa0-40bb-87d8-55c602c1fdfc
  const send = () =>
  {
  // console.log("Sending");
  fetch('https://hisocial.in/api/send/', {
    // mode: 'cors',
    method: 'POST',
    mode: 'no-cors',
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify(
    {
      "number": 918085440774,
      "type": "text",
      "message": "Sheet 360 hello",
      "instance_id": "65E40266D9B6C",
      "access_token": "65e1654fe7411"
      }
    ),
  }).then((result) => {
    alert("Message Successfully Sent");
    // console.log(result);
  }).catch((error) => {
    console.error('Error:', error);
  });
  }

  const createInstance = async () =>{
  
  
  // fetch('https://hisocial.in/api/set_webhook?webhook_url=https%3A%2F%2Fwebhook.site%2F642808ea-6aa0-40bb-87d8-55c602c1fdfc&enable=true&instance_id=65E40266D9B6C&access_token=65e1654fe7411', {
  //   mode: 'no-cors',
  //   headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
  //   method: 'GET',
  // }).then((response) => {
    //   // response.json();
    //   console.log("Response :",response);
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // });
    
  // var url = "https://hisocial.in/api/create_instance?access_token=65e1654fe7411";

  try {
    const accessToken = '65e1654fe7411';
    const apiUrl = `https://hisocial.in/api/create_instance?access_token=${accessToken}`;

    const requestOptions = {
    method: 'GET',
    mode:'no-cors'
    };

    const res = await fetch(apiUrl, requestOptions).then((res)=>console.log(res.json()));
    // const data = res.json();
    // console.log(res);
    setResData(JSON.stringify(res, null, 2));
  } catch (error) {
    console.log('Error fetching data:', error);
  }

  }
  
  const getQRCode = () =>{
  // "https://hisocial.in/api/get_instance?access_token=65e1654fe7411 "
  
  fetch('https://hisocial.in/api/get_qrcode?instance_id=65E40266D9B6C&access_token=65e1654fe7411', {
    method: 'GET',
    mode: 'no-cors',
  }).then((response) => {
    // response.json();
    console.log(response);
  })
  .then((result) => {
    console.log("Result :",result);
  }).catch((error) => {
    console.error('Error:', error);
  });
  }

  useEffect(()=>{
  // console.log("Whatsapp Components");
  }, []);

  return (
  <div>
    <br /><br /><br />
    <div className='btnPrimary1' onClick={createInstance}>
    Create a new instance
    </div>
    <br />
    <div className='btnPrimary1' onClick={getQRCode}>Get QR Code</div>
    <br /><br />
    <div>Enter recipients Number : <input type='text' id="nu" /></div>
    <br /><button onClick={send}>Send</button>

    <div>Res : { resData ? resData : ''}</div>
  </div>
  )
}

export default WhatsAppMSG