import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DashNavbar from '../DashContainers/DashNavbar';
import DashPanel from '../DashContainers/DashPanel';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { createRoot } from 'react-dom/client'

import Select from 'react-select';

import defProdImage from '../../static/DPImages/med1.png';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';


ReactSession.setStoreType("localStorage");

const Products = () => {

  const navigate = useNavigate();
  // const animatedComponents = makeAnimated();

  const [page, setPage] = useState(1);;
  const [noofRec, setNoofRec] = useState(0);
  const [pagesize, setPageSize] = useState(25);
  const [listRec, setListRec] = useState([]);


  //For Filter Array of object Multi Select List
  var [selectedCate, setSelectedCate] = useState([]);
  var handleSelect = (e) => {
    setSelectedCate(Array.isArray(e) ? e.map(x => x.value) : []);
    removeErrorMessage("selectCat");
  }

  // For Filter array of object Single Selection List
  // const [selectedCate, setSelectedCate] = useState(cateOptions.label);
  // const handleSelect = e => {
  //   setSelectedCate(e.label);
  // }

  // var qry = "";
  const [qry, setQry] = useState('');

  const [cateOptions, setCateOptions] = useState([{ value: '', label: 'Select Category' },]);
  const [groupOptions, setGroupOptions] = useState([{ value: '', label: 'Select Group' },]);
  // const [fillGroupOptions, setFillGroupOptions] = useState([{ value: '', label: 'Select Group' },]);

  const statusOptions = [
    { value: '', label: 'None' },
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
  ]

  const prodDomainsOptions = [
    { value: "Product", label: "Product" },
    { value: "Cardiac Care", label: "Cardiac Care" },
    { value: "Vega", label: "Vega" },
    { value: "Diebetes Care", label: "Diebetes Care" },
    { value: "Trending Products", label: "Trending Products" },
    { value: "Sexual Wellness", label: "Sexual Wellness" },
    { value: "Hair Care", label: "Hair Care" },
    { value: "Body Care", label: "Body Care" },
    { value: "Stomach Care", label: "Stomach Care" },
    { value: "Womens Care", label: "Womens Care" },
    { value: "Kedney Care", label: "Kedney Care" },
    { value: "Eye Care", label: "Eye Care" },
    { value: "Skin Care", label: "Skin Care" },
    { value: "Vega", label: "Vega" },
    { value: "Mamaearth", label: "Mamaearth" },
    { value: "Biotique", label: "Biotique" },
  ]

  // window.onclick = function (e) {   
  //   console.log("Window Clicked");
  //   var clss = document.getElementsByClassName("prodCatCont");
  //   for(let i = 0; i<clss.length;i++)
  //   {
  //   if (!e.target.matches(clss[i].className) || !e.target.matches('.DropDown')) {
  //       clss[i].classList.replace("show","hide");
  //     }  
  //   }
  // }

  const topPage = () => {
    if (page > 1) {
      setPage(1)
    }
  }

  const prevPage = () => {
    // console.log("Revious Clicked :" + page);
    if (page > 1) {
      setPage(page - 1)
    }
  }

  const nextPage = () => {
    // console.log("Next Clicked ...");
    if (page <= parseInt(noofRec / pagesize)) {
      setPage(page + 1)
    }
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize)) {
      setPage(parseInt(noofRec / pagesize) + 1)
    }
  }

  const [RawHTML, setHTML] = useState(null);
  const [defCate, setDefCate] = useState(null);
  const [currImage, setCurrImage] = useState(null);
  const [productsData, setProductsData] = useState(null);
  const [productsImages, setProductsImages] = useState(null);

  const [eState, setEState] = useState(EditorState.createEmpty());

  function onEditorChange(editstate) {
    var rawContentState = convertToRaw(editstate.getCurrentContent());
    setHTML(draftToHtml(rawContentState));
  }

  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }


  // Calculating amount after discount
  function calculateAmount() {
    document.getElementById('newprodAmount').value = parseInt(document.getElementById('newprodPrice').value) -
      (parseInt(document.getElementById('newprodPrice').value) * parseInt(document.getElementById('newprodDiscount').value) / 100);
  }


  //Call API to retreive data for filter on 'manage product' & 'add product' UI
  const buildSearchOptions = () => {
    fetch("http://43.205.199.21/searchProAPI", {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      setCateOptions(res.proCateD);
      setGroupOptions(res.proGrpD);
      // setFillGroupOptions(res.proGrpD);
    });
  }

  //Call API to tetreive data
  const pickProduct = () => {
    var url = "";
    // console.log("Query :", qry);
    if (qry === "")
      url = "http://43.205.199.21/manageProdAPI?page=" + String(page) + "&pagesize=" + String(pagesize);
    else
      url = "http://43.205.199.21/manageProdAPI?qry=" + qry + "&page=" + String(page) + "&pagesize=" + String(pagesize);

    fetch(url, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((res) => {
      setProductsImages(res.images);
      setProductsData(res.data);
      setNoofRec(res.noofRec);
      document.getElementById('pageNo').placeholder = page;
    });
  }

  function deleteRecord(proId, delImg = null) {
    var confDel = prompt("Enter admin password for confirm deletion :");
    if (confDel === 'secret') {
      fetch('http://43.205.199.21/manageProdAPI/', {
        mode: 'cors',
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          pro_id: proId,
          delImage: delImg,
        })
      })
        .then((response) => {
          response.json();
          pickProduct();
        })
        .then((data) => {
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Deletion request denied..!")
  }

  //Function to build filter query
  const createQry = () => {
    var serText = document.getElementById('searchprodName').value.trim();
    var serSalt = document.getElementById('searchSaltName').value.trim();
    var qry1 = serText;

    if (serSalt.length !== '')
      qry1 += '&serSalt=' + serSalt;

    if (document.getElementById('SearchCatID').value !== '')
      qry1 += "&cat_id=" + document.getElementById('SearchCatID').value;

    if (document.getElementById('SearchGroupID').value !== '')
      qry1 += "&GroupID=" + document.getElementById('SearchGroupID').value;

    if (document.getElementById('SearchActiveID').value !== '')
      qry1 += "&is_active=" + document.getElementById('SearchActiveID').value;
    setQry(qry1);
    setPage(1);
    pickProduct();
  }

  // "Clearing Filter"
  const clearFilters = () => {
    // qry = '';
    setQry('');
    document.getElementById('SearchCatID').value = '';
    document.getElementById('SearchGroupID').value = '';
    document.getElementById('SearchActiveID').value = '';
    document.getElementById('prodCatSearch').value = '';
    document.getElementById('prodGroupSearch').value = '';
    document.getElementById('prodActiveSearch').value = '';
    document.getElementById('searchprodName').value = '';
    document.getElementById('searchSaltName').value = '';

    setPage(1);
    pickProduct();
  }

  const [filGroupLabel, setFilterGroup] = useState();

  const [addProd, setShowAddProd] = useState(false);
  const AddProdClose = () => setShowAddProd(false);
  const AddProdShow = (listRecord = null, currimage = null) => {
    setFilterGroup("");
    setSelectedCate([]);
    setCurrImage(null);
    setDefCate(null);
    setHTML('undefined');
    setSelectedFile(null);

    if (listRecord !== null) {
      if (listRecord[26] !== 0 || listRecord[26] !== null) {
        const filGrpID = groupOptions.filter(groupid => {
          return groupid.value === listRecord[26];
        });
        if (filGrpID.length > 0)
          setFilterGroup((filGrpID[0].label));
      }

      var catList = String(listRecord[2]).split(",");
      var defCats = [];
      var filCatID = "";
      setSelectedCate(listRecord[2]);
      for (let i = 0; i < catList.length; i++) {
        filCatID = cateOptions.filter(cate => {
          return String(cate.value) === catList[i];
        });
        defCats.push(filCatID[0]);
      }
      setDefCate(defCats);


      if (listRecord[4] === null || listRecord[4] === 'null')
        setEState(EditorState.createEmpty());
      else {
        const blocksFromHTML = convertFromHTML(listRecord[4]);
        const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        setEState(EditorState.createWithContent(content));
        var rawContentState = convertToRaw(content);
        setHTML(draftToHtml(rawContentState));
      }

      if (currimage !== null) {
        setCurrImage(`data:image/jpeg;base64,${currimage}`);
      }
      else {
        setCurrImage(null);
        setSelectedFile(null);
      }
    }
    else {
      setCurrImage(null);
      setDefCate(null);
      setHTML('undefined');
      setSelectedFile(null);
      setEState(EditorState.createEmpty());
    }

    setListRec(listRecord);
    setShowAddProd(true);
  }

  // function to Populate option in select container through reactDom
  function SetSelectedItem(value, label, CatGrp, proCon, ids, isMulti) {
    var CatG = document.getElementById(CatGrp);
    var cid = document.getElementById(ids);

    if (isMulti === true) {
      CatG.value = CatG.value + " " + label;
      cid.value = cid.value + "," + value;
    }
    else {
      CatG.value = label;
      cid.value = value;
    }
    document.getElementById(proCon).classList.replace("show", "hide");
  }

  function PopulateItems(props) {
    const myLists = props.myLists;
    const listItems = myLists.map((item) =>
      <div className="SelectOptions" id="SelectOptions" tabIndex="0"
        onKeyDown={(e) => {
          if (e.key === "Enter")
            SetSelectedItem(item.value, item.label, props.CatGrp, props.SelectDiv, props.ids, props.isMultiSel);
        }}
        onClick={() => { SetSelectedItem(item.value, item.label, props.CatGrp, props.SelectDiv, props.ids, props.isMultiSel) }}>
        {item.label}
      </div>
    );
    return (
      <div>
        {listItems}
      </div>
    );
  }

  const PopulateOptions = (SelectDiv, CatGrp, data, ids, isMulti = null) => {
    var prodCatCont = document.getElementById(SelectDiv);
    prodCatCont.classList.replace("hide", "show");
    createRoot(prodCatCont).render(<PopulateItems myLists={data} CatGrp={CatGrp} SelectDiv={SelectDiv} ids={ids} isMultiSel={isMulti} />);
  }


  const [selectedFile, setSelectedFile] = useState(null);

  const changeHandler = (event) => {
    // const file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('preview');
      output.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // cat_id GroupID newprodName newprodPrice newprodComp newprodDiscount newprodUnit newprodSize newprodMaxQty
    //  newprodAmount prodStatusID newprodQuantity isActive outStock isRx isMol newprodImage

    // Minimum One Category, Title Name, Company Name, Pack Size, MRP, Discount, Max Buying Quantity
    // cat_idDD, newprodName, newprodComp, newprodUnit, newprodPrice, newprodDiscount,  newprodMaxQty
    var errorElements = [];
    if (selectedCate.length === 0)
      errorElements.push("selectCat");

    if (document.getElementById('newprodName').value === "")
      errorElements.push("newprodName");

    if (document.getElementById('newprodComp').value === "")
      errorElements.push("newprodComp");

    if (document.getElementById('newprodUnit').value === "")
      errorElements.push("newprodUnit");

    if (document.getElementById('newprodPrice').value === "")
      errorElements.push("newprodPrice");
    else if (isNaN(document.getElementById('newprodPrice').value)) {
      document.getElementById("errorMessage").innerHTML = "Price must be numeric";
      errorElements.push("newprodPrice");
    }
    if (document.getElementById('newprodDiscount').value === "")
      errorElements.push("newprodDiscount");
    else if (isNaN(document.getElementById('newprodDiscount').value)) {
      document.getElementById("errorMessage").innerHTML = "Discount must be numeric";
      errorElements.push("newprodDiscount");
    }
    if (document.getElementById('newprodMaxQty').value === "")
      errorElements.push("newprodMaxQty");
    else if (isNaN(document.getElementById('newprodMaxQty').value)) {
      document.getElementById("errorMessage").innerHTML = "Quantity must be numeric";
      errorElements.push("newprodMaxQty");
    }

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
    }
    else {
      var responseMsg = "One product added successfully"
      let formData = new FormData();    //formdata object
      if (listRec !== null) {
        formData.append('pro_id', listRec[0]);
        formData.append('fileName', listRec[6])
        responseMsg = "One product updated successfully"
      }

      var files = document.getElementById('newprodGallery').files;
      for (let i = 0; i < files.length; i++) {
        formData.append("Gallery", files[i], files[i].name)
      }

      formData.append('File', selectedFile);
      formData.append('cat_id', selectedCate);
      formData.append('GroupID', document.getElementById('GroupID').value);
      formData.append('newprodName', document.getElementById('newprodName').value);
      formData.append('newprodPrice', document.getElementById('newprodPrice').value);
      formData.append('newprodComp', document.getElementById('newprodComp').value);
      formData.append('newprodDetails', RawHTML);
      formData.append('newprodDiscount', document.getElementById('newprodDiscount').value);
      formData.append('newprodUnit', document.getElementById('newprodUnit').value);
      formData.append('newprodSize', document.getElementById('newprodSize').value);
      formData.append('newprodMaxQty', document.getElementById('newprodMaxQty').value);
      formData.append('newprodAmount', document.getElementById('newprodAmount').value);
      formData.append('prodStatus', document.getElementById('prodStatus').value);
      formData.append('newprodQuantity', document.getElementById('newprodQuantity').value);
      formData.append('isActive', document.getElementById('isActive').checked ? "1" : "0");
      formData.append('outStock', document.getElementById('outStock').checked ? "1" : "0");
      formData.append('isRx', document.getElementById('isRx').checked ? "1" : "0");
      formData.append('isMol', document.getElementById('isMol').checked ? "1" : "0");

      fetch(
        'http://43.205.199.21/manageProdAPI/',
        {
          mode: 'cors',
          // Headers NOT REQUIRED with fromDATA  // headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          method: 'POST',
          body: formData,
        }).then((response) => response.json())
        .then((result) => {
          alert(responseMsg);
          pickProduct();
          AddProdClose();
        }).catch((error) => {
          console.error('Error:', error);
        });
    }
  }


  useEffect(() => {
    if (!ReactSession.get('adminSheet360ERP')) {
      navigate('/Admin');
    }
    else {
      document.getElementById('dashboard').classList.replace("hide", "show");
      buildSearchOptions();
      pickProduct();
    }
  }, [page, qry]);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercent = (scrollTop / scrollHeight) * 100;
      // console.log(`Scroll Percent: ${scrollPercent}%`);

      // console.log(e);
      // console.log(showButton)
      if (scrollPercent > 1) {
        setShowButton(true);
      } else{
        setShowButton(false);
      }
    };

    document.body.addEventListener('scroll', handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    // console.log("Called");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='hide' id='dashboard'>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel'>
          <DashPanel />
        </div>
        <div className='dashContents' id='dashContents'>
          <div className='dashRecords' id='dashRecords'>
            {/* Filters */}

            <div className='ProdFilter' id='ProdFilter' >

              <Row style={{width:"100%"}}>
                <Col md={2} xl={2} sm={12} xs={12} >
                  <div className='DropDown adminSelectInput'>
                    <input type="hidden" id="SearchCatID" />
                    <input className="DropDownInput" type="text" name="prodCatSearch" id="prodCatSearch"
                      onFocus={() => {
                        PopulateOptions('SearchprodCatCont', "prodCatSearch", cateOptions, "SearchCatID")
                      }}
                      placeholder='Select Category' onKeyDown={(e) => { if (e.key === "Enter") { createQry(); } }} />
                    <div className="dropDownArrow"
                      onClick={() => { PopulateOptions('SearchprodCatCont', "prodCatSearch", cateOptions, "SearchCatID") }}>
                      <img className="dropDownBtn" src={require("../../static/images/Icons/down-arrow.png")} alt="" />
                    </div>
                  </div>
                  <div className='prodCatCont divshadow hide' id='SearchprodCatCont'></div>
                </Col>

                <Col md={3} xl={3} sm={12} xs={12}>
                  <div className='DropDown adminSelectInput'>
                    <input type="hidden" id="SearchGroupID" />
                    <input className="DropDownInput" type="text" name="prodGroupSearch" id="prodGroupSearch" onFocus={() => { PopulateOptions('SearchprodGroupCont', "prodGroupSearch", groupOptions, "SearchGroupID") }}
                      placeholder='Assign Group' onKeyDown={(e) => { if (e.key === "Enter") { createQry(); } }} />
                    <div className="dropDownArrow" onClick={() => { PopulateOptions('SearchprodGroupCont', "prodGroupSearch", groupOptions, "SearchGroupID") }}>
                      <img className="dropDownBtn" src={require("../../static/images/Icons/down-arrow.png")} alt="" /></div>
                  </div>
                  <div className='prodCatCont divshadow hide' id='SearchprodGroupCont'></div>
                </Col>

                <Col style={{width:150}} sm={12} xs={12}>
                  <div className='DropDown adminSelectInput'>
                    <input type="hidden" id="SearchActiveID" />
                    <input className="DropDownInput" type="text" name="prodActive" id="prodActiveSearch" onFocus={() => { PopulateOptions('ActiveprodGroupCont', "prodActiveSearch", statusOptions, "SearchActiveID") }}
                      placeholder='Is Active' />
                    <div className="dropDownArrow" onClick={() => { PopulateOptions('ActiveprodGroupCont', "prodActiveSearch", statusOptions, "SearchActiveID") }}>
                      <img className="dropDownBtn" src={require("../../static/images/Icons/down-arrow.png")} alt="" />
                    </div>
                  </div>
                  <div className='prodCatCont divshadow hide' id='ActiveprodGroupCont'></div>
                </Col>

            
                <Col className='adminSelectInput' md={2} lg={2} xl={2} sm={12} xs={12}>
                  <input type="text" id="searchSaltName" className='ProductFilterProName' placeholder='Search By Salt Name'
                    onKeyDown={(e) => { if (e.key === "Enter") { createQry(); } }} />
                </Col>

                <Col className='adminSelectInput' style={{width:275,marginLeft:10}} md={2} lg={2} xl={2} sm={12} xs={12}>
                  <input type="text" id="searchprodName" className='ProductFilterProName' placeholder='Search By Product Name'
                    onKeyDown={(e) => { if (e.key === "Enter") { createQry(); } }} />
                </Col>

                <Col style={{marginTop:8}} md={1} xl={1} sm={1} xs={1}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <Tooltip title="Search">
                      <div className="filterBtn" >
                        <img style={{width:25,height:25}} src={require('../../static/DPImages/Icons/search.png')} onClick={() => { createQry() }} alt="" />
                      </div>
                    </Tooltip>
                    <Tooltip title="Remove Filters">
                      <div className="filterBtn">
                        <img style={{width:25,height:25}} src={require('../../static/AdminPanelImages/no-filter.png')} onClick={() => { clearFilters() }} alt="" />
                      </div>
                    </Tooltip>
                  </div>
                </Col>

                <Col className='addProductBtn' style={{marginTop:8}} md={1} xl={1} sm={1} xs={1}>
                  <div style={{ float: 'right', width: "100%" }}>
                    <Tooltip title="Add Product">
                      <div className="filterBtn addButtonBottom">
                        <img style={{width:70,height:70,borderRadius:"50%"}} onClick={() => { AddProdShow() }} src={require('../../static/AdminPanelImages/add.gif')}
                          alt="" />
                      </div>
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            </div>


            <div
              className='productListCon'
              style={{ paddingTop: '1vh' }} >
              {productsData ?
                productsData.map((product, index) => {
                  var base64String = productsImages[product[6]];
                  return (
                    <div key = {index} className="productTableCard">
                      <div className="details" style={{ height: "100%", width: "100%" }}>

                        <div class="row">

                          <div class="col-sm" style={{ marginLeft: 10, display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <p style={{ fontSize: 13, margin: 0, padding: 0, color: "gray", marginBottom: 5 }}>Image</p>
                            <img className='proImgAdmin' id='proImg' src={`data:image/jpeg;base64,${base64String}`}
                              alt="products" onError={(e) => { e.target.src = defProdImage }} />
                            <p style={{ color: "gray", fontSize: "14px", marginTop: 5, cursor: "pointer" }}>REMOVE</p>
                          </div>
                          <div class="col-sm">
                            <p style={{ fontSize: 13, margin: 0, padding: 0, color: "gray", marginBottom: 5 }}>Product Details</p>
                            <h3 style={{ color: "#000", fontSize: "17px" }}>{product[3]} | <span style={{ color: "gray", fontSize: "15px" }}>{product[0]}</span></h3>
                            <p>{product[9]}</p>
                            <p>{product[10]}</p>
                            <p style={{ color: "gray", marginTop: 10, margin: 0, padding: 0, display: "flex", alignItems: "center", gap: 7 }}>Salt Group : <input style={{ marginTop: 2.5 }} type="checkbox" /></p>
                          </div>

                          <div class="col-sm ml-3" style={{ marginLeft: 5 }}>
                            <p style={{ fontSize: 13, margin: 0, padding: 0, color: "gray", marginBottom: 0 }}>MRP</p>
                            <input value={product[5]} type="text" placeholder='MRP' id='mrp' name='mrp' style={{ width: "80px", textAlign: "center", fontSize: 14, marginBottom: 5, padding: 4 }} />
                            <p style={{ fontSize: 13, margin: 0, padding: 0, color: "gray", marginBottom: 0 }}>Discount %</p>
                            <input value={product[14]} type="text" placeholder='Discount' id='discount' name='discount' style={{ width: "80px", textAlign: "center", fontSize: 14, marginBottom: 5, padding: 4 }} />
                            <p style={{ fontSize: 13, margin: 0, padding: 0, color: "gray", marginBottom: 0 }}>Price</p>
                            <input value={product[15]} type="text" placeholder='Price' id='price' name='price' style={{ width: "80px", textAlign: "center", fontSize: 14, marginBottom: 5, padding: 4 }} />
                          </div>
                          <div class="col-sm">
                            <p style={{ fontSize: 13, margin: 0, padding: 0, color: "gray", marginBottom: 0 }}>Max Quantity</p>
                            <input value={product[18]} type="text" placeholder='Max Quantity' id='maxQuantity' name='maxQuantity' style={{ width: "130px", textAlign: "center", fontSize: 14, marginBottom: 5, padding: 4 }} />
                          </div>

                          <div className="col-xl" style={{ justifySelf: "flex-end", display: "flex", alignItems: "center", height: "100%", marginTop: 10 }}>
                            <div>
                              <p style={{ fontSize: 13, margin: 0, padding: 0, color: "gray", marginBottom: 0, marginLeft: 10 }}>Edit</p>
                              <button style={{ border: 0, outline: 0, width: "50px", background: "#fff" }} onClick={() => {
                                if (productsImages[product[6]] === 'None')
                                  AddProdShow(product)
                                else
                                  AddProdShow(product, productsImages[product[6]])
                              }}>
                                <img style={{ width: 25, height: 25 }} src={require('../../static/images/edit.png')} alt="" />
                              </button>
                            </div>

                            <div>
                              <p style={{ fontSize: 13, margin: 0, padding: 0, color: "gray", marginBottom: 0, marginLeft: 3 }}>Delete</p>
                              <button style={{ border: 0, outline: 0, width: "50px", background: "#fff" }} onClick={() => {
                                deleteRecord(product[0])
                              }} >
                                <img style={{ width: 25, height: 25 }} src={require('../../static/images/trash.png')} alt="" />
                              </button>
                            </div>
                          </div>


                        </div>

                        <div style={{ gap: "15px", width: "100%", padding: "0px 70px", display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "100px" }}>
                          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <p style={{ color: "gray", marginTop: 10, display: "flex", alignItems: "center", gap: 5 }}>Stock Quantity : <b style={{ color: "#000" }}>{product[20]}</b> | Is Active : <b style={{ color: "#000" }}> {product[7] === 0 ? "No" : "Yes"} </b> |
                              Rx : <b style={{ color: "#000" }}> {product[17] === 0 ? "No" : "Yes"} </b>| Total Sale : <b style={{ color: "#000" }}> 0  </b></p>
                          </div>
                          <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>Last Updated On : 2/11/2023 - 12:34:56 By User Name / Id</p>
                        </div>


                      </div>
                    </div>
                  )

                })
                : ''
              }
            </div>

            {/* Pagination Contorls */}
            <div className="prodPagination">
              <div style={{ width: '33%', display: 'flex', justifyContent: 'center', margin: "0 auto", height: "60px" }}>
                <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
                <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
                <input className={"pageNavButton"} size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
                <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
                <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
              </div>
            </div>

            <button
              onClick={scrollToTop}
              style={{ display: showButton ? 'block' : 'none' }}
              className='scrollTopBtn'
              id="myBtn"
              title="Go to top"
            >
              Top
            </button>

            {/* product Cards prev */}
            {/* <div className='topPartition'>
                    <div className='ProdRow' >
                      <div className='ProdCol' style={{ width: '20%' }} >
                        {product[0]}: {product[3]}
                      </div>
                      <div className='ProdCol' style={{ width: '3%', display: 'flex', alignItems: 'center', }} >
                        <input type='checkbox' name={product[0]} id={product[0]} />
                      </div>
                      <div className='ProdCol' style={{ width: '5%', height: '105%' }} >
                        <img className='proImg' id='proImg' src={`data:image/jpeg;base64,${base64String}`}
                          alt="products" onError={(e) => { e.target.src = defProdImage }} />
                        <div style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                          onClick={() => { deleteRecord(product[0], product[6]) }}>
                          REMOVE
                        </div>
                        <br />
                      </div>
                      <div className='ProdCol' style={{ width: '15%' }} >{product[9]}</div>
                      <div className='ProdCol' style={{ width: '10%' }} >{product[10]}</div>

                      <div className='ProdCol' style={{ width: '10%' }} >
                        <div style={{ textAlign: 'right' }}>MRP :{product[5]}</div>
                        <div style={{ textAlign: 'right' }}>Discount :{product[14]}</div>
                        <div style={{ textAlign: 'right' }}>Amount :{product[15]}</div>
                      </div>
                      <div className='ProdCol' style={{ width: '5%', textAlign: 'center' }} >{product[18]}</div>
                      <div className='ProdCol' style={{ width: '3%' }} >{product[16]}</div>
                      <div className='ProdCol' style={{ width: '3%' }} >{product[19]}</div>
                      <div className='ProdCol' style={{ width: '3%' }} >{product[7]}</div>
                      <div className='ProdCol' style={{ width: '4%' }} >{product[17]}</div>
                      <div className='ProdCol' style={{ width: '4%' }} >{product[20]}</div>
                      <div className='ProdCol' style={{ width: '4%' }} ></div>
                      <Tooltip title="Edit">
                        <button className='ProdCol' onClick={() => {
                          if (productsImages[product[6]] === 'None')
                            AddProdShow(product)
                          else
                            AddProdShow(product, productsImages[product[6]])
                        }}>
                          <img src={require('../../static/images/Icons/edit.png')} alt="" />
                        </button>
                      </Tooltip>
                      <Tooltip title="Remove">
                        <button className='ProdCol' onClick={() => deleteRecord(product[0])} >
                          <img src={require('../../static/images/Icons/trash.png')} alt="" />
                        </button>
                      </Tooltip>
                    </div>
                  </div> */}

            {/* <h6>&nbsp;&nbsp;&nbsp;&nbsp;Populating products</h6> */}
          </div>
        </div>
      </div>

      {/* addProd AddProdClose AddProdShow */}
      {/*  Col Attributes  sm md xs lg xl xxl  
          Modal Events onHide, onEnter, onEntered, onExit and onExited */}
      {/* <Modal show={addProd} onHide={AddProdClose} size="xl" backdrop="static" keyboard={false} onEntered = {PopulateOptions} > */}
      <Modal show={addProd} onHide={AddProdClose} size="xl" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>{listRec !== null ? "Editing " : "Add New"} Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>
          <Form onSubmit={handleSubmit} >
            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <div className="addProddropDown" id="selectCat">
                  <Select isMulti options={cateOptions} id="cat_idDD"
                    defaultValue={defCate} onChange={handleSelect} placeholder="Select Category"
                  />
                </div>
              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <div className="addProddropDown">
                  <div className='DropDown'>
                    <input type="hidden" name="GroupID" id="GroupID" defaultValue={listRec !== null ? listRec[26] : ""} />
                    <input className="DropDownInput" style={{ lineHeight: '1.9rem' }} type="text" name="prodGroup" id="prodGroup"
                      onFocus={() => { PopulateOptions('prodGroupCont', "prodGroup", groupOptions, "GroupID") }}
                      placeholder='Assign Group' defaultValue={filGroupLabel}
                      onKeyDown={(e) => {
                        var searGrp = document.getElementById('prodGroup').value;
                        const fillGroup = groupOptions.filter(option => option.label.toLowerCase().includes(searGrp.toLowerCase()));
                        // console.log("Fill qty", fillGroup.length, "   Text:", searGrp);
                        PopulateOptions('prodGroupCont', "prodGroup", fillGroup, "GroupID");
                      }}
                    />
                    <div className="dropDownArrow" style={{ width: '10%', marginTop: 3, marginRight: -20 }} onClick={() => { PopulateOptions('prodGroupCont', "prodGroup", groupOptions, "GroupID") }}>
                      <img className="dropDownBtn" src={require("../../static/images/Icons/down-arrow.png")} alt="" />
                    </div>
                  </div>
                  <div className='prodCatCont divshadow hide' id='prodGroupCont'></div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <input type="text"
                  defaultValue={listRec !== null ? listRec[3] : ""} onKeyPress={() => { removeErrorMessage("newprodName") }}
                  name="newprodName" id="newprodName" className='ProductFilterOption' placeholder='Product Name' />
              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <input defaultValue={listRec !== null ? listRec[9] : ""} type="text" name="newprodComp" id="newprodComp"
                  onKeyPress={() => { removeErrorMessage("newprodComp") }} className='ProductFilterOption' placeholder='Company Name' />
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={3} xl={3} sm={6} xs={12}>
                <input type="text" defaultValue={listRec !== null ? listRec[5] : ""} name="newprodPrice" id="newprodPrice"
                  onKeyPress={() => { removeErrorMessage("newprodPrice") }} className='ProductFilterOption' placeholder='Price MRP:' onBlur={() => { calculateAmount() }} />
              </Col>
              <Col lg={3} md={3} xl={3} sm={6} xs={12}>
                <input type="text" defaultValue={listRec !== null ? listRec[14] : ""} name="newprodDiscount" id="newprodDiscount"
                  onKeyPress={() => { removeErrorMessage("newprodDiscount") }} className='ProductFilterOption' placeholder='Discount in %' onBlur={() => { calculateAmount() }} />
              </Col>
              <Col lg={3} md={3} xl={3} sm={6} xs={12}>
                <input type="text" defaultValue={listRec !== null ? listRec[15] : ""} name="newprodAmount" id="newprodAmount" className='ProductFilterOption' placeholder='Product Amount' />
              </Col>
              <Col lg={3} md={3} xl={3} sm={6} xs={12}>
                <input type="text" defaultValue={listRec !== null ? listRec[10] : ""} name="newprodUnit" id="newprodUnit"
                  onKeyPress={() => { removeErrorMessage("newprodUnit") }} className='ProductFilterOption' placeholder='Unit' />
              </Col>
            </Row>
            <Row>
              <Col lg={3} md={3} xl={3} sm={6} xs={12}>
                <input type="text" defaultValue={listRec !== null ? listRec[18] : ""} name="newprodMaxQty" id="newprodMaxQty"
                  onKeyPress={() => { removeErrorMessage("newprodMaxQty") }} className='ProductFilterOption' placeholder='Max Buying Quantity' />
              </Col>
              <Col lg={3} md={3} xl={3} sm={6} xs={12}>
                <input type="text" defaultValue={listRec !== null ? listRec[13] : ""} name="newprodSize" id="newprodSize" className='ProductFilterOption' placeholder='Size' />
              </Col>
              <Col lg={3} md={3} xl={3} sm={6} xs={12}>
                <div className="addProddropDown">
                  <div className='DropDown'>
                    <input type="hidden" defaultValue={listRec !== null ? listRec[8] : ""} name="prodStatusID" id="prodStatusID" />
                    <input className="DropDownInput" defaultValue={listRec !== null ? listRec[8] : ""} type="text"
                      name="prodStatus" id="prodStatus" style={{ lineHeight: '1.9rem' }} placeholder='Domain Status'
                      onFocus={() => { PopulateOptions('prodStatusCont', "prodStatus", prodDomainsOptions, "prodStatusID") }} />
                    <div className="dropDownArrow" style={{ width: '15%', marginTop: 3, marginRight: -10 }}
                      onClick={() => { PopulateOptions('prodStatusCont', "prodStatus", prodDomainsOptions, "prodStatusID") }}>
                      <img className="dropDownBtn" src={require("../../static/images/Icons/down-arrow.png")} alt="" />
                    </div>
                  </div>
                  <div className='prodCatCont divshadow hide' id='prodStatusCont'></div>
                </div>
              </Col>
              <Col lg={3} md={3} xl={3} sm={6} xs={12}>
                <input type="text" defaultValue={listRec !== null ? listRec[17] : ""} name="newprodQuantity" id="newprodQuantity" className='ProductFilterOption' placeholder='Product Stock Quantity' />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <div className="CheckBoxes">
                  <div className="CheckBoxItems">
                    {/* {
                      listRec !== null? listRec[7] === 1 ?
                        <input type="checkbox" name="isActive" id="isActive" defaultChecked/>
                      : <input type="checkbox" name="isActive" id="isActive"/>
                      : <input type="checkbox" name="isActive" id="isActive" defaultChecked/>
                    }&nbsp;Is Active */}
                    {
                      listRec !== null ? listRec[7] === 1 ?
                        <input type="checkbox" name="isActive" id="isActive" defaultChecked />
                        : <input type="checkbox" name="isActive" id="isActive" />
                        : <input type="checkbox" name="isActive" id="isActive" defaultChecked />
                    }&nbsp;Is Active
                  </div>
                  <div className="CheckBoxItems">
                    {
                      listRec !== null ? listRec[16] === 1 ?
                        <input type="checkbox" name="outStock" id="outStock" defaultChecked /> :
                        <input type="checkbox" name="outStock" id="outStock" /> :
                        <input type="checkbox" name="outStock" id="outStock" />
                    }&nbsp;Is Out of Stock
                  </div>
                  <div className="CheckBoxItems">
                    {
                      listRec !== null ? listRec[19] === 1 ?
                        <input type="checkbox" name="isRx" id="isRx" defaultChecked /> :
                        <input type="checkbox" name="isRx" id="isRx" /> :
                        <input type="checkbox" name="isRx" id="isRx" />
                    }&nbsp;Prescription Required
                  </div>
                  <div className="CheckBoxItems">
                    {
                      listRec !== null ? listRec[20] === 1 ?
                        <input type="checkbox" name="isMol" id="isMol" defaultChecked /> :
                        <input type="checkbox" name="isMol" id="isMol" /> :
                        <input type="checkbox" name="isMol" id="isMol" />
                    }&nbsp;Is Molecule
                    {/* <input type="checkbox" name="isMol" id="isMol" /> */}
                  </div>
                </div>
              </Col>

              <Col lg={3} md={3} xl={3} sm={12} xs={12}>
                <input type="file" name="file" onChange={changeHandler}
                  // accept="image/*" 
                  accept=".jpg, .jpeg, .png"
                  id="newprodImage" className='ProductFilterOption' />
                <img id="preview" src={currImage !== null ? currImage : null } alt="" />
              </Col>

              <Col lg={3} md={3} xl={3} sm={12} xs={12}>
                <input type="file" name="gallery" multiple
                  // onChange={changeGalleryHandler} 
                  accept=".jpg, .jpeg, .png" id="newprodGallery" className='ProductFilterOption' />
                {/* <img id="preview" src={ currImage !== null ? currImage : ''} alt="" /> */}
              </Col>

            </Row>
            <Row>
              <Col lg={12} md={12} xl={12} sm={12} xs={12} >
                <div >
                  <p style={{ fontSize: "80%", marginTop: '2%', fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}> Details</p>
                  <Editor wrapperClassName="wrapper" editorClassName="editor" toolbarClassName="toolbar"
                    onEditorStateChange={onEditorChange}
                    defaultEditorState={eState}
                  // editorState = { eState }
                  />
                  <br />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12} >
              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12} >
                <button type='submit' className='btn btn-primary btn-sm'>Submit</button>
              </Col>
            </Row>

          </Form>
        </Modal.Body>

        {/* `pro_id0`, `temp_cat_id1`, `cat_id2` , `pro_name3` , `pro_detail4` , `pro_price5`, `pro_img6`, 
        `is_active7`, `pro_status8` , `company9` , `unit10` , `variants11` , `basevariant12` , `size13` , `pro_discount14` , 
        `pro_amount15`, `out_of_stock16`, `productquan17`, `maxBuyingProduct18` , `IsReqPrescription19` , `IsBuyable20` ,
        `ProductDate21` , `created_at22`, `updated_at23` , `ServiceTax24` , `serviceTaxPercent25` , `GroupID26` , `flag27` */}
      </Modal>
    </div>
  )
}

export default Products