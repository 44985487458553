import React, { useEffect, useState } from 'react'
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
import Footer from '../Containers/Footer';
import { ReactSession } from 'react-client-session';
// import cart from '../../SharedItems';
import { useNavigate } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import { Tooltip } from '@material-ui/core';

const ShippingAdd = () => {

  // orderType, test, presReq: presReq, cCity: selectedCityShip
  const queryParameters = new URLSearchParams(window.location.search);
  const cCity = queryParameters.get("custCity");
  const orderType = queryParameters.get("orderType", "");
  const test = queryParameters.get("test", "");

  // const files = queryParameters.get("files");

  const navigate = useNavigate();

  const [listAdd, setListAdd] = useState([]);
  const [filCityShip, setFilterCityShip] = useState();
  const [filStateShip, setFilterStateShip] = useState();

  const [cityOptions, setCityOptions] = useState([{ key: 0, value: '', label: 'None' },]);
  const [stateOptions, setStateOptions] = useState([{ key: 0, value: '', label: 'None' },]);
  const [shippAddresses, setShippAddresses] = useState(null);

  // For Address
  // const statusOptions = [
  //   { value: '', label: 'None' },
  //   { value: 1, label: 'Active' },
  //   { value: 0, label: 'Inactive' },
  // ]

  const [selectedCityShip, setCityShip] = useState(cityOptions.label);
  const handleSelectCity = e => {
    removeErrorMessage("cityDiv");
    setCityShip(e.value);
    const optionToSelect = cityOptions.find((option) => option.value === e.value);
    setFilterCityShip(optionToSelect);
  }

  const [selectedStateShip, setStateShip] = useState(stateOptions.label);
  const handleSelectState = e => {
    setStateShip(e.value);
    const optionToSelect = stateOptions.find((option) => option.value === e.value);
    setFilterStateShip(optionToSelect);
  }

  const [SelShipAddCode, SetShippAdd] = useState("0");

  //Select Styles
  const selectStyle = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      background: "white",
      border: '1px solid gray',
      lineHeight: '.5rem',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      paddingTop: '-20px',
      height: '20px',
      marginTop: '.4rem',
      minHeight: '30px',
      border: selectedCityShip !== null ? '1px solid gray' : '2px solid orangered',
    }),

    input: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginTop: '-5px',
    }),
  }


  function deleteCart(caID, flag) {
    var cartdata = ""
    if (flag === "item")
      cartdata = { cartId: caID, }
    else if (flag === "address")
      cartdata = { address: caID }

    fetch('http://43.205.199.21/userCartsAPI/', {
      mode: 'cors',
      method: 'DELETE',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(cartdata)
    })
      .then((response) => {
        response.json();
        setCityShip(null);
      })
      .then((data) => {
      })
      .catch((err) => {
        console.log(err.message);
      });
    setCityShip(null);
    setStateShip(null);
  }


  const handleSubmitAddress = (event) => {
    event.preventDefault();
    var responseMsg = "Address added successfully"
    var addID;

    var errorElements = [];


    if (listAdd !== null) {
      addID = listAdd[12];
      responseMsg = "Address updated successfully"
    }
    else
      addID = null;

    if (document.getElementById("custName").value === "")
      errorElements.push("custName");
    if (document.getElementById("custAddress").value === "")
      errorElements.push("custAddress");

    if (document.getElementById("custPincode").value === "" || isNaN(document.getElementById("custPincode").value))
      errorElements.push("custPincode");
    if (document.getElementById("custLandmark").value === "")
      errorElements.push("custLandmark");
    if (document.getElementById("custContact1").value === "" || isNaN(document.getElementById("custContact1").value))
      errorElements.push("custContact1");
    if (isNaN(document.getElementById("custContact2").value))
      errorElements.push("custContact2");

    if (cCity !== null) {
      if (String(cCity) !== String(selectedCityShip)) {
        const optionToSelect = cityOptions.find((option) => String(option.value) === cCity);
        // console.log("Location City :", optionToSelect.label);
        document.getElementById("errorMessage").innerHTML = "<h5>Choose correct city</h5><br />";
        document.getElementById("errorMessage").innerHTML += "<h6>Your Selected Location :<b>" + optionToSelect?.label + "</b></h6>";
        errorElements.push("cityDiv");
      }
    }

    errorElements.forEach(showError);
    function showError(item) {
      document.getElementById(item).classList.add("errorField");
    }

    if (errorElements.length > 0) {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById(errorElements[0]).focus();
      return false;
    }
    else {
      document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
      fetch('http://43.205.199.21/userCartsAPI/', {
        mode: 'cors',
        method: 'POST',
        headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        body: JSON.stringify({
          addressID: addID,
          custID: ReactSession.get('id_eu'),
          custName: document.getElementById('custName').value,
          custAddress: document.getElementById('custAddress').value,
          custCity: selectedCityShip,
          custState: selectedStateShip,
          custPincode: document.getElementById('custPincode').value,
          custLandmark: document.getElementById('custLandmark').value,
          custContact1: document.getElementById('custContact1').value,
          custContact2: document.getElementById('custContact2').value,
          isActive: document.getElementById('isActive').checked ? "1" : "0",
        })
      })
        .then((response) => {
          response.json();
          setCityShip(null);
        })
        .then((data) => {
          // console.log(data);
          GetAddress();
        })
        .catch((err) => {
          console.log(err.message);
        });

      alert(responseMsg);
      AddAddressClose();
    }
  }

  const setAddressCode = (addr) => {
    SetShippAdd(addr);
  }


  const removeErrorMessage = (element) => {
    document.getElementById(element).classList.remove("errorField");
  }

  //Address Back Drop Modal Down 
  const [addAddress, setShowAddAddress] = useState(false);
  const AddAddressClose = () => {
    document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
    setShowAddAddress(false);
  }

  const AddAddressShow = (address = null) => {
    setFilterCityShip("");
    setFilterStateShip("");
    setCityShip(null);
    setStateShip(null);

    if (address !== null) {
      if (address[2] !== 0 || address[2] !== null) {
        const filCityID = cityOptions.filter(cityid => {
          return cityid.label === address[2];
        });
        if (filCityID.length > 0) {
          setFilterCityShip(filCityID[0]);
          setCityShip(filCityID[0].value);
        }
      }

      if (address[3] !== 0 || address[3] !== null) {
        const filStateID = stateOptions.filter(stateid => {
          return stateid.label === address[3];
        });
        if (filStateID.length > 0) {
          setFilterStateShip(filStateID[0]);
          setStateShip(filStateID[0].value);
        }
      }
    }
    else {
      setFilterCityShip(null);
      setFilterStateShip(null);
    }
    setListAdd(address);
    setShowAddAddress(true);
  }

  function handleContinue() {
    if (SelShipAddCode === "0")
      document.getElementById("errorMessageAdd").classList.replace("errorMessageHide", "errorMessage");
    else {
      document.getElementById("errorMessageAdd").classList.replace("errorMessage", "errorMessageHide");
      navigate('/OrderSummary?shippingAddress=' + SelShipAddCode + '&custCity=' + cCity + '&orderType=' + orderType + '&test=' + test);
    }
  }


  function locatePinCity() {
    // var URL = "https://api.postalpincode.in/pincode/"
    var locateURL = "http://43.205.199.21/location?pincode=";
    fetch(locateURL + document.getElementById("custPincode").value, {
      method: 'GET',
    }).then((response) => {
      return response.json()
    }).then((res) => {
      // console.log("Pin :", res);
      const filCityID = cityOptions.filter(cityid => {
        // return cityid.label === res[0].PostOffice[0].District;
        return cityid.label === res.city;
      });
      if (filCityID.length > 0) {
        setFilterCityShip(filCityID[0]);
        setCityShip(filCityID[0].value);
      }

      // console.log("Length State",(res.state).substring(0, ((res.state).length -1)));
      const filStateID = stateOptions.filter(stateid => {
        return stateid.label === (res.state).substring(0, ((res.state).length - 1));
      });
      if (filStateID.length > 0) {
        setFilterStateShip(filStateID[0]);
        setStateShip(filStateID[0].value);
      }
    });
    return;
  }

  const GetAddress = () => {
    fetch("http://43.205.199.21/userCartsAPI?userId=" + ReactSession.get('id_eu') + "&custCity=" + cCity, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      setShippAddresses(res.addresses);
      setCityOptions(res.citiesD);
      setStateOptions(res.statesD);
    });
  }


  useEffect(() => {
    GetAddress();
  }, []);

  return (
    <>
      <Logo />
      <NavSearchBar />
      <div id="addresses">
        {
          shippAddresses ?
          shippAddresses.map((address) => {
              return (
                <div className='cartColTopBorderDotted' style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                  <Row>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1}>
                      <input type='radio' name='shipAddress' value={address[12]} id='shipAddress' onClick={() => setAddressCode(address[12])} />
                    </Col>

                    <Col md={9} lg={9} xl={9} sm={9} xs={9} className='px-0 mx-0'>
                      <p className='prodInfo' >Name : {address[4]}</p>
                      <p className='prodInfo' >Address  :{address[5]} &nbsp;&nbsp; Pin: {address[1]}</p>
                      <p className='prodInfo' >Landmark : {address[7]}</p>
                      <p className='prodInfo' >City : {address[2]} &nbsp;&nbsp;&nbsp;&nbsp;State : {address[3]}</p>
                      <p className='prodInfo' >Contact 1 :{address[6]} &nbsp;&nbsp;Contact 2 : {address[8]}</p>
                    </Col>

                    <Col md={1} lg={1} xl={1} sm={1} xs={1} className='px-0 mx-0'>
                      {/* Deleting Address Need to disable for customer  */}
                      <Tooltip title="Delete">
                        <img className="MyCartIcons" onClick={() => deleteCart(address[12], "address")} src={require('../../static/images/Icons/trash.png')} alt="" />
                      </Tooltip>
                    </Col>
                    <Col md={1} lg={1} xl={1} sm={1} xs={1} className='px-0 mx-0'>
                      <Tooltip title="Edit">
                        <img className="MyCartIcons" onClick={() => { AddAddressShow(address) }} src={require('../../static/images/Icons/edit.png')} alt="" />
                      </Tooltip>
                    </Col>
                  </Row>
                </div>)
            })
            : null
        }
        <div style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
          <button className='btn btn-outline-primary btn-sm' style={{ lineHeight: '.7rem', marginTop: '1rem' }} onClick={() => { AddAddressShow() }}>Add Delivery Address</button>
        </div>
      </div>
      <div className='cartColTopBorderDotted' style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="errorMessageHide" id="errorMessageAdd">Please select a shipping address</div>

        <Row>
          <Col md={12} lg={12} xl={12} sm={12} xs={12}>
            <button className='btn btn-outline-primary btn-sm' style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
              onClick={() => handleContinue()}
            >Continue</button>
          </Col>
        </Row>
      </div>

      <Footer />


      <Modal show={addAddress} onHide={AddAddressClose} size="lg" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>{listAdd !== null ? "Editing " : "Add New"} Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

          <Form onSubmit={handleSubmitAddress} >
            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <input defaultValue={listAdd !== null ? listAdd[4] : ""} type="text" name="custName" id="custName" className='ProductFilterOption' onKeyPress={() => { removeErrorMessage("custName") }} placeholder='Name' />
              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <input type="text" defaultValue={listAdd !== null ? listAdd[5] : ""} name="custAddress" id="custAddress" className='ProductFilterOption' onKeyPress={() => { removeErrorMessage("custAddress") }} placeholder='Address' />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <input type="text" size='6' maxLength={6} defaultValue={listAdd !== null ? listAdd[1] : ""} name="custPincode" id="custPincode" className='ProductFilterOption' onBlur={() => { locatePinCity() }} onKeyPress={() => { removeErrorMessage("custPincode") }} placeholder='Pin Code :' />
              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <input type="text" defaultValue={listAdd !== null ? listAdd[7] : ""} name="custLandmark" id="custLandmark" className='ProductFilterOption' onKeyPress={() => { removeErrorMessage("custLandmark") }} placeholder='Landmark :' />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <input type="text" size='10' maxLength={10} defaultValue={listAdd !== null ? listAdd[6] : ""} name="custContact1" id="custContact1" className='ProductFilterOption' onKeyPress={() => { removeErrorMessage("custContact1") }} placeholder='Primary Contact :' />
              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <input type="text" size='10' maxLength={10} defaultValue={listAdd !== null ? listAdd[8] : ""} name="custContact2" id="custContact2" className='ProductFilterOption' placeholder='Alternate Contact : ' />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12} id="cityDiv" className="cityDiv">

                <Select
                  options={cityOptions}
                  id="LocationCity1"
                  defaultValue={filCityShip}
                  value={filCityShip}
                  onChange={handleSelectCity}
                  placeholder="Select City"
                  styles={selectStyle} />

              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <Select
                  options={stateOptions}
                  id="StateID1"
                  defaultValue={filStateShip}
                  value={filStateShip}
                  onChange={handleSelectState}
                  placeholder="Select State"
                  styles={selectStyle}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12}>
                <div className="CheckBoxes">
                  <div className="CheckBoxItems">
                    {
                      listAdd !== null ? listAdd[9] === 1 ?
                        <input type="checkbox" name="isActive" id="isActive" checked /> :
                        <input type="checkbox" name="isActive" id="isActive" /> :
                        <input type="checkbox" name="isActive" id="isActive" />
                    } &nbsp;Is Active
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} xl={6} sm={12} xs={12} >
              </Col>
              <Col lg={6} md={6} xl={6} sm={12} xs={12} >
                <button type='submit' className='btn btn-primary btn-sm'>Submit</button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default ShippingAdd