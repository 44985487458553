import React from 'react'

const NewTips = () => {

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 5;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);

  return (
    <>
      <div className='row topPartition'>
        <div className='col text-center pt-2'>
          <h3>Latest News & Health Tips</h3>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
          <div className="card reveal">
            <img src={require("../../static/images/NewsTips/Diabetes.png")} className="card-img-top" alt="..." />
            <div className='row'>
              <div className='col-6'>
              <h5 className="card-title">Diabetic</h5>
              </div>
              <div className='col-6' style={{textAlign:"right"}}>
              <h5 className="card-title">2 Days ago</h5>
              </div>
            </div>
            <br/>
            <div>
              <h5 className="">Dr. Amit Trivedi</h5>
              <p className="">MBBS, MD, Endocrinology</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
          <div className="card reveal">
            <img src={require("../../static/images/NewsTips/HealthDay.png")} className="card-img-top" alt="..." />
            <div className='row'>
              <div className='col-6'>
              <h5 className="card-title">World Health Day</h5>
              </div>
              <div className='col-6' style={{textAlign:"right"}}>
              <h5 className="card-title">1 Day ago</h5>
              </div>
            </div>
            <br/>
            <div>
              <h5 className="">Dr. Samay Gupta</h5>
              <p className="">DM, Cardiology</p>
            </div>
          </div>
        </div>

        <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
          <div className="card reveal">
            <img src={require("../../static/images/NewsTips/Corona.png")} className="card-img-top" alt="..." />
            <div className='row'>
              <div className='col-6'>
              <h5 className="card-title">Corona Pendamic</h5>
              </div>
              <div className='col-6' style={{textAlign:"right"}}>
              <h5 className="card-title">6 Hours ago</h5>
              </div>
            </div>
            <br/>
            <div>
              <h5 className="">Dr. Narendra Chhablani</h5>
              <p className="">MBBS, MD Medicine</p>
            </div>
            {/* <div className="card-body">
              <h5 className="card-title">Card title</h5>
              <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">An item</li>
              <li className="list-group-item">A second item</li>
              <li className="list-group-item">A third item</li>
            </ul>
            <div className="card-body">
              <a href={() => false} className="card-link">Card link</a>
              <a href={() => false} className="card-link">Another link</a>
            </div> */}
          </div>
        </div>

      </div>
    </>
  )
}
export default NewTips