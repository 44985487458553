import React, {useEffect, useState} from 'react'
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel'
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar'
import { IoSearchOutline } from 'react-icons/io5'
import { FaAngleDown } from 'react-icons/fa'
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select';
import { FiSearch } from "react-icons/fi";
import { ReactSession } from 'react-client-session';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from '../Containers/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../DatePicker.css';
import { useNavigate } from "react-router-dom";
import Oops from '../Containers/Oops'


ReactSession.setStoreType("localStorage");

const OutstandingReports = () => {
  
  const navigate = useNavigate();
  const [partyWiseSales, setPartyWiseSales] = useState(null);
  const [selectedParty, setSelectedParty] = useState(null);
  const [allInvoices, setAllInvoices] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Outstandings, setOutstandings] = useState(null);

  const [partyTotals, setPartyTotals] = useState(null);
  const [totalsInvoice, setTotalsInvoice] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [dateFilter, setDateFilter] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  
  // Invoice Details
  const [selStockist, setSelStockist] = useState(null);
  const [salesBillVendor, setSalesBillVendor]= useState(null);
  const [invHeads, setInvHeads] = useState(null);
  const [totals, setTotals] =useState(null); 
  const [invoiceItems, setInvoiceItems] = useState(null);
  const [vendorType, setVendorType] = useState(null);
  // Handle Printer
  const printInvoiceDetailsRef = React.useRef();
  const handlePrintUserStock = useReactToPrint({ content: () => printInvoiceDetailsRef.current, });
  // const [partyWiseSalesPrint, setPartyWiseSalesPrint] = useState(null);

  const [filltered, setFilltered] = useState(false);
  const [Qry, setQry] = useState('');

  const [pagesize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [noofRec, setNoOfRec] = useState(null);
  
  function inWords(n) {
    if (n < 0)
      return false;
    var single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    var double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    var below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
    if (n === 0) return ''
    function translate(n) {
      var word = "";
      var rem;
      if (n < 10) {
        word = single_digit[n] + ' '
      }
      else if (n < 20) {
        word = double_digit[n - 10] + ' '
      }
      else if (n < 100) {
        rem = translate(n % 10)
        word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
      }
      else if (n < 1000) {
        word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
      }
      else if (n < 1000000) {
        word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
      }
      else if (n < 1000000000) {
        word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
      }
      else {
        word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
      }
      return word
    }
    var result = translate(n)
    return result.trim() + '.'
  }

  const executeQry = () => {
    setFilltered(!filltered);
    setPage(1);
  }

  const handleSearchText = (e) => {
    setQry(e.target.value);
    if (e.key === "Enter") {
      executeQry();
    }
  }

  const prevPage = () => {
    if (page > 1)
      setPage(page - 1);
  }

  const nextPage = () => {
    if (page <= parseInt(noofRec / pagesize))
      setPage(page + 1);
    else
      alert("No more data");
  }

  const topPage = () => {
    if (page > 1)
      setPage(1);
  }

  const lastPage = () => {
    if (page < parseInt(noofRec / pagesize))
      setPage(parseInt(noofRec / pagesize) + 1);
    else
      alert("No more data");
  }

  // Days difference between dates
  const calculateDaysBetween = (date1, date2) => {
    const date1_ms = new Date(date1).getTime();
    const date2_ms = new Date(date2).getTime();
    const difference_ms = date2_ms - date1_ms;
    const differenceInDays = difference_ms / (1000 * 60 * 60 * 24);
    return differenceInDays;
  };

  
  const getTransactionDetails = (transID) => {
    setIsLoading(true);
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?user_Id=' + dp_id + '&sales_bill=' + transID;
    
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      else if (resp.status === "success") { 
        setSalesBillVendor(resp.vendorDet[0]);
        setInvHeads(resp.invoiceHeads[0]);
        // setPayMode(resp.invoiceHeads[0][4]);
             
        // setSelStockist([resp.invoiceHeads[0][7], resp.invoiceHeads[0][8], resp.invoiceHeads[0][9], resp.invoiceHeads[0][10], resp.invoiceHeads[0][11], resp.invoiceHeads[0][12], resp.invoiceHeads[0][13], resp.invoiceHeads[0][14], resp.invoiceHeads[0][15], resp.invoiceHeads[0][16], resp.invoiceHeads[0][17], resp.invoiceHeads[0][18], resp.invoiceHeads[0][19]]);
        
        setTotals([resp.invoiceHeads[0][20], resp.invoiceHeads[0][21], resp.invoiceHeads[0][22], resp.invoiceHeads[0][23], resp.invoiceHeads[0][24], resp.invoiceHeads[0][25], resp.invoiceHeads[0][26], resp.invoiceHeads[0][27]]);
        setInvoiceItems(resp.invoiceItems);
        setVendorType(1);
      }
      setIsLoading(false);
    }).catch((Error)=>{
      alert("Request Fails");
      setIsLoading(false);
      console.log("Error :",Error);
    });
  }

  const GetOutstandings = (dtFrom = null, dtTo = null) => {
    var dp_id = ReactSession.get("id");
    var apiURL = 'http://43.205.199.21/digiPartnerAPI?user_Id=' + dp_id + '&getOutStandings=0&page=' + page + '&pagesize=' + pagesize + '&Qry=' + Qry;

    if (dtFrom !== null && dtTo !== null)
      apiURL += '&dtFrom=' + dtFrom + '&dtTo=' + dtTo;

    setIsLoading(true);
    fetch(apiURL, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((resp) => {
      if (resp.status === "validityExpired") {
        alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
      }
      else if (resp.status === "accessError") {
        alert("Your current subscription doesnot Party Wise Details, please contact Sheet360ERP sales team.")
      }
      else if (resp.status === "success") {
        setTotalsInvoice(resp.totalsInvoice);
        setAllInvoices(resp.allInvoices);
        setOutstandings(resp.outstandings);
        setNoOfRec(resp.noofRec);
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    setPage(1);
  }, [filltered, endDate]);

  useEffect(() => {
    if (startDate !== null && endDate !== null && endDate > startDate) {
      var dtFrom = (startDate.toLocaleDateString()).split('/');
      var dtTo = (endDate.toLocaleDateString()).split('/');
      var dateFrom = dtFrom[2] + '-' + dtFrom[0] + '-' + dtFrom[1];
      var dateTo = dtTo[2] + '-' + dtTo[0] + '-' + dtTo[1];
      GetOutstandings(dateFrom, dateTo);
    }
    else {
      GetOutstandings();
    }
  }, [page, filltered, endDate]);

  useEffect(() => {
    if (invoiceItems !== null) {
      handlePrintUserStock();
    }

    return setInvoiceItems(null);
  }, [selStockist, salesBillVendor, invHeads, totals, invoiceItems, vendorType]);

  const openDropDown = (element) => {
    // let el = document.querySelector(element);
    var el = document.getElementById(element);
    el.classList.toggle('active');
  }

  return (
    <>
      <DigiPartNavBar />
      <div className='DigitalPartnerContainer' >
        <div className='DigiDashPanel' id='DigiDashPanel'>
          <DigiPartnerSidePanel />
        </div>
        <div className='DigiDashContent outStandingReportsCon' id='DigiDashContent' style={{ backgroundColor: '#fff', padding: "10px 20px" }}>
          { isLoading ? <Loader /> : null}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", paddingBottom: "10px", marginBottom: "10px", borderBottom: "1.5px solid #000", marginTop: 10 }}>
            <h3 style={{ margin: 0, padding: 0 }}>Outstanding Reports</h3>
            <div className="inputBox" >
              <input type="text" placeholder='Search By Customer Name...' onChange={handleSearchText}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    executeQry();
                  }
                }}
                value={Qry} />
              <i><IoSearchOutline /></i>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 10, marginLeft: 20 }}>
              {/* <input type="date" className='removeDateIcon' style={{ border: 0, outline: 0, textTransform: "uppercase", color: "gray", width: "105px" }} />
              <span style={{ margin: 0, padding: 0 }}>-</span>
              <input type="date" className='removeDateIcon' style={{ border: 0, outline: 0, textTransform: "uppercase", color: "gray", width: "105px" }} /> */}
              <div className='dateCont'>
                <DatePicker selected={startDate} onChange={(date) => {
                  if (endDate && (new Date(date) > endDate)) {
                    alert("Start date must smaller than End date");
                  }
                  else {
                    setstartDate(new Date(date));
                    setDateFilter(true);
                  }
                }} placeholderText='MM/DD/YYYY'/>
              </div>

              <div>&nbsp;:&nbsp;</div>
              <div className='dateCont'>
                <DatePicker selected={endDate}
                  onChange={(date) => {
                    if (startDate && (new Date(date) < startDate)) {
                      alert("End Date must bigger than start date");
                    }
                    else {
                      setDateFilter(true);
                      setendDate(new Date(date));
                    }
                  }} placeholderText='MM/DD/YYYY'
                />
              </div>
              <img src={require("../../static/DPImages/calendar.png")} style={{ width: 25, height: 25, cursor: "pointer" }} alt="" />
            </div>
            {
              Qry !== "" || dateFilter !== null ?
              <Tooltip title="Remove Filter">
                <img onClick={() => {
                  setDateFilter(null);
                  setendDate(null);
                  setstartDate(null);
                  setQry('');
                  setFilltered(!filltered);
                }} className="closeBtn" style={{ width: '20px', height: '20px' }} src={require('../../static/DPImages/Icons/filterClear.png')} alt="" />
              </Tooltip>
              :
              null
          }
          </div>

          <div className="outStandingReports customScroll" style={{ marginTop: 10, paddingRight: 15 }}>
            <div className="reportHead" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <p style={{ color: "gray", fontSize: 14, margin: 0, padding: 0, width: "55vw" }}>Customer Details</p>
              <p style={{ color: "gray", fontSize: 14, margin: 0, padding: 0 }}>Pending Invoice</p>
              <p style={{ color: "gray", fontSize: 14, margin: 0, padding: 0 }}>Total Outstanding</p>
            </div>

            { 
              Outstandings && Outstandings !== null ? 
              Outstandings.map((outStanding, index )=>{
                const clss = "reportDetailsDropDownItem-"+String(index);
                return (
                  <>
                    <div key = {index} className="report">
                      <div className="details" style={{ height: "100%", width: "55vw" }}>
                        <h3 style={{ margin: 0, padding: 0, fontSize: 22 }}>{outStanding[6]}-{'('}{outStanding[5]}{')'}</h3>
                        <p style={{ fontSize: 15, color: "gray", margin: 0, padding: 0 }}>{outStanding[7]}, {outStanding[8]}, {outStanding[13]}, {outStanding[14]} - {outStanding[12]} <br />STD: {outStanding[16]}: {outStanding[17]}, Mobile : {outStanding[9]}, {outStanding[10]} </p>
                      </div>
                      <p style={{ fontSize: 15, color: "gray", margin: 0, padding: 0, textAlign: "center" }}>{outStanding[3]}</p>
                      <p style={{ fontSize: 15, color: "#000", margin: 0, padding: 0, textAlign: "center", fontWeight: "700" }}>{Number(outStanding[21]).toFixed(2)}<br /> 
                      <button onClick={()=>openDropDown(`${clss}`, outStanding[5])} style={{ padding: 5, minWidth: "120px", border: 0, outline: 0, color: "#fff", cursor: "pointer", background: "#00BAFF", borderRadius: 10, fontSize: 13 }}>Details <i style={{color:"#fff",fontSize:14}}><FaAngleDown /></i></button> </p>
                    </div>
                    
                    {/* All Invoices */}
                    <div className="reportDetailsDropDownItems" id = {`${clss}`}>
                      <div class="row tableHead">
                        <div class="col-sm">Date</div>
                        <div class="col-sm">Invoice No</div>
                        <div class="col-sm">Pending Days</div>
                        <div class="col-sm">Total Outstanding</div>
                        <div class="col-sm">Invoices</div>
                      </div>
                      {
                        allInvoices && allInvoices !== null ?
                        allInvoices.map((invoice, index)=>{
                          var dateNtime = String(invoice[11]).split('T');
                          var pendingDays = parseInt(calculateDaysBetween(invoice[11], currentDate));

                          if (outStanding[5] === invoice[5])
                          {
                            return (
                            <div key={index} className="tableRecords table-record-1">
                              <div class="row ">
                                <div class="col-sm">{invoice[11].split('T')[0]}</div>
                                <div class="col-sm">{invoice[2]}</div>
                                <div class="col-sm">{pendingDays}</div>
                                <div class="col-sm">{Number(invoice[8]).toFixed(2)}</div>
                                <div class="col-sm">
                                  <button  onClick={()=>{
                                    setSelStockist(outStanding);
                                    getTransactionDetails(invoice[7]);
                                    }} className="view" style={{ padding: "2px 10px", minWidth: "70px", color: "#fff", border: 0, outline: 0, borderRadius: 10, background: "#00BAFF", fontSize: 12 }}>View Invoice</button>
                                </div>
                              </div>
                            </div>
                          )}
                          else return null
                        })
                        : null
                      }
                    </div>
                  </>
                )
              })
              : <Oops title={"No Records Found !"}/>
            }
          </div>
          <div className="outStandingCon" style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
            <p style={{ color: "gray", margin: 0, padding: 0, marginRight: "50px", marginTop: "10px" }}>Total Outstanding: <b style={{ color: "#000" }}>{ totalsInvoice && totalsInvoice !== null ? Number(totalsInvoice).toFixed(2) : null}</b></p>
          </div>

          {/* Paginations */}
          <div className="prodPagination" style={{ padding: '0px', margin: '0px' }}>
            <div style={{ width: '33%', display: 'flex', justifyContent: 'center', margin: "0 auto" }}>
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => topPage()} >Top</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page > 1 ? "active" : ""}`} onClick={() => prevPage()} >Previous</button>&nbsp;&nbsp;
              <input className={"pageNavButton"} size='3' type="text" name="pageNo" id="pageNo" placeholder={page} />&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => nextPage()} >Next</button>&nbsp;&nbsp;
              <button className={`pageNavButton ${page !== page.length ? "active" : ""}`} onClick={() => lastPage()} >Bottom</button>
            </div>
          </div>
        </div>


        {/* classes => printable-document page-break page */}
        <div ref={printInvoiceDetailsRef} className='printable-document' style={{overflow:'visible'}}>
            {
              invoiceItems && invoiceItems.length > 0 ?
                invoiceItems.map((invItem, index) => {
                  return (
                    <>
                      {index % 15 === 0 && index > 9 ?
                        <div style={{ marginLeft: '30%' }}>to be continued on next page......</div>
                        : null
                      }

                      {
                        index % 15 === 0 && salesBillVendor !== null ?
                          <div className='page-break' style={{ width: '100%', marginLeft: '3%', marginRight: '3%', display: 'flex', justifyContent: 'flex-start' }}>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                              <div style={{ width: '90%', marginTop: '4%', lineHeight: '95%', color: "gray" }}>
                                <div style={{ fontSize: 25, marginBottom: 5, lineHeight: '100%', color: "#000",}}>{salesBillVendor[2]}</div>
                                <div>{salesBillVendor[3]}</div>
                                <div>{salesBillVendor[6]}</div>
                                <div>{salesBillVendor[4]}, {salesBillVendor[5]}</div>
                                {/* <div>Bhopal Madhya Pradesh 462001</div> */}
                                <div>{salesBillVendor[7] !== null || salesBillVendor[8] !== null ? 'Phone ':null}{salesBillVendor[7] !== null ? salesBillVendor[7] : null}, {salesBillVendor[8] !== null ? salesBillVendor[8] : null} <br />{salesBillVendor[9] !== null ? 'Email: '+salesBillVendor[9] : null }</div>
                              </div>

                            </div>

                            <div style={{ width: '50%', marginTop: '4%', lineHeight: '95%' }}>
                              {
                                selStockist[18] === '' && invHeads !== null ?
                                  <div style={{ textAlign: "right", color: "gray", marginRight: 50 }}>
                                    <div> Mr./Mrs.{invHeads[8]} </div>
                                    <div> {invHeads[9]} </div>
                                    <div> {invHeads[10]} </div>
                                    <div> {invHeads[11] !== null || invHeads[11] !== '' ? invHeads[11] : null} {invHeads[12] !== null || invHeads[12] !== '' ? ', '+invHeads[12] : null}</div>
                                    <div>{invHeads[15] !== null || invHeads[16] !== null ? 'Contact ': null} {invHeads[15] !== null || invHeads[15] !== '' ? invHeads[31] : null}{invHeads[16] !== null || invHeads[16] !== '' ? ', '+invHeads[16] : null} </div>
                                  </div>
                                  :
                                  selStockist && selStockist !== "" ?
                                    <div style={{ fontSize: '90%', lineHeight: '95%', marginLeft: '3%', marginRight: '3%' }}>
                                      <span style={{ fontWeight: 'bold' }}>Ms. {selStockist[4]}</span> <br />
                                      Invoice no : {selStockist[2]} <br />
                                      {selStockist[6]} <br />
                                      {selStockist[7]} <br />
                                      {selStockist[18] !== null ? 'GSTIN Number '+selStockist[18] : null} <br />
                                      {selStockist[19] ? 'Drug License Number '+selStockist[19].split('___')[0] + "  |  " + selStockist[19].split('___')[1] : null} <br />
                                      {selStockist[9] !== null || selStockist[10] !== null ? selStockist[9] : null}{selStockist[10] !== null ? ', '+selStockist[10] : null}
                                      {/* <br />E-Mail :{selStockist[10]} */}
                                    </div>
                                    : null
                              }
                            </div>

                          </div>
                          : null
                      }

                      {index % 15 === 0 && salesBillVendor !== null ?
                        <div style={{ fontSize: '90%', marginTop: '2%', paddingBlock: '.5%', borderTop: "1px solid #000", borderBottom: "1px solid #000", paddingBottom: 5, paddingTop: 5, lineHeight: '94%', display: 'flex', width: '95%', marginLeft: '3%', marginRight: '3%', justifyContent: "space-between", alignItems: "center" }}>
                          <div style={{ width: '40%', color: "gray" }}>
                            <div>
                              {salesBillVendor[18] !== null ? 'GSTIN: '+salesBillVendor[18] : null}
                            </div>
                            {/* <div>
                              FSSAI No:
                            </div> */}

                            <div>
                            {selStockist[19] ? 'Drug License Number '+selStockist[19].split('___')[0] + "  |  " + selStockist[19].split('___')[1] : null} <br />
                            </div>
                          </div>

                          <h2 style={{ fontSize: 20, marginLeft: -50, marginTop: 10 }}>Tax Invoice</h2>

                          <div style={{ textAlign: "right", color: "gray" }}>
                            {
                              invHeads ?
                                <>
                                  <div>Invoice No : {invHeads[1]} </div>
                                  <div>Date :{invHeads[5].substring(0, 10)}</div>
                                </>
                                : null
                            }
                            {/* <div>Bill: {payMode} </div> */}
                          </div>

                        </div>
                        : null
                      }

                      {index % 15 === 0 ?
                        <div style={{ fontSize: '90%', display: 'flex', minWidth: '95%', justifyContent: 'flex-start', marginLeft: '3%', marginRight: '3%' }}>
                          <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>S.N</div>
                          <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Particulars</div>
                          <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Packing</div>
                          <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Hsn</div>
                          <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Batch</div>
                          <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Exp</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Qty</div>
                          <div style={{ width: '5%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Rate</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Disc.</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>SGST</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>CGST</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>IGST</div>
                          <div style={{ width: '10%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5 }}>Amoumt</div>
                        </div>
                        :
                        null
                      }

                        <div style={{
                          fontSize: '90%', display: 'flex', minWidth: '95%', justifyContent: 'flex-start', marginLeft: '3%',
                          marginRight: '3%', borderBottom: (index + 1) % 15 === 0 || (index + 1) === invoiceItems.length ? '1px solid black' : '',
                        }}>
                          <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {index + 1} </div>
                          <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[1].length <= 25 ? `${invItem[1]}` : `${invItem[1].substring(0, 22)}...`} </div>
                          <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[2].length <= 15 ? `${invItem[2]}` : `${invItem[2].substring(0, 12)}...`} </div>
                          <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[12]} </div>
                          <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[11]} </div>
                          <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[9]}/{(String(invItem[10])).substring(2, 4)}</div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[4]} </div>
                          <div style={{ width: '5%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[14]} </div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[15]} </div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[6]} </div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[7]} </div>
                          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "gray" }}> {invItem[8]} </div>
                          <div style={{ width: '10%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', padding: 5, color: "#000" }}> {invItem[16]} </div>
                        </div>


                      {
                        (index + 1) === invoiceItems.length && salesBillVendor !== null ?
                          <div style={{ width: '95%', marginRight: '3%', marginLeft: '3%',color:"gray" ,marginTop:20,}}>
                            <div style={{ marginRight: '.5vw', display: 'flex', justifyContent: 'flex-start', marginTop: '.5vw' }} >
                              <div style={{ width: '45%', fontSize: '80%', lineHeight: '90%' }}>
                                <div style={{ textDecoration: 'underline',marginBottom:10 }}>Terms & Conditions</div>
                                <div>1. Transactions, subject to {salesBillVendor[4]} Jurisdiction.</div>
                                <div>2. Goods once sold will not be taken back.</div>
                                <div>3. We will not reponsible for any demage of loss in transit.</div>
                                <div>4. Interest: @2% per month will be charged over due date.</div>
                                {/* <div>NOTE:</div> */}
                              </div>

                              {/* <div style={{ paddingLeft: '3%', width: '20%', textAlign: 'right', paddingRight: '.5%', fontSize: '85%', lineHeight: '80%' }}>
                                <div>for {String(salesBillVendor[2]).toUpperCase()}</div>
                                <br /><br /><br /><br />
                                <div>Authorised Signatory</div>
                              </div> */}

                              <div style={{ fontSize: '90%', lineHeight: '90%', width: '18%',marginLeft: '10%' }}>
                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    SGST
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[2]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    CGST
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[3]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    IGST
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[4]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    GST Total
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[2] + totals[3] + totals[4]).toFixed(2)}
                                  </div>
                                </div>
                              </div>

                              <div style={{ fontSize: '14px',minWidth:"250px",lineHeight:1, width: '20%' }}>
                                <div style={{ display: 'flex'}}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    {vendorType === 0 ? 'Sub Total' : 'MRP Total'}
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[6]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex'}}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    Cash Discount
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[5]).toFixed(2)}
                                  </div>
                                </div>

                                <div style={{ display: 'flex'}}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    Debit / Credit
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    0.00{ }
                                  </div>
                                </div>
                                <div style={{ display: 'flex'}}>
                                  <div style={{ paddingInline: '2%', width: '60%', }}>
                                    Others
                                  </div>
                                  <div style={{ paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    0.00{ }
                                  </div>
                                </div>

                                <div style={{ display: 'flex',paddingTop:10,borderTop:"1px solid #000",marginTop:10,color:"#000"}}>
                                  <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '60%', }}>
                                    Payable
                                  </div>
                                  <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '40%', textAlign: 'right' }}>
                                    {Number(totals[6] - totals[5]).toFixed(2)}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div style={{ fontSize: '1vw',marginTop:-20 }}>
                              <div style={{ fontSize: '.9vw', }}>Amount in words Rupees</div>
                              <div style={{color: '#000'}}>{inWords(totals[6] - totals[5])}</div>
                            </div>
                          </div>
                        : null
                      }
                    </>
                  )
                })
                :
                null
            }
          </div>
      </div>
    </>
  )
}

export default OutstandingReports