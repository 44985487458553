import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import DigiPartNavBar from './DigiPartnerContainers/DigiPartNavBar';
import DigiPartnerSidePanel from './DigiPartnerContainers/DigiPartnerSidePanel';
import Loader from '../Containers/Loader';
import Modal from 'react-bootstrap/Modal';
import { useReactToPrint } from 'react-to-print';
import Oops from '../Containers/Oops';

ReactSession.setStoreType("localStorage");

const SalesChallan = () => {

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  // Handle Printer
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  });
  const [totals, setTotals] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invHeads, setInvHeads] = useState(null);
  const [salesBillVendor, setSalesBillVendor] = useState(null);
  const [selStockist, setSelStockist] = useState(null);
  const [vendorType, setVendorType] = useState(1);
  const [billingCat, setBillingCat] = useState("retCus");
  const handleBillingOptions = e => {
  setBillingCat(e.value);
  };
  const [payMode, setPayMode] = useState('Credit');
  
  const [salesChallanData, setSalesChallanData] = useState(null);
  const [fillSalesChallanData, setfillSalesChallanData] = useState(null);
  const [salesChallanDetails, setSalesChallanDetails] = useState(null);

  // Challan Details Modal States
  const [challanDetailsModal, setChallanDetailsModal] = useState(false);
  const challanDetailsModalClose = () => setChallanDetailsModal(false);
  const challanDetailsModalShow = () => setChallanDetailsModal(true);

  // Function to convert digits into words
  function inWords(n) {
  if (n < 0)
    return false;
  var single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  var double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
  var below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  if (n === 0) return ''
  function translate(n) {
    var word = "";
    var rem;
    if (n < 10) {
    word = single_digit[n] + ' '
    }
    else if (n < 20) {
    word = double_digit[n - 10] + ' '
    }
    else if (n < 100) {
    rem = translate(n % 10)
    word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
    }
    else if (n < 1000) {
    word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
    }
    else if (n < 1000000) {
    word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
    }
    else if (n < 1000000000) {
    word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
    }
    else {
    word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
    }
    return word
  }
  var result = translate(n)
  return result.trim() + '.'
  }

  const ViewChallan = (challanVendorId) => {
  var SalesChallanURL = "http://43.205.199.21/digiPartnerAPI?salesChallanDPID="+challanVendorId+"&user_Id=" + ReactSession.get("id");
  setLoader(true);
  fetch(SalesChallanURL, {
    method: 'GET',
    headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
  }).then((response) => {
    return response.json();
  }).then((resp) => {
    if(resp.status === "success")
    {
    setSalesBillVendor(resp.vendorDet[0]);
    setInvHeads(resp.invoiceHeads[0]);
    setPayMode(resp.invoiceHeads[0][4]);
    setSelStockist([resp.invoiceHeads[0][7], resp.invoiceHeads[0][8], resp.invoiceHeads[0][9], resp.invoiceHeads[0][10], resp.invoiceHeads[0][11], resp.invoiceHeads[0][12], resp.invoiceHeads[0][13], resp.invoiceHeads[0][14], resp.invoiceHeads[0][15], resp.invoiceHeads[0][16], resp.invoiceHeads[0][17], resp.invoiceHeads[0][18], resp.invoiceHeads[0][19]]);
    setTotals([resp.invoiceHeads[0][20], resp.invoiceHeads[0][21], resp.invoiceHeads[0][22], resp.invoiceHeads[0][23], resp.invoiceHeads[0][24], resp.invoiceHeads[0][25], resp.invoiceHeads[0][26], resp.invoiceHeads[0][27]]);
    setInvoiceItems(resp.invoiceItems);
    challanDetailsModalShow();
    setLoader(false);
    }
    else{
    alert("Data Not Found");
    setLoader(false);
    }
  }).catch((err) => {
    alert("Query Failed");
    console.log(err.message);
    setLoader(false);
  });
  }

  const getSalesChallan = () =>{
  var SalesChallanURL = "http://43.205.199.21/digiPartnerAPI?salesChallanDPID=1&user_Id=" + ReactSession.get("id");
  setLoader(true);
  fetch(SalesChallanURL, {
    method: 'GET',
    headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
  }).then((response) => {
    return response.json();
  }).then((resp) => {
    if (resp.status === "validityExpired")
    {
      alert("You have no active subscription plan. please subscribe to continue.");
              navigate("/Subscriptions");
    }
    else
    {
      setSalesChallanData(resp.challanData);
      setfillSalesChallanData(resp.challanData);
    }
    setLoader(false);
  }).catch((err) => {
    console.log(err.message);
    setLoader(false);
  });
  }

  useEffect(() => {
  if (!ReactSession.get('dpsession')) {
    alert("You are not logged in.")
    navigate('/DigitalPartner');
  }
  else
    if (String(ReactSession.get('is_verified')) === "0") {
    alert("PLease complete your profile before search...")
    navigate('/DigiPartnerProfile');
    }
    else 
      if (String(ReactSession.get('is_verified')) === "2") {
        alert("Dear Pharmacy Partner, Your profile is under review, please contact SheetERP sales team...")
      }
      else {
        getSalesChallan();
      }
  }, []);
  
  return (
  <>
  <DigiPartNavBar />
  <div className='DigitalPartnerContainer'>
    {loader ? <Loader /> : ''}

    <div className='DigiDashPanel' id='DigiDashPanel'>
    <DigiPartnerSidePanel />
    </div>
    <div className='DigiDashContent' id='DigiDashContent' style={{ paddingInline: '2%', backgroundColor: 'white' }}>
    <div style={{marginBlock:'10px', width:'100%', display:'flex', justifyContent:'space-between',alignItems:'center'}}>
      <div style={{ fontSize:'2vw'}}>Sales Challans</div>
      <div className="DPsearch" style={{width:'40%',boxShadow:"none", display:'flex', justifyContent:'space-between',alignItems:'center' ,padding:'5px', border:'1px solid #000',borderRadius:"5px"}}>
      <input type="text" placeholder="Search Vendors by Name/ID/Contact...!" name="searchChallan" id="searchChallan" autoComplete="off"
        onKeyDown={(e) => {
        if (salesChallanData !== null)
        {
          const filData = salesChallanData.filter(data =>
          String(data[2])?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data[3]?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data[6]?.toLowerCase().includes(e.target.value.toLowerCase())
          );
          setfillSalesChallanData(filData);
        }
        }}
      />
      <button style={{ backgroundColor: "black",width:"80px" }} type="submit"><i className="fa fa-search"></i></button>
      </div>
    </div>
    <div className='salesChallanHeader'>
      <div style={{width:'6vw'}} className='salesChallanItem'>S.No</div>
      <div style={{width:'10vw'}} className='salesChallanItem'>Vendor ID</div>
      <div style={{width:'30vw'}} className='salesChallanItem'>Vendor Name</div>
      <div style={{width:'14vw'}} className='salesChallanItem'>Contact</div>
      <div style={{width:'8vw'}} className='salesChallanItem'>Bills</div>
      <div style={{width:'8vw'}} className='salesChallanItem'>Qty</div>
      <div style={{width:'10vw'}} className='salesChallanItem'>Amount</div>
    </div>
    {
      fillSalesChallanData && fillSalesChallanData.length > 0 ? 
      fillSalesChallanData.map((challans, index)=>{
        return(
        <div className={index %2 !== 0 ? 'salesChallanRecord' : 'salesChallanRecordGray'} onClick={()=>ViewChallan(challans[2])}>
        <div style={{width:'6vw'}} className='salesChallanItem'>{index+1}</div>
        <div style={{width:'10vw'}} className='salesChallanItem'>{challans[2]}</div>
        <div style={{width:'30vw'}} className='salesChallanItem'>{challans[3]}</div>
        <div style={{width:'14vw'}} className='salesChallanItem'>{challans[6]}</div>
        <div style={{width:'8vw'}} className='salesChallanItem'>{challans[1]}</div>
        <div style={{width:'8vw'}} className='salesChallanItem'>{challans[13]}</div>
        <div style={{width:'10vw'}} className='salesChallanItem'>{Number(challans[15]).toFixed(2)}</div>
        </div>
        )
      })
      : <Oops title={"No Records Found !"}/>
    }
    </div>
    </div>

    <Modal show={challanDetailsModal} onHide={challanDetailsModalClose} size="xl" backdrop="static" >
    <Modal.Body>
      <div className='purchModalHeader'>
      <h3>Sales Challan</h3>
      <div style={{ display: 'flex' }}>
        <span style={{ fontSize: '1vw', marginTop: '.3vw' }}>Shortcut keys:  F2=New  |  F3-Edit  |  End=Save  |  Esc=Back &nbsp;&nbsp;&nbsp;&nbsp;</span>
        <button style={{ fontSize: '1vw', backgroundColor: 'rgb(61, 197, 238)', marginLeft: '.5vw', paddingLeft: '1%', paddingRight: '1%', borderRadius: '0px' }} type='submit' onClick={handlePrint} className='Disabled btn btn-primary btn-sm' tabIndex="21" >Print</button>
        <div className='purchModalCloseBTN' onClick={challanDetailsModalClose}>
        <img style={{ width: '2vw', height: '2vw' }} src={require('../../static/images/Icons/close1.png')} alt="" />
        </div>
      </div>
      </div>

    <div className='printable-document1'>
    {
      invoiceItems && invoiceItems.length > 0 ?
      invoiceItems.map((invItem, index) => {
        return (
        <>
          { index === 0 
          ?
          <>
          <div className='page-break' style={{ width: '100%', marginLeft: '3%', marginRight: '3%', display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ width: '50%', marginTop: '4%', lineHeight: '95%' }}>
            <div style={{ fontSize: '130%', fontWeight: 'bold', lineHeight: '100%' }}>{salesBillVendor[2]}</div>
            <div>{salesBillVendor[3]}</div>
            <div>{salesBillVendor[6]}</div>
            <div>{salesBillVendor[4]}, {salesBillVendor[5]}</div>
            <div>Phone {salesBillVendor[7]}, {salesBillVendor[8]} , Email: {salesBillVendor[9]}</div>
          </div>

          <div style={{ width: '50%', marginTop: '4%', lineHeight: '95%' }}>
            {
            billingCat === "retCus" && invHeads != null ?
              <div >
              <div> Mr./Mrs.{invHeads[8]} </div>
              <div> {invHeads[9]} </div>
              <div> {invHeads[10]} </div>
              <div> Contact {invHeads[15]}, {invHeads[16]} </div>
              </div>
              :
              selStockist && billingCat !== "retCus" ?
              <div style={{ fontSize: '90%', lineHeight: '95%', marginLeft: '3%', marginRight: '3%' }}>
                <span style={{ fontWeight: 'bold' }}>Ms. {selStockist[1]}</span> <br />
                {selStockist[2]} <br />
                {' '}{selStockist[3]}
                {' '}{selStockist[4]}
                {' '}{selStockist[5]}
                {selStockist[6]}
                {selStockist[7]} <br />
                GSTIN Number {selStockist[12]} <br />
                Drug License Number {selStockist[11] ? selStockist[11].split('___')[0] + "  |  " + selStockist[11].split('___')[1] : ''}
                {selStockist[8]}, {selStockist[9]}
                {/* <br />E-Mail :{selStockist[10]} */}
              </div>
              : ""
            }
          </div>

          </div>
          
          <div style={{ fontSize: '90%', marginTop: '2%', paddingBlock: '.5%', border: '1px solid black', lineHeight: '94%', display: 'flex', width: '95%', marginLeft: '3%', marginRight: '3%' }}>
          <div style={{ width: '50%' }}>
            <div>
            GSTIN: {salesBillVendor[12]}
            </div>
            <div>
            FSSAI No:
            </div>

            <div>
            D.L.No: {salesBillVendor[11]}
            </div>
          </div>

          <div>
            {
            invHeads ?
              <>
              <div>Invoice No : {invHeads[1]} </div>
              <div>Date :{invHeads[5].substring(0, 10)}</div>
              </>
              : ''
            }
            <div>Bill: {payMode} </div>
          </div>

          </div>

          <div style={{ fontSize: '90%', display: 'flex', minWidth: '95%', justifyContent: 'flex-start', marginLeft: '3%', marginRight: '3%' }}>
          <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>S.N</div>
          <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>PRODUCT NAME</div>
          <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>PACKING</div>
          <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>HSN</div>
          <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>BATCH NO</div>
          <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>EXP</div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>QTY</div>
          <div style={{ width: '5%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>RATE</div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>DISC.</div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>SGST</div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>CGST</div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>IGST</div>
          <div style={{ width: '10%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>AMOUNT</div>
          </div>

          </>
          :
          ''
          }

          <div style={{
          fontSize: '90%', display: 'flex', minWidth: '95%', justifyContent: 'flex-start', marginLeft: '3%',
          marginRight: '3%', borderBottom: (index + 1) % 15 === 0 || (index + 1) === invoiceItems.length ? '1px solid black' : ''
          }}>
          <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {index + 1} </div>
          <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[1].length <= 25 ? `${invItem[1]}` : `${invItem[1].substring(0, 22)}...`} </div>
          <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[2].length <= 15 ? `${invItem[2]}` : `${invItem[2].substring(0, 12)}...`} </div>
          <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[12]} </div>
          <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[11]} </div>
          <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[9]}/{(String(invItem[10])).substring(2, 4)}</div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[4]} </div>
          <div style={{ width: '5%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[14]} </div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[15]} </div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[6]} </div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[7]} </div>
          <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[8]} </div>
          <div style={{ width: '10%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', borderRight: '1px solid gray' }}> {invItem[16]} </div>
          </div>
          {
          (index + 1) === invoiceItems.length && salesBillVendor !== null ?
            <div style={{ width: '95%', marginRight: '3%', marginLeft: '3%' }}>
            <div style={{ marginRight: '.5vw', display: 'flex', justifyContent: 'flex-start', marginTop: '.5vw' }} >
              <div style={{ width: '45%', fontSize: '80%', lineHeight: '90%' }}>
              <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Terms & Conditions</div>
              <div>1. Transactions, subject to {salesBillVendor[4]} Jurisdiction.</div>
              <div>2. Goods once sold will not be taken back.</div>
              <div>3. We will not reponsible for any demage of loss in transit.</div>
              <div>4. Interest: @2% per month will be charged over due date.</div>
              <div>NOTE:</div>
              </div>

              <div style={{ paddingLeft: '3%', width: '20%', textAlign: 'right', paddingRight: '.5%', fontSize: '85%', lineHeight: '80%' }}>
              <div>for {String(salesBillVendor[2]).toUpperCase()}</div>
              <br /><br /><br /><br />
              <div>Authorised Signatory</div>
              </div>

              <div style={{ fontSize: '90%', lineHeight: '90%', width: '18%', marginRight: '.5%' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                SGST
                </div>
                <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                {Number(totals[2]).toFixed(2)}
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                CGST
                </div>
                <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                {Number(totals[3]).toFixed(2)}
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                IGST
                </div>
                <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                {Number(totals[4]).toFixed(2)}
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                GST Total
                </div>
                <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                {Number(totals[2] + totals[3] + totals[4]).toFixed(2)}
                </div>
              </div>
              </div>

              <div style={{ fontSize: '1vw', lineHeight: '1vw', width: '20%' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                {vendorType === 0 ? 'Sub Total' : 'MRP Total'}
                </div>
                <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                {Number(totals[6]).toFixed(2)}
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                Cash Discount
                </div>
                <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                {Number(totals[5]).toFixed(2)}
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                Debit / Credit
                </div>
                <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                { }
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                Others
                </div>
                <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                { }
                </div>
              </div>

              <div style={{ display: 'flex' }}>
                <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                Grand Total
                </div>
                <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                {Number(totals[6] - totals[5]).toFixed(2)}
                </div>
              </div>
              </div>
            </div>
            <div style={{ fontSize: '1vw', marginBottom:'1.5vw' }}>
              <div style={{ fontSize: '.9vw', color: 'gray' }}>Amount in words Rupees</div>
              <div>{inWords(totals[6] - totals[5])}</div>
            </div>
            </div>

            : ''
          }
        </>
        )
      }
      )
      :
      ''
    }
    </div>

      <div style={{
      borderBottom: '3px solid rgb(61, 197, 238)', display: 'flex',
      width: '100%', backgroundColor: 'rgb(12, 12, 12)', position: 'absolute', left: '0px', bottom: '0px', paddingTop: '1vw', paddingBottom: '1vw'
      }}>

      </div>
    </Modal.Body>
    </Modal>

    {/* classes => printable-document page-break page */}
    <div ref={componentRef} className='printable-document'>
    {
      invoiceItems && invoiceItems.length > 0 ?
      invoiceItems.map((invItem, index) => {
        return (
        <>
          {index % 15 === 0 && index > 9 ?
          <div style={{ marginLeft: '30%' }}>to be continued on next page......</div>
          : ''
          }

          {
          index % 15 === 0 && salesBillVendor !== null ?
            <div className='page-break' style={{ width: '100%', marginLeft: '3%', marginRight: '3%', display: 'flex', justifyContent: 'flex-start' }}>
            <div style={{ width: '50%', marginTop: '4%', lineHeight: '95%' }}>
              <div style={{ fontSize: '130%', fontWeight: 'bold', lineHeight: '100%' }}>{salesBillVendor[2]}</div>
              <div>{salesBillVendor[3]}</div>
              <div>{salesBillVendor[6]}</div>
              <div>{salesBillVendor[4]}, {salesBillVendor[5]}</div>
              <div>Phone {salesBillVendor[7]}, {salesBillVendor[8]} , Email: {salesBillVendor[9]}</div>
            </div>

            <div style={{ width: '50%', marginTop: '4%', lineHeight: '95%' }}>
              {
              billingCat === "retCus" && invHeads != null ?
                <div >
                <div> Mr./Mrs.{invHeads[8]} </div>
                <div> {invHeads[9]} </div>
                <div> {invHeads[10]} </div>
                <div> Contact {invHeads[15]}, {invHeads[16]} </div>
                </div>
                :
                selStockist && billingCat !== "retCus" ?
                <div style={{ fontSize: '90%', lineHeight: '95%', marginLeft: '3%', marginRight: '3%' }}>
                  <span style={{ fontWeight: 'bold' }}>Ms. {selStockist[1]}</span> <br />
                  {selStockist[2]} <br />
                  {' '}{selStockist[3]}
                  {' '}{selStockist[4]}
                  {' '}{selStockist[5]}
                  {selStockist[6]}
                  {selStockist[7]} <br />
                  GSTIN Number {selStockist[12]} <br />
                  Drug License Number {selStockist[11] ? selStockist[11].split('___')[0] + "  |  " + selStockist[11].split('___')[1] : ''}
                  {selStockist[8]}, {selStockist[9]}
                  {/* <br />E-Mail :{selStockist[10]} */}
                </div>
                : ""
              }
            </div>

            </div>
            : ''
          }

          {index % 15 === 0 && salesBillVendor !== null ?
          <div style={{ fontSize: '90%', marginTop: '2%', paddingBlock: '.5%', border: '1px solid black', lineHeight: '94%', display: 'flex', width: '95%', marginLeft: '3%', marginRight: '3%' }}>
            <div style={{ width: '50%' }}>
            <div>
              GSTIN: {salesBillVendor[12]}
            </div>
            <div>
              FSSAI No:
            </div>

            <div>
              D.L.No: {salesBillVendor[11]}
            </div>
            </div>

            <div>
            {
              invHeads ?
              <>
                <div>Invoice No : {invHeads[1]} </div>
                <div>Date :{invHeads[5].substring(0, 10)}</div>
              </>
              : ''
            }
            <div>Bill: {payMode} </div>
            </div>

              </div>
                    : ''
                  }

                  {index % 15 === 0 ?
                    <div style={{ fontSize: '90%', display: 'flex', minWidth: '95%', justifyContent: 'flex-start', marginLeft: '3%', marginRight: '3%' }}>
                      <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>S.N</div>
                      <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>PRODUCT NAME</div>
                      <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>PACKING</div>
                      <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>HSN</div>
                      <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>BATCH NO</div>
                      <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>EXP</div>
                      <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>QTY</div>
                      <div style={{ width: '5%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>RATE</div>
                      <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>DISC.</div>
                      <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>SGST</div>
                      <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>CGST</div>
                      <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>IGST</div>
                      <div style={{ width: '10%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', border: '1px solid gray' }}>AMOUNT</div>
                    </div>
                    :
                    ''
                  }

                  <div style={{
                    fontSize: '90%', display: 'flex', minWidth: '95%', justifyContent: 'flex-start', marginLeft: '3%',
                    marginRight: '3%', borderBottom: (index + 1) % 15 === 0 || (index + 1) === invoiceItems.length ? '1px solid black' : ''
                  }}>
                    <div style={{ width: '3%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {index + 1} </div>
                    <div style={{ width: '25%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[1].length <= 25 ? `${invItem[1]}` : `${invItem[1].substring(0, 22)}...`} </div>
                    <div style={{ width: '14%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[2].length <= 15 ? `${invItem[2]}` : `${invItem[2].substring(0, 12)}...`} </div>
                    <div style={{ width: '8%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[12]} </div>
                    <div style={{ width: '10%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[11]} </div>
                    <div style={{ width: '5%', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[9]}/{(String(invItem[10])).substring(2, 4)}</div>
                    <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[4]} </div>
                    <div style={{ width: '5%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[14]} </div>
                    <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[15]} </div>
                    <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[6]} </div>
                    <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[7]} </div>
                    <div style={{ width: '4%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray' }}> {invItem[8]} </div>
                    <div style={{ width: '10%', textAlign: 'right', paddingLeft: '.1%', paddingRight: '.1%', borderLeft: '1px solid gray', borderRight: '1px solid gray' }}> {invItem[16]} </div>
                  </div>
                  {
                    (index + 1) === invoiceItems.length && salesBillVendor !== null ?
                      <div style={{ width: '95%', marginRight: '3%', marginLeft: '3%' }}>
                        <div style={{ marginRight: '.5vw', display: 'flex', justifyContent: 'flex-start', marginTop: '.5vw' }} >
                          <div style={{ width: '45%', fontSize: '80%', lineHeight: '90%' }}>
                            <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Terms & Conditions</div>
                            <div>1. Transactions, subject to {salesBillVendor[4]} Jurisdiction.</div>
                            <div>2. Goods once sold will not be taken back.</div>
                            <div>3. We will not reponsible for any demage of loss in transit.</div>
                            <div>4. Interest: @2% per month will be charged over due date.</div>
                            <div>NOTE:</div>
                          </div>

                          <div style={{ paddingLeft: '3%', width: '20%', textAlign: 'right', paddingRight: '.5%', fontSize: '85%', lineHeight: '80%' }}>
                            <div>for {String(salesBillVendor[2]).toUpperCase()}</div>
                            <br /><br /><br /><br />
                            <div>Authorised Signatory</div>
                          </div>

                          <div style={{ fontSize: '90%', lineHeight: '90%', width: '18%', marginRight: '.5%' }}>
                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                SGST
                              </div>
                              <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                {Number(totals[2]).toFixed(2)}
                              </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                CGST
                              </div>
                              <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                {Number(totals[3]).toFixed(2)}
                              </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                IGST
                              </div>
                              <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                {Number(totals[4]).toFixed(2)}
                              </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                GST Total
                              </div>
                              <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                {Number(totals[2] + totals[3] + totals[4]).toFixed(2)}
                              </div>
                            </div>
                          </div>

                          <div style={{ fontSize: '1vw', lineHeight: '1vw', width: '20%' }}>
                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                {vendorType === 0 ? 'Sub Total' : 'MRP Total'}
                              </div>
                              <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                {Number(totals[6]).toFixed(2)}
                              </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                Cash Discount
                              </div>
                              <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                {Number(totals[5]).toFixed(2)}
                              </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                Debit / Credit
                              </div>
                              <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                { }
                              </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                              <div style={{ paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                Others
                              </div>
                              <div style={{ paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                { }
                              </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                              <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '60%', border: '1px solid gray' }}>
                                Grand Total
                              </div>
                              <div style={{ fontWeight: 'bold', paddingInline: '2%', width: '40%', border: '1px solid gray', textAlign: 'right' }}>
                                {Number(totals[6] - totals[5]).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ fontSize: '1vw' }}>
                          <div style={{ fontSize: '.9vw', color: 'gray' }}>Amount in words Rupees</div>
                          <div>{inWords(totals[6] - totals[5])}</div>
                        </div>
                      </div>

                      : ''
                  }
                </>
              )
            }
            )
            :
            ''
        }
      </div>
    </>
  )
}

export default SalesChallan