import React, { useState } from 'react';
import DashNavbar from '../../DashContainers/DashNavbar';
import DashPanel from '../../DashContainers/DashPanel';
import Select from 'react-select';
import { FiSearch } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';


const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

// const animatedComponents = makeAnimated();

const CityWisePayments = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents cityWisePayments' id='dashContents'>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 style={{ fontSize: 30, fontWeight: 500, margin: 0, padding: 0 }}>Manage City Wise Payments</h3>
          </div>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div className="inputBox" style={{ display: "flex", position: "relative", alignItems: "center", border: "1px solid #000", borderRadius: 5, width: "50vw", marginTop: 10, outline: 0 }}>
              <Select isMulti className='select' options={options} id="cat_idDD"
                defaultValue={options[1]} placeholder="Select Category"
              />
              <i style={{ width: "80px", height: "40px", fontSize: "20px", borderTopRightRadius: 5, borderBottomRightRadius: 5, cursor: "pointer", color: "#fff", background: "#000", display: "flex", alignItems: "center", justifyContent: "center" }}><FiSearch /></i>
            </div>

            <div style={{ display: "flex", marginTop: 10, alignItems: "center", marginLeft: 29, justifyContent: "center", padding: 3, width: "12vw" }}>
              <input type="checkbox" className='removeDateIcon checkBox'
                style={{ width: "50%", textAlign: "center", border: 0, outline: 0, textTransform: "uppercase", margin: 0, padding: 0 }}
              />
              <p style={{ margin: 0, padding: 0, marginLeft: -10 }}>COD</p>

              <input type="checkbox" className='removeDateIcon checkBox'
                style={{ width: "50%", textAlign: "center", border: 0, outline: 0, textTransform: "uppercase", margin: 0, padding: 0 }}
              />
              <p style={{ margin: 0, padding: 0, marginLeft: -10 }}>ONLINE</p>
            </div>

            <button style={{ padding: 8, border: 0, outline: 0, borderRadius: 5, color: "#fff", background: "#00C7E4", minWidth: "120px" }}>Submit</button>
          </div>


          <div className="cityTableContainer">

            <div className="tableHead mt-3" style={{ background: "#000", color: "#fff" }}>
              <div class="row">
                <div class="col-sm">
                  City & State
                </div>
                <div class="col-sm">
                  Online Payment
                </div>
                <div class="col-sm">
                  COD Payment
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  Edit City/State
                </div>
              </div>
            </div>

            <div className="tableRecords customScroll" style={{ overflowX: "hidden", overflowY: "auto", paddingRight: 15, height: "70vh" }}>

              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img onClick={()=>{
                    setIsModalOpen(true);
                    setIsEditing(true);
                    }} style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>

              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>
              <div class="row" style={{ alignItems: "center" }}>
                <div class="col-sm" >
                  <div style={{ textAlign: "left" }}>
                    <b style={{ margin: 0, padding: 0, color: "#000", fontSize: 15 }}>Bhopal</b> <br />
                    <p style={{ margin: 0, padding: 0, fontSize: 14 }}>Madhya Pradesh</p>
                  </div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="true"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="customCheckBox checkBox-1" data-check="false"></div>
                </div>
                <div class="col-sm" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ width: 25, height: 25 }} src={require("../../../static/images/edit.png")} alt="" />
                </div>
              </div>

            </div>

          </div>

        </div>

        <div className='addNewCat' onClick={()=>{setIsModalOpen(true)}} style={{ border: 0, outline: 0 }} >
          <img style={{ width: "70px", height: "70px", borderRadius: "50%" }} src={require('../../../static/AdminPanelImages/add.gif')} alt="" />
        </div>

      </div>

      <Modal show={isModalOpen} onHide={()=>{
        setIsModalOpen(false);
        setIsEditing(false);
        }} size="md" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit" : "Add New"} City & State</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form>

            <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray" }}>City Name</p>
            <input style={{width:"100%",padding:7,marginTop:3,border:0,outline:0,background:"#F4F4F4"}} type="text" placeholder='City Name' required/>

            <p style={{ margin: 0, padding: 0, fontSize: 14, color: "gray",marginTop:10 }}>State Name</p>
            <input style={{width:"100%",padding:7,marginTop:3,border:0,outline:0,background:"#F4F4F4"}} type="text" placeholder='State Name' required/>

            <div style={{display:"flex",alignItems:"center",gap:5,marginTop:10}}>
              <p style={{margin:0,padding:0}}>is Active</p>
              <input type="checkbox" name="check" id="" />
            </div>

            <button type="submit" className='btnPrimary1' style={{marginTop:10,border:0,outline:0,borderRadius:5,width:"100%",height:"38px"}}>Create</button>

          </form>

        </Modal.Body>
      </Modal>

    </>
  );
};

export default CityWisePayments;
