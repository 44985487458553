import React, { useEffect, useState } from 'react'
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
import Footer from '../Containers/Footer';
import { ReactSession } from 'react-client-session';
import { useNavigate } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import { Tooltip } from '@material-ui/core';
import presImages from '../../SharedItems';
import Modal from 'react-bootstrap/Modal';

const OrderSummary = () => {
  const navigate = useNavigate();
  // files:files, totals:total, data:data, presReq: presReq, custCity: custCity , shippingAddress: document.getElementById('delAddress').value
  const queryParameters = new URLSearchParams(window.location.search);
  const custCity = queryParameters.get("custCity");
  const shippingAddress = queryParameters.get("shippingAddress");
  const orderId = queryParameters.get("orderId", null);
  const orderType = queryParameters.get("orderType", null);
  const test = queryParameters.get("test", null);

  const [cartData, setCartData] = useState(null);
  const [totals, setTotals] = useState([]);
  const [addresses, setAddresses] = useState(null);

  var freeDeliveryAmt = 0;
  
  const [email, setEmail] = useState('');
  
  var deliAddress = [];
  const [paymentMode, setMode] = useState('online');

  if (custCity === "9")
    freeDeliveryAmt = 400;
  else
    freeDeliveryAmt = 1000;

  var options = {
    "key": "rzp_test_dqhhlfzNjbhqjV",
    "amount": (1 * 100),
    "name": "Sheet360ERP",
    "description": "Thanks in purchasing with Piilsit",
    "image": "https://example.com/your_logo",
    // "order_id": "order_9A33XWu170gUtm", 
    "handler": function (response) {
      alert(response.razorpay_payment_id);
      createOrder(response.razorpay_payment_id);
    },
    "prefill": {
      "name": deliAddress[4],
      "email": email,
      "contact": deliAddress[6]
    },
    "theme": {
      "color": "#3399cc"
    }
  };
  var rzp1 = new window.Razorpay(options);
  // rzp1.on('payment.failed', function (response){
  //         alert(response.error.code);
  //         alert(response.error.description);
  //         alert(response.error.source);
  //         alert(response.error.step);
  //         alert(response.error.reason);
  //         alert(response.error.metadata.order_id);
  //         alert(response.error.metadata.payment_id);
  // });

  // const checkOut = (e) => {
  //   e.preventDefault();
  //   rzp1.open();
  // }

  //Cancel Order Static BackDrop Modal
  //Address Back Drop Modal Down 
  const [OrderCancel, setCancelOrder] = useState(false);
  const cancelOrderClose = () => setCancelOrder(false);
  const cancelOrderShow = () => setCancelOrder(true);

  //Cancel an Order
  const CancelOrder = (ordID) => {
    var reason = document.getElementById('cancellationReason').value;
    if (reason.length < 100) {
      alert("Reason should contains at least 100 characters ");
      document.getElementById('cancellationReason').focus();
    }
    else {
      fetch(
        'http://43.205.199.21/ordersAPI/',
        {
          mode: 'cors',
          headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
          method: 'PUT',
          body: JSON.stringify({
            ordId: orderId,
            reason: reason
          })
        }).then((response) => response.json())
        .then((result) => {
          // console.log('Success:', result);
          alert("Your order is cancelled, Looking forward for your good health");
          navigate('/MyOrders');
        }).catch((error) => {
          console.error('Error:', error);
        });
    }
  }

  //Creating an order
  const createOrder = (tarnsactionID = null) => {
    let formData = new FormData();    //formdata object

    for (let i = 0; i < presImages.length; i++) {
      formData.append("Gallery", presImages[i], presImages[i].name)
    }
    // total, data, presReq, custCity, shippingAddress

    formData.append('custID', ReactSession.get('id_eu'));
    formData.append('paymentMode', paymentMode);
    formData.append('custCity', custCity);
    formData.append('shippingAddress', shippingAddress);
    formData.append('tarnsactionID', tarnsactionID);
    formData.append('orderType', orderType);
    formData.append('test', test);

    fetch('http://43.205.199.21/ordersAPI/',
      {
        mode: 'cors',
        // Headers NOT REQUIRED with fromDATA  // headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        method: 'POST',
        body: formData,
      }).then((response) => response.json())
      .then((result) => {
        // console.log('Success:', result);
        alert("Your order is successfully placed");
        navigate('/');
      }).catch((error) => {
        console.error('Error:', error);
      });
  }

  const CheckOut = (e) => {
    e.preventDefault();
    if (orderType === null) {
      switch (paymentMode) {
        case 'cod':
          createOrder();
          break;
        case 'online':
          rzp1.open();
          break;
        default:
          console.log("Invalid payment options ");
          break;
      }
    }
    else {
      createOrder();
    }
  }


  //Call API to tetreive data
  const GetCart = () => {
    fetch("http://43.205.199.21/userCartsAPI?userId=" + ReactSession.get('id_eu') + "&orderId=" + orderId +"&custCity=" + custCity, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      setCartData(res.data);
      setTotals(res.totals);
      setAddresses(res.addresses);
      if (res.email !== null) { setEmail(res.addresses[0][15]); }
    });
  }

  useEffect(() => {
    GetCart();
  }, []);

  return (
    <>
      <Logo />
      <NavSearchBar />
      <p className="MyHeader">Order Summary</p>
      <hr width="80%" size="8" className="MyDivider" style={{ marginLeft: '10%' }} />
      <div className="MyCart divshadow">
        <Row>
          <Col md={6} lg={6} xl={6} sm={12} xs={12}>
            <div className="Products" id='Products'>
              <div className="selectProduct">
                {(orderType === "cart" || orderType === 'null' || orderType === null) && cartData ?
                  cartData.map((myList) => {
                    return <div className='topPartition'>
                      <Row>
                        <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                          <div style={{ fontSize: '1rem' }} >{myList[5]}</div>
                        </Col>
                        <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                          <div style={{ textAlign: 'right' }} >Price : {Number(myList[7] * myList[3]).toFixed(2)}</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                          {myList[8]}
                        </Col>
                        <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                          <div style={{ textAlign: 'right' }} >MRP :
                            <span style={{ textDecoration: 'line-through' }}>{Number(myList[6] * myList[3]).toFixed(2)} </span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={6} xl={6} sm={6} xs={6}>
                          <span style={{ color: "darkgreen", fontWeight: 'bold' }}>{myList[9] === 1 ? "Prescription Required" : ""}</span>
                        </Col>
                        <Col lg={6} md={6} xl={6} sm={6} xs={6}>
                          <div style={{ textAlign: 'right' }} >
                            <span id="Qty">Quantity :{myList[3]}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  })

                  :
                  <>
                    <Row className='notcartItems'>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12} className='prodInfo'>
                        Your Cart items will be released after order confirmation.
                      </Col>
                    </Row>
                  </>
                }
              </div>
            </div>
          </Col>

          <Col md={6} lg={6} xl={6} sm={12} xs={12}>
            <div className="CartBillingDiv pt-2" id="CartBillingDiv">
              <div>
                {(orderType === "cart" || orderType === 'null' || orderType === null) && totals ?
                  totals.map((myTot) => {
                    if (orderId !== null) {
                      var ordDate = myTot[6] !== null ? (myTot[6]).substring(0, 10).split("-") : ['', '', ''];
                      var deDate = myTot[7] !== null ? (myTot[7]).substring(0, 10).split("-") : ['', '', ''];
                    }
                    return (
                      <div className='totBilling'>
                        <div className="billingHead">Total Amount :</div>
                        <div className="billingAmt"> {Number(myTot[2]).toFixed(2)}</div>
                        <div className="billingHead">Total Price : </div>
                        <div className="billingAmt">
                          <span style={{ textDecoration: 'line-through' }}>{Number(myTot[1]).toFixed(2)} </span>
                        </div>
                        <div className="billingHead">Total Quantity : </div>
                        <div className="billingAmt">{Number(myTot[0]).toFixed(2)}</div>
                        <div className="billingHead" style={{ color: 'green', fontWeight: 'bold' }}>Your Savings : </div>
                        <div className="billingAmt" style={{ color: 'green', fontWeight: 'bold' }}>{Number(myTot[1] - myTot[2]).toFixed(2)}</div>
                        {
                          freeDeliveryAmt > myTot[2] ?
                            <>
                              <div className='cartColTopBorderDotted'>
                                <Row >
                                  <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                                    <p>Delivery Charges :</p>
                                  </Col>
                                  <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                                    <p style={{ textAlign: "right" }}>{freeDeliveryAmt === 400 ? "30/-" :
                                      freeDeliveryAmt === 1000 ? "60/-" : ""}</p>
                                  </Col>
                                </Row>
                              </div>
                            </>
                            : ""
                        }

                        <div style={{ width: "95%" }}>
                          <Row>
                            <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                              <p className='prodInfo' style={{ fontSize: '100%' }}>Amount to be paid :</p>
                            </Col>
                            <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                              <p className='prodInfo' style={{ fontSize: '100%', textAlign: "right" }}>
                                {freeDeliveryAmt === 400 && parseInt(myTot[2]) < 400 ? Number(parseInt(myTot[2]) + 30).toFixed(2) :
                                  freeDeliveryAmt === 1000 && parseInt(myTot[2]) < 1000 ? Number(parseInt(myTot[2]) + 60).toFixed(2) : Number(parseInt(myTot[2])).toFixed(2)}/-</p>
                            </Col>
                          </Row>
                          {orderId !== null ?
                            <>
                              <Row>
                                <Col lg={6} md={6} xl={6} sm={6} xs={6}>
                                  <p className='prodInfo' style={{ fontSize: '100%' }}>Order Date : {ordDate[2]}/{ordDate[1]}/{ordDate[0]}</p>
                                </Col>
                                <Col lg={6} md={6} xl={6} sm={6} xs={6}>
                                  <p className='prodInfo' style={{ fontSize: '100%' }}>Expected Delivery : {deDate[2]}/{deDate[1]}/{deDate[0]}</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6} md={6} xl={6} sm={6} xs={6}>
                                  <p className='prodInfo' style={{ fontSize: '100%' }}>Payment Mode : {myTot[5] === 1 ? "Online" : "Cash on Delivery"}</p>
                                </Col>
                                <Col lg={6} md={6} xl={6} sm={6} xs={6}>
                                  <p className='prodInfo' style={{ fontSize: '100%' }}>Status : {myTot[8]}</p>
                                </Col>
                              </Row>
                            </> :
                            <>
                            </>}
                        </div>
                      </div>)
                  })
                  :
                  <>
                    <Row style={{ color: "green", fontSize: '95%' }}>
                      <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                        Your billing information will be released after order confirmation.
                      </Col>
                    </Row>
                  </>
                }
                <div className='cartColTopBorderDotted'>Shipping Address</div>
                {addresses ?
                  addresses.map((address) => {
                    if (shippingAddress === address[12])
                      deliAddress = address;

                    return (
                      <div >
                        {shippingAddress === address[12] || orderId !== null ?
                          <Row>
                            <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                              <p className='prodInfo' >Name : {address[4]}</p>
                              <p className='prodInfo' >Address  :{address[5]} &nbsp;&nbsp; Pin: {address[1]}</p>
                              <p className='prodInfo' >Landmark : {address[7]}</p>
                              <p className='prodInfo' >City : {address[2]} &nbsp;&nbsp;&nbsp;&nbsp;State : {address[3]}</p>
                              <p className='prodInfo' >Contact 1 :{address[6]} &nbsp;&nbsp;Contact 2 : {address[8]}</p>
                            </Col>
                          </Row>
                          : ""}
                      </div>)
                  })
                  : ''}
                <div className='cartColTopBorderDotted'>
                  {orderId === null ?
                    <>
                      <Row>
                        <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                          <button className='btn btn-outline-primary btn-sm' style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
                            onClick={(e) => CheckOut(e)} >CHECKOUT</button>
                        </Col>
                      </Row>
                    </>
                    :
                    <>
                      <Row>
                        <Col md={6} lg={6} xl={6} sm={6} xs={6}>
                          <button className='btn btn-outline-primary btn-sm' style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
                            onClick={(e) => { navigate('/MyOrders') }}
                          >Back to Orders</button>
                        </Col>
                        <Col md={6} lg={6} xl={6} sm={6} xs={6}>
                          <button className='btn btn-outline-primary btn-sm' style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
                            onClick={() => cancelOrderShow()}
                          >Cancel Order</button>
                        </Col>
                      </Row>
                    </>
                  }
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <button id="rzp-button1" onClick={(e) => checkOut(e)} >Pay</button> */}
      <Footer />


      <Modal show={OrderCancel} onHide={cancelOrderClose} size="sm" backdrop="static" keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>Mention Reason for Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="errorMessageHide" id="errorMessage">Please mention Cancellation Reason</div>
          <Row>
            <Col lg={12} md={12} xl={12} sm={12} xs={12}>
              <textarea cols={30} rows={5} maxLength={500} minLength={100} name="cancellationReason" id="cancellationReason" placeholder='Cancellation reason minimum 100 characters'></textarea>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} xl={12} sm={12} xs={12}>
              <button type='submit' className='btn btn-primary btn-sm' onClick={() => CancelOrder(orderId)}>Cancel</button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default OrderSummary