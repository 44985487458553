import React, { useEffect, useState } from 'react'
import DashNavbar from '../DashContainers/DashNavbar'
import DashPanel from '../DashContainers/DashPanel'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom'

import Select from 'react-select';
// import makeAnimated from 'react-select/animated';

import { ReactSession } from 'react-client-session';

const CategoryDiscount = () => {
    const navigate = useNavigate();
    const [cateOptions, setCateOptions] = useState([{ value: '', label: 'Select Category' },]);
    const [cateProdCount, setCateProdCount] = useState(0);
    const [catDiscount, setCatDiscount] = useState(0);

    const removeErrorMessage = (element) => {
        document.getElementById(element).classList.remove("errorField");
    }

    const [cat_id, setCatId] = useState(0);
    const handleSelect = e => {
        removeErrorMessage("selectCat");
        setCatId(e.value);
        fetch("http://43.205.199.21/manageCateDisAPI?cat_Id=" + e.value, {
            mode: 'cors',
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        }).then((response) => {
            return response.json();
        }).then((resp) => {
            setCatDiscount(resp.catDiscount);
            setCateProdCount(resp.recCount);
            document.getElementById('catDiscount').value = resp.catDiscount;
        });
        // console.log(e.label);
        // console.log(e.value);
    }


    // Utility Methods
    const handleSubmit = () => {
        var errorElements = [];
        if (cat_id === 0)
            errorElements.push("selectCat");

        if (document.getElementById('catDiscount').value === "")
            errorElements.push("catDiscount");
        else if (isNaN(document.getElementById('catDiscount').value)) {
            document.getElementById("errorMessage").innerHTML = "Discount must be numeric";
            errorElements.push("catDiscount");
        }

        errorElements.forEach(showError);
        function showError(item) {
            document.getElementById(item).classList.add("errorField");
        }

        if (errorElements.length > 0) {
            document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
            document.getElementById(errorElements[0]).focus();
        }
        else {
            let formData = new FormData();    //formdata object
            formData.append('cat_Id', cat_id);
            formData.append('catDiscount', document.getElementById('catDiscount').value);

            fetch('http://43.205.199.21/manageCateDisAPI/', {
                mode: 'cors',
                method: 'POST',
                body: formData,
            }).then((response) => response.json())
                .then((result) => {
                    // console.log('Success:', result);
                    alert("Category Dicount Updated");
                }).catch((error) => {
                    console.error('Error:', error);
                });
            document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");   
        }
    }

    //Call API to retreive data for filter on 'manage product' & 'add product' UI
    const buildSearchOptions = () => {
        fetch("http://43.205.199.21/searchProAPI", {
            method: 'GET',
            headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
        }).then((response) => {
            return response.json()
        }).then((res) => {
            setCateOptions(res.proCateD);
        });
    }


    useEffect(() => {
        if (!ReactSession.get('adminSheet360ERP')) {
            navigate('/Admin');
        }
        else {
            buildSearchOptions();
        }
    }, []);

    return (
        <div>
            <DashNavbar />
            <div className='dashContainer'>
                <div className='dashPanel customScroll'>
                    <DashPanel />
                </div>
                <div className='dashContents' id='dashContents' style={{ marginLeft: '1rem' }}>
                    <div className="errorMessageHide" id="errorMessage">Red marked fields are mandatory</div>

                    <div id='catDisHead'>
                        Manage Category Wise Discounts
                    </div>

                    <Row style={{ alignItems: 'center' }}>
                        <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                            <div className="addProddropDown" id="selectCat">
                                <Select options={cateOptions} id="cat_idDD"
                                onChange={handleSelect} placeholder="Select Category" />
                            </div>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={4} xs={12}>
                            <div>
                                Products count
                            </div>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={2} xs={12}>
                            <div className='manageCataDisProdCo'>
                                {cateProdCount ? cateProdCount : ''}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '1rem', paddingBottom: '1rem', alignItems: 'center' }}>
                        <Col xl={3} lg={3} md={3} sm={6} xs={12}>
                            Category Discount
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6} xs={12}>
                            <input type="text"onKeyPress={() => { removeErrorMessage("catDiscount") }}
                                name="catDiscount" id="catDiscount" className='ProductFilterOption' placeholder='Discount' />
                        </Col>

                        <Col xl={3} lg={3} md={3} sm={6} xs={12}>
                            <button className='btn-outline-primary' onClick={() => handleSubmit()}>Update</button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default CategoryDiscount