import React, { useEffect, useState } from 'react'
// import { createRoot } from 'react-dom/client';
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
import { useNavigate } from "react-router-dom";
import Footer from '../Containers/Footer';
import { ReactSession } from 'react-client-session';
import Button from '@material-ui/core/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';

import cart from '../../SharedItems';

const MyCart = () => {
  let user_session = false;
  const navigate = useNavigate();

  const [filCity, setFilterCity] = useState({});
  // const [filState, setFilterState] = useState();

  const [cartData, setCartData] = useState(null);
  const [totals, setTotals] = useState([]);

  const [cityOptions, setCityOptions] = useState([{ key: 0, value: '', label: 'None' },]);
  // const [stateOptions, setStateOptions] = useState([{ key: 0, value: '', label: 'None' },]);

  const [freeDeliveryAmt, setFreeDeliveryAmt] = useState(0);
  var presReq = "";

  // For Filter array of object Single Selection List
  const [selectedCity, setCity] = useState(cityOptions.label);

  const handleSelectCity = e => {
    document.getElementById("errorMessage").classList.replace("errorMessage", "errorMessageHide");
    const filCityID = cityOptions.filter(cityid => {
      return cityid.label === e.label;
    });

    if (filCityID.length > 0) {
      setFilterCity(filCityID[0]);
      setCity(filCityID[0].value);
    }
    // console.log("City Bhopal :",e.label);
    if (e.label === "Bhopal")
      setFreeDeliveryAmt(400);
    else
      setFreeDeliveryAmt(1000);
  }

  // const [selectedState, setState] = useState(stateOptions.label);
  // const handleSelectState = e => {
  //   setState(e.value);
  // }

  //Select Styles
  const selectStyle = {
    options: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
    }),

    menu: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      background: "white",
      border: '1px solid gray',
      lineHeight: '.5rem',
    }),

    control: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '80%',
      paddingTop: '-20px',
      height: '20px',
      marginTop: '.4rem',
      minHeight: '30px',
    }),

    input: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      // paddingBlockEnd:'10px',
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      marginTop: '-5px',
    }),
  }

  //Cart Controls
  function updateQty(cartId, act) {
    fetch('http://43.205.199.21/userCartsAPI/', {
      mode: 'cors',
      method: 'PUT',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        cartId: cartId,
        action: act,
      })
    })
      .then((response) => {
        response.json();
        GetCart();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function deleteCart(caID, flag) {
    var cartdata = ""
    if (flag === "item")
      cartdata = { cartId: caID, }
    else if (flag === "address")
      cartdata = { address: caID }

    fetch('http://43.205.199.21/userCartsAPI/', {
      mode: 'cors',
      method: 'DELETE',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
      body: JSON.stringify(cartdata)
    })
      .then((response) => {
        response.json();
        GetCart();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }


  // CartID, UserID, ProductID, Quantity, pro_img, pro_name, pro_price, pro_amount, unit
  
  // Geo Location handling methods
  function success(pos) {
    // URL = "http://43.205.199.21/latlngCity?lat="
    var crd = pos.coords;
    var lat = crd.latitude.toString();
    var lng = crd.longitude.toString();
    // var lat =  23.259933
    // var lng = 77.412613
    // Bhopal location lat 23.259933,   lon 77.412613
    // console.log(`Latitude: ${lat}, Longitude: ${lng}`);
    fetch("https://us1.locationiq.com/v1/reverse.php?key=pk.288836d77ee7a0ef19613ed4d4f2be53&lat=" +
      lat + "&lon=" + lng + "&format=json", {
      method: 'GET',
    }).then((response) => {
      return response.json();
    }).then((res) => {

      // console.log("Res :",res.address);
      const filCityID = cityOptions.filter(cityid => {
        return cityid.label === res.address.city;
      });

      if (filCityID.length > 0) {
        setCity(filCityID[0].value);
        if (filCityID[0].label === "Bhopal")
          setFreeDeliveryAmt(400);
        else
          setFreeDeliveryAmt(1000);
        setFilterCity(filCityID[0]);
      }
    });
    return;
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }


  const handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }


  const Checkout = (total, data) => {
    if (selectedCity !== null)
      if (presReq === "yes")
        navigate('/UploadPrescription?presReq=' + presReq + '&custCity=' + selectedCity);
      else
        navigate('/ShippingAdd?custCity=' + selectedCity);
    else {
      document.getElementById("errorMessage").classList.replace("errorMessageHide", "errorMessage");
      document.getElementById("LocationCity").focus();
    }
  }


  //Call API to tetreive data
  const GetCart = () => {
    // var Products = document.getElementById("Products");
    // var CartBillingDiv = document.getElementById("CartBillingDiv");
    fetch("http://43.205.199.21/userCartsAPI?userId=" + ReactSession.get('id_eu'), {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json()
    }).then((res) => {
      // createRoot(Products).render(<ListCart myLists={res} />)
      // createRoot(CartBillingDiv).render(<BillingCart myTots={res} />)
      setCityOptions(res.citiesD);
      // setStateOptions(res.statesD);
      setCartData(res.data);
      setTotals(res.totals);


      // for (let i = 0; i < res.cities.length; i++) {
      //   cityOptions.push({ value: res.cities[i][0], label: res.cities[i][1] })
      // }

      // for (let i = 0; i < res.states.length; i++) {
      //   stateOptions.push({ value: res.states[i][0], label: res.states[i][1] })
      // }
    });
  }



  useEffect(() => {
    GetCart();
  }, [filCity]);

  return (
    <>
      <Logo />
      <NavSearchBar sess={user_session} />
      <p className="MyHeader">My Carts</p>
      <hr width="80%" size="8" className="MyDivider" style={{ marginLeft: '10%' }} />
      <div className="errorMessageHide" id="errorMessage">Select a City</div>
      <div className="MyCart divshadow">
        <Row>
          <Col md={6} lg={6} xl={6} sm={12} xs={12}>
            <div className="Products" id='Products'>
              {cartData ?
                cartData.map((myList) => {
                  document.getElementById('CartCount').classList.replace('hide', 'show');
                  cart.value = cartData.length;
                  document.getElementById("CartCount").innerText = cart.value;

                  if (myList[9] === 1) {
                    presReq = "yes";
                  }

                  return <div className='topPartition'>
                    <Row>
                      <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                        <div style={{ fontSize: '1rem' }} >{myList[5]}</div>
                      </Col>
                      <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                        <div style={{ textAlign: 'right' }} >Price : {Number(myList[7] * myList[3]).toFixed(2)}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                        {myList[8]}
                      </Col>
                      <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                        <div style={{ textAlign: 'right' }} >MRP :
                          <span style={{ textDecoration: 'line-through' }}>{Number(myList[6] * myList[3]).toFixed(2)} </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                        <Button variant="text" onClick={() => deleteCart(myList[0], "item")}>
                          <img className="MyCartIcons" src={require('../../static/images/Icons/trash.png')} alt="" />
                        </Button>
                      </Col>
                      <Col lg={6} md={6} xl={6} sm={6} xs={6}>
                        <span style={{ color: "darkgreen", fontWeight: 'bold' }}>{myList[9] === 1 ? "Prescription Required" : ""}</span>
                      </Col>
                      <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                        <div style={{ textAlign: 'right' }} >
                          <button style={{ border: "none", background: "none" }} onClick={() => {
                            if (myList[3] < 2)
                              deleteCart(myList[0], "item");
                            else
                              updateQty(myList[0], "-");
                          }}>
                            <img className="MyCartIcons" src={require('../../static/images/Icons/minus-button.png')} alt="" />
                          </button>
                          <span id="Qty">{myList[3]}</span>
                          <button style={{ border: "none", background: "none" }} onClick={() => updateQty(myList[0], "+")}>
                            <img className="MyCartIcons" src={require('../../static/images/Icons/add-button.png')} alt="" />
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
                )
                : ''}
            </div>
          </Col>

          <Col md={6} lg={6} xl={6} sm={12} xs={12}>
            <div className="CartBillingDiv pt-2" id="CartBillingDiv">
              {totals ?
                totals.map((myTot) => {
                  return (
                    <div className='totBilling'>
                      <div className="billingHead" style={{ fontSize: '115%' }}>Total Amount :</div>
                      <div className="billingAmt" style={{ fontSize: '115%' }}> {Number(myTot[2]).toFixed(2)}</div>
                      <div className="billingHead">Total Price : </div>
                      <div className="billingAmt">
                        <span style={{ textDecoration: 'line-through' }}>{Number(myTot[1]).toFixed(2)} </span>
                      </div>
                      <div className="billingHead">Total Quantity : </div>
                      <div className="billingAmt">{Number(myTot[0]).toFixed(2)}</div>
                      <div className="billingHead" style={{ color: 'green', fontWeight: 'bold' }}>Your Savings : </div>
                      <div className="billingAmt" style={{ color: 'green', fontWeight: 'bold' }}>{Number(myTot[1] - myTot[2]).toFixed(2)}</div>

                      <div className='cartColTopBorderDotted'>
                        <Row >
                          <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                            <img width='45%' src={require('../../static/images/Icons/coupon.png')} alt="" /><b>+</b>
                          </Col>
                          <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                            <p className='prodInfo' >5% Extra discount + Free Shipping </p>
                            <p className='prodInfo' style={{ fontSize: '.6rem' }}>With membership plan <i className="fa fa-exclamation-circle"></i></p>
                          </Col>
                        </Row>
                      </div>
                      {
                        freeDeliveryAmt > myTot[2] ?
                          <>
                            <div className='cartColTopBorderDotted'>
                              <Row >
                                <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                                  <img width='50%' src={require('../../static/images/Icons/freeDelivery.png')} alt="" /><b>+</b>
                                </Col>
                                <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                                  <p className='prodInfo' >Free Shipping </p>
                                  <p className='prodInfo' style={{ fontSize: '.6rem', marginTop: '.3rem' }} >Add item worth Rs. {Number(freeDeliveryAmt - myTot[2]).toFixed(2)} for Free Shipping </p>
                                </Col>
                              </Row>
                            </div>
                            <div className='cartColTopBorderDotted'>
                              <Row >
                                <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                                  <p className='prodInfo'>Delivery Charges :</p>
                                </Col>
                                <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                                  <p className='prodInfo' style={{ textAlign: "right" }}>{freeDeliveryAmt === 400 ? "30/-" :
                                    freeDeliveryAmt === 1000 ? "60/-" : ""}</p>
                                </Col>
                              </Row>
                            </div>
                          </>
                          : ""
                      }

                      <div style={{ width: "95%" }}>
                        <Row>
                          <Col lg={9} md={9} xl={9} sm={9} xs={9}>
                            <p className='prodInfo' style={{ fontSize: '115%' }}>Amount to be paid :</p>
                          </Col>
                          <Col lg={3} md={3} xl={3} sm={3} xs={3}>
                            <p className='prodInfo' style={{ fontSize: '115%', textAlign: "right" }}>
                              {freeDeliveryAmt === 400 && parseInt(myTot[2]) < 400 ? Number(parseInt(myTot[2]) + 30).toFixed(2) :
                                freeDeliveryAmt === 1000 && parseInt(myTot[2]) < 1000 ? Number(parseInt(myTot[2]) + 60).toFixed(2) : Number(parseInt(myTot[2])).toFixed(2)}/-</p>
                          </Col>
                        </Row>
                      </div>
                    </div>)
                })
                : ''}
              <div className='cartColTopBorderDotted'>
                <Row>
                  <Col md={4} lg={4} xl={4} sm={4} xs={4}>
                    <p className='prodInfo' style={{ marginTop: '.8rem' }}>Choose Location</p>
                  </Col>
                  <Col md={1} lg={1} xl={1} sm={1} xs={1} className='px-0 mx-0'>
                    <img className='location' src={require('../../static/images/Icons/location.png')} alt="" onClick={handleLocation} />
                  </Col>
                  <Col md={7} lg={7} xl={7} sm={7} xs={7} className='ps-0 mx-0'>
                    <Select
                      options={cityOptions}
                      id="LocationCity"
                      value={filCity}
                      onChange={handleSelectCity}
                      placeholder="Select City"
                      styles={selectStyle} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={12} xl={12} sm={12} xs={12}>
                    <button className='btn btn-outline-primary btn-sm' style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }}
                      onClick={() => Checkout(totals[0], cartData)}>CHECKOUT</button>
                  </Col>
                </Row>
              </div>
            </div>
            {/* <div id='addresses'></div> */}
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  )
}

export default MyCart