import React, { useEffect, useState } from 'react'
// import { createRoot } from 'react-dom/client';
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
import Footer from '../Containers/Footer';
import { ReactSession } from 'react-client-session';
import cart from '../../SharedItems';
import { useNavigate } from "react-router-dom";

let user_session = false;

const PillsSearch = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const sertext = queryParameters.get("sertext")
  const navigate = useNavigate();
  const [searchRes, setSearchRes] = useState(null);
  
  //Method to add product in cart
  function addtoCart(ProId) {
    // headers = {'Content-type': 'application/json; charset=UTF-8'}
    
    fetch('http://43.205.199.21/selectProduct/', {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
      body: JSON.stringify({
        proId: ProId,
        userId: ReactSession.get('id_eu'),
      })
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('CartCount').classList.replace('hide','show');
        cart.value = data.cart;
        document.getElementById("CartCount").innerText = cart.value;
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  // function ListProducts(props) {  
  //   const myLists = props.myLists;  
  //   const listItems = myLists.data.map((myList) =>  
  //   <div className='topPartition'>
  //     <div className='row' style={{cursor:"pointer"}} onClick={ () => {
  //     navigate("/DisplayProduct?pro_id=" + myList[0])
  //     window.location.reload(true);
  //     }}>
  //         <div className='col-9' style={{ fontSize: '1rem' }} >{myList[1]}</div>
  //         {myList[7] === 0 ? 
  //         <div className='col-2' style={{ fontSize: '1rem' }} >Price: {myList[5]}</div>
  //         : ""}
     
  //     </div>
  //     <div className='row' style={{cursor:"pointer"}} onClick={ () => {
  //     navigate("/DisplayProduct?pro_id=" + myList[0])
  //     window.location.reload(true);
  //     }}>
  //         <div className='col-9' style={{ fontSize: '1rem' }} >{myList[2]}</div>
  //         {myList[7] === 0 ? 
  //         <>
  //         <div className='col-2' style={{ fontSize: '1rem' }} >MRP : 
  //         <span style={{ textDecoration: 'line-through' }}>{Number(myList[4]).toFixed(2)} </span>
  //         </div>
  //         </>
  //         : "Details"}
  //     </div>
  //     <div className='row'>
  //     {myList[7] === 0 ?
  //       <>
  //         <div className='col-8'>
  //         {myList[8]}
  //         </div>
  //         <div className='col-3'>
  //           <button className = "btn btn-outline-primary btn-sm toolItem smallHeight" onClick={() => addtoCart(myList[0]) }>Add to Cart</button> 
  //           <img src={require('../../static/images/Icons/favourite.png')} alt ="" className = "toolItem"/>
  //         </div>
  //       </>
  //       : 
  //       ""}    
  //     </div> 
  //   </div>  
  //   );  
  //   return (  
  //     <div className="selectProduct">  
  //         {listItems}  
  //     </div>  
  //   );  
  // }  

  
  //Call API to tetreive data
  const pickProduct = () => {
    // var ProductContainer = document.getElementById("ProductContainer");

    fetch("http://43.205.199.21/selectProduct?sertext=" + sertext, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; multipart/form-data; charset=UTF-8' },
    }).then((response) => {
      return response.json();
    }).then((res) => {
      setSearchRes(res.data);
      // createRoot(ProductContainer).render(<ListProducts myLists={res} />);
    });
  }

  useEffect(() => {
    pickProduct();
  }, []);

  return (
    <>
      <Logo />
      <NavSearchBar sess={user_session} />
      <>
        <p className="MyHeader">Select Products</p>
        <hr width="80%" size="8" className="MyDivider" style={{ marginLeft: '10%' }} />
        <div className="ProductContainer divshadow" id="ProductContainer">
          {
            searchRes ?
            searchRes.map((myList) =>  
            <div className='topPartition'>
              <div className='row' style={{cursor:"pointer"}} onClick={ () => {
              navigate("/DisplayProduct?pro_id=" + myList[0])
              window.location.reload(true);
              }}>
                  <div className='col-9' style={{ fontSize: '1rem' }} >{myList[1]}</div>
                  {myList[7] === 0 ? 
                  <div className='col-2' style={{ fontSize: '1rem' }} >Price: {myList[5]}</div>
                  : ""}
             
              </div>
              <div className='row' style={{cursor:"pointer"}} onClick={ () => {
              navigate("/DisplayProduct?pro_id=" + myList[0]);
              // window.location.reload(true);
              }}>
                  <div className='col-9' style={{ fontSize: '1rem' }} >{myList[2]}</div>
                  {myList[7] === 0 ? 
                  <>
                  <div className='col-2' style={{ fontSize: '1rem' }} >MRP : 
                  <span style={{ textDecoration: 'line-through' }}>{Number(myList[4]).toFixed(2)} </span>
                  </div>
                  </>
                  : "Details"}
              </div>
              <div className='row'>
              {myList[7] === 0 ?
                <>
                  <div className='col-8'>
                  {myList[8]}
                  </div>
                  <div className='col-3'>
                    <button className = "btn btn-outline-primary btn-sm toolItem smallHeight" onClick={() => addtoCart(myList[0]) }>Add to Cart</button> 
                    <img src={require('../../static/images/Icons/favourite.png')} alt ="" className = "toolItem"/>
                  </div>
                </>
                : 
                ""}    
              </div> 
            </div>  
            )
            :''
          }
        </div>
      </>
      <Footer />
    </>)
}

export default PillsSearch