import React, { useEffect } from 'react'
import DigiPartNavBar from '../DigitalPartner/DigiPartnerContainers/DigiPartNavBar';
import DigiPartFooter from '../DigitalPartner/DigiPartnerContainers/DigiPartFooter';

const Distributors = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <DigiPartNavBar />
      <div className="aboutUsImg" style={{ minHeight: "400px" }}>
        <img src={require("../../static/DPImages/Distributor.png")} alt="" />
        <div className="center" style={{ overflow: "hidden" }}>
          <h1 data-aos="fade-up" data-aos-delay="400">Distributor</h1>
        </div>
      </div>

      <div className="aboutUs">

        <ol type='1'>
          <li><b>Vast Product Selection</b> <br />
            Gain access to a catalog of over 2 million products online, offering a diverse range of items to meet the needs of various industries
            and customer segments. This extensive product range ensures that partners have ample options to fulfill customer demands and
            stay competitive in the market.</li>

          <li><b>High Daily Order Volume</b> <br />
            Benefit from a high volume of daily active orders, indicating strong demand and market traction for the products offered by the
            distributor. Partners can capitalize on this steady flow of orders to generate consistent revenue and expand their customer base.</li>

          <li><b>Efficient Order Fulfillment</b> <br />
            Distributors prioritize efficient order fulfillment processes, ensuring that orders are processed and shipped promptly to meet
            customer expectations for timely delivery. This commitment to speed and reliability helps partners maintain high levels of customer
            satisfaction and loyalty.</li>

          <li><b>Quality Assurance</b> <br />
            Distributors uphold strict quality standards for the products they offer, sourcing goods from reputable manufacturers and conducting
            rigorous quality control checks to ensure product integrity and customer safety. Partners can trust in the reliability and consistency of
            the products supplied by the distributor.</li>

          <li><b>Competitive Pricing</b> <br /> Access competitive pricing and favorable terms as a partner, enabling partners to maximize margins and remain price-competitive in
            the market. Distributors may offer bulk discounts, promotional deals, or special pricing arrangements to incentivize partnership and
            foster mutually beneficial relationships.</li>

          <li><b>Dedicated Support</b> <br />
            Receive dedicated support from the distributor&#39;s team, including account managers, sales representatives, and customer service
            professionals who are committed to providing personalized assistance and addressing partners&#39; needs. This proactive support
            enhances the partner experience and fosters strong partnerships built on trust and collaboration.</li>

          <li><b>Streamlined Logistics</b> <br />
            Leverage the distributor&#39;s efficient logistics network and infrastructure to streamline order processing, inventory management, and
            shipping operations. Partners can benefit from economies of scale and logistical expertise to optimize supply chain efficiency and
            reduce operational costs.</li>

          <li><b>Market Insights and Trends</b> <br />
            Stay informed about market insights, trends, and consumer preferences through regular updates and communication from the
            distributor. Partners can leverage this information to make informed business decisions, adapt their product offerings, and capitalize
            on emerging opportunities in the market.</li>

          <li><b>Marketing and Promotional Support</b> <br />
            Access marketing collateral, promotional materials, and co-marketing opportunities provided by the distributor to drive visibility and
            demand for the products. Partners can leverage these resources to enhance brand awareness, attract new customers, and
            stimulate sales growth.</li>

          <li><b>Long-Term Growth Opportunities</b> <br />
            Partnering with a reputable distributor opens doors to long-term growth opportunities and expansion possibilities. By tapping into the
            distributor&#39;s resources, networks, and expertise, partners can accelerate their business growth trajectory and achieve sustainable
            success in their respective markets.</li>

        </ol>
      </div>

      <DigiPartFooter />
    </>
  )
}

export default Distributors