import React, { useEffect, useState } from "react";
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
import Footer from '../Containers/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import presImages from '../../SharedItems';

const PrescriptionOrder = () => {

  const navigate =  useNavigate();

  // const [orderType, setOrderType] = useState("all");
  var oType = "all";
  function SetOrder(typ){
    oType = typ;
    // console.log("Type :",oType);
  }

  function handleContinue(){
    var Tests = document.getElementById('labtest').checked ? "1" : "0" ;
    navigate('/ShippingAdd?orderType='+oType+'&test='+Tests);
  }

  const PopulatePres = () =>{
    let presDiv = document.getElementById('uploadedPres');
    presDiv.innerHTML = "";
    let file;

    for (let i = 0; i < presImages.length; i++) {
      let reader = new FileReader();
      file = presImages[i];
      reader.onload = (file) => {
        presDiv.innerHTML += "<img src="+reader.result+" alt='' />";
      };
      reader.readAsDataURL(file);
    }
  }

  useEffect(() => {
    PopulatePres();
    // setOrderType(null);
  }, []);
  
  return (
  <>
  <Logo />
  <NavSearchBar />
  <Row className="presOrders">
    <Col lg={7} md={7} xl={7} sm={12} xs={12}>
      Medicines
      <div className="presOrderSection">
        <input name="ordertype" type="radio" id="all" defaultChecked onClick={() => {SetOrder("all")}} style={{marginTop:'.3rem'}}/>&nbsp; 
        <label for='all' id='fileLabel' style={{ cursor: 'pointer'}}>
        All Medicines as per prescriptions
        </label>
      </div>
      <div className="presOrderSection">
        <input name="ordertype" type="radio" id="cart" onClick={() => {SetOrder("cart")}} style={{marginTop:'.3rem'}}/>&nbsp; 
        <label for='cart' id='fileLabel' style={{ cursor: 'pointer'}}>
        Medicines as per cart 
        </label>
      </div>
      <div className="presOrderSection">
        <input type="radio" name="ordertype" id="notify" onClick={() => {SetOrder("notify")}} style={{marginTop:'.3rem'}}/>&nbsp; 
        <label for='notify' id='fileLabel' style={{ cursor: 'pointer'}}>
        Confirm on call or by notification 
        </label> <br />
        <span style={{fontSize:'80%'}}>
          We will call you within 30 mins to confirm medicines (8:00 am to 8:00 pm)
        </span> 
      </div>
      <div className="presOrderSection">
        <input name="labtest" type="checkbox" id="labtest" style={{marginTop:'.3rem'}}/>&nbsp; 
        <label for='labtest' id='fileLabel' style={{ cursor: 'pointer'}}>
        Includes Medical Test's 
        </label>
      </div>
     
    </Col>
    <Col lg={5} md={5} xl={5} sm={12} xs={12}>
      <div className="uploadedPres customScroll" id="uploadedPres">

      </div>
    </Col>  
  </Row>
    <Row>
      <Col lg={12} md={12} xl={12} sm={12} xs={12}>
        <p className="contButton" onClick={()=>{handleContinue()}}>Continue</p>
      </Col>
    </Row>
  <Footer />
  </>
  )
}

export default PrescriptionOrder