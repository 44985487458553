import React, { useEffect } from "react";
import Logo from '../Containers/Logo';
import NavSearchBar from '../Containers/NavSearchBar';
import Footer from '../Containers/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import presImages from '../../SharedItems';

const UploadPrescription = () => {
  
  const queryParameters = new URLSearchParams(window.location.search);
  // const presReq = queryParameters.get("presReq",null);
  const selectedCity = queryParameters.get("custCity", null);
  const navigate = useNavigate();
  
  
  // TABLE `prescriptions`(, PrescriptionID, UserID, order_id, ImageIndex, orderNo, description, order_description
  // Status, cancellation_reason_prs, order_come_from, created_at, updated_at ) 

  const changeHandler = (event) => {
  // const file = event.target.files[0];
  presImages.push(event.target.files[0]);
  // if (file.size > 524288)
  //   alert("File size cannot exceed more than 500 KB");
  // else {
    var reader = new FileReader();
    reader.onload = function () {
    document.getElementById('uploadedPresDummy').classList.replace('show','hide');        
    document.getElementById('ConPresBtn').style.visibility='visible';
    var uploadedPres = document.getElementById('uploadedPres');
    uploadedPres.classList.replace('hide', 'show');
    uploadedPres.innerHTML += "<img src="+reader.result+" alt='' />";
    };
    reader.readAsDataURL(event.target.files[0]);
  // }      
  };

  
  function handlePresContinue(){
    if (selectedCity == null)
    navigate('/PrescriptionOrder')
    else
    navigate('/ShippingAdd?custCity='+selectedCity)
  }

  useEffect(() => {
  presImages.value = {};
  }, []);

  return (
  <>
    <Logo />
    <NavSearchBar />
    <div className="UploadMain">
    <h6>Home&#8680;Upload Prescription</h6>
    <Row className="uploadContainer divshadow">
      <Col lg={6} md={6} xl={6} sm={12} xs={12} className="UploadColumn">
      <Row>
        <Col lg={12} md={12} xl={12} sm={12} xs={12}>
        Upload Prescription
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} xl={12} sm={12} xs={12} className="topPartition">
        <Row>
          <Col lg={11} md={11} xl={11} sm={11} xs={11} className="UploadButton">
          <label for='buttonfile' id='fileLabel' style={{ cursor: 'pointer', display:'flex'}}>
            <img src={require('../../static/images/Icons/NewUpload.png')} alt="" />
            <h6 style={{marginTop:'.75rem', marginLeft:'1rem'}}>Click to Add Prescription</h6>
          </label>
          </Col>
          <Col lg={1} md={1} xl={1} sm={1} xs={1} >
          <input accept="image/*" id="buttonfile" type="file" capture="environment" onChange={changeHandler}/><br /><br />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} xl={12} sm={12} xs={12} className="UploadButton">
          <img src={require('../../static/images/Icons/SavedUpload.png')} alt="" />
          <h6>Your Saved Prescriptions</h6>
          </Col>
        </Row>
        </Col>
      </Row>
      <Row>
        <br />
        <Col lg={12} md={12} xl={12} sm={12} xs={12}>
        <h6>Attached Prescription</h6>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} xl={12} sm={12} xs={12} className="topPartition">
        <div className="NewUploadPres show" id='uploadedPresDummy'>
          <img src={require('../../static/images/Icons/YourUpload.png')} alt="" />
          <h6>Your uploaded prescription will be shown here</h6>
        </div>
        <div className="NewUploadPres hide" id='uploadedPres'></div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} xl={12} sm={12} xs={12} className="topPartition PresRules">
        <h6>Government regulations requires a valid prescription</h6>
        <p>Don't crop out and part of the image</p>
        <p>Avoid blured image</p>
        <p>includes details of doctor and patient. Clinic visit date</p>
        <p>Medicines will be dispensed as per prescription</p>
        <p>Supported files here .jpeg, .png and .pdf</p>
        <br />
        <h6>Upload prescription to Continue</h6>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} xl={12} sm={12} xs={12} className="UploadPresButton"
         id="ConPresBtn" onClick={()=>handlePresContinue()} >
        <h6>Continue</h6>
        </Col>
      </Row>
      </Col>

      <Col lg={6} md={6} xl={6} sm={12} xs={12} className="UploadColumn LeftBorder">
      <h6>Valid Prescription Guide</h6>
      <div className="SampleUpload topPartition">
        <img src={require('../../static/images/Icons/SampleUpload.png')} alt="" />
      </div>
      </Col>
    </Row>
    </div>
    <Footer />
  </>
  );
};

export default UploadPrescription