import React, { useState, useEffect } from 'react'
import DashPanel from '../../DashContainers/DashPanel'
import DashNavbar from '../../DashContainers/DashNavbar'
import { IoMdClose } from 'react-icons/io'

import defProdImage from '../../../static/images/Banner3.jpeg';


const ManageSlider = ({ type }) => {

  // Modals
  const [isAddSliderModalShow, setIsAddSliderModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(null);

  // UseStates
  const [allSliders, setAllSliders] = useState(null);
  const [sliderImages, setSliderImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAllSliders = () =>{
    fetch("http://43.205.199.21/manageSliderAPI?getSliders=0&caller=sheetAdminERP&adminPage="+type, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' },
    }).then((respo) => {
      return respo.json()
    }).then((res) => {
      if (res.status === "success") {
        setAllSliders(res.allSliders);
        setSliderImages(res.sliderImages);
      }
    }).catch((error)=>{
      console.log("Error :",error);
    });
  }

  const [selectedFile, setSelectedFile] = useState(null);
  const changeHandler = (event) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('preview');
      output.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };


  const AddSlider = () =>{
    if (selectedFile == null)
    {
      alert("PLease select a banner image..!");
    }
    else{
      let formData = new FormData();
      formData.append('File', selectedFile);
      formData.append('type', String(type));
      formData.append('adminKey', "adminKey");

      setIsLoading(true);
      fetch("http://43.205.199.21/manageSliderAPI/", {
        method:'POST',
        body: formData,
      }).then((response) => {
        return response.json();
      }).then((resp) => {
        if (resp.status === "success") {
          alert("Slider added succesfully");
          getAllSliders();
          setSelectedFile(null);
          setIsAddSliderModalShow(false);
        }
        else
        {
          alert("Error in adding banner image");
        }
        setIsLoading(false);
      }).catch((error)=>{
        setIsLoading(false);
        console.log("Error :",error);
      });
    }
  }

  const deleteSlider = (sID) =>{
    // sliderId adminKey
    var confDel = prompt("Enter admin password for confirm deletion :");
    if (confDel === 'secret') {
      setIsLoading(true);
      fetch('http://43.205.199.21/manageSliderAPI/', {
        mode: 'cors',
        method: 'DELETE',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          sliderId: sID,
          adminKey:"adminKey",
        })
      }).then((response) => response.json())
      .then((data) => {
        if (data.status === "success")
          alert("Slider image deleted...!");
        else
          alert("Error in deleting slider...!");

        setIsLoading(false);
        getAllSliders();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Deletion request denied..!")
  }

  const updateSlider = (sID) => {
    var confDel = prompt("Enter admin password :");
    if (confDel === 'secret') {
      setIsLoading(true);
      fetch('http://43.205.199.21/manageSliderAPI/', {
        mode: 'cors',
        method: 'PUT',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({
          sliderId: sID,
          adminKey: "adminKey",
          isActive: document.getElementById("isActive").checked ? "1" : "0",
        })
      }).then((response) => response.json())
      .then((data) => {
        if (data.status === "success")
          alert("Slider image updated...!");
        else
          alert("Error in updating slider...!");

        setIsEdit(null);
        setIsLoading(false);
        getAllSliders();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.message);
        });
    }
    else
      alert("Invalid Admin password, Updation request denied..!")
  }

  useEffect(()=>{
    getAllSliders();
  }, []);


  return (
    <>
      <DashNavbar />
      <div className='dashContainer'>
        <div className='dashPanel customScroll'>
          <DashPanel />
        </div>
        <div className='dashContents manageSlider' id='dashContents' style={{ padding: "0px 20px" }}>

          <div style={{ padding: "10px 0px", display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: "10px", borderBottom: "1.5px solid #000" }}>
            <h3 style={{ margin: 0, padding: 0 }}>Manage <span style={{ textTransform: "uppercase" }}>{type}</span> Slider</h3>
            <button onClick={()=>{setIsAddSliderModalShow(true)}} className='btnBlue' style={{ width: "160px" }}>Add New Slider</button>
          </div>

          <div className="sliderImages customScroll" style={{ height: "70vh", overflowX: "hidden", overflowY: "auto" }}>
            <div className="head" style={{ display: "flex", alignItems: "center" }}>
              <p style={{ margin: 0, padding: 0, color: "gray", fontSize: 14, marginTop: 10, marginBottom: 10, width: "50%" }}>Slider Images</p>
              <p style={{ margin: 0, padding: 0, color: "gray", fontSize: 14, marginTop: 10, marginBottom: 10, width: "25%", display: "flex", alignItems: "center", justifyContent: "center" }}>Status</p>
              <p style={{ margin: 0, padding: 0, color: "gray", fontSize: 14, marginTop: 10, marginBottom: 10, width: "25%", display: "flex", alignItems: "center", justifyContent: "center" }}>Action</p>
            </div>
            {
              allSliders && allSliders !== null ?
              allSliders.map((slider)=>{
                var base64Image = sliderImages[slider[1]];
                return (
                  <div className="sliderImage" style={{ width: "100%", display: "flex", alignItems: "center", marginBottom: 20 }}>

                    <div className="image" style={{ width: "50%", height: "30vh" }}>
                      <img style={{ width: "70%", height: "100%", objectFit: "cover" }} 
                      src= {`data:image/jpeg;base64,${base64Image}`} onError={(e) => { e.target.src = defProdImage }} alt="" />
                    </div>

                    <div style={{ width: "25%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      { parseInt(slider[3]) == '1' ? 
                        <button style={{ padding: "5px 20px", background: "#269616", color: "#fff", border: 0, borderRadius: 5, cursor: "pointer" }}>Active</button>
                      : 
                        <button style={{ padding: "5px 20px", background: "#4299fd", color: "#fff", border: 0, borderRadius: 5, cursor: "pointer" }}>In Active</button>
                      } 
                    </div>

                    <div style={{ width: "25%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <img style={{ width: "30px", height: "30px", cursor: "pointer" }} src={require("../../../static/images/edit.png")} 
                      onClick={()=>{setIsEdit(slider)}} alt="" />
                      <img style={{ width: "30px", height: "30px", marginLeft: 10, cursor: "pointer" }}
                      onClick={()=>{deleteSlider(slider[0])}} src={require("../../../static/images/trash.png")} alt="" />
                    </div>

                  </div>
                )
              })
              :
              <div>
                Sliders are Empty....!
              </div>
            }

          </div>
        </div>
      </div>
      
      {
        isAddSliderModalShow ?
          <div className="addNewSlideModalCon modalCon" style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0, height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 1000, background: "rgba(0,0,0,0.5)" }}>
            
            { 
              isLoading ? 
              <div style={{position:'absolute', width:'100%', height:'100%', top:'0', left:'0', zIndex:'9999999999', backgroundColor:'rgba(157, 157, 157, 0.5)'}}>
                <img style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -70%)', width:100}} src={require('../../../static/Loader/Spinner2.png')} alt=""/>
              </div>
              : null
            }

            <div style={{ width: "100%", maxWidth: "500px", background: "#fff", padding: 20, borderRadius: 10 }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 10, borderBottom: "1px solid #000" }}>
                <h3 style={{ margin: 0, padding: 0, fontSize: 23 }}>Add New Slider</h3>
                <i onClick={()=>{setIsAddSliderModalShow(false)}} style={{ cursor: "pointer", fontSize: 25, margin: 0, padding: 0 }}><IoMdClose /></i>
              </div>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20, flexDirection: "column" }}>
                <input style={{ background: "#949494", padding: 10, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 5, marginBottom: 10, color: "#fff" }} 
                accept=".jpg, .jpeg, .png" type="file" placeholder='Slider Title' onChange={changeHandler}/>

                <img id="preview" src = "" alt="" />

                <p style={{ margin: 0, padding: 0, fontSize: 14, marginTop: 10 }}>Recommended File Type: PNG, JPG, JPEG</p>
                <p style={{ margin: 0, padding: 0, fontSize: 14, marginTop: 10 }}><span style={{ color: "red" }}>Note : </span>Slider's Image Pixel Size : 1200x400</p>

                <button className="btnBlue mt-4" style={{ width: "40%" }} onClick={()=>{AddSlider()}}>Add Slider</button>
              </div>
            </div>
          </div>
          : null
      }

      {
        isEdit && isEdit !== null ?
          <div className="addNewSlideModalCon modalCon" style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0, height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 1000, background: "rgba(0,0,0,0.5)" }}>
            
            { 
              isLoading ? 
              <div style={{position:'absolute', width:'100%', height:'100%', top:'0', left:'0', zIndex:'9999999999', backgroundColor:'rgba(157, 157, 157, 0.5)'}}>
                <img style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -70%)', width:100}} src={require('../../../static/Loader/Spinner2.png')} alt=""/>
              </div>
              : null
            }

            <div style={{ width: "100%", maxWidth: "500px", background: "#fff", padding: 20, borderRadius: 10 }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 10, borderBottom: "1px solid #000" }}>
                <h3 style={{ margin: 0, padding: 0, fontSize: 23 }}>Update slider image</h3>
                <i onClick={()=>{setIsEdit(null)}} style={{ cursor: "pointer", fontSize: 25, margin: 0, padding: 0 }}><IoMdClose /></i>
              </div>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20, flexDirection: "column" }}>
                <img id="preview" style={{width:'30vw', height:'10vw'}} src = {`data:image/jpeg;base64,${sliderImages[isEdit[1]]}`}  alt="" />
                <br />
                <div style={{width:'10vw', display:'flex', justifyContent:'space-around', alignItems:'center'}}>
                  <input type="checkbox" id="isActive" defaultChecked = { parseInt(isEdit[3]) === 1 ? true : false}/>
                  <label htmlFor='isActive'>Is Active</label>
                </div>  
                <button className="btnBlue mt-4" style={{ width: "40%" }} onClick={()=>{updateSlider(isEdit[0])}}>Save</button>
              </div>
            </div>
          </div>
          : null
      }

    </>
  )
}

export default ManageSlider