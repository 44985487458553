import React from 'react'

const Footer = () => {

    function revealFooter() {
        var reveals = document.querySelectorAll(".revealFooter");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 5;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
      
      window.addEventListener("scroll", revealFooter);
    
  return (
    <>
    <div className='row footer revealFooter mt-2 pt-2 topPartition'>
        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
            <h4>Pills <font color='blue'>it</font></h4>
            <p>Designed and built with all the love in the world by the Sheet360ERP team with help of our love in the world by the love in world by contributers</p>
            <br />
            <p>Code licensed by CODE-SOFT BPL. docs CC By 1.0 Currently v1.1</p>
            <br/>
            <p>Download the Sheet360ERP App</p>
            <div className = "d-inline-flex" >
                <div className='downloadStore'>
                        <img src={require('../../static/images/Footer/playstore.png')} alt ='..' />
                        <div style={{display:"inline"}}>
                            <p>GET IT ON <br/><div className='downloadBText'>Google Play</div></p>
                        </div>
                </div> <p>&nbsp;&nbsp;</p>
                <div className='downloadStore'>
                        <img src={require('../../static/images/Footer/apple-logo.png')} alt ='..' />
                        <div style={{display:"inline"}}>
                            <p>Download on the<br /> <div className='downloadBText'>App Store</div></p>
                        </div>
                </div>
            </div>
            
        </div>
        <div style = {{textAlign:"center"}} className="border border-2 border-top-0 border-bottom-0 col-6 col-sm-6 col-md-3 col-lg-3">
            <h4>Links</h4>
            <p>Home</p>
            <p>Documents</p>
            <p>Example</p>
            <p>Icons</p>
            <p>Themes</p>
            <p>Blog</p>
            <p>Swag Store</p>
        </div>
        <div style = {{textAlign:"center"}} className="border border-2 border-top-0 border-bottom-0 border-start-0 col-6 col-sm-6 col-md-3 col-lg-3">
            <h4>Social Networks</h4>
            <p>Facebook</p>
            <p>Instagram</p>
            <p>Linkedin</p>
            <p>Twitter</p>
            <p>YouTube</p>
            <p>Health News</p>
        </div>
        <div style = {{textAlign:"center"}} className="col-6 col-sm-6 col-md-3 col-lg-3">
            <h4>Services</h4>
            <p>About Us</p>
            <p>FAQ</p>
            <p>Contact Us</p>
            <p>Privacy Policy</p>
            <p>Terms & Condition</p>
            <p>Business Partnership</p>
        </div>
        <div className='row pt-4 text-center'>
        <div >
            <p>Copyright 2023 &copy; www.sheet360erp.com | All rights reserved</p>
            <br/>
        </div>
    </div>
    
    </div>
    </>
  )
}

export default Footer